import * as Joi from "joi";
import i18n from "i18n";

export const APP_VERSION_SCHEMA = Joi.object({
    platform: Joi
        .string()
        .label(i18n.t("Platform"))
        .valid('IOS', 'ANDROID')
        .required()
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    version: Joi
        .string()
        .pattern(/^\d\.\d\.\d$/)
        .label(i18n.t("Version"))
        .required()
        .messages({
            "string.pattern.base": `{{#label}} ${i18n.t("validationErrors.invalidVersionNumber")}`,
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    changeLog: Joi
        .string()
        .label(i18n.t("Change Log"))
        .required()
        .empty('')
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        })
})

export const MIN_SUPPORTED_VERSION_EDIT_SCHEMA = Joi.object({
    ios: Joi
        .string()
        .label(i18n.t("iOS"))
        .required()
        .empty('')
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
        })
    ,
    android: Joi
        .string()
        .label(i18n.t("Android"))
        .required()
        .empty('')
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
        })
});