import { LoadingButton } from "@mui/lab";
import { Card, CardActions, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import { useUpdateExpiryAtMutation } from "api/settingsApi";
import { TextFieldNumber } from "modules/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function LanguagesCard({ currentExpiryAt, refetchSettings, allowUpdate }) {
    const { t } = useTranslation()
    const [updateExpiryAt, { isLoading, isSuccess, error, reset }] = useUpdateExpiryAtMutation()
    const [expiryAt, setExpiryAt] = useState('')

    useEffect(() => {
        if (!!currentExpiryAt) {
            setExpiryAt(Number(currentExpiryAt))
        }
    }, [currentExpiryAt])

    const onConfirmExpiryAt = async () => {
        await updateExpiryAt({ expiryAt: Number(expiryAt) })
        refetchSettings()
        await new Promise(resolve => setTimeout(resolve, 2000));
        reset()
    }

    return (
        <Card>
            <CardHeader title={t("Assign transaction expiration time")} />
            <Divider />
            <CardContent>
                <TextFieldNumber
                    name="expiryAt"
                    value={expiryAt}
                    onChange={({ target: { value } }) => setExpiryAt(value)}
                    variant="outlined"
                    size="small"
                    label={t("The number of hours")}
                    fullWidth
                    disabled={!allowUpdate}
                />
            </CardContent>
            {
                allowUpdate
                    ?
                    <>
                        <Divider />
                        <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                            <LoadingButton
                                loading={isLoading}
                                disabled={!expiryAt || expiryAt === currentExpiryAt}
                                variant="contained"
                                onClick={onConfirmExpiryAt}
                                sx={{ textTransform: "none" }}
                            >
                                {t("Submit")}
                            </LoadingButton>
                            {!!error && <Typography color="error">{t(error)}</Typography>}
                            {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
                        </CardActions>
                    </>
                    : null
            }
        </Card>
    )
}