import { apiSlice } from "./apiSlice";

export const myFatoorahApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        initiateSession: builder.mutation({
            query: (payload) => ({
                url: `/transaction/initiate-payment`,
                method: 'POST',
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        executePayment: builder.mutation({
            query: (payload) => ({
                url: `/transaction/execute-payment`,
                method: 'POST',
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getPaymentStatus: builder.mutation({
            query: (payload) => ({
                url: `/transaction/payment-status`,
                method: 'POST',
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useInitiateSessionMutation,
    useExecutePaymentMutation,
    useGetPaymentStatusMutation
} = myFatoorahApi