import * as Joi from "joi";
import i18n from "i18n";
import parsePhoneNumber from 'libphonenumber-js';
import { joiString } from "utils/joi";

export const USER_INFO_SCHEMA = Joi.object({
    userName: joiString.string()
        .required()
        .empty('')
        .label(i18n.t("Name"))
    ,
    email: joiString.string()
        .email({ tlds: { allow: false } })
        .required()
        .label(i18n.t("Email")),
    phone: joiString.string()
        .required()
        .label(i18n.t("Phone"))
        .custom((value, helper) => {
            const parsedPhone = parsePhoneNumber(value, { extract: true });
            if (!parsedPhone || !parsedPhone.isValid())
                return helper.message(i18n.t("validationErrors.isValidPhone"));
            return true
        }),
});

export const USER_CHANGE_PASSWORD_SCHEMA = Joi.object({
    password: joiString.string()
        .min(6)
        .label(i18n.t("New Password")),
    confirmPassword: joiString.string()
        .min(6)
        .valid(Joi.ref('password'))
        .label(i18n.t("Confirm Password")),
    currentPassword: joiString.string()
        .min(6)
        .label(i18n.t("Current Password")),
});

export const THIRD_PARTY_SCHEMA = Joi.object({
    name: joiString.string()
        .required()
        .empty('')
        .label(i18n.t("Name")),
    webhookUrl: joiString.string()
        .required()
        .empty('')
        .label(i18n.t("Webhook Url")),
    redirectUrl: joiString.string()
        .required()
        .empty('')
        .label(i18n.t("Redirect Url")),
});
