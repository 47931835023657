import { apiSlice } from "./apiSlice";

export const businessApiSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['BUSINESS'] })
    .injectEndpoints({
        endpoints: builder => ({
            getBusinesses: builder.query({
                query: (params) => ({
                    url: '/business',
                    method: 'GET',
                    params
                }),
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getBusinessProfile: builder.query({
                query: () => ({
                    url: '/business/get-my-business-profile',
                    method: 'GET',
                }),
                transformResponse: (response, meta, arg) => response?.body,
                providesTags: ['BUSINESS'],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateBusinessProfile: builder.mutation({
                query: (payload) => ({
                    url: '/business/update-my-business-profile',
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ['BUSINESS'],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            completeBussinesProfle: builder.mutation({
                query: (payload) => ({
                    url: '/business/complete-business-profile',
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ['BUSINESS'],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
        })
    })

export const {
    useGetBusinessProfileQuery,
    useUpdateBusinessProfileMutation,
    useCompleteBussinesProfleMutation,
    useGetBusinessesQuery
} = businessApiSlice