import { createSlice } from "@reduxjs/toolkit"
import { disconnectSocket } from "socket"

const authSlice = createSlice({
    name: 'auth',
    initialState: { profile: null, isAuth: false },
    reducers: {
        setCredentials: (state, action) => {
            const payload = action.payload
            if (!!payload) {
                state.profile = payload
            }
            if (!!payload?.token) {
                localStorage.setItem("token", payload?.token)
                state.isAuth = true
            }

        },
        setProfile: (state, action) => {
            state.profile = action.payload
        },
        setToken: (state, action) => {
            const token = action.payload
            if (!!token) {
                localStorage.setItem("token", token)
                state.isAuth = true
            }
        },
        setIsAuth: (state, action) => {
            state.isAuth = action.payload
        },
        logOut: (state, action) => {
            state.profile = null
            state.isAuth = false
            localStorage.removeItem("token")
            disconnectSocket()
        }
    },
})

export const { setCredentials, setProfile, setToken, setIsAuth, logOut } = authSlice.actions

export default authSlice.reducer
