import { Box, Chip, Grid, Typography } from "@mui/material";
import { status_colors } from "constants";
import { PaymentMethodIcon } from "modules/common";
import { useTranslation } from "react-i18next";

export default function PaymentInfo({ transaction }) {

    const { t } = useTranslation();

    return (
        <Grid container spacing={20} sx={{ my: 10 }}>
            <Grid item xs={12} sm={12} md={6}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Status")}</Typography>
                    <Chip
                        label={t(transaction?.currentStatus)}
                        sx={{ color: "white" }}
                        color={status_colors[transaction?.currentStatus]}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Box display="flex" alignItems="center">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Payment Method")}</Typography>
                    <PaymentMethodIcon method={transaction?.source?.paymentMethod} />
                </Box>
            </Grid>
            <Grid item xs={5} md={6}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Amount")}</Typography>
                    <Typography>{transaction?.amount?.toFixed(2)}</Typography>
                </Box>
            </Grid>
            <Grid item xs={5} md={6}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Net of the transaction")}</Typography>
                    <Typography>{(Number(transaction?.amount) - Number(transaction?.ezpayFees) - Number(transaction?.paymentGatewayFees))?.toFixed(2)}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Ezpay fees includes tax")}</Typography>
                    <Typography>{transaction?.ezpayFees?.toFixed(2)}</Typography>
                </Box>
            </Grid>
            <Grid item xs={5} md={6}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Payment gateway fees includes tax")}</Typography>
                    <Typography>{transaction?.paymentGatewayFees?.toFixed(2)}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Provider Invoice ID")}</Typography>
                    <Typography>{transaction?.providerInvoiceId}</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}