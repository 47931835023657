import { LoadingButton } from "@mui/lab";
import { Box, Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { useGetUserInvoicesQuery, useLazyGetInvoiceExcelQuery, useSettleMerchantBalanceMutation } from "api/invoiceApi";
import { ConfirmationDialog, DownloadExcleDialog } from "modules/common";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function CurrentInvoiceCard({ id, allowSettleBalance }) {
    const { t } = useTranslation()
    const { data: invoice, refetch } = useGetUserInvoicesQuery({ id, params: { type: "CURRENT" } })
    const [settleBalance, { isLoading: settleBalanceLoading, isSucess: settleBalanceIsSuccess, error: settleBalanceError }] = useSettleMerchantBalanceMutation()
    const [getInvoiceExcel,
        { isFetching: invoiceExcelLoading, isSuccess: invoiceExcelIsSuccess, error: invoiceExcelError }
    ] = useLazyGetInvoiceExcelQuery()

    const settleBalanceHandler = async () => {
        setOpenConfirmDialog(false)
        await settleBalance(id)
        refetch()
    }
    const invoiceExcelHandler = async (payload) => {
        if (!!payload) {
            await getInvoiceExcel({ invoiceId: invoice?.id, payload })
        } else {
            await getInvoiceExcel({ invoiceId: invoice?.id })
        }
        // refetch()
    }

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [openExcelDialog, setOpenExcelDialog] = useState(false)

    const closeExcelDialogHanlder = () => {
        setOpenExcelDialog(false)
    }

    return (
        <>
            <ConfirmationDialog
                title="Warning"
                open={openConfirmDialog}
                confirmFn={settleBalanceHandler}
                onClose={() => setOpenConfirmDialog(false)}
                content={"Are you sure you want to settle the balance?"}
            />
            <DownloadExcleDialog
                open={openExcelDialog}
                loading={invoiceExcelLoading}
                isSuccess={invoiceExcelIsSuccess}
                error={invoiceExcelError}
                onSend={invoiceExcelHandler}
                onClose={closeExcelDialogHanlder}
            />
            <Card>
                <CardHeader title={t("Current Invoice")} />
                <Divider />
                <CardContent>
                    <Grid container spacing={20}>
                        <Grid item md={6} xs={6}>
                            <Box>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {t("Total amount")}
                                </Typography>
                                <Typography>
                                    {invoice?.docs[0]?.totalAmount}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Box>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {t("Total fees")}
                                </Typography>
                                <Typography>
                                    {invoice?.docs[0]?.ezpayFees}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Box>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {t("Net amount")}
                                </Typography>
                                <Typography>
                                    {invoice?.docs[0]?.netAmount}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Box>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {t("Settle balance date")}
                                </Typography>
                                <Typography>
                                    {invoice?.docs[0]?.settleBalanceDate ?
                                        moment(invoice?.docs[0]?.settleBalanceDate).format("DD/MM/YY - h:mm A")
                                        : '----'
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                    <Box display="flex" alignItems="center">
                        {
                            allowSettleBalance
                                ?
                                <LoadingButton
                                    disabled={!invoice?.docs[0]?.totalAmount}
                                    sx={{ textTransform: "none", mr: 10 }}
                                    variant="contained"
                                    onClick={() => setOpenConfirmDialog(true)}
                                    loading={settleBalanceLoading || invoiceExcelLoading}
                                >
                                    {t("Settled Balance")}
                                </LoadingButton>
                                : null
                        }
                        <LoadingButton
                            disabled={!invoice?.docs[0]?.settleBalanceDate}
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            onClick={() => setOpenExcelDialog(true)}
                        >
                            {t("Download Balance Report")}
                        </LoadingButton>
                    </Box>
                    {!!settleBalanceError && <Typography color="error">{t(settleBalanceError)}</Typography>}
                    {!!settleBalanceIsSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
                </CardActions>
            </Card>
        </>
    )
}