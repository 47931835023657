import { useEffect, useMemo, useState } from "react";

import { Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField, Typography, Box, Autocomplete, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { cloneDeep, get, includes, isEmpty, omitBy, } from "lodash";
import InfoIcon from '@mui/icons-material/Info';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { useTranslation } from "react-i18next";
import runJoiValidate from "utils/runJoiValidate";
import { USER_BANK_ACCOUNT_SCHEMA } from "./ValidationSchemas";
import { useGetBanksQuery } from "api/banksAPI";
import { ACCOUNT_STATUS } from "constants";
// import { useGetSettingsQuery } from 'api/settingsApi'
import { useSelector } from "react-redux";
import { ROLES } from "constants";

export default function AutomaticTransferCard({
    userBusiness,
    onUpdateBankAccount,
    isLoading,
    error,
    isSuccess
}) {
    const { t, i18n } = useTranslation();
    const { profile } = useSelector(state => state.auth);

    const [validationErrors, setValidationErrors] = useState(null);
    const [bankAccount, setBankAccount] = useState({
        bank: '',
        accountName: '',
        accountNumber: '',
        iban: ''
    })

    const { data: banks, isLoading: banksLoading } = useGetBanksQuery({ country: userBusiness?.user?.country?.id });

    const submitHandler = async () => {
        try {
            setValidationErrors(null)
            let payload = cloneDeep(bankAccount)
            payload = omitBy(payload, v => !Boolean(v));

            payload.accountName = payload?.accountName?.trim()
            payload.accountNumber = payload?.accountNumber?.replace(/\s/g, '')
            payload.iban = payload?.iban?.replace(/\s/g, '')

            const errors = runJoiValidate(USER_BANK_ACCOUNT_SCHEMA, payload)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }
            console.log(payload)
            onUpdateBankAccount({ bankAccount: payload })
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = ({ target: { name, value } }) => {
        setBankAccount((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    useEffect(() => {
        if (!!userBusiness) {
            let data = get(userBusiness, "bankAccount");
            setBankAccount(data)
        }
    }, [userBusiness])

    const disabled = useMemo(() => {
        return !bankAccount?.bank || !bankAccount?.accountNumber || !bankAccount?.accountName || !bankAccount?.iban
    }, [bankAccount])

    const canUpdate = useMemo(_ => {
        if (profile?.role === ROLES.admin) return true
        const allowedStatuses = [ACCOUNT_STATUS.accountCreated, ACCOUNT_STATUS.waiting];
        let statusCondition = includes(allowedStatuses, get(userBusiness, 'user.currentStatus'));

        const bankAccount = get(userBusiness, 'bankAccount');
        const isBankEnteredBefore = !!bankAccount && Object.values(bankAccount).some(value => value);

        return statusCondition && !isBankEnteredBefore;

    }, [userBusiness, profile]);

    const supportContacts = useMemo(() => {
        // TODO: to be replaced with UAE frontend env variable
        if (window?.location?.href?.includes("ezpayuae")) {
            return { phone: "+971509443956", email: "pm@ezpayuae.com" }
        } else {
            return { phone: "+966581849999", email: "info@ezpay.sa" }
        }
    })

    return (
        <Card>
            <CardHeader title={t("Bank Account Info")} />
            <Divider />
            <CardContent>
                {!!userBusiness &&
                    <>
                        {
                            !!userBusiness?.user?.country?.id
                                ?
                                <Grid container spacing={10}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            value={banks?.find(bank => bank?.id === bankAccount?.bank) ?? null}
                                            onChange={(_, value) => handleChange({ target: { name: 'bank', value: value?.id } })}
                                            options={!isEmpty(banks) ? banks : []}
                                            getOptionLabel={(option) => get(option, `name${i18n.dir() === 'rtl' ? "Ar" : "En"}`)}
                                            disablePortal
                                            fullWidth
                                            size="small"
                                            loading={banksLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("Select Bank")}
                                                />
                                            )}
                                            disabled={!canUpdate}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="accountNumber"
                                            label={t("bankAccountDetails.accountNumber")}
                                            value={bankAccount?.accountNumber ?? ''}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            error={!!validationErrors?.accountNumber}
                                            helperText={validationErrors?.accountNumber}
                                            disabled={!canUpdate}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="accountName"
                                            label={t("bankAccountDetails.accountName")}
                                            value={bankAccount?.accountName ?? ''}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            error={!!validationErrors?.accountName}
                                            helperText={validationErrors?.accountName}
                                            disabled={!canUpdate}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="iban"
                                            label={t("bankAccountDetails.iban")}
                                            value={bankAccount?.iban ?? ''}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            error={!!validationErrors?.iban}
                                            helperText={validationErrors?.iban}
                                            disabled={!canUpdate}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                <Typography color="error">{t("Select Country First")}</Typography>
                        }
                    </>
                }
            </CardContent>
            <Divider />
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                {
                    canUpdate
                        ?
                        <>
                            <LoadingButton disabled={disabled} loading={isLoading} variant="contained" onClick={submitHandler} sx={{ textTransform: "none" }}>
                                {t("Confirm")}
                            </LoadingButton>
                            {!!error && <Typography color="error">{t(error)}</Typography>}
                            {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
                        </>
                        :
                        <>
                            <Box sx={{ display: 'flex' }}>
                                <IconButton href={`https://wa.me/${supportContacts?.phone}`} color="primary">
                                    <WhatsAppIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                                <InfoIcon style={{ fontSize: 16, marginInlineEnd: 4, marginTop: 2 }} />
                                {t("To update your bank account details, kindly contact support")}
                            </Typography>
                        </>
                }
            </CardActions>
        </Card >
    )
}