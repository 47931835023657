import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import { HighlightBox } from "./components";
import { NoFees, PaymentLink, QRCode, CustomizedInvoice, Secure, Fast, OSSysyemts, Variety } from "_assets";

const highlights = [
    { icon: Fast, title: "landing.highlights.Fast" },
    { icon: NoFees, title: "landing.highlights.NoFees" },
    { icon: QRCode, title: "landing.highlights.QRCode" },
    { icon: PaymentLink, title: "landing.highlights.PaymentLink" },
    { icon: Variety, title: "landing.highlights.Variety" },
    { icon: Secure, title: "landing.highlights.Secure" },
    { icon: CustomizedInvoice, title: "landing.highlights.CustomizedInvoice" },
    { icon: OSSysyemts, title: "landing.highlights.OSSysyemts" },
]

export default function Highlights() {

    const { t } = useTranslation()
    const downLg = useMediaQuery(theme => theme.breakpoints.down('md'));
    return (
        <Box backgroundColor="backgroundGrey.main" sx={{ px: 20, whiteSpace: "pre-wrap"}}>
            <Container>
                <Box display="flex" flexDirection="column" py={downLg ? 50 : 100} alignItems="center" textAlign="center">
                    <Typography variant="h4" sx={{ fontWeight: "bold" }}>{t("landing.highlights.title")}</Typography>
                    <Typography sx={{ mb: 50, fontSize: "1.5rem" }}>{t("landing.highlights.description")}</Typography>
                    <Grid container justifyContent="center" alignItems="stretch" spacing={20} >
                        {
                            highlights.map(item => (
                                <Grid item lg={3} md={3} sm={5} xs={12} key={item?.title}>
                                    <HighlightBox item={item} />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}