import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCountryDataList } from "countries-list";

export default function CountriesListAutoComplete({ country, onChange, excludedCountries }) {
    const { t } = useTranslation();
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        let data = getCountryDataList();
        setCountries(data);
    }, []);

    return (
        <Autocomplete
            fullWidth
            size="small"
            id="countries-id"
            options={countries}
            getOptionLabel={(option) => option?.name}
            filterOptions={(options, state) => {
                return options?.filter((option) => (!excludedCountries?.includes(option?.iso2) && option?.name?.toLocaleLowerCase().includes(state?.inputValue?.toLocaleLowerCase())));
            }}
            value={country}
            onChange={(_, newValue) => {
                onChange(newValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={t("Countries")} />
            )}
        />
    );
}
