import { LoadingButton } from '@mui/lab';
import { Card, CardHeader, CardContent, CardActions, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Divider, Box, Autocomplete, Typography } from '@mui/material';
import { useUpdateBankInfoByAdminMutation } from 'api/adminApi';
import { cloneDeep, get, isArray, omit, set } from 'lodash';
import { TextFieldNumber } from 'modules/common';
import { useTranslation } from 'react-i18next';
import { DEPOSITE_SCHEMA } from './ValidationSchemas';
import { DAYS } from 'constants';
import { useEffect, useState } from 'react';
import { runJoiValidate } from 'utils';

export default function MyFatoorahTransferPeriodCard({
    businessId,
    deposit,
    allowUpdate,
    ...props
}) {

    const { t } = useTranslation();

    const [depositData, setDepositeData] = useState({
        terms: "Daily",
        days: []
    });

    useEffect(() => {
        if (!!deposit) {
            let data = cloneDeep(deposit)
            if (!!data?.days) {
                data.days = data.days.split(",").map(el => Number(el))
            } else {
                data = { terms: "Daily" }
            }
            setDepositeData(data)
        }
    }, [deposit])

    const handleChange = ({ target: { name, value } }) => {

        if (name === "terms") {
            setDepositeData((prevState) => {
                let clone = cloneDeep(prevState)
                let terms = get(deposit, "terms")
                if (terms === value) {
                    set(clone, "days", get(deposit, "days"))
                } else {
                    set(clone, "days", [])
                }
                return clone
            })
        }

        setDepositeData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const [updateBankInfoByAdmin,
        {
            isLoading,
            error,
            isSuccess,
        }] = useUpdateBankInfoByAdminMutation()

    const [validationErrors, setValidationErrors] = useState(null)

    const handleSubmit = () => {
        try {
            setValidationErrors(null)
            let payload = cloneDeep(depositData)

            if (payload?.terms !== "Daily") {
                if (isArray(payload.days)) {
                    payload.days = payload.days.join(",")
                }
            } else {
                payload = omit(payload, "days")
            }

            const errors = runJoiValidate(DEPOSITE_SCHEMA, payload)
            console.log("errors", errors)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }

            updateBankInfoByAdmin({ businessId, payload: { deposit: { ...payload } } })
        } catch (error) {
            console.log("Error:", error)
        }
    }


    return (
        <Card {...props}>
            <CardHeader title={t("Payment Period")} />
            <Divider />
            <CardContent>
                <FormControl sx={{ display: "flex", mb: 10 }}>
                    <RadioGroup
                        row
                        aria-label="payment-type"
                        name="terms"
                        value={depositData?.terms ?? ''}
                        onChange={handleChange}
                    >
                        <FormControlLabel disabled={!allowUpdate} value="Daily" control={<Radio />} label={t("Daily")} />
                        <FormControlLabel disabled={!allowUpdate}  value="Weekly" control={<Radio />} label={t("Weekly")} />
                        <FormControlLabel disabled={!allowUpdate} value="Monthly" control={<Radio />} label={t("Monthly")} />
                    </RadioGroup>
                </FormControl>
                {depositData?.terms === 'Weekly' && (
                    <FormControl fullWidth>
                        <Autocomplete
                            multiple
                            options={DAYS}
                            getOptionLabel={(option) => t(`week.${option?.label}`)}
                            fullWidth
                            value={DAYS?.filter(day => depositData?.days?.includes(day?.id)) ?? []}
                            onChange={(_, newValue) => {
                                if (newValue.length > 3) return
                                handleChange({ target: { name: 'days', value: newValue.map(v => v.id) } })
                            }}
                            ChipProps={{
                                size: "small",
                                variant: "outlined",
                                color: "secondary"
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("Select Day")}
                                    name="days"
                                    size="small"
                                    variant="outlined"
                                    placeholder={t("Choose from one to three days")}
                                    error={!!validationErrors?.days}
                                    helperText={validationErrors?.days ?? ''}
                                />
                            )}
                        />
                    </FormControl>
                )}
                {depositData?.terms === 'Monthly' && (
                    <TextFieldNumber
                        size="small"
                        variant="outlined"
                        label={t("Select Day in Month")}
                        name="days"
                        value={depositData?.days}
                        onChange={handleChange}
                        fullWidth
                        max={28}
                        error={!!validationErrors?.days}
                        helperText={validationErrors?.days ?? ''}
                    />
                )}
            </CardContent>
            {
                allowUpdate
                    ?
                    <>
                        <Divider />
                        <CardActions>
                            {isSuccess && <Typography color="success.main">{t("Done successfully")}</Typography>}
                            {!!error && <Typography color="error">{t(error)}</Typography>}
                            <Box mr="auto" />
                            <LoadingButton
                                sx={{ textTransform: "none" }}
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                loading={isLoading}
                            >
                                {t("Confirm")}
                            </LoadingButton>
                        </CardActions>
                    </>
                    : null
            }
        </Card>
    );
}
