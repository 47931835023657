import { first, split, last, includes } from "lodash";

const generateImageLink = (image, requiredSize) => {
    if (!image) return '';
    let { baseURL, name, provider, sizes } = image;
    let requiredName = name;
    if (requiredSize && includes(sizes, requiredSize)) {
        let nameComponents = split(name, '.');
        requiredName = `${first(nameComponents)}_${requiredSize}.${last(nameComponents)}`;
    }
    return `${baseURL}${requiredName}`
};

export default generateImageLink;