import { PaymentMethods } from "constants";
import { get } from 'lodash';
import { Typography, Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function PaymentMethodIcon({ method }) {

    const { t } = useTranslation();
    
    return (
        <Tooltip title={t(`paymentMethod.${method ?? "OTHER"}`)}>
            <Box sx={{ width: 50, display: 'flex', justifyContent: 'center' }}>
                {!!get(PaymentMethods, method) ?
                    <img style={{ objectFit: "contain", maxWidth: 50, height: "30px" }} src={get(PaymentMethods, method)} alt={method} />
                    :
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    {t("N/A")}
                    </Typography>}
            </Box>
        </Tooltip>
    )
}