import { get, has } from "lodash";
import { apiSlice } from "./apiSlice";

const transformErrorResponse = (response) => has(response?.data, 'message') ? get(response?.data, 'message') : "Something went wrong please try again";

export const appVersionSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['APP-VERSION'] })
    .injectEndpoints({
        endpoints: builder => ({
            getAppVersions: builder.query({
                query: (params) => ({
                    url: '/app-version',
                    method: 'GET',
                    params
                }),
                transformResponse: (response) => response?.body,
                transformErrorResponse,
                providesTags: (result, _, __) =>
                    result
                        ? [
                            ...result?.docs.map(({ id }) => ({ type: "APP-VERSION", id })),
                            { type: "APP-VERSION", id: "APP-VERSION-PARTIAL-LIST" },
                        ]
                        : [{ type: "APP-VERSION", id: "APP-VERSION-PARTIAL-LIST" }]
            }),
            getAppVersionById: builder.query({
                query: (id) => ({
                    url: `/app-version/${id}`,
                    method: 'GET',
                }),
                transformResponse: (response) => response?.body,
                transformErrorResponse,
                providesTags: (_, __, id) => [{ type: 'APP-VERSION', id }]
            }),
            addAppVersion: builder.mutation({
                query: (payload) => ({
                    url: `/app-version`,
                    method: 'POST',
                    body: { ...payload }
                }),
                transformErrorResponse,
                invalidatesTags: (_, __, { id }) => [
                    { type: 'APP-VERSION', id },
                    { type: 'APP-VERSION', id: 'APP-VERSION-PARTIAL-LIST' },
                ],
            }),
            updateAppVersion: builder.mutation({
                query: ({ id, payload }) => ({
                    url: `/app-version/${id}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                transformResponse: (response) => response?.body,
                transformErrorResponse,
                invalidatesTags: (_, __, { id }) => [
                    { type: 'APP-VERSION', id },
                    { type: 'APP-VERSION', id: 'APP-VERSION-PARTIAL-LIST' },
                ],
            }),
            deleteAppVersion: builder.mutation({
                query: (id) => ({
                    url: `/app-version/${id}`,
                    method: 'DELETE',
                }),
                transformResponse: (response) => response?.body,
                transformErrorResponse,
                invalidatesTags: (_, __, id) => [
                    { type: 'APP-VERSION', id },
                    { type: 'APP-VERSION', id: 'APP-VERSION-PARTIAL-LIST' },
                ],
            }),
        })
    });

export const {
    useAddAppVersionMutation,
    useDeleteAppVersionMutation,
    useGetAppVersionsQuery,
    useUpdateAppVersionMutation,
    useGetAppVersionByIdQuery
} = appVersionSlice;
