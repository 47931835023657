import { Box, CssBaseline, Toolbar } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminAppBar from "./AdminAppBar";
import AdminDrawer from "./AdminDrawer";

export default function AdminLayout() {
    const [openDrawer, setOpenDrawer] = useState(false)
    const toggleDrawer = () => {
        setOpenDrawer(prevStatus => !prevStatus)
    }
    return (
        <Box display="flex">
            <CssBaseline />
            <AdminAppBar toggleDrawer={toggleDrawer} />
            <AdminDrawer openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
            <Toolbar />
            <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: "auto" }}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    )
}