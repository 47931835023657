import { Description, PrivacyTip } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Divider, TextField, Typography } from "@mui/material";
import { Facebook, Instagram, Linkedin, Snapchat, Twitter, Youtube } from "_assets";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function SocialLinksCard({ settings, loading, error, isSuccess, onSubmit, allowUpdate }) {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [socials, setSocials] = useState([
        { name: "facebook", url: '', img: Facebook },
        { name: "instagram", url: '', img: Instagram },
        { name: "linkedin", url: '', img: Linkedin },
        { name: "youtube", url: '', img: Youtube },
        { name: "snapchat", url: '', img: Snapchat },
        { name: "twitter", url: '', img: Twitter },
    ])

    const onSocialChangeHandler = ({ target: { name, value } }) => {
        setSocials(prevSocials => {
            let items = cloneDeep(prevSocials)
            const itemIndex = items.findIndex(item => item?.name === name)
            items[itemIndex] = { ...items[itemIndex], url: value }
            return items
        })
    }

    const onSubmitHandler = () => {
        const payload = socials.reduce((result, item) => {
            return { ...result, [item.name]: item.url }
        }, {})
        onSubmit(payload)
    }

    useEffect(() => {
        if (!!settings) {
            let data = socials.map(social => {
                return { ...social, url: settings[social?.name] ?? '' }
            })
            setSocials(data)
        }
    }, [settings])

    return (
        <Card>
            <CardHeader title={t("Social media links")} />
            <Divider />
            <CardContent>
                <Box display="flex" justifyContent="space-around" alignItems="center">
                    <Button
                        onClick={() => navigate("/admin/privacy_policy")}
                        startIcon={<PrivacyTip />}
                        variant="outlined"
                    >
                        {t("Privacy policy")}
                    </Button>
                    <Button
                        onClick={() => navigate("/admin/terms_and_conditions")}
                        startIcon={<Description />}
                        variant="outlined"
                    >
                        {t("Terms and conditions")}
                    </Button>
                </Box>
                {
                    socials.map(social => (
                        <Box display="flex" alignItems="center" justifyContent="space-between" key={social?.name}>
                            <Avatar src={social?.img} alt="social url" sx={{ mt: 10, mr: 10 }} />
                            <TextField
                                margin="normal"
                                name={social?.name}
                                value={social?.url ?? ''}
                                onChange={onSocialChangeHandler}
                                variant="outlined"
                                size="small"
                                label={t(social?.name)}
                                fullWidth
                                dir="ltr"
                                disabled={!allowUpdate}
                            />
                        </Box>
                    ))
                }
            </CardContent>
            {
                allowUpdate
                    ?
                    <>
                        <Divider />
                        <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                onClick={onSubmitHandler}
                                sx={{ textTransform: "none" }}
                            >
                                {t("Submit")}
                            </LoadingButton>
                            {!!error && <Typography color="error">{t(error)}</Typography>}
                            {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
                        </CardActions>
                    </>
                    : null
            }
        </Card>
    )
}