export default function createEzPayTheme(direction) {
  return {
    palette: {
      primary: {
        main: "#00588C"
      },
      secondary: {
        main: "#FDB714",
        light: "#fbbf24"
      },
      backgroundGrey: {
        main: "#FAFAFA"
      },
      lightGrey: {
        main: "#8E8E8E"
      },
      whiteColor: {
        main: "#FFF"
      },
      blackColor: {
        main: "#0000"
      },
      success: {
        main: "#28a745",
      },
      warning: {
        main: "#FF8800"
      },
      danger: {
        main: "#F44336"
      }
    },
    typography: {
      fontFamily: direction === "rtl" ? 'IBM Plex Sans Arabic !important' : 'Poppins'
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: direction === "rtl" ? 'IBM Plex Sans Arabic' : 'Poppins'
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: direction === "rtl" ? 'IBM Plex Sans Arabic' : 'Poppins',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontFamily: direction === "rtl" ? 'IBMPlexSansArabic' : 'Poppins',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontFamily: direction === "rtl" ? 'IBMPlexSansArabic' : 'Poppins',
          },
        },
      },
    },
    spacing: 1,
    direction,
  }
}
