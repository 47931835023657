import { Helmet } from 'react-helmet';
import { $APP_NAME } from "constants";
import {
    Box,
    Grid,
    Container,
    CircularProgress,
    Typography
} from '@mui/material';
import { map, get } from 'lodash';
import { BlogGalleryItem, NoPosts } from './components';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGetBlogPostsWebQuery } from 'api/blogApi';
import { Footer } from '../../common';

const BlogGallery = () => {

    const { t } = useTranslation();
    const { data: blogPosts, isLoading: loading } = useGetBlogPostsWebQuery({ limit: 100 })

    return (
        <>
            <Helmet>
                <title>{$APP_NAME} Blog</title>
            </Helmet>
            <Box
                sx={{
                    overflow: "hidden",
                    backgroundColor: "white",
                    pt: 10,
                    pb: 30
                }}
            >
                <Box sx={{ position: 'relative', width: "100%", height: '100%', display: 'flex', flexDirection: 'column', alignContent: 'center', zIndex: 1 }}>
                    <Container maxWidth="md" sx={{ height: "100%" }} >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: 'center',
                                height: '100%',
                                // height: { xs: 245 + 20, md: 458 - 60 },
                            }}
                        >
                            <Typography variant='h3'>
                                {t("Ezpay Blog")}
                            </Typography>
                            {/* <Typography
                                sx={{
                                    // color: "#fff",
                                    fontSize: { xs: "15px", md: "25px" },
                                    textAlign: 'center'
                                }}
                            >
                                {t("blog.subtitle")}
                            </Typography> */}
                        </Box>
                    </Container>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "white",
                    px: { xs: 3, md: 5 },
                    pb: 10
                }}
                flexGrow={1}
            >
                <Container maxWidth="lg" >
                    {
                        loading
                            ?
                            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", py: 10 }}>
                                <CircularProgress color="primary" size={80} />
                            </Box>
                            : (
                                !isEmpty(blogPosts?.docs)
                                    ?
                                    <Grid container spacing={40} >
                                        {
                                            map(
                                                blogPosts?.docs,
                                                post => (
                                                    <Grid key={get(post, 'id')} item xs={12} md={4} sx={{ display: "flex", alignItems: "center", }}>
                                                        <BlogGalleryItem blogPost={post} />
                                                    </Grid>
                                                )
                                            )
                                        }
                                    </Grid>
                                    :
                                    <NoPosts />
                            )
                    }
                </Container>
            </Box>
            <Footer />
        </>
    );
}

export default BlogGallery;