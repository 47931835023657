import { Menu } from "@mui/icons-material";
import { AppBar, Box, Button, Container, IconButton, Toolbar, styled, useMediaQuery } from "@mui/material";
import { ElevationScroll } from "layouts/common";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLanguage } from "store/languageSlice";
import { Logo } from "_assets";

const AppBarButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    fontWeight: 500,
    marginStart: 10,
    fontSize: "1rem",
    fontFamily: theme.typography.fontFamily,
    '&:hover': {
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent'
    },
}));

export default function LandingAppBar({ blogPosts, toggleDrawer }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const lessThanMedium = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const { language } = useSelector(state => state?.lang)

    const changeLanguage = () => {
        let target = language === "ar-SA" ? "en-US" : "ar-SA"
        dispatch(setLanguage({ language: target }))
    }

    const goTo = ({ id, top, route }) => {
        if (!!route && route !== location?.pathname) {
            navigate(route);
            if (!!id) {
                setTimeout(() => {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: top ? 0 : document.getElementById(id).getBoundingClientRect().top + window.scrollY - 50
                    })
                }, 1000);
            }
        } else if (!!id) {
            window.scrollTo({
                behavior: 'smooth',
                top: top ? 0 : document.getElementById(id).getBoundingClientRect().top + window.scrollY - 50
            })
        }
    }

    return (
        <ElevationScroll>
            <AppBar sx={{ backgroundColor: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Container>
                    <Toolbar sx={{ paddingInlineStart: lessThanMedium ? 20 : undefined }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} >
                            <img style={{ cursor: "pointer", height:"50px" }} onClick={() => navigate("/")} src={Logo} alt="ezpay logo" />
                            {
                                lessThanMedium
                                    ?
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                        onClick={toggleDrawer}
                                    >
                                        <Menu color="primary" />
                                    </IconButton>
                                    :
                                    <Box>
                                        <AppBarButton onClick={() => goTo({ id: 'home', top: true, route: "/" })} >
                                            {t("landing.home")}
                                        </AppBarButton>
                                        <AppBarButton onClick={() => goTo({ id: "about-us", route: "/" })} >
                                            {t("landing.aboutUs")}
                                        </AppBarButton>
                                        {
                                            (!window?.location?.href?.includes("ezpayuae"))
                                                ?
                                                <AppBarButton
                                                    onClick={() => navigate("/pricing")} >
                                                    {t("landing.pricing.title")}
                                                </AppBarButton>
                                                : null
                                        }
                                        <AppBarButton
                                            onClick={() => navigate("/contact-us")} >
                                            {t("landing.contactUs")}
                                        </AppBarButton>
                                        <AppBarButton
                                            onClick={() => navigate("/documentation")} >
                                            {t("Documentation")}
                                        </AppBarButton>
                                        {
                                            !!blogPosts?.docs?.length &&
                                            <AppBarButton
                                                onClick={() => navigate("/blog")} >
                                                {t("Blog")}
                                            </AppBarButton>
                                        }
                                        <AppBarButton
                                            onClick={() => navigate("/auth/login")} >
                                            {t("landing.login")}
                                        </AppBarButton>
                                        <AppBarButton onClick={() => changeLanguage()} >
                                            {t(i18n.dir() === "rtl" ? "En" : "العربية")}
                                        </AppBarButton>
                                    </Box>
                            }
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </ElevationScroll>
    )
}
