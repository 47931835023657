import { createSlice } from '@reduxjs/toolkit';

const confirmationDialogSlice = createSlice({
    name: 'dialog',
    initialState: {
        open: false,
        title: "Warning",
        content: 'Are you sure you want to delete it?',
        onClose: undefined,
        onConfirm: () => { },
    },
    reducers: {
        openConfirmDialog: (state, action) => {
            state.title = action?.payload?.title ?? "Warning"
            state.content = action?.payload?.content ?? 'Are you sure you want to delete it?'
            state.onConfirm = action?.payload?.onConfirm ?? null
            state.onClose = action?.payload?.onClose ?? undefined
            state.open = true;
        },
        closeConfirmDialog: (state) => {
            state.open = false;
            state.title = 'Warning'
            state.content = 'Are you sure you want to delete it?'
            state.onConfirm = () => { }
            state.onClose = undefined
        },
    },
});

export const { openConfirmDialog, closeConfirmDialog } = confirmationDialogSlice.actions

export default confirmationDialogSlice.reducer