import { LoadingButton } from "@mui/lab";
import { Box, Card, CardActions, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import { useVerifyVendorMutation } from "api/adminApi";
import { useTranslation } from "react-i18next";

export default function AutoTransferStatusCard({
    businessId,
    myFatoorahVendor
}) {

    const { t } = useTranslation()
    const [verifyVendor, { isLoading, isSuccess, error }] = useVerifyVendorMutation()

    return (
        <Card>
            <CardHeader title={t("Auto Transfer Status")} />
            <Divider />
            <CardContent>
                {
                    myFatoorahVendor?.isApproved ?
                        <Typography color="success.main">
                            {t("The automatic transfer actived successfully.")}
                        </Typography>
                        :
                        <Typography color="secondary.main">
                            {t("The automatic transfer will be activated soon. You can inquire about the status from time to time")}
                        </Typography>
                }
            </CardContent>
            {
                !!myFatoorahVendor?.feedback &&
                <CardContent>
                    {
                        !!myFatoorahVendor?.feedback?.rejectReasons &&
                        <Box>
                            <Typography variant="h6" color="primary">{t("Reasons for failure to activate automatic transfer")}</Typography>
                            <Typography variant="body1" color="error">{myFatoorahVendor?.feedback?.rejectReasons}</Typography>
                        </Box>
                    }
                    {
                        !!myFatoorahVendor?.feedback?.comments &&
                        <Box>
                            <Typography variant="h6" color="primary">{t("Please review the following")}</Typography>
                            <Typography variant="body1" color="error">{myFatoorahVendor?.feedback?.comments}</Typography>
                        </Box>
                    }
                </CardContent>
            }
            <Divider />
            <CardActions>
                {isSuccess && <Typography color="success.main">{t("Done successfully")}</Typography>}
                {!!error && <Typography color="error">{t(error)}</Typography>}
                <Box mr="auto" />
                <LoadingButton
                    onClick={() => verifyVendor(businessId)}
                    loading={isLoading}
                    variant="contained"
                    sx={{ textTransform: "none" }}
                >
                    {t("Check Status")}
                </LoadingButton>
            </CardActions>
        </Card>
    )
}
