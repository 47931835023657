import { Container, Grid } from '@mui/material'
import { useGetUserByIdQuery, useUpdateUserMutation, useUpdateUserStatusMutation } from 'api/userApi'
import { $APP_NAME } from 'constants'
import { omit } from 'lodash'
import ActivationCard from 'modules/admin/AdminUserDetails/ActivationCard'
import UserInfoCard from 'modules/admin/AdminUserDetails/UserInfoCard'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export default function UserDetails() {
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()

    const { data: userData, isError } = useGetUserByIdQuery(id)

    useEffect(() => {
        if (isError) {
            navigate(-1, { replace: true })
        }
    }, [isError])

    //======= Update User By Admin =========
    const [updateUser, { isLoading: updateUserLoading, error: updateUserError, isSuccess: updateUserIsSuccess }] = useUpdateUserMutation()

    const onUpdateUserHandler = (payload) => {
        updateUser({ id, payload: omit(payload, ["email"]) })
    }

    //======= Update User Status By Admin =========
    const [updateUserStatus, {
        isLoading: updateUserStatusLoading,
        isSuccess: updateUserStatusIsSuccess,
        error: updateUserStatusError
    }] = useUpdateUserStatusMutation()

    const onUpdateUserStatusHandler = (data) => {
        if (data?.payload?.currentStatus === "BLOCKED") {
            data.payload.currentStatus = "BLOCKED_BY_PARENT"
        }
        updateUserStatus(data)
    }

    return (
        <>
            <Helmet>
                <title>{t("User Details")} | {$APP_NAME}</title>
            </Helmet>
            <Container>
                <Grid container spacing={10} sx={{ my: 15 }}>
                    <Grid item xs={12} md={6}>
                        <UserInfoCard
                            id={id}
                            userData={userData}
                            onUpdate={onUpdateUserHandler}
                            isLoading={updateUserLoading}
                            isSuccess={updateUserIsSuccess}
                            error={updateUserError}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ActivationCard
                            id={id}
                            status={userData?.currentStatus}
                            timeline={userData?.statusTrack}
                            onUpdateStatus={onUpdateUserStatusHandler}
                            isLoading={updateUserStatusLoading}
                            isSuccess={updateUserStatusIsSuccess}
                            error={updateUserStatusError}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
