import { Logout, Menu } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Tooltip, useMediaQuery } from "@mui/material";
import { ElevationScroll, NotificationsMenu } from "layouts/common";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "store/authSlice";
import { WhiteLogo } from "_assets";
import { apiSlice } from "api/apiSlice";

export default function AdminAppBar({ toggleDrawer }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const lessThanSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"));
    const logoutHandler = () => {
        dispatch(logOut())
        dispatch(apiSlice.util.resetApiState())
    }
    return (
        <ElevationScroll>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Box mx={20}>
                    <Toolbar>
                        {lessThanSmall
                            &&
                            <IconButton
                                onClick={toggleDrawer}
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                <Menu />
                            </IconButton>
                        }
                        <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
                            <img onClick={() => navigate("/")} src={WhiteLogo} style={{ cursor: "pointer", height: "50px" }} alt="ezpay logo" />
                            <Box display="flex" alignItems="center">
                                <NotificationsMenu />
                                <Tooltip title={t("Logout")}>
                                    <IconButton sx={{ ml: 20 }} size="large" color="inherit" onClick={() => logoutHandler()}>
                                        <Logout />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
        </ElevationScroll>
    )
}