import { apiSlice } from "./apiSlice";

export const thirdPartyApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getThirdParty: builder.query({
            query: () => ({
                url: `/third-party`,
                method: 'GET'
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        updateThirdParty: builder.mutation({
            query: (payload) => ({
                url: `/third-party`,
                method: 'PUT',
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getThirdPartyByAdmin: builder.query({
            query: (userId) => ({
                url: `/third-party/admin/user/${userId}`,
                method: 'GET'
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        updateThirdPartyByAdmin: builder.mutation({
            query: ({ userId, payload }) => ({
                url: `/third-party/admin/${userId}`,
                method: 'PUT',
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useLazyGetThirdPartyQuery,
    useLazyGetThirdPartyByAdminQuery,
    useUpdateThirdPartyMutation,
    useUpdateThirdPartyByAdminMutation
} = thirdPartyApi