import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generateImageLink } from 'utils';

export default function MerchantName({ creator, business, AccessoryView }) {

    const { t } = useTranslation();

    if (isEmpty(creator)) {
        return t("N/A")
    }
    
    const { parent, userName } = creator;

    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {
                business
                    ?
                    <Tooltip title={business?.businessName}>
                        <Avatar sx={{ width: 36, height: 36, marginInlineEnd: 6 }} src={generateImageLink(business?.logo, "small")} alt="business logo" />
                    </Tooltip>
                    : null
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <Typography variant="body2" sx={{ marginInlineStart: 3, fontWeight: 500 }}> {userName} </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <AccountCircleIcon color={parent ? "secondary" : "primary"} sx={{ fontSize: 14 }} />
                    <Typography sx={{ marginInlineStart: 3, fontWeight: 300, fontSize: 10 }}> {t(parent ? "Sub account" : "Main account")} </Typography>
                </Box>
            </Box>
            {
                AccessoryView
                    ?
                    AccessoryView
                    : null
            }
        </Box>
    );
}