// AvatarUploader.js

import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { AddAPhoto } from '@mui/icons-material';
import { Box, Dialog, Typography } from '@mui/material';
import { isValidBase64ImageString } from 'utils/base64Utils';
import { generateImageLink } from 'utils';
import FileInput from './inputs/FileInput';

export default function AvatarUploader({ name, image, onChange, error }) {

    const [source, setSource] = useState();

    useEffect(() => {
        if (!image) return
        if (isValidBase64ImageString(image)) {
            setSource(image)
        } else {
            setSource(
                generateImageLink(image, "small")
            )
        }
    }, [image])

    const [openImage, setOpenImage] = useState(false)

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Dialog onClose={() => setOpenImage(false)} open={openImage}>
                <img src={source} style={{ objectFit: 'contain' }} alt={name} />
            </Dialog>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <Avatar
                    alt="User Avatar"
                    src={source}
                    style={{ width: '140px', height: '140px', border: !!error ? "2px solid red" : '' }} // Increase avatar size
                />

                {/* Add Image icon button */}
                <FileInput
                    name={name}
                    id="avatar-upload"
                    style={{ display: 'none' }}
                    onChange={onChange}
                />
                <label htmlFor="avatar-upload" style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <IconButton size='large' color="primary" component="span">
                        <AddAPhoto sx={{ fontSize: 30 }} />
                    </IconButton>
                </label>
            </div>
            <Typography variant="caption" color="error" sx={{ mt: 5 }}>{error}</Typography>
        </Box>
    );
}