import { Box, Grid } from '@mui/material'
import { $APP_NAME } from 'constants';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function AdminStatistics() {
    const { t } = useTranslation()
    const { profile } = useSelector(state => state?.auth);

    return (
        <>
            <Helmet>
                <title>{t("Statistics")} | {$APP_NAME}</title>
            </Helmet>
            <Box m={5}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height: "40vh" }}>
                        <iframe style={{ background: '#FFFFFF', border: 'none' }} width={"100%"} height={"100%"} src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '4e28abee-78ce-4b49-8e3c-f6576f908bb9' : '6666fb93-9b3f-4ae7-8730-1ab18143385d'}&maxDataAge=3600&theme=light&autoRefresh=true&filter={user : ObjectId('${profile?.id}')}`}></iframe>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "50vh" }}>
                        <iframe style={{ background: '#FFFFFF', border: 'none' }} width={"100%"} height={"100%"} src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '2c96307f-e8a5-48bf-9a65-270c39d7a640' : 'ab2c74cc-be04-4734-a518-5468ee752c62'}&maxDataAge=3600&theme=light&autoRefresh=true&filter={user : ObjectId('${profile?.id}')}`}></iframe>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "50vh" }}>
                        <iframe style={{ background: '#FFFFFF', border: 'none' }} width={"100%"} height={"100%"} src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '29b55b43-4c97-42c0-8640-f77077a38d21' : '6666f00a-2080-4b87-812f-c23077e4862f'}&maxDataAge=3600&theme=light&autoRefresh=true&filter={user : ObjectId('${profile?.id}')}`}></iframe>
                    </Grid>
                    {
                        (!!profile?.id && !profile?.parent) &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "50vh" }}>
                            <iframe
                                style={{ background: '#FFFFFF', border: 'none' }} width={"100%"} height={"100%"}
                                src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '4ea4fb58-28b7-4949-a642-d4139143dedc' : '66697f2e-fe72-4001-8435-7f661ea20678'}&maxDataAge=3600&theme=light&autoRefresh=true&filter={$or : [{'user_lookup_users.parent' : ObjectId('${profile?.id}')}, {'user' : ObjectId('${profile?.id}')}]}`}
                            ></iframe>
                        </Grid>
                    }
                </Grid>
            </Box>
        </>
    )
}
