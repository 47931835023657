import * as Joi from "joi";
import i18n from "i18n";
import { joiString } from "utils/joi";

export const BANK_SCHEMA = Joi.object({
    nameAr: joiString.string()
        .required()
        .empty('')
        .label(i18n.t("Arabic Name"))
    ,
    nameEn: joiString.string()
        .required()
        .empty('')
        .label(i18n.t("English Name"))
    ,
    myFatoorahId: joiString.string()
        .required()
        .empty('')
        .label(i18n.t("MyFatoorah ID"))
    ,
    country: joiString.string()
        .required()
        .empty('')
        .label(i18n.t("Country"))
    ,
})