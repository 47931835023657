import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function DownloadExcleDialog({ open, onSend, onClose, isSuccess, error, loading }) {
    const { t } = useTranslation()
    const [selectedEmail, setSelectedEmail] = useState("MY_EMAIL")
    const [email, setEmail] = useState('')

    const onSendHandler = () => {
        if (selectedEmail === "MY_EMAIL") {
            onSend()
        } else {
            if (!email) return
            onSend({ email })
        }
    }

    const onCloseHandler = () => {
        setEmail('')
        setSelectedEmail('MY_EMAIL')
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={(e) => e.preventDefault()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="dialog-title">
                {t("Choose email")}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={selectedEmail}
                    onChange={({ target: { value } }) => setSelectedEmail(value)}
                >
                    <FormControlLabel value="MY_EMAIL" control={<Radio />} label={t("Your Email")} />
                    <FormControlLabel value="ANOTHER" control={<Radio />} label={t("Another Email")} />
                    {
                        selectedEmail === "ANOTHER" &&
                        <TextField
                            value={email}
                            onChange={({ target: { value } }) => setEmail(value)}
                            sx={{ mt: 2 }}
                            size="small"
                            label={t("Email")}
                            variant="outlined"
                        />
                    }
                    {isSuccess && <Typography sx={{ mt: 2 }} color="success.main">{t("An excel file will be sent to your email for download")}</Typography>}
                    {!!error && <Typography color="error">{t(error)}</Typography>}
                </RadioGroup>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button sx={{ textTransform: "none" }} variant="contained" onClick={onCloseHandler}>{t("Close")}</Button>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={onSendHandler}
                    sx={{ mt: 3, textTransform: "none" }}
                >
                    {t("Send")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
