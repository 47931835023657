import { Dialog, DialogContent, Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export default function VideoDialog({ open, onClose }) {
    const theme = useTheme();
    const [video, setVideo] = useState('')

    useEffect(() => {
        const domain = window.location.origin
        if (domain === "https://www.ezpay.sa") {
            if (theme?.direction === "rtl") {
                setVideo("https://player.vimeo.com/video/945250962")
            } else {
                setVideo("https://player.vimeo.com/video/946053996")
            }
        } else if (domain === "https://www.ezpayuae.com") {
            if (theme?.direction === "rtl") {
                setVideo("https://player.vimeo.com/video/945250962")
            } else {
                setVideo("https://player.vimeo.com/video/946053996")
            }
        }else{
            if (theme?.direction === "rtl") {
                setVideo("https://player.vimeo.com/video/945250962")
            } else {
                setVideo("https://player.vimeo.com/video/946053996")
            }
        }
    }, [theme?.direction])

    return (
        <Dialog
            // fullWidth={true}
            maxWidth={"lg"}
            open={open}
            onClose={onClose}
        >
            <DialogContent sx={{ p: 1 }}>
                <Box
                    // ref={dialogContentRef}
                    sx={{
                        width: { xs: "80vw", sm: "560px", md: "840px" },
                        height: { xs: "80vh", sm: "315px", md: "472.5px" },
                        overflow: "hidden",
                    }}
                >
                    <iframe
                        width="100%"
                        height="100%"
                        src={video}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
}