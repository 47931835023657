import { apiSlice } from "./apiSlice";

export const invoiceApi = apiSlice.injectEndpoints({
    keepUnusedDataFor: 1,
    endpoints: builder => ({
        getMyInvoices: builder.query({
            query: (params) => ({
                url: `/invoice/my-invoices`,
                method: 'GET',
                params
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getAllUsersInvoices: builder.query({
            query: (params) => ({
                url: `/invoice`,
                method: 'GET',
                params
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getUserInvoices: builder.query({
            query: ({ id, params }) => ({
                url: `/invoice/user/${id}`,
                method: 'GET',
                params
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getInvoiceById: builder.query({
            query: ({ invoiceId, params }) => ({
                url: `/invoice/${invoiceId}`,
                method: 'GET',
                params
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getInvoiceExcel: builder.query({
            query: ({invoiceId, payload}) => ({
                url: `/invoice/xlsx/${invoiceId}`,
                method: 'GET',
                params: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        settleMerchantBalance: builder.mutation({
            query: (userId) => ({
                url: `/invoice/settle-balance/${userId}`,
                method: 'POST'
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useGetAllUsersInvoicesQuery,
    useGetUserInvoicesQuery,
    useGetInvoiceByIdQuery,
    useLazyGetInvoiceExcelQuery,
    useSettleMerchantBalanceMutation,
    useGetMyInvoicesQuery
} = invoiceApi