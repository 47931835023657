import { LoadingButton } from "@mui/lab";
import { Box, Card, CardActions, CardContent, CardHeader, Divider, TextField, Typography, useTheme } from "@mui/material";
import { cloneDeep, omit, omitBy, pick } from "lodash";
import { PhoneInput, ServerAutoCompleteSearch } from "modules/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import runJoiValidate from "utils/runJoiValidate";
import { USER_INFO_SCHEMA } from "./ValidationSchemas";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { useGetCountriesQuery } from "api/countriesApis";
import { useSelector } from "react-redux";
import { ROLES } from "constants";
import { Link } from 'react-router-dom';

export default function UserInfoCard({
    id,
    userData,
    onUpdate,
    isLoading,
    isSuccess,
    error
}) {
    const { t } = useTranslation()
    const theme = useTheme()
    const { profile } = useSelector(state => state?.auth)
    const [validationErrors, setValidationErrors] = useState(null)
    const [user, setUser] = useState({
        userName: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: ''
    })

    const submitHandler = async () => {
        try {
            setValidationErrors(null)
            let payload = cloneDeep(user)
            if (!payload?.password && !payload?.confirm_password) {
                payload = omit(payload, ["password", "confirm_password"])
            }
            payload = omitBy(payload, v => !Boolean(v));

            let SCHEMA = cloneDeep(USER_INFO_SCHEMA)
            if (profile?.role === ROLES.admin) {
                SCHEMA = SCHEMA.fork(
                    ['country'],
                    (x) => x.required()
                );
            }
            const errors = runJoiValidate(SCHEMA, payload)

            if (!!errors) {
                setValidationErrors(errors)
                return
            }
            payload = omit(payload, ["confirm_password"])
            onUpdate(payload)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = ({ target: { name, value } }) => {
        setUser((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    useEffect(() => {
        if (!!userData && !!profile) {
            const fields = ["userName", "email", "phone"]
            if (profile?.role === ROLES.admin) {
                fields.push("country")
            }
            let data = pick(userData, fields)
            data = { ...data, password: '', confirm_password: '' }
            setUser(data)
        }
    }, [userData, profile])

    //=======Countries=======
    const [countrySearchKey, setCountrySearchKey] = useState('')
    const { data: countries, isLoading: countriesLoading } = useGetCountriesQuery(omitBy({ searchKey: countrySearchKey, active: true }, v => !Boolean(v)))

    return (
        <Card>
            <CardHeader title={t("Account basic info")} />
            <Divider />
            <CardContent>
                {
                    (!!userData && !userData?.parent) &&
                    <Box display="flex" justifyContent="space-between" alignItems="center" my={7}>
                        <Typography sx={{ fontWeight: "bold" }}>{t("Email verified")}</Typography>
                        {userData?.emailVerified ? <CheckCircle color="success" /> : <Cancel color="error" />}
                    </Box>
                }
                {
                    !!userData?.parent &&
                    <Box display="flex" justifyContent="space-between" alignItems="center" my={7}>
                        <Typography sx={{ fontWeight: "bold" }}>{t("The main account")}</Typography>
                        <Typography sx={{ fontWeight: "bold", color: "primary.main", textDecoration:"none" }} component={Link} to={`/admin/user/${userData?.parent?.id}`}>{t(userData?.parent?.userName)}</Typography>
                    </Box>
                }
                <Box display="flex" justifyContent="space-between" alignItems="center" my={7}>
                    <Typography sx={{ fontWeight: "bold" }}>{t("Phone verified")}</Typography>
                    {userData?.phoneVerified ? <CheckCircle color="success" /> : <Cancel color="error" />}
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" my={7}>
                    <Typography sx={{ fontWeight: "bold" }}>{t("Account Source")}</Typography>
                    <Typography sx={{ fontWeight: "bold" }}>{t(userData?.createdFrom)}</Typography>
                </Box>
                <Divider sx={{ my: 15 }} />
                <TextField
                    name="userName"
                    label={t("Name")}
                    value={user?.userName}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 7 }}
                    error={!!validationErrors?.userName}
                    helperText={validationErrors?.userName}
                />
                {
                    userData?.parent === null &&
                    <TextField
                        name="email"
                        label={t("Email")}
                        value={user?.email ?? ''}
                        onChange={handleChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                        sx={{ my: 7 }}
                        error={!!validationErrors?.email}
                        helperText={validationErrors?.email}
                    />
                }
                <PhoneInput
                    label={t("Phone")}
                    name="phone"
                    value={user?.phone}
                    onChange={handleChange}
                    fullWidth
                    sx={{ my: 7 }}
                    error={validationErrors?.phone}
                />
                {
                    profile?.role === ROLES.admin &&
                    <ServerAutoCompleteSearch
                        label={t("Country")}
                        value={countries?.docs?.find((country) => country?.id === user?.country) ?? null}
                        isOptionEqualToValue={(option, value) => option?.id === value}
                        loading={countriesLoading}
                        getOptionLabel={(option) => !!option?.info ? option?.info[`${theme.direction === "rtl" ? "arabic" : "english"}`]?.name : ''}
                        options={countries?.docs?.filter((country) => ["EG", "SA", "AE"].includes(country?.isoCode)) ?? []}
                        onChange={(value) => handleChange({ target: { name: "country", value: value?.id } })}
                        onInputChange={(text) => setCountrySearchKey(text)}
                        error={validationErrors?.country}
                    />
                }
                <TextField
                    name="password"
                    type="password"
                    label={t("Password")}
                    value={user?.password}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 7 }}
                    inputProps={{
                        autoComplete: "new-password"
                    }}
                />
                <TextField
                    name="confirm_password"
                    type="password"
                    label={t("Confirm password")}
                    value={user?.confirm_password}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 7 }}
                    error={!!validationErrors?.confirm_password}
                    helperText={validationErrors?.confirm_password}
                />
            </CardContent>
            <Divider />
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                <LoadingButton loading={isLoading} variant="contained" onClick={submitHandler} sx={{ textTransform: "none" }}>
                    {t("Submit")}
                </LoadingButton>
                {!!error && <Typography color="error">{t(error)}</Typography>}
                {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
            </CardActions>
        </Card>
    )
}