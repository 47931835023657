import { useEffect, useMemo, useState } from "react";

import { Avatar, Box, Divider, useTheme, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get, includes, isEmpty } from "lodash";
import Lottie from "lottie-react";

import { PaymentService } from "services";
import { EzPayLoadingIndicator, Logo, Success, Error } from "_assets";
import { generateImageLink } from "utils";
import MyFatoorahPayment from "./components/MyFatoorahPayment";
import { LocalizationMenu, PoweredByEzPay } from "modules/common";
import { TRANSACTION_STATUSES } from "constants";
import ClientInfo from "modules/admin/AdminTransactionDetails/ClientInfo";
import { SendInvoiceByEmail } from "./components";
import { Cancel } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";

const InvoicePayment = () => {

    const { t } = useTranslation()
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const invoiceId = useMemo(_ => searchParams.get('id'), [searchParams]);

    const [invoice, setInvoice] = useState();
    const [error, setError] = useState();

    const [animationComplete, setAnimationComplete] = useState(false);

    const statusMessageI18nKeys = {
        [TRANSACTION_STATUSES.PAID]: "successMessage",
        [TRANSACTION_STATUSES.FAILED]: "invoiceFailed",
        [TRANSACTION_STATUSES.CANCELED]: "invoiceCancelled",
        [TRANSACTION_STATUSES.CANCELED_BY_BLOCK]: "invoiceCancelledByBlock",
        [TRANSACTION_STATUSES.CANCELED_BY_PARENT_BLOCK]: "invoiceCancelledByParentBlock",
        [TRANSACTION_STATUSES.EXPIRED]: "invoiceExpired",
    }

    useEffect(_ => {
        // TODO: handle case when invoice is paid or cancelled
        const loadInvoice = async _ => {
            if (!isEmpty(invoiceId)) {
                try {
                    const response = await PaymentService.getInvoiceDetails(invoiceId);
                    const invoice = response.data.body;
                    setInvoice(invoice);
                } catch (e) {
                    console.log(e)
                    setError(e?.response?.data?.message ?? e?.message ?? "An error occurred")
                }
            }
        }
        loadInvoice();
    }, [invoiceId])

    if (!!error) {
        return (
            <>
                <Helmet>
                    <title>{t("Invoice Payemnt")} | {$APP_NAME}</title>
                </Helmet>
                <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <Box display='flex' alignItems='center'>
                        <Cancel color="error" sx={{ fontSize: 40, mr: 5 }} />
                        <Typography variant="h4" color="error">{error}</Typography>
                    </Box>
                </Box>
            </>
        )
    }

    if (!invoice) {
        return (
            <>
                <Helmet>
                    <title>{t("Invoice Payemnt")} | {$APP_NAME}</title>
                </Helmet>
                <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <Lottie animationData={EzPayLoadingIndicator} loop={true} style={{ width: 240, height: 240 }} />
                </Box>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>{t("Invoice Payemnt")} | {$APP_NAME}</title>
            </Helmet>
            <Box sx={{ paddingBlock: 32, marginInline: 'auto', display: "flex", flexDirection: "column", alignItems: "stretch", minHeight: '100vh' }} maxWidth="400px">
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingInline: 32 }}>
                    <LocalizationMenu hasMargins={false} />
                    <Avatar src={generateImageLink(invoice?.business?.logo, "small")} variant="circular" sx={{ height: 60, width: 60, ml: 40 }} />
                    <Avatar src={Logo} variant="square" sx={{ height: 60, width: 60 }} />
                </Box>

                {
                    includes([
                        TRANSACTION_STATUSES.PAID,
                        TRANSACTION_STATUSES.FAILED,
                        TRANSACTION_STATUSES.CANCELED,
                        TRANSACTION_STATUSES.CANCELED_BY_PARENT_BLOCK,
                        TRANSACTION_STATUSES.CANCELED_BY_BLOCK,
                        TRANSACTION_STATUSES.EXPIRED,
                    ], invoice?.currentStatus)
                        ?
                        <Box sx={{
                            flex: 1, display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center",
                        }}>
                            <Typography color="secondary" variant="h4" sx={{ fontWeight: "bold", p: 10, textAlign: "center" }}>
                                {invoice?.transactionNumber ?? '-------'}
                            </Typography>
                            <Lottie
                                animationData={
                                    invoice?.currentStatus === TRANSACTION_STATUSES.PAID ? Success : Error
                                }
                                loop={false}
                                style={{ width: 240, height: 240, marginTop: "-10%" }}
                                onComplete={_ => setAnimationComplete(true)}
                            />
                            <Typography sx={{ textAlign: 'center', whiteSpace: 'pre-wrap', fontWeight: "600", fontSize: "20px", height: 36 }}>
                                {
                                    animationComplete ?
                                        invoice?.currentStatus === TRANSACTION_STATUSES.PAID ?
                                            t("transactionPaidSuccessfully", { price: invoice?.amount })
                                            :
                                            `${t(`payment.${get(statusMessageI18nKeys, invoice?.currentStatus)}`)}`
                                        :
                                        ""
                                }
                            </Typography>
                            {
                                invoice?.currentStatus === TRANSACTION_STATUSES.PAID &&
                                <Box p={20} width="100%">
                                    <Box display="flex" justifyContent="center" alignItems="center" mb={30}>
                                        <SendInvoiceByEmail invoiceId={invoiceId} />
                                    </Box>
                                    {/********************Merchant Info**********************/}
                                    <Divider>
                                        <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Merchant Info")}</Typography>
                                    </Divider>
                                    <Box display="flex" alignItems="center" pb={20}>
                                        <Avatar sx={{ width: 60, height: 60, mr: 20 }} src={generateImageLink(invoice?.business?.logo, "small")} alt="business logo" />
                                        <Typography sx={{ fontWeight: "bold" }}>{invoice?.business?.businessName}</Typography>
                                    </Box>
                                    {/********************Transaction created by**********************/}
                                    <Divider>
                                        <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Transaction created by")}</Typography>
                                    </Divider>
                                    <Box display="flex" my={10} pb={20}>
                                        <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Name")}</Typography>
                                        <Typography>{invoice?.user?.userName}</Typography>
                                    </Box>
                                    {/********************Client Info**********************/}
                                    {
                                        !(!invoice?.name && !invoice?.phone && !invoice?.serviceDetails) &&
                                        <>
                                            <Divider>
                                                <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Client Info")}</Typography>
                                            </Divider>
                                        </>
                                    }
                                    <ClientInfo transaction={invoice} mt={10} />
                                </Box>
                            }
                        </Box>
                        :
                        <>
                            <Box sx={{ marginTop: 32, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingInline: 32 }}>
                                <Typography sx={{ textAlign: 'center', whiteSpace: 'pre-wrap', fontWeight: "600", fontSize: "20px", }}>
                                    {t(`payment.disclaimerLine${invoice?.name ? "One" : "OneDefault"}`, { userName: invoice?.name })}
                                </Typography>
                                <Typography sx={{ textAlign: 'center', whiteSpace: 'pre-wrap', font: "300 17px ", }}>
                                    {t("payment.disclaimerLineTwo", {
                                        amount: invoice?.amount,
                                        currency: invoice?.user?.country?.info?.[theme.direction === "rtl" ? "arabic" : "english"]?.currency
                                    })}
                                </Typography>
                                <Typography sx={{ textAlign: 'center', whiteSpace: 'pre-wrap', fontWeight: "600", fontSize: "20px", }}>
                                    {invoice?.business?.businessName}
                                </Typography>
                                {
                                    invoice?.serviceDetails
                                        ?
                                        <Typography style={{ font: "400 14px ", marginTop: 12, textAlign: 'center' }}>
                                            {t("payment.disclaimerLineThree", { description: invoice?.serviceDetails })}
                                        </Typography>
                                        : null
                                }
                            </Box>
                            <Box sx={{ marginTop: 32 }}>
                                <MyFatoorahPayment
                                    invoiceId={invoice?.id}
                                    requiredToPay={invoice?.amount}
                                    userCountry={process.env.REACT_APP_ENV === "production" ? invoice?.user?.country?.isoCode3 : "KWT"}
                                    userCurrency={invoice?.user?.country?.info?.english?.currency}
                                />
                            </Box>
                        </>
                }
                <PoweredByEzPay style={{ marginTop: 'auto', paddingTop: 32 }} />
            </Box>
        </>
    )
}

export default InvoicePayment;