import { Card, CardContent, CardHeader, Divider, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UploadImage from "./UploadImage";

export default function PostDetails({ post, handleChange, validationErrors, allowUpdate, ...props }) {
    const { t } = useTranslation()

    return (
        <Card {...props}>
            <CardHeader title={t("Post description")} />
            <Divider />
            <CardContent>
                <Grid container spacing={10}>
                    <Grid item xs={12} sm={4} md={3}>
                        <UploadImage
                            image={post?.image}
                            handleChange={handleChange}
                            error={validationErrors && validationErrors?.image}
                            disabled={!allowUpdate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} container spacing={5} alignContent="center">
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined" size="small"
                                label={t("The Arabic title")}
                                name="title_ar"
                                value={post?.title_ar ?? ''}
                                inputProps={{
                                    maxlength: 90
                                }}
                                onChange={handleChange}
                                required={true}
                                error={!!validationErrors && !!validationErrors.title_ar}
                                helperText={!!validationErrors && validationErrors.title_ar}
                                multiline={true}
                                disabled={!allowUpdate}
                            />
                            <Typography variant="caption">{t("*90 characters max.")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined" size="small"
                                label={t("The English title")}
                                name="title_en"
                                value={post?.title_en ?? ''}
                                inputProps={{
                                    maxlength: 90
                                }}
                                onChange={handleChange}
                                required={true}
                                error={!!validationErrors && !!validationErrors.title_en}
                                helperText={!!validationErrors && validationErrors.title_en}
                                multiline={true}
                                disabled={!allowUpdate}
                            />
                            <Typography variant="caption">{t("*90 characters max.")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined" size="small"
                                label={t("Metadata")}
                                name="meta"
                                value={post?.meta}
                                inputProps={{
                                    maxlength: 90
                                }}
                                onChange={handleChange}
                                required={true}
                                error={!!validationErrors && !!validationErrors.meta}
                                helperText={validationErrors && validationErrors.meta}
                                multiline={true}
                                disabled={!allowUpdate}
                            />
                            <Typography variant="caption">{t("*90 characters max.")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined" size="small"
                                label={t("URL name")}
                                name="slug"
                                value={post?.slug}
                                onChange={handleChange}
                                required={true}
                                error={!!validationErrors && !!validationErrors.slug}
                                helperText={validationErrors && validationErrors.slug}
                                disabled={!allowUpdate}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )
}