import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'
import { useState } from 'react'

export default function PasswordTextField({ ...props }) {
    const [showPassword, setShowPassword] = useState(false)
    return (
        <TextField
            variant="outlined"
            size="small"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            }}
            {...props}
        />
    )
}
