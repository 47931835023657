import React, { useMemo, useRef } from 'react';
import {
    Typography,
    Paper,
    Card,
    CardMedia,
    CardContent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useComponentDimensions, generateImageLink } from 'utils';
import { BLOG_POST_CHARACTER_LIMIT } from "constants";

const BlogGalleryItem = ({ blogPost }) => {

    const navigate = useNavigate();
    const cardMediaContainerRef = useRef();
    const { width: cardMediaContainerWidth } = useComponentDimensions(cardMediaContainerRef);

    const {
        meta,
        title_ar,
        image,
        slug
    } = blogPost;

    const displayTitle = useMemo(_ => {
        if (blogPost) {
            let title = title_ar;
            if (title.length > BLOG_POST_CHARACTER_LIMIT) {
                title = title.substring(0, BLOG_POST_CHARACTER_LIMIT) + " ..."
            }
            return title
        }
    }, [blogPost])

    const description = useMemo(_ => {
        if (blogPost) {
            let description = meta;
            if (description.length > BLOG_POST_CHARACTER_LIMIT) {
                description = description.substring(0, BLOG_POST_CHARACTER_LIMIT) + " ..."
            }
            return description
        }
    }, [blogPost])

    return (
        <Paper
            elevation={0}
            sx={{
                borderWidth: 0,
                transition: `all .3s ease 0s`,
                flex: 1,
                borderRadius: '8px',
                '&:hover': {
                    transform: `scale(1.075)`,
                    boxShadow: `0 40px 40px 0 rgb(14 17 51 / 10%)`,
                    cursor: "pointer"
                },
            }}
            onClick={_ => navigate(`/blog/${slug}`)}
        >
            <Card >
                <CardMedia
                    component={"img"}
                    ref={cardMediaContainerRef}
                    sx={{height: cardMediaContainerWidth * 176.0/314.0}}
                    src={`${generateImageLink(image, "medium")}`}
                >
                </CardMedia>
                <CardContent>
                    <Typography
                        gutterBottom
                        sx={{
                            fontSize: {xs: "21px", md: "25px"},
                            lineHeight: {xs: "26px", md: "30px"},
                            fontWeight: 600,
                            letterSpacing: "-.016em",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            minHeight:  {xs: "78px", md: "90px"},
                            overflow: "hidden",
                        }}
                    >
                        {displayTitle}
                    </Typography>
                    <Typography
                        sx={{
                            color: "rgba(51,51,51,.6)!important",
                            fontSize: { xs: "20px", md: "24px" },
                            lineHeight: { xs: "21px", md: "25px" },
                            minHeight: { xs: "70px", md: "80px" },
                            fontWeight: 300,
                            overflow: "hidden",
                        }}
                    >
                        {description}
                    </Typography>
                </CardContent>
            </Card>
        </Paper>
    );
}

export default BlogGalleryItem;