import { Box, Button, Chip, Paper, Typography } from "@mui/material";
import { useGetTransactionsForAdminQuery, useLazyRequestTransactionsXlsQuery, useUpdateTransactionStatusByAdminMutation } from "api/transactionApi";
import { DataTable, MerchantName, DownloadExcleDialog } from "modules/common";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TransactionsFilter from "./TransactionsFilter";
import UpdateTransactionDialog from "./UpdateTransactionDialog";
import { useNavigate } from "react-router-dom";
import { cloneDeep, get, omit } from "lodash";
import { useSelector } from "react-redux";
import { useGetPaymentMethodsQuery } from "api/paymentApi";
import { generateImageLink } from "utils";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { status_colors } from "constants";

const $COLUMN_WIDTH = 200
export default function AdminTransactions() {
    const navigate = useNavigate()
    const { profile } = useSelector(state => state?.auth);

    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const [params, setParams] = useState({
        page: 1,
        limit: 100,
    })
    const {
        data: transactions,
        isLoading: transactionsLoaing,
        isFetching,
        refetch: refetchTransactions
    } = useGetTransactionsForAdminQuery(params)

    const pageChangeHandler = ({ page }) => {
        if (!isFetching) {
            setPage(page)
            setParams((prevParams) => ({ ...prevParams, page }))
        }
    }

    const paramChangeHandler = (data) => {
        setParams((prev) => ({
            page: prev?.page ?? 1,
            limit: 100,
            ...data
        }))
    }

    //===================Data tables handlers============================
    const [transaction, setTransaction] = useState(null)
    const [openTransactionDialog, setTransactionDialog] = useState(false)

    const [updateTransactionStatusByAdmin,
        {
            isLoading: updateTransactionLoading,
            isSuccess: updateTransactionIsSuccess,
            error: updateTransactionError,
            reset: updateTransactionReset
        }
    ] = useUpdateTransactionStatusByAdminMutation()


    const openTransactionDialogHandler = (transaction) => {
        setTransaction(transaction)
        setTransactionDialog(true)
    }
    const closeDialogHandler = () => {
        setTransactionDialog(false)
        setTransaction(null)
        updateTransactionReset()
    }

    const TransactionStatus = (params) => {
        return (
            <Chip
                label={t(params?.value)}
                sx={{ color: "white", flex: 1, fontWeight: 500 }}
                color={status_colors[params?.value]}
                disabled={params?.value === "PAID"}
                onClick={(event) => {
                    event.stopPropagation()
                    openTransactionDialogHandler(params?.row)
                }}
            />
        )
    }

    //======= Payment Methods =======
    const { data: paymentMethods } = useGetPaymentMethodsQuery({})

    const PaymentMethodImage = ({ value }) => {
        if (!value?.paymentMethod) return <></>
        const method = paymentMethods?.find(method => method?.info?.english?.name?.toLowerCase() === value?.paymentMethod?.toLowerCase())
        if (!method) return <></>
        return (
            <img style={{ objectFit: "contain", maxWidth: 50, height: "30px" }} src={generateImageLink(method?.image, "small")} alt={method} />
        )
    }

    const IDCell = ({ value }) => (
        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
            {value ?? t("N/A")}
        </Typography>
    )
    let columns = [
        { field: "transactionNumber", renderCell: IDCell, sortable: false, headerName: t("Transaction number"), width: $COLUMN_WIDTH * 0.8 },
        { field: "currentStatus", renderCell: TransactionStatus, sortable: false, headerName: t("Status"), width: $COLUMN_WIDTH * 0.7 },
        { field: "name", valueGetter: ({ value }) => value || t("N/A"), sortable: false, headerName: t("Client"), width: $COLUMN_WIDTH * 1.1 },
        {
            field: "user",
            renderCell: ({ value }) => <MerchantName creator={value} business={get(value, 'business')} />,
            sortable: false,
            headerName: t("User name"),
            width: $COLUMN_WIDTH * 1.1
        },
        {
            field: "bussiness",
            valueGetter: ({ row }) => row?.user?.business?.businessName,
            sortable: false,
            headerName: t("Business Name"),
            width: $COLUMN_WIDTH * 1.1
        },
        { field: "amount", sortable: false, headerName: t("Amount"), width: $COLUMN_WIDTH * 0.5, },
        {
            field: "source",
            renderCell: PaymentMethodImage,
            // valueGetter: ({ row }) => row?.source?.paymentMethod,
            headerName: t("Payment Method"),
            width: $COLUMN_WIDTH * 0.7
        },
        { field: "providerInvoiceId", renderCell: IDCell, sortable: false, headerName: t("Provider Invoice ID"), width: $COLUMN_WIDTH * 0.8 },
        { field: "createdFrom", valueGetter: ({ value }) => t(value ?? ''), sortable: false, headerName: t("Transaction Source"), width: $COLUMN_WIDTH * 0.8 },
        { field: "createdAt", valueGetter: ({ value }) => moment(value).format("DD/MM/YY - h:mm A"), sortable: false, headerName: t("Created At"), width: $COLUMN_WIDTH * 0.8 },
    ];

    //======= Excel =======
    const [requestTransactionsXls, {
        isLoading: requestTransactionsXlsLoading,
        isSuccess: requestTransactionsXlsIsSuccess,
        error: requestTransactionsXlsError
    }
    ] = useLazyRequestTransactionsXlsQuery()

    const [openExcelDialog, setOpenExcelDialog] = useState(false)

    const sendRequestTransactionsHandler = async (payload) => {
        if (!!payload) {
            await requestTransactionsXls({ ...omit(cloneDeep(params), ["limit", "page"]), ...payload })
        } else {
            await requestTransactionsXls({ ...omit(cloneDeep(params), ["limit", "page"]), email: profile?.email })
        }
        // refetch()
    }

    const closeExcelDialogHanlder = () => {
        setOpenExcelDialog(false)
    }

    return (
        <>
            <Helmet>
                <title>{t("Transactions List")} | {$APP_NAME}</title>
            </Helmet>
            <Box mr={5}>
                <Box display="flex" alignItems="center" justifyContent="space-between" my={10}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t("Transactions")}</Typography>

                    <Button
                        color="success"
                        sx={{ textTransform: "none" }}
                        variant="contained"
                        onClick={() => setOpenExcelDialog(true)}
                    >
                        {t("Excel")}
                    </Button>
                    <DownloadExcleDialog
                        open={openExcelDialog}
                        loading={requestTransactionsXlsLoading}
                        isSuccess={requestTransactionsXlsIsSuccess}
                        error={requestTransactionsXlsError}
                        onSend={sendRequestTransactionsHandler}
                        onClose={closeExcelDialogHanlder}
                    />
                </Box>
                <TransactionsFilter
                    onParamsChange={paramChangeHandler}
                />
                <Paper sx={{ height: "72vh" }}>
                    <DataTable
                        rows={transactions?.docs ?? []}
                        columns={columns}
                        rowCount={transactions?.totalDocs ?? 0}
                        onPaginationModelChange={pageChangeHandler}
                        paginationModel={{ page: page - 1, pageSize: 100 }}
                        loading={transactionsLoaing || isFetching}
                        onRowClick={row => navigate(`/admin/transaction/${row?.id}`)}
                    />
                </Paper>
                <UpdateTransactionDialog
                    open={openTransactionDialog}
                    transaction={transaction}
                    onClose={closeDialogHandler}
                    updateTransaction={updateTransactionStatusByAdmin}
                    loading={updateTransactionLoading}
                    isSuccess={updateTransactionIsSuccess}
                    error={updateTransactionError}
                    refetchTransactions={refetchTransactions}
                />
            </Box>
        </>
    )
}