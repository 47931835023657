import { get, has } from "lodash";
import { apiSlice } from "./apiSlice";

const transformErrorResponse = (response) => has(response?.data, 'message') ? get(response?.data, 'message') : "Something went wrong please try again";

export const subAdminSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['SUB-ADMIN'] })
    .injectEndpoints({
        endpoints: builder => ({
            getSubAdmins: builder.query({
                query: (params) => ({
                    url: `/user/admin/list-admins`,
                    method: 'GET',
                    params
                }),
                providesTags: (result, _, __) =>
                    result
                        ? [
                            ...result?.docs.map(({ id }) => ({ type: "SUB-ADMIN", id })),
                            { type: "SUB-ADMIN", id: "SUB-ADMIN-PARTIAL-LIST" },
                        ]
                        : [{ type: "SUB-ADMIN", id: "SUB-ADMIN-PARTIAL-LIST" }],
                transformResponse: (response) => response?.body,
                transformErrorResponse
            }),
            getSubAdminById: builder.query({
                query: ({ id, payload }) => ({
                    url: `/user/admin/${id}`,
                    method: 'GET',
                    body: { ...payload }
                }),
                providesTags: (_, __, id) => [{ type: "SUB-ADMIN", id }],
                transformErrorResponse
            }),
            createSubAdmin: builder.mutation({
                query: (payload) => ({
                    url: '/user/admin/create-admin',
                    method: 'POST',
                    body: { ...payload }
                }),
                invalidatesTags: (_, __, id) => [
                    { type: 'SUB-ADMIN', id },
                    { type: 'SUB-ADMIN', id: 'SUB-ADMIN-PARTIAL-LIST' },
                ],
                transformErrorResponse
            }),
            updateSubAdmin: builder.mutation({
                query: ({ id, payload }) => ({
                    url: `/user/admin/${id}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: (_, __, id) => [
                    { type: 'SUB-ADMIN', id },
                    { type: 'SUB-ADMIN', id: 'SUB-ADMIN-PARTIAL-LIST' },
                ],
                transformErrorResponse
            }),
            updateAdminAccount: builder.mutation({
                query: (payload) => ({
                    url: `/admin`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: [
                    { type: 'SUB-ADMIN', id: 'SUB-ADMIN-PARTIAL-LIST' },
                ],
                transformErrorResponse
            }),
            updateSubAdminPassword: builder.mutation({ // The admin update password for himself
                query: ({ id, payload }) => ({
                    url: `/admin/update-password/${id}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                transformErrorResponse
            }),
            deleteSubAdmin: builder.mutation({
                query: (id) => ({
                    url: `/user/admin/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_, __, id) => [
                    { type: 'SUB-ADMIN', id },
                    { type: 'SUB-ADMIN', id: 'SUB-ADMIN-PARTIAL-LIST' },
                ],
                transformErrorResponse
            }),
        })
    });

export const {
    useCreateSubAdminMutation,
    useGetSubAdminByIdQuery,
    useGetSubAdminsQuery,
    useUpdateSubAdminPasswordMutation,
    useUpdateSubAdminMutation,
    useDeleteSubAdminMutation,
    useUpdateAdminAccountMutation
} = subAdminSlice;
