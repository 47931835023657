import { Autocomplete, TextField } from "@mui/material";
import { useRef } from "react";

export default function ServerAutoCompleteSearch({
    value,
    options,
    onChange,
    label,
    onInputChange,
    isOptionEqualToValue,
    loading,
    error,
    getOptionLabel,
    textFiledProps,
    ...props }) {

    const timerRef = useRef(null)

    const onInputChangeHandler = (value) => {
        if (!!timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(() => {
            onInputChange(value)
        }, 1000);
    }

    return (
        <Autocomplete
            fullWidth
            size="small"
            value={value}
            options={options}
            isOptionEqualToValue={isOptionEqualToValue}
            onInputChange={(event, text) => {
                if (event?.type === "change") {
                    onInputChangeHandler(text)
                }
            }}
            onChange={(_, newValue) => {
                onChange(newValue)
            }}
            loading={loading}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
                <TextField
                    margin="dense"
                    {...params}
                    label={label}
                    error={!!error}
                    helperText={error ?? ''}
                    {...textFiledProps}
                />
            )}
            {...props}
        />
    )
}
