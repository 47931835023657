import * as React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLanguage } from 'store/languageSlice';

const LocalizationMenu = ({ color = "primary.main", ...props }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch()

    const languages = [
        {
            title: "العربية",
            onPress: () => dispatch(setLanguage({ language: "ar-SA" })) // i18n.changeLanguage("ar-SA")
        },
        {
            title: "English",
            onPress: () => dispatch(setLanguage({ language: "en-US" })) //i18n.changeLanguage("en-US")
        }
    ]

    // Localization Menu
    const [anchorElLocalization, setAnchorElLocalization] = React.useState(null);
    const handleOpenLocalizationMenu = (event) => setAnchorElLocalization(event.currentTarget);
    const handleCloseLocalizationMenu = () => setAnchorElLocalization(null);

    return (
        <Box {...props}>
            <Tooltip title={t("Language")}>
                <IconButton onClick={handleOpenLocalizationMenu} sx={{ p: 0, color: color }} >
                    <LanguageIcon color={color} />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElLocalization}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElLocalization)}
                onClose={handleCloseLocalizationMenu}
            >
                {map(languages, ({ title, onPress }) => (
                    <MenuItem key={title} onClick={_ => { handleCloseLocalizationMenu(); onPress() }}>
                        <Typography textAlign="center">{title}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
export default LocalizationMenu;
