import { apiSlice } from "./apiSlice";

export const notificationApi = apiSlice.injectEndpoints({
    keepUnusedDataFor: 1,
    endpoints: builder => ({
        getNotifications: builder.query({
            query: (params) => ({
                url: `/notification`,
                method: 'GET',
                params
            }),
            // Only have one cache entry because the arg always maps to one string
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems) => {
                currentCache.unreadCount = newItems?.unreadCount
                if (currentCache?.notifications?.page !== newItems?.notifications?.page) {
                    currentCache.notifications = {
                        ...newItems?.notifications,
                        docs: [...currentCache.notifications.docs, ...newItems.notifications.docs]
                    }
                } else {
                    let startIndex = (newItems.notifications.page - 1) * newItems.notifications.limit
                    currentCache = currentCache.notifications.docs.splice(startIndex, newItems.notifications.limit, ...newItems.notifications.docs);
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.page !== previousArg?.page
            },
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        updateNotificationRead: builder.mutation({
            query: (payload) => ({
                url: `/notification/update`,
                method: 'PUT',
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useGetNotificationsQuery,
    useLazyGetNotificationsQuery,
    useUpdateNotificationReadMutation
} = notificationApi