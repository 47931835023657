import { apiSlice } from "./apiSlice";

export const adminApi = apiSlice
    .enhanceEndpoints({ addTagTypes: ["USER", "USER-BUSINESS-INFO"] })
    .injectEndpoints({
        keepUnusedDataFor: 1,
        endpoints: builder => ({
            updateUserByAdmin: builder.mutation({
                query: ({ id, payload }) => ({
                    url: `/user/admin/${id}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ["USER", "USER-BUSINESS-INFO"],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateBusinessByAdmin: builder.mutation({
                query: ({ businessId, payload }) => ({
                    url: `/business/admin/update-business/${businessId}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ["USER-BUSINESS-INFO"],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateBusinessPricingByAdmin: builder.mutation({
                query: ({ businessId, payload }) => ({
                    url: `/business/admin/update-pricing/${businessId}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ["USER-BUSINESS-INFO"],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateBankInfoByAdmin: builder.mutation({
                query: ({ businessId, payload }) => ({
                    url: `/business/admin/update-bank/${businessId}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ["USER-BUSINESS-INFO"],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateDocumentsByAdmin: builder.mutation({
                query: ({ businessId, payload }) => ({
                    url: `/business/admin/update-documents/${businessId}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ["USER-BUSINESS-INFO"],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateUserStatusByAdmin: builder.mutation({
                query: ({ id, payload }) => ({
                    url: `/user/admin/update-status/${id}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ["USER"],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getUsersByAdmin: builder.query({
                query: (params) => ({
                    url: '/user/admin/users',
                    method: 'GET',
                    params
                }),
                providesTags: (result, _, __) =>
                    result
                        ? [
                            ...result?.docs.map(({ id }) => ({ type: "USER", id })),
                            { type: "USER", id: "USER-PARTIAL-LIST" },
                        ]
                        : [{ type: "USER", id: "USER-PARTIAL-LIST" }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getTransactionsForAdmin: builder.query({
                query: (params) => ({
                    url: '/transaction/admin',
                    method: 'GET',
                    params
                }),
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateTransactionStatusByAdmin: builder.mutation({
                query: ({ transactionId, payload }) => ({
                    url: `/transaction/admin/${transactionId}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            createUserByAdmin: builder.mutation({
                query: (payload) => ({
                    url: '/user/admin/create-user',
                    method: 'POST',
                    body: { ...payload }
                }),
                //  transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            submitVendor: builder.mutation({
                query: (id) => ({
                    url: `/business/submit-vendor/${id}`,
                    method: 'POST',
                }),
                invalidatesTags: ["USER-BUSINESS-INFO"],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            verifyVendor: builder.mutation({
                query: (id) => ({
                    url: `/business/verify-vendor/${id}`,
                    method: 'POST',
                }),
                invalidatesTags: ["USER-BUSINESS-INFO"],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            deleteDocument: builder.mutation({
                query: ({ businessId, payload }) => ({
                    url: `/business/${businessId}/file`,
                    method: 'DELETE',
                    body: { ...payload }
                }),
                invalidatesTags: ["USER-BUSINESS-INFO"],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
        })
    })

export const {
    useGetUsersByAdminQuery,
    useLazyGetUsersByAdminQuery,
    useGetTransactionsForAdminQuery,
    useUpdateUserStatusByAdminMutation,
    useUpdateUserByAdminMutation,
    useUpdateBusinessByAdminMutation,
    useUpdateBusinessPricingByAdminMutation,
    useUpdateTransactionStatusByAdminMutation,
    useCreateUserByAdminMutation,
    useUpdateBankInfoByAdminMutation,
    useUpdateDocumentsByAdminMutation,
    useSubmitVendorMutation,
    useVerifyVendorMutation,
    useDeleteDocumentMutation,
} = adminApi