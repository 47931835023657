import { LoadingButton } from "@mui/lab";
import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useGetSettingsQuery, useUpdateSettingsMutation } from "api/settingsApi";
import { $APP_NAME, ADMIN_TYPES } from "constants";
import { Editor } from "modules/common";
import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function AdminEditor({ type }) {
    const { t } = useTranslation()
    const { profile } = useSelector(state => state?.auth);

    const [lang, setLang] = useState(`${type}_ar`)
    const langRef = useRef(lang)
    langRef.current = lang

    const [description, setDescription] = useState({
        privacy_en: '',
        privacy_ar: '',
        terms_conditions_en: '',
        terms_conditions_ar: '',
    })

    const { data: settings, refetch: refetchSettings } = useGetSettingsQuery()

    const [updateSettings, {
        isLoading: updateSettingsLoading,
        error: updateSettingsError,
        isSuccess: updateSettingsIsSuccess,
        reset
    }
    ] = useUpdateSettingsMutation()

    const saveDataHandler = async () => {
        if (!!description[lang]) {
            await updateSettings({ [lang]: description[lang] })
            refetchSettings()
        }
    }

    const handleChange = (value) => {
        setDescription((prevDescription) => ({ ...prevDescription, [langRef.current]: value }))
    }

    const onLangChange = (event, value) => {
        reset()
        if (!!settings && description[lang] !== settings[lang]) {
            saveDataHandler()
        }
        setLang(value)
    }

    useEffect(() => {
        if (!!settings) {
            setDescription(prevData => ({ ...prevData, ...settings }))
        }
    }, [settings])

    const [currentData, setCurrentData] = useState('')
    useEffect(() => {
        if (!!settings && !!settings[lang]) {
            setCurrentData(settings[lang])
        } else {
            setCurrentData('')
        }
    }, [lang, settings])

    const allowUpdate = useMemo(() => {
        if (!!profile && profile?.type !== ADMIN_TYPES.accountManager) {
            return true
        }
        return false
    }, [profile])

    return (
        <>
            <Helmet>
                <title>{t("Editor")} | {$APP_NAME}</title>
            </Helmet>
            <Box my={20} mx={5}>
                <Typography sx={{ my: 20, fontWeight: "bold" }} variant="h5">{t(type)}</Typography>
                <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={lang}
                    onChange={onLangChange}
                    aria-label="Type"
                    size="small"
                    sx={{ textTransform: 'none' }}
                    fullWidth
                >
                    <ToggleButton sx={{ textTransform: 'none', fontWeight: "bold" }} value={`${type}_ar`}>المحتوى العربى</ToggleButton>
                    <ToggleButton sx={{ textTransform: 'none', fontWeight: "bold" }} value={`${type}_en`}>English content</ToggleButton>
                </ToggleButtonGroup>
                <Box my={20}>
                    <Editor
                        name={lang}
                        description={currentData ? currentData : ''}
                        handleChange={handleChange}
                        allowUpdate={allowUpdate}
                    />
                </Box>
                {
                    allowUpdate
                        ?
                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between" alignItems="center">
                            <LoadingButton
                                onClick={saveDataHandler}
                                loading={updateSettingsLoading}
                                variant="contained"
                                sx={{ textTransform: "none" }}
                                disabled={description[lang] === currentData}
                            >
                                {t("Confirm")}
                            </LoadingButton>
                            {!!updateSettingsError && <Typography color="error">{t(updateSettingsError)}</Typography>}
                            {updateSettingsIsSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
                        </Box>
                        : null
                }
            </Box>
        </>
    )
}