import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { About as AboutIllustration } from "_assets";

export default function About() {

    const { t } = useTranslation()
    const downLg = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <Box backgroundColor="white" sx={{ px: 20, whiteSpace: "pre-wrap" }}>
            <Container sx={{ px: 20, py: 70, textAlign: downLg && "center" }} id="about-us">
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xl={8} lg={8} md={6} sm={12} xs={12} alignSelf="center">
                        <Typography variant="h4" color="primary" sx={{ fontWeight: "bold" }}>{t("landing.about.subtitle")}</Typography>
                        <Typography variant="h6" sx={{ mt: 30, fontWeight: "500" }}>{t("landing.about.description")}</Typography>
                        <Typography variant="h6" sx={{ mt: 10, fontWeight: "700" }}>{t("landing.about.disclaimer")}</Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={9}>
                        <img width="100%" style={{ objectFit: "cover" }} src={AboutIllustration} alt="About EzPay, softpos, pos, mpos, payment, Soft POS KSA, Point of Sale Software Saudi Arabia, Mobile POS Solutions KSA, POS Systems for Small Businesses in Saudi Arabia, Saudi Arabia POS Software, Retail POS Systems KSA, Cloud-Based POS Solutions KSA, POS Integration Saudi Arabia, Saudi Arabia Payment Processing, POS Solutions for Retail KSA, Contactless Payment Solutions Saudi Arabia, POS Hardware and Software KSA, E-commerce Payment Solutions KSA, Digital Payment Systems Saudi Arabia, POS for Grocery Stores KSA, POS Software Providers KSA" />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}