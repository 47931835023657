import { useEffect, useState } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Dialog, useMediaQuery, useTheme, Grid, Autocomplete, TextField, Tooltip, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ImageUpload, TextFieldNumber, UploadDocument } from 'modules/common';
import { get, pick, cloneDeep, set, forEach, omitBy, omit, isArray, isString } from 'lodash';
import { useGetBanksQuery } from 'api/banksAPI';
import { generateImageLink, runJoiValidate } from 'utils';
import { USER_BUSINESS_SCHEMA } from './ValidationSchemas';
import { LoadingButton } from '@mui/lab';
import { useDeleteDocumentMutation, useSubmitVendorMutation, useUpdateBusinessByAdminMutation } from 'api/adminApi';
import { DAYS, DOCUMNETS } from 'constants';

export default function ConfirmBusinessInfoDialog({ open, onClose, userBusiness, refetchUserBusiness }) {

    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { data: banks, isLoading: banksLoading } = useGetBanksQuery();

    //======= Update Business Info =======
    const [updateBusinessByAdmin,
        {
            isLoading: updateBusinessLoading,
            error: updateBusinessError,
            isSuccess: updateBusinessIsSuccess,
            reset: updateBusinessReset
        }] = useUpdateBusinessByAdminMutation()

    const [businessInfo, setBusinessInfo] = useState({})
    const [validationErrors, setValidationErrors] = useState(null)

    useEffect(() => {
        if (!!userBusiness) {
            const fields = [
                "pricing",
                "bankAccount",
                "logo",
                "businessName",
                "expiryAt",
                "deposit"
            ]
            let data = cloneDeep(pick(userBusiness, fields))
            if (!!data?.deposit?.days) {
                data.deposit.days = data.deposit.days.split(",").map(el => Number(el))
            } else {
                data.deposit = { terms: "Daily" }
            }
            setBusinessInfo(data)
        }
    }, [userBusiness])


    const [documentsError, setDocumentsError] = useState(null)

    const submitHandler = () => {
        try {
            setCurrentFile(null)
            setValidationErrors(null)
            setDocumentsError(null)

            let payload = cloneDeep(businessInfo)
            payload = omit(payload, "pricing")
            //------ Handle image fields -------
            const selectedItems = [];

            forEach(DOCUMNETS.map(doc => doc?.name), (key) => {
                if (!!userBusiness[key]?.name) {
                    selectedItems.push(key);
                }
            })

            if (selectedItems.length < 1) {
                setDocumentsError("Please upload a minimum of 1 document.")
                return
            }

            payload.accountName = payload?.accountName?.trim()
            payload.accountNumber = payload?.accountNumber?.replace(/\s/g, '')
            payload.iban = payload?.iban?.replace(/\s/g, '')

            payload = omitBy(payload, v => !Boolean(v));

            if (payload?.expiryAt) payload.expiryAt = Number(payload.expiryAt)

            if (payload?.deposit === "Daily") {
                omit(payload, "deposit.days")
            } else if (isArray(payload?.deposit?.days)) {
                payload.deposit.days = payload?.deposit?.days?.join(",")
            }

            const errors = runJoiValidate(USER_BUSINESS_SCHEMA, payload)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }

            if (payload?.deposit?.terms !== "Daily") {
                if (isArray(payload?.deposit?.days)) {
                    payload.deposit.days = payload.deposit.days.join(",")
                }
            } else {
                payload = omit(payload, "deposit.days")
            }

            forEach(["logo"], (item) => {
                if (!!payload[item]?.name && /\.(jpg|jpeg|png|gif|svg)$/i.test(payload[item]?.name)) {
                    delete payload[item];
                }
            })

            updateBusinessByAdmin({ businessId: userBusiness?.id, payload })

        } catch (error) {
            console.log(error)
        }
    }

    //======= Store old deposit =======
    const handleChange = ({ target: { name, value, files } }, filePrepared = false) => {
        if (["logo"].includes(name) && !filePrepared) {
            const reader = new FileReader();
            const file = files[0];
            reader.addEventListener("load", function () {
                const base64String = reader.result
                handleChange({
                    target: {
                        name,
                        value: base64String
                    }
                }, true)
            }, false);
            return reader.readAsDataURL(file);
        }

        if (name === "deposit.terms") {
            setBusinessInfo((prevState) => {
                let clone = cloneDeep(prevState)
                let terms = get(userBusiness, "deposit.terms")
                if (terms === value) {
                    set(clone, "deposit.days", get(userBusiness, "deposit.days"))
                } else {
                    set(clone, "deposit.days", [])
                }
                return clone
            })
        }

        setBusinessInfo((prevState) => {
            let clone = cloneDeep(prevState)
            set(clone, name, value)
            return clone
        })
    }

    const [submitVendor, {
        isLoading: submitVendorLoading,
        isSuccess: submitVendorIsSuccess,
        error: submitVendorError,
        reset: submitVendorReset,
    }
    ] = useSubmitVendorMutation()

    useEffect(() => {
        if (!open) {
            updateBusinessReset()
            submitVendorReset()
        }
    }, [open])

    const submitVendorHandler = async () => {
        try {
            await submitVendor(userBusiness?.id).unwrap()
            setTimeout(() => {
                onClose()
            }, 3000);
        } catch (error) { }
    }

    const [currentFile, setCurrentFile] = useState(null);

    //======= Documents Handler ============
    const [deleteDocument,
        {
            isLoading: deleteDocumentLoading,
            error: deleteDocumentError,
            isSuccess: deleteDocumentIsSuccess
        }] = useDeleteDocumentMutation()

    const deleteDocumentHandler = async (key) => {
        try {
            setCurrentFile(key)
            await deleteDocument({ businessId: userBusiness?.id, payload: { key } }).unwrap()
            refetchUserBusiness()
        } catch (error) { }
    }


    const uploadDocument = async ({ target: { name, value } }) => {
        if (!!name && !!value) {
            setCurrentFile(name)
            await updateBusinessByAdmin({ businessId: userBusiness?.id, payload: { [name]: value } }).unwrap()
            refetchUserBusiness()
        }
    }

    const isLoading = deleteDocumentLoading || updateBusinessLoading

    return (
        <Dialog
            open={open}
            onClose={(e) => e.preventDefault()}
            fullScreen={fullScreen}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                {t("Confirm data for automatic transfer")}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={10}>
                    <Grid item xs={4}>
                        <ImageUpload
                            variant={"rounded"}
                            name="logo"
                            image={businessInfo?.logo ?? ''}
                            title="Logo"
                            handleChange={handleChange}
                            error={validationErrors?.logo}
                            isImageClickable={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="businessName"
                            label={t("Business name")}
                            value={businessInfo?.businessName ?? ''}
                            onChange={handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                            error={!!validationErrors?.businessName}
                            helperText={validationErrors?.businessName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldNumber
                            name={"expiryAt"}
                            value={businessInfo?.expiryAt ?? ''}
                            onChange={handleChange}
                            decimal={false}
                            label={t("Payment Links Validity (hours)")}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Typography variant="h6" sx={{ mt: 30, mb: 15 }}>{t("Documents")}</Typography>
                {!!deleteDocumentError && <Typography sx={{ mb: 20 }} color="error">{t(deleteDocumentError)}</Typography>}
                <Grid container spacing={10}>
                    {
                        DOCUMNETS.map(doc => (
                            <Grid item xs={4}>
                                <UploadDocument
                                    name={doc.name}
                                    image={(!!userBusiness && !!userBusiness[doc.name]) ? userBusiness[doc.name] : ''}
                                    title={doc?.label}
                                    onChange={uploadDocument}
                                    onDelete={deleteDocumentHandler}
                                    isImageClickable={true}
                                    isLoading={currentFile === doc.name && isLoading}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Typography variant="h6" sx={{ mt: 30, mb: 15 }}>{t("Bank Account Info")}</Typography>
                {isString(validationErrors?.bankAccount) && <Typography color="error">{validationErrors?.bankAccount}</Typography>}
                <Autocomplete
                    value={banks?.find(bank => bank?.id === businessInfo?.bankAccount?.bank) ?? null}
                    onChange={(_, value) => handleChange({ target: { name: 'bankAccount.bank', value: value?.id } })}
                    options={banks ?? []}
                    // onInputChange={(event, newInputValue) => {
                    //     onUserSearchKeyChange(newInputValue)
                    // }}
                    getOptionLabel={(option) => get(option, `name${theme.direction === 'rtl' ? "Ar" : "En"}`)}
                    disablePortal
                    fullWidth
                    size="small"
                    loading={banksLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={!!validationErrors?.bankAccount?.bank}
                            helperText={validationErrors?.bankAccount?.bank ?? ''}
                            label={t("Select Bank")}
                        />
                    )}
                />
                <TextField
                    name="bankAccount.accountNumber"
                    label={t("bankAccountDetails.accountNumber")}
                    value={businessInfo?.bankAccount?.accountNumber ?? ''}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    error={!!validationErrors?.bankAccount?.accountNumber}
                    helperText={validationErrors?.bankAccount?.accountNumber ?? ''}
                />
                <TextField
                    name="bankAccount.accountName"
                    label={t("bankAccountDetails.accountName")}
                    value={businessInfo?.bankAccount?.accountName ?? ''}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    error={!!validationErrors?.bankAccount?.accountName}
                    helperText={validationErrors?.bankAccount?.accountName ?? ''}
                />
                <TextField
                    name="bankAccount.iban"
                    label={t("bankAccountDetails.iban")}
                    value={businessInfo?.bankAccount?.iban ?? ''}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    error={!!validationErrors?.bankAccount?.iban}
                    helperText={validationErrors?.bankAccount?.iban ?? ''}
                />
                <Typography variant="h6" sx={{ mt: 30, mb: 15 }}>{t("Pricing")}</Typography>
                {
                    businessInfo?.pricing?.map((paymentMethod, index) => (
                        <Box display="flex" alignItems="center" my={10} key={paymentMethod?.id}>
                            <Tooltip title={paymentMethod?.payment?.info[theme.direction === "rtl" ? "arabic" : "english"].name}>
                                <img style={{ objectFit: "contain", width: "58px" }} src={generateImageLink(get(paymentMethod, 'payment.image'), "small")} alt={paymentMethod?.payment?.info[theme.direction === "rtl" ? "arabic" : "english"].name} />
                            </Tooltip>
                            <TextFieldNumber
                                sx={{ mx: 5 }}
                                max={999999}
                                name={`${index}.fixedFee`}
                                value={paymentMethod?.fixedFee?.toString() ?? ''}
                                onChange={handleChange}
                                decimal={true}
                                label={t("Fixed fee")}
                                size="small"
                                fullWidth
                                disabled={true}
                                error={!!validationErrors && !!get(validationErrors, `[${index}]?.fixedFee`)}
                                helperText={!!validationErrors && get(validationErrors, `[${index}]?.fixedFee`)}
                            />
                            <TextFieldNumber
                                sx={{ mx: 5 }}
                                max={999999}
                                name={`${index}.percentage`}
                                value={paymentMethod?.percentage?.toString() ?? ''}
                                onChange={handleChange}
                                decimal={true}
                                label={t("Percentage")}
                                size="small"
                                fullWidth
                                disabled={true}
                                error={!!validationErrors && !!get(validationErrors, `[${index}]?.percentage`)}
                                helperText={!!validationErrors && get(validationErrors, `[${index}]?.percentage`)}
                            />
                        </Box>
                    ))
                }
                <Typography variant="h6" sx={{ mt: 30, mb: 15 }}>{t("Payment Period")}</Typography>
                <FormControl sx={{ display: "flex", mb: 10 }}>
                    <RadioGroup
                        row
                        aria-label="payment-type"
                        name="deposit.terms"
                        value={businessInfo?.deposit?.terms}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="Daily" control={<Radio />} label={t("Daily")} />
                        <FormControlLabel value="Weekly" control={<Radio />} label={t("Weekly")} />
                        <FormControlLabel value="Monthly" control={<Radio />} label={t("Monthly")} />
                    </RadioGroup>
                </FormControl>
                {businessInfo?.deposit?.terms === 'Weekly' && (
                    <FormControl fullWidth>
                        <Autocomplete
                            multiple
                            options={DAYS}
                            getOptionLabel={(option) => t(`week.${option?.label}`)}
                            fullWidth
                            value={DAYS?.filter(day => businessInfo?.deposit?.days?.includes(day?.id)) ?? []}
                            onChange={(_, newValue) => {
                                if (newValue.length > 3) return
                                handleChange({ target: { name: 'deposit.days', value: newValue.map(v => v.id) } })
                            }}
                            ChipProps={{
                                size: "small",
                                variant: "outlined",
                                color: "secondary"
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("Select Day")}
                                    name="deposit.days"
                                    size="small"
                                    variant="outlined"
                                    placeholder={t("Choose from one to three days")}
                                    error={!!validationErrors?.deposit?.days}
                                    helperText={validationErrors?.deposit?.days ?? ''}
                                />
                            )}
                        />
                    </FormControl>
                )}
                {businessInfo?.deposit?.terms === 'Monthly' && (
                    <TextFieldNumber
                        size="small"
                        variant="outlined"
                        label={t("Select Day in Month")}
                        name="deposit.days"
                        value={businessInfo?.deposit?.days}
                        onChange={handleChange}
                        fullWidth
                        max={28}
                        error={!!validationErrors?.deposit?.days}
                        helperText={validationErrors?.deposit?.days ?? ''}
                    />
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    color="primary"
                    onClick={onClose}>
                    {t("Close")}
                </Button>
                <Box mr="auto" />
                {
                    (!submitVendorError && !submitVendorIsSuccess) &&
                    <>
                        {!!updateBusinessError && <Typography color="error">{t(updateBusinessError)}</Typography>}
                        {(updateBusinessIsSuccess && !currentFile) && <Typography color="success.main">{t("The data has been confirmed successfully. You can now activate the automatic transfer")}</Typography>}
                    </>
                }
                {!!submitVendorError && <Typography color="error">{t(submitVendorError)}</Typography>}
                {!!documentsError && <Typography color="error">{t(documentsError)}</Typography>}
                {submitVendorIsSuccess && <Typography color="success.main">{t("Done successfully")}</Typography>}
                <Box mr="auto" />
                {
                    (updateBusinessIsSuccess && !currentFile) ?
                        <LoadingButton
                            onClick={submitVendorHandler}
                            variant="contained"
                            sx={{ textTransform: "none" }}
                            loading={submitVendorLoading}
                        >
                            {t("Activate automatic transfer")}
                        </LoadingButton>
                        :
                        <LoadingButton
                            onClick={submitHandler}
                            variant="contained"
                            sx={{ textTransform: "none" }}
                            loading={updateBusinessLoading}
                        >
                            {t("Confirm")}
                        </LoadingButton>
                }
            </DialogActions>
        </Dialog >
    );
};
