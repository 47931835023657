import { useEffect, useState } from 'react'

import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEmpty, map } from 'lodash'
import { LoadingButton } from '@mui/lab'

import { runJoiValidate } from 'utils'

import { useGetSettingsQuery, useUpdateSettingsMutation } from 'api/settingsApi'
import { MIN_SUPPORTED_VERSION_EDIT_SCHEMA } from './app-versions-validation-schemas'

export default function MinimumSupportedVersion({ ios, android }) {

    const { t } = useTranslation();

    const { data: settings } = useGetSettingsQuery({});
    const [updateSettings, { isLoading }] = useUpdateSettingsMutation();
    const [validationErrors, setValidationsErrors] = useState(null);

    useEffect(() => {
        if (settings && settings?.minSupportedVersion) {
            setMinimumSupportedVersion(settings?.minSupportedVersion)
        }
    }, [settings])

    const [minSupportedVersion, setMinimumSupportedVersion] = useState({});

    const handleMinSupportedVersionChange = platform => ({ target: { value } }) => {
        setMinimumSupportedVersion((prev) => ({
            ...prev,
            [platform]: value
        }))
    }

    const handleConfirm = () => {
        setValidationsErrors(null);
        let payload = cloneDeep(minSupportedVersion);
        let errors = runJoiValidate(MIN_SUPPORTED_VERSION_EDIT_SCHEMA, payload);
        if (!!errors) { setValidationsErrors(errors); return; }
        updateSettingsHandler(payload)
    }

    const updateSettingsHandler = async payload => {
        await updateSettings({ minSupportedVersion: payload });
    }

    return (
        <Grid container spacing={8} style={{ marginBlock: 16 }}>
            <Grid item xs={12} >
                <Typography variant='h6'>
                    {t("Minimum Supported Version")}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                <FormControl margin="dense" size="small" fullWidth>
                    <InputLabel id="paltform-label">{t("iOS")}</InputLabel>
                    <Select
                        labelId="paltform-ios"
                        id="ios"
                        name="ios"
                        value={minSupportedVersion?.ios || ""}
                        label={"iOS"}
                        onChange={handleMinSupportedVersionChange("ios")}
                        error={Boolean(validationErrors?.ios)}
                    >
                        {
                            map(
                                ios,
                                version => <MenuItem key={version} value={version}>{version}</MenuItem>
                            )
                        }
                    </Select>
                    {
                        !!validationErrors?.ios && typeof validationErrors?.ios === "string"
                            ? <Typography color="error">{validationErrors?.ios}</Typography>
                            : null
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={5} >
                <FormControl margin="dense" size="small" fullWidth>
                    <InputLabel id="paltform-label">{t("Android")}</InputLabel>
                    <Select
                        labelId="paltform-android"
                        id="android"
                        name="android"
                        value={minSupportedVersion?.android || ""}
                        label={"Android"}
                        onChange={handleMinSupportedVersionChange("android")}
                        error={Boolean(validationErrors?.android)}
                    >
                        {
                            map(
                                android,
                                version => <MenuItem key={version} value={version}>{version}</MenuItem>
                            )
                        }
                    </Select>
                    {
                        !!validationErrors?.android && typeof validationErrors?.android === "string"
                            ? <Typography color="error">{validationErrors?.android}</Typography>
                            : null
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', paddingTop: 16.75}}>
                <LoadingButton
                    variant="contained"
                    disableElevation
                    onClick={handleConfirm}
                    loading={isLoading}
                    sx={{ textTransform: "none", fontWeight: "bold", }}
                    disabled={isLoading || isEmpty(minSupportedVersion)}
                >
                    {t("Confirm")}
                </LoadingButton>
            </Grid>
        </Grid>
    )
}
