import { Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function AdminContactUsMessage({ open, contactusMessage, onClose, onDelete, isSuccess, error, loading }) {
    const { t } = useTranslation()
    return (
        <Dialog
            open={open}
            onClose={(e) => e.preventDefault()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">
                {t("Message")}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container alignItems="center" justifyContent="space-between" spacing={10}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box display="flex" alignItems="center">
                            <Typography sx={{ mr: 1 }}>{t("Name")} : </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>{contactusMessage?.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box display="flex" alignItems="center">
                            <Typography sx={{ mr: 1 }}>{t("Phone")} : </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                                <bdi>
                                    {contactusMessage?.phone}
                                </bdi>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box display="flex" alignItems="center">
                            <Typography sx={{ mr: 1 }}>{t("Created At")} : </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>{moment(contactusMessage?.createdAt).format("DD/MM/YYYY - hh:mm A")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box display="flex" alignItems="center">
                            <Typography sx={{ mr: 1 }}>{t("Subject")} : </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>{contactusMessage?.subject}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6">{t("Message")} : </Typography>
                        <Box display="flex" alignItems="center" border="1px solid" borderColor="primary.main" p={1} borderRadius={2} mt={2}>
                            <Typography sx={{ fontWeight: "bold", display: "flex" }}> {contactusMessage?.message}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                {/* <LoadingButton
                    color="error"
                    loading={loading}
                    sx={{ textTransform: "none", color: "white", mx: '4px', fontWeight: 'bold' }}
                    variant='contained'
                    onClick={() => onDelete(contactusMessage?.id)}
                    autoFocus
                >
                    {t("Delete")}
                </LoadingButton> */}
                <Box>
                    {isSuccess && <Typography color="success.main">{t("Done successfully")}</Typography>}
                    {!!error && <Typography color="error">{t(error)}</Typography>}
                </Box>
                <Button
                    sx={{ textTransform: "none", color: "white", fontWeight: 'bold' }}
                    variant='contained'
                    onClick={() => onClose()}
                >
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
