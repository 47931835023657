import { useMemo } from 'react';
import PhoneInput from 'react-phone-number-input'
import { PropTypes } from "prop-types";
import PhoneInputComponent from './PhoneInputComponent';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { includes, isEmpty } from 'lodash';
import { useGetCurrentCountry } from 'modules/hooks';
import { useSelector } from 'react-redux';
import 'react-phone-number-input/style.css'

const MapitPhoneInput = ({ countries, defaultCountry, value, onChange, error, children, containerProps, style, ...props }) => {

    const { t } = useTranslation();

    const { language } = useSelector(state => state?.lang)

    const handleChangeInside = value => {
        const e = { target: { name: props.name, value } }
        onChange(e)
    }

    const { profile } = useSelector(state => state?.auth);

    const { currentCountry } = useGetCurrentCountry()

    const country = useMemo(() => {
        if (profile?.country) {
            return profile?.country?.isoCode
        } else if (!!currentCountry?.country_code && countries?.includes(currentCountry?.country_code)) {
            return currentCountry?.country_code
        } else {
            return defaultCountry
        }
    }, [profile, currentCountry])

    return (
        <Box >
            <Box dir="ltr">
                <PhoneInput
                    style={{ ...style }}
                    placeholder="Enter phone number"
                    value={value}
                    onChange={handleChangeInside}
                    defaultCountry={country}
                    countries={countries}
                    addInternationalOption={false}
                    international={true}
                    countryCallingCodeEditable={false}
                    error={!!error}
                    inputComponent={PhoneInputComponent}
                    flags={{
                        SA: (props) => <img alt="sa" width={"100%"} src={require("./flags/SA.svg").default} />,
                        EG: (props) => <img alt="eg" width={"100%"} src={require("./flags/EG.svg").default} />
                    }}
                    {...props}
                />
            </Box>
            {
                error &&
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "0.75rem",
                        letterSpacing: "-0.05px",
                        fontFamily: language === "en-US" ? 'Poppins' : 'IBM Plex Sans Arabic',
                        lineHeight: 1.66,
                        marginLeft: "14px",
                        marginBottom: 0,
                        marginRight: "14px",
                        color: "#d32f2f"
                    }}
                    color='text.danger'
                >
                    {t(error)}
                </Typography>
            }
        </Box>
    )
}

MapitPhoneInput.propTypes = {
    countries: PropTypes.array.isRequired,
    defaultCountry: function (props, propName, componentName) {
        if (
            props['countries'] &&
            !isEmpty(props['countries']) &&
            !includes(props['countries'], props[propName])
        ) {
            return new Error(
                'Invalid prop `' + propName + '` supplied to' +
                ' `' + componentName + '`. Expected one of: ' + JSON.stringify(props['countries'])
            );
        }
    },
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    containerProps: PropTypes.object,
    props: PropTypes.object
};

MapitPhoneInput.defaultProps = {
    countries: process.env.REACT_APP_ENV === "production" ? ["SA", "AE"] : ["SA", "AE", "EG"],
    defaultCountry: window?.location?.href?.includes("ezpayuae") ? "AE" : "SA",
};

export default MapitPhoneInput;