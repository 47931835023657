import { Analytics, Business, Group, Paid, Person, Receipt, ReceiptLong } from "@mui/icons-material";
import { Avatar, Box, Divider, Drawer, Toolbar, useMediaQuery } from "@mui/material";
import { Logo } from "_assets";
import { DrawerList } from "layouts/common";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
const drawerWidth = 250;

export default function MerchantDrawer({ openDrawer, toggleDrawer }) {
    const navigate = useNavigate()
    const lessThanSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"));
    const { profile } = useSelector(state => state?.auth);

    const LIST_ITEMS = [
        {
            title: "Statistics",
            href: "/merchant",
            icon: Analytics,
        },
        {
            title: "Create Invoice",
            href: "/merchant/create-transaction",
            icon: Receipt,
        },
        {
            title: "Transactions",
            href: "/merchant/transactions",
            icon: Paid,
        },
        {
            title: "Invoices",
            href: "/merchant/invoices",
            icon: ReceiptLong,
        },
        {
            title: "Users",
            href: "/merchant/users",
            icon: Group,
        },
        {
            title: "Profile",
            href: "/merchant/profile",
            icon: Person,
        },
        {
            title: "Business Profile",
            href: "/merchant/business-profile",
            icon: Business
        }
    ]

    const drawerList = useMemo(() => {
        const parentItems = ["/merchant/invoices", "/merchant/users", "/merchant/business-profile"]
        if (!!profile?.parent) {
            return LIST_ITEMS.filter((item) => !parentItems.includes(item.href))
        }
        return LIST_ITEMS
    }, [profile, LIST_ITEMS])

    return (
        <Drawer
            open={openDrawer}
            onClose={toggleDrawer}
            variant={lessThanSmall ? "temporary" : "permanent"}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box display="flex" justifyContent="center" alignItems="center" my={5} mb={10}>
                <Avatar onClick={() => navigate("/")} variant='square' src={Logo} sx={{ height: 75, width: 75, cursor: "pointer" }} style={{ objectFit: "cover" }} />
            </Box>
            <Divider />
            <Box mt={10} sx={{ overflow: 'auto' }}>
                <DrawerList tabs={drawerList} />
            </Box>
        </Drawer>
    )
}