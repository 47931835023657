import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from 'App';
import { Provider } from "react-redux";
import StyleWrapper from './StyleWrapper';
import { store } from 'store';
import { MYTFATOORAH_APPLE_PAY_PRODUCTION, MYTFATOORAH_APPLE_PAY_PRODUCTION_SA, MYTFATOORAH_APPLE_PAY_TEST, MYTFATOORAH_CARDS_PRODUCTION, MYTFATOORAH_CARDS_PRODUCTION_SA, MYTFATOORAH_CARDS_TEST } from 'constants';

const root = ReactDOM.createRoot(document.getElementById('root'));

const setScriptsURLs = (card, apple) => {

    const cardScript = document.createElement('script');
    cardScript.src = card;
    document.head.appendChild(cardScript);

    const appleScript = document.createElement('script');
    appleScript.src = apple;
    document.head.appendChild(appleScript);
  }

  const getRestaurantCountry = async () => {
    try {
      const country = new URLSearchParams(window.location.search).get('country')
      if (process.env.REACT_APP_ENV ==="production") {
        if (country === "SA") {
          setScriptsURLs(MYTFATOORAH_CARDS_PRODUCTION_SA, MYTFATOORAH_APPLE_PAY_PRODUCTION_SA)
        } else {
          setScriptsURLs(MYTFATOORAH_CARDS_PRODUCTION, MYTFATOORAH_APPLE_PAY_PRODUCTION)
        }
      } else {
        setScriptsURLs(MYTFATOORAH_CARDS_TEST, MYTFATOORAH_APPLE_PAY_TEST)
      }
    } catch (error) {
      console.log('getRestaurantCountry Error:', error);
    }
  }

if(window.location.pathname === "/payment"){
    getRestaurantCountry()
}

root.render(
    <Provider store={store}>
        <StyleWrapper>
            <App />
        </StyleWrapper>
    </Provider>
);

