import { Box, Grid } from "@mui/material";
import { useGetSettingsQuery, useUpdateSettingsMutation } from "api/settingsApi";
import LanguagesCard from "./LanguagesCard";
import TransactionExpiredCard from "./TransactionExpiredCard";
import SocialLinksCard from "./SocialLinksCard";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { ADMIN_TYPES } from "constants";

export default function AdminSettings() {
    const { t } = useTranslation();
    const { profile } = useSelector(state => state?.auth);

    const { data: settings, refetch: refetchSettings } = useGetSettingsQuery();

    const [updateSettings, {
        isLoading: updateSettingsLoading,
        error: updateSettingsError,
        isSuccess: updateSettingsIsSuccess
    }
    ] = useUpdateSettingsMutation()

    const updateSettingsHandler = (payload) => {
        updateSettings(payload)
    }

    const allowUpdate = useMemo(() => {
        if (!!profile && profile?.type !== ADMIN_TYPES.accountManager) {
            return true
        }
        return false
    }, [profile])

    return (
        <>
            <Helmet>
                <title>{t("Settings")} | {$APP_NAME}</title>
            </Helmet>
            <Box sx={{ my: 20, mr: 5 }}>
                <Grid container alignItems="flex-start" spacing={10}>
                    <Grid item md={6} sm={12} xs={12}>
                        <LanguagesCard />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TransactionExpiredCard currentExpiryAt={settings?.expiryAt} refetchSettings={refetchSettings} allowUpdate={allowUpdate} />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <SocialLinksCard
                            settings={settings}
                            loading={updateSettingsLoading}
                            error={updateSettingsError}
                            isSuccess={updateSettingsIsSuccess}
                            onSubmit={updateSettingsHandler}
                            allowUpdate={allowUpdate}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}