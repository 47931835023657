import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Fragment, useMemo, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next";
import DrawerList from "./DrawerList";

export default function DrawerListItem({ tab }) {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const location = useLocation()

    const [searchParams] = useSearchParams();
    const depositType = searchParams.get('depositType')

    const isSelected = useMemo(() => {
            let path = tab?.href?.split('?')[0]
            let search = tab?.href?.split('?')[1]
            return location.pathname === path &&
                ((!Boolean(location?.search)) ? true : search?.includes(depositType))
    }, [tab, location])

    return (
        <>
            {
                tab?.children?.length ?
                    <Fragment>
                        <ListItemButton
                            sx={{ color: "text.title" }}
                            onClick={() => setOpen((prevState) => !prevState)}
                            selected={isSelected}
                        >
                            {!!tab?.icon &&
                                <ListItemIcon sx={{ color: "primary.main" }}>
                                    <tab.icon />
                                </ListItemIcon>
                            }
                            <ListItemText primary={t(tab.title)} sx={{ color: "black" }} />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <DrawerList tabs={tab?.children} sx={{ pl: 15 }} />
                        </Collapse>
                    </Fragment>
                    :
                    <ListItem
                        disablePadding
                        component={Link}
                        to={tab?.href}
                        sx={{ color: "text.title" }}
                        secondaryAction={tab?.secondaryAction}
                    >
                        <ListItemButton
                            selected={isSelected}
                        >
                            {!!tab?.icon &&
                                <ListItemIcon sx={{ color: "primary.main" }}>
                                    <tab.icon />
                                </ListItemIcon>
                            }
                            <ListItemText primary={t(tab?.title)} sx={{ color: "black" }} />
                        </ListItemButton>
                    </ListItem>
            }
        </>
    )
}