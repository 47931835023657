import React from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { useSelector } from "react-redux";
import { PublicRoutes } from "./public";
import { AuthRoutes } from "./auth";
import { AdminRoutes } from "./admin";
import { MerchantRoutes } from "./merchant";


export default function Router() {

    const { isAuth, profile } = useSelector(state => state.auth)

    const router = createBrowserRouter([
        ...MerchantRoutes({ role: profile?.role, currentStatus: profile?.currentStatus}),
        ...AdminRoutes({ role: profile?.role }),
        ...PublicRoutes,
        ...AuthRoutes({ isAuth, role: profile?.role }),
        { path: "*", element: <Navigate to="/" /> },
    ]);
    return (
        <RouterProvider router={router} />
    )
}