import * as React from 'react';
import {
    Box,
    Drawer,
    Toolbar,
    List,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Fab,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { Article } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = 240;

export default function DocumentDrawer() {
    const lessThanSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"));
    const [open, setOpen] = React.useState(true)

    const location = useLocation()

    React.useEffect(() => {
        if (lessThanSmall) {
            setOpen(false)
        }
    }, [])

    const theme = useTheme();
    const style = React.useMemo(() => {
        if (theme.direction === 'rtl') {
            return {
                right: 0,
                left: 'unset'
            }
        }
        return {}
    }, [theme.direction])

    return (
        <>
            {lessThanSmall &&
                <Fab
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                    }}
                    variant="extended"
                    onClick={() => setOpen(true)}
                >
                    Documentation List
                </Fab>
            }
            <Drawer
                anchor="left"
                direction="ltr"
                open={open}
                onClose={() => setOpen(prev => !prev)}
                variant={lessThanSmall ? "temporary" : "permanent"}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    direction: 'ltr',
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        borderRight: 'none',
                        direction: 'ltr',
                        ...style
                    },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto', direction: "ltr" }}>
                    <List>
                        <Divider sx={{ mb: 20 }} />
                        <Box display="flex" justifyContent="center" alignItems="center" my={5} mb={10}>
                            <Typography variant="h6" color="primary" sx={{ fontWeight: "bold" }}>Documentation List</Typography>
                        </Box>
                        <Divider sx={{ mb: 20 }} />
                        <ListItem
                            sx={{ color: "black" }}
                            to="/documentation"
                            component={Link}
                            disablePadding>
                            <ListItemButton
                                style={{ direction: "ltr", textAlign: "left" }}
                                selected={location.pathname === "/documentation"}
                                onClick={() => setOpen((prevState) => !prevState)}
                            >
                                <ListItemIcon>
                                    <Article />
                                </ListItemIcon>
                                <ListItemText primary={"For Your Website"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            sx={{ color: "black" }}
                            to="/documentation/api"
                            component={Link}
                            disablePadding>
                            <ListItemButton
                                style={{ direction: "ltr", textAlign: "left" }}
                                selected={location.pathname === "/documentation/api"}
                                onClick={() => setOpen((prevState) => !prevState)}
                            >
                                <ListItemIcon>
                                    <Article />
                                </ListItemIcon>
                                <ListItemText primary={"For Your System"} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    )
}
