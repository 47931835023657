import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Card, CardActions, CardContent, CardHeader, Divider, Typography, Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { cloneDeep, get, set } from "lodash";
import { generateImageLink, runJoiValidate } from "utils";
import { RESTAURNAT_PAYMENT_METHODS_EDIT_SCHEMA } from "./validationSchemas";
import { TextFieldNumber } from "modules/common";
import { useTheme } from "@emotion/react";

export default function PaymentMethodsCard({
    title,
    onConfirm,
    isSuccess,
    error,
    loading,
    pricingData,
    allowUpdate
}) {
    const { t } = useTranslation();
    const theme = useTheme()

    //===========Payment Methods============
    const [pricing, setPricing] = useState([]);


    const [validationErrors, setValidationErrors] = useState(null);

    useEffect(() => {
        if (!!pricingData) {
            setPricing(cloneDeep(pricingData));
        }
    }, [pricingData]);


    const handleChange = ({ target: { name, value } }) => {
        let payload = cloneDeep(pricing);
        let keys = name.split(".");
        let obj = payload[keys[0]]
        set(obj, keys[1], value);
        setPricing(payload);
    };

    const updatePaymentMethodsPricingHandler = () => {
        try {
            setValidationErrors(null);
            let payload = cloneDeep(pricing);

            payload = payload.map((item) => {
                return {
                    payment: item.payment.id,
                    fixedFee: Number(item.fixedFee),
                    percentage: Number(item.percentage),
                }
            })

            const errors = runJoiValidate(RESTAURNAT_PAYMENT_METHODS_EDIT_SCHEMA, payload);
            console.log(payload);
            console.log(errors);
            if (!!errors) {
                setValidationErrors(errors);
                return;
            }
            onConfirm(payload);
        } catch (error) {
            console.log("Error: updatePaymentMethodsPricingHandler");
            console.log(error);
        }
    };

    return (
        <Card>
            <CardHeader title={title} />
            <Divider />
            <CardContent>
                {
                    pricing?.map((paymentMethod, index) => (
                        <Box display="flex" alignItems="center" my={10} key={paymentMethod?.id}>
                            <Tooltip title={paymentMethod?.payment?.info[theme.direction === "rtl" ? "arabic" : "english"].name}>
                                <img style={{ objectFit: "contain", width: "58px" }} src={generateImageLink(get(paymentMethod, 'payment.image'), "small")} alt={paymentMethod?.payment?.info[theme.direction === "rtl" ? "arabic" : "english"].name} />
                            </Tooltip>

                            <TextFieldNumber
                                sx={{ mx: 5 }}
                                max={999999}
                                name={`${index}.fixedFee`}
                                value={paymentMethod?.fixedFee?.toString() ?? ''}
                                onChange={handleChange}
                                decimal={true}
                                label={t("Fixed fee")}
                                size="small"
                                fullWidth
                                error={!!validationErrors && !!get(validationErrors, `[${index}]?.fixedFee`)}
                                helperText={!!validationErrors && get(validationErrors, `[${index}]?.fixedFee`)}
                                disabled={!allowUpdate}
                            />
                            <TextFieldNumber
                                sx={{ mx: 5 }}
                                max={999999}
                                name={`${index}.percentage`}
                                value={paymentMethod?.percentage?.toString() ?? ''}
                                onChange={handleChange}
                                decimal={true}
                                label={t("Percentage")}
                                size="small"
                                fullWidth
                                error={!!validationErrors && !!get(validationErrors, `[${index}]?.percentage`)}
                                helperText={!!validationErrors && get(validationErrors, `[${index}]?.percentage`)}
                                disabled={!allowUpdate}
                            />
                        </Box>
                    ))
                }
            </CardContent>
            <Divider />
            {
                allowUpdate
                    ?
                    <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                        <LoadingButton
                            variant="contained"
                            onClick={updatePaymentMethodsPricingHandler}
                            loading={loading}
                            sx={{ textTransform: "none" }}
                        >
                            {t("Confirm", { ns: "main" })}
                        </LoadingButton>
                        {!!error && <Typography color="error">{t(error)}</Typography>}
                        {(!!validationErrors && typeof validationErrors === "string") && <Typography color="error">{validationErrors}</Typography>}
                        {!!isSuccess && <Typography color="success.main">{t("Done successfully", { ns: "main" })}</Typography>}
                    </CardActions>
                    : null
            }
        </Card>
    );
}
