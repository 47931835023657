import { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, Box, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Campaign, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Mada, Visa } from "_assets";
import { useNavigate } from "react-router-dom";

export default function AlertDialog() {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        const isAdAppear = localStorage.getItem("isAdAppear");
        const token = localStorage.getItem("token");

        if (!token) {
            if (isAdAppear === "CLOSE" || !isAdAppear) {
                setTimeout(() => {
                    setOpen(true);
                    localStorage.setItem("isAdAppear", "OPEN");
                }, 5000);
            }
        }
    }, [])

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    minWidth: { xs: '95vw', sm: "70vw", md: '80vw', lg: '70vw', xl: '57vw' },
                    minHeight: '30vh',
                }
            }}
        >
            <DialogContent sx={{ display: "flex", p: 0, flexDirection: { xs: 'column-reverse', md: 'row' } }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, p: 20 }} whiteSpace="pre-wrap">
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, p: { xs: 0, md: 20 }, alignItems: { xs: 'center', md: 'flex-start'} }} whiteSpace="pre-wrap">
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                            <Typography
                                sx={{
                                    mb: { xs: 0, md: 10 },
                                    fontSize: { xs: "1.8rem", sm: "2rem", md: "2rem", lg: "2.5rem" },
                                }}
                            >
                                {t("landing.pricing.limitedTimeOffer")}
                            </Typography>
                            <Campaign sx={{ marginInlineStart: 10, fontSize: 28, transform: theme?.direction === "rtl" ? `scale(-1, 1)` : 'none' }} color="danger" />
                        </Box>
                        <Typography
                            sx={{ fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.4rem" },  mt: isXsScreen ? 20 : 0 }}
                            color="#BA245F"
                            whiteSpace="pre-wrap" 
                            >
                            {t("landing.pricing.cta-ad")}
                        </Typography>
                    </Box>
                    <Button onClick={() => navigate("/auth/register")} variant="contained" color="primary" sx={{ justifySelf: 'flex-end', textTransform: 'none', mt: 10 }}>
                        {t("Register to get the offer")}
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 0.75, backgroundColor: 'grey.100' }}>
                    <IconButton onClick={handleClose} sx={{ alignSelf: "end", p: 10 }}>
                        <Close sx={{ fontSize: "inherit" }} />
                    </IconButton>
                    <Box sx={{ display: 'flex', flexDirection: { xs: "row", md: "column" }, flex: 1, justifyContent: { xs: "space-around", md: "center" } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 15 }} >
                            <img src={Mada} style={{ height: 30 }} alt="mada" />
                            <Typography fontWeight="bold" variant="h5" color="text.secondary" component="div" sx={{ mt: 6 }}>
                                {t("1.1%")} + <span style={{ textDecoration: "line-through", textDecorationColor: "red" }}>{t("1 SAR")}</span>
                            </Typography>
                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                {t("landing.pricing.perTransaction")}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 15 }} >
                            <img src={Visa} style={{ height: 27 }} alt="mada" />
                            <Typography fontWeight="bold" variant="h5" color="text.secondary" component="div" sx={{ mt: 6 }}>
                                {t("2.5%")} + <span style={{ textDecoration: "line-through", textDecorationColor: "red" }}>{t("1 SAR")}</span>
                            </Typography>
                            <Typography variant="subtitle2" color="text.secondary" component="div">
                                {t("landing.pricing.perTransaction")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
