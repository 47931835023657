import { useLayoutEffect, useRef, useState } from "react";

import { Box, Button, Card, CardContent, Grid, Typography, useTheme } from "@mui/material";
import { Campaign } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { $APP_NAME } from "constants";
import { Mada, Visa } from "_assets";

import { Footer, QuickLinks } from "./common";

export default function Pricing() {

    const { t } = useTranslation();
    const { direction } = useTheme();

    const targetRef = useRef();
    const [height, setHeight] = useState({});

    useLayoutEffect(() => {
        console.log(targetRef.current.offsetHeight);
        if (targetRef.current) {
            setHeight({
                height: targetRef.current.offsetHeight + "px",
            })
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{t("Pricing")} | {$APP_NAME}</title>
            </Helmet>
            <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1} whiteSpace="pre-wrap">
                <Box display="flex" flex={1} backgroundColor="white" justifyContent="center" padding={20} >
                    <Grid container maxWidth="lg" justifyContent="center" alignItems="center" spacing={16} >
                        <Grid item xs={12} sx={{ mb: 15 }}>
                            <Box display="flex" alignItems="center" justifyContent="center" mb={20}>
                                <Typography variant="h3" color="primary">{t("Ezpay Pricing")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} sm={8} xs={10}>
                            <Card sx={{ display: 'flex' }} ref={targetRef}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <Typography variant="h4" component="div">
                                            {t("landing.pricing.basic")}
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center' }}>
                                            <Typography variant="subtitle1" component="div">
                                                {t("landing.pricing.noSetupFees")}
                                            </Typography>
                                            <Typography variant="subtitle1" component="div">
                                                {t("landing.pricing.noMonthlyFees")}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 0.75, backgroundColor: 'grey.100' }}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flex: 1 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                            <img src={Mada} style={{ height: 20 }} alt="mada" />
                                            <Typography fontWeight="bold" variant="h6" color="text.secondary" component="div" sx={{ mt: 6 }}>
                                                {t("landing.pricing.mada")}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary" component="div">
                                                {t("landing.pricing.perTransaction")}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 25 }} >
                                            <img src={Visa} style={{ height: 16 }} alt="visa" />
                                            <Typography fontWeight="bold" variant="h6" color="text.secondary" component="div" sx={{ mt: 6 }}>
                                                {t("landing.pricing.visa")}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary" component="div">
                                                {t("landing.pricing.perTransaction")}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item md={6} sm={8} xs={10}>
                            <Card sx={{ display: 'flex', height: height }} >
                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', flex: 1 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                            <Typography variant="h4" component="div">
                                                {t("landing.pricing.limitedTimeOffer")}
                                            </Typography>
                                            <Campaign sx={{ marginInlineStart: 10, fontSize: 28, transform: direction === "rtl" ? `scale(-1, 1)` : 'none' }} color="danger" />
                                        </Box>
                                        <Typography variant="h6" color="#BA245F" component="div">
                                            {t("landing.pricing.cta")}
                                        </Typography>
                                        <Button href="/auth/register" variant="contained" color="primary" sx={{ justifySelf: 'flex-end', textTransform: 'none' }}>
                                            {t("landing.pricing.register")}
                                        </Button>
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <QuickLinks allowSocial={true} />
                <Footer />
            </Box>
        </>
    )
}