import { Box, CssBaseline, Toolbar } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import MerchantAppBar from "./MerchantAppBar";
import MerchantDrawer from "./MerchantDrawer";

export default function MerchantLayout() {
    const [openDrawer, setOpenDrawer] = useState(false)
    const toggleDrawer = () => {
        setOpenDrawer(prevStatus => !prevStatus)
    }
    return (
        <Box display="flex">
            <CssBaseline />
            <MerchantAppBar toggleDrawer={toggleDrawer} />
            <MerchantDrawer openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
            <Toolbar />
            <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: "auto" }}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    )
}