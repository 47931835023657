import { useEffect, useRef, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, Container, CssBaseline, Link, TextField, Typography, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useLazyGetProfileQuery, useVeifyPhoneMutation } from "api/authApi";
import { setIsAuth, setProfile, setToken } from "store/authSlice";
import { Logo } from "_assets";
import { startSocket } from "socket";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";

export default function PhoneVerifcationCode() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [getProfile] = useLazyGetProfileQuery()

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    const phone = searchParams.get('phone')
    const email = searchParams.get('email')

    // Firebase Authentication    
    const [code, setCode] = useState('');

    const handleVerifyCode = async (e) => {
        try {
            e.preventDefault();
            setLoading(true)
            proceedWithRegistrationAndLogin()
        } catch (error) {
            setLoading(false)
        }
    };

    // Registration Completion and Login    
    const [loading, setLoading] = useState(false)
    const [verifyPhone, { error }] = useVeifyPhoneMutation()

    const proceedWithRegistrationAndLogin = async () => {
        try {
            setLoading(true);
            const data = await verifyPhone({ user: id, code }).unwrap()
            const { token } = data

            dispatch(setToken(token))

            await new Promise((resolve) => setTimeout(resolve, 500));

            let response = await getProfile()

            dispatch(setProfile(response?.data?.body))
            dispatch(setToken(token))
            dispatch(setIsAuth(true))

            startSocket({ userId: response?.data?.body?.id, jwt: token });
            navigate("/merchant");

        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    //======= Remaining time handlers =======
    const remainingTimeRef = useRef(null)
    const [remainingTime, setRemainingTime] = useState(60);

    const startTimer = () => {
        remainingTimeRef.current = setInterval(() => {
            const currentValue = Number(remainingTime);
            let newValue = currentValue - 1;
            if (newValue < 0) {
                return clearInterval(remainingTimeRef.current);
            }
            setRemainingTime(newValue < 10 ? `0${newValue}` : newValue)
        }, 1000);
    }

    useEffect(() => {
        startTimer()
        return () => clearTimeout(remainingTimeRef.current)
    }, [remainingTime])

    const resendCode = async () => {
        setRemainingTime(60);
        await new Promise((resolve) => setTimeout(resolve, 500));
        startTimer();
    }

    return (
        <>
            <Helmet>
                <title>{t("Phone Verification")} | {$APP_NAME}</title>
            </Helmet>
            <Container sx={{ padding: 16 }}>
                <CssBaseline />
                <Box sx={{ marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <Box component="a" href="/" sx={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }} >
                        <img alt='white-logo' height="auto" src={Logo} style={{ cursor: "pointer", width: 100 }} />
                    </Box>
                    <Grid container justifyContent="center" alignItems="center" sx={{ textAlign: "center", my: 10 }}>
                        <Grid item xs={12} sm={8} md={5} lg={4} xl={4}>
                            <Typography sx={{ fontWeight: "bold" }} color="secondary">
                                {t("Phone verification code was sent to")}
                            </Typography>
                            <bdi style={{ display: "flex", direction: "ltr", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                                <Typography sx={{ direction: "ltr" }} fontWeight="bold">
                                    +
                                </Typography>
                                <Typography sx={{ direction: "ltr" }} fontWeight="bold">
                                    {phone}
                                </Typography>
                            </bdi>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={8} md={5} lg={4} xl={4}>
                            <Box component="form" onSubmit={handleVerifyCode} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    size="small"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="code"
                                    label={t("Verification Code")}
                                    id="code"
                                    autoComplete="code"
                                />
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    fullWidth
                                    variant="contained"
                                    disabled={!code}
                                    sx={{ my: 15, textTransform: 'none' }}
                                >
                                    {t("Verify")}
                                </LoadingButton>
                                {
                                    error
                                        ?
                                        <Box textAlign="center">
                                            <Typography color="error">{t(error)}</Typography>
                                        </Box>
                                        : null
                                }
                                <Box display="flex" justifyContent="space-between" textAlign="center">
                                    <Box display="flex" alignItems="center" sx={{ minHeight: 40 }}>
                                        <Typography variant="body2">
                                            {`${t("Didn't receive the code?")}`}
                                        </Typography>
                                        {
                                            Number(remainingTime) !== 0 ?
                                                <Typography variant="body2" sx={{ marginInlineStart: 4 }}>
                                                    {`00:${remainingTime}`}
                                                </Typography>
                                                :
                                                <Button
                                                    variant="text"
                                                    onClick={resendCode}
                                                    sx={{ textTransform: 'none' }}
                                                >
                                                    {t("Resend Code")}
                                                </Button>
                                        }
                                    </Box>
                                    <Box>
                                        <Button
                                            sx={{ textTransform: "none" }}
                                            onClick={() => navigate(`/auth/change-phone?id=${id}&currentPhone=${phone}&email=${email}`)}
                                        >
                                            {t("Change Phone")}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <div id="recaptcha-container" >

                </div>
                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 18, mb: 4 }}>
                    {t('Copyright © ')}
                    <Link color="inherit" href=""> EzPay </Link>{' '}
                    {new Date().getFullYear()}
                </Typography>
            </Container>
        </>
    )
}