import { Block, Logout } from '@mui/icons-material';
import { Box, Container, IconButton, Link, Typography } from '@mui/material';
import { $APP_NAME } from 'constants';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logOut } from "store/authSlice";

export default function BlockPage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logOut())
        return navigate("/", { replace: true })
    }

    const supportContacts = useMemo(() => {
        // TODO: to be replaced with UAE frontend env variable
        if (window?.location?.href?.includes("ezpayuae")) {
            return { phone: "+971509443956", email: "pm@ezpayuae.com" }
        } else {
            return { phone: "+966581849999", email: "info@ezpay.sa" }
        }
    }, [window])

    return (
        <>
            <Helmet>
                <title>{t("Block")} | {$APP_NAME}</title>
            </Helmet>
            <Container>
                <IconButton color="primary" size="large" onClick={() => logoutHandler()}>
                    <Logout />
                </IconButton>
                <Box display="flex" justifyContent="center" alignItem="center" flexDirection="column" textAlign="center" marginTop={50}>
                    <Box display="flex" justifyContent="center" alignItem="center">
                        <Block color="error" sx={{ fontSize: 300 }} />
                    </Box>
                    <Typography variant="h3" color="error" fontWeight="bold" alignSelf="center">
                        {t("Business Blocked!")}
                    </Typography>
                    <Typography variant="h5" sx={{ my: 20 }}>
                        {t("Your business has been blocked! Kindly contact ezPay adminstration.")}
                    </Typography>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
                        <Typography
                            sx={{ fontSize: "15px", fontWeight: "300", lineHeight: 1.5, color: "rgb(107 119 140)" }}>
                            {t("block.email")}
                            <Link style={{ marginInlineStart: 8 }} color="inherit" href={`mailto:${supportContacts?.email}`}>{supportContacts?.email}</Link>
                        </Typography>
                        <Typography
                            sx={{ fontSize: "15px", fontWeight: "300", lineHeight: 1.5, color: "rgb(107 119 140)" }}>
                            {t("block.phone")}
                            <Link style={{ marginInlineStart: 8 }} color="inherit" href={`tel:${supportContacts?.phone}`}>
                                <bdi>{supportContacts?.phone}</bdi>
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </>
    )
}
