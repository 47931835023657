import { isEmpty, omitBy } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

function useQueryParams() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryParams = (params) => {
    params = omitBy(params, v => v === '' || v === null || v === undefined);

    setSearchParams(params);
  }

  const getQueryParams = () => {
    const queryParams = Object.fromEntries(searchParams.entries());
    return queryParams;
  }

  useEffect(() => {
    const queryParams = getQueryParams()
    if (!isEmpty(queryParams)) setQueryParams(queryParams);
  }, []);

  return { setQueryParams, getQueryParams };
}

export default useQueryParams;
