import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { get, includes } from "lodash";
import { useLocation } from "react-router-dom";

import { $APP_NAME } from "constants";
import { Footer, QuickLinks } from "./common";
import { useGetSettingsQuery } from "api/settingsApi";
import { useTranslation } from "react-i18next";

export default function TermsAndConditions() {
    const { t } = useTranslation()
    const { language } = useSelector(state => state?.lang);
    const { data: settings } = useGetSettingsQuery();
    const { pathname } = useLocation();

    return (
        <>
            <Helmet>
                <title>{t("Terms And Conditions")} | {$APP_NAME}</title>
            </Helmet>
            <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1} py={20} backgroundColor="white">
                <Container>
                    <div style={{ paddingInline: 24 }} dangerouslySetInnerHTML={{ __html: get(settings, `terms_conditions_${language === "ar-SA" ? "ar" : "en"}`) }} />
                </Container>
            </Box>
            {
                includes(pathname, 'webview')
                    ? null
                    :
                    <>
                        <QuickLinks allowSocial={true} />
                        <Footer />
                    </>
            }
        </>
    )
}