import { useState } from 'react'
import { Box, Button, Paper, Typography, useTheme } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useCreateSubAdminMutation, useDeleteSubAdminMutation, useGetSubAdminsQuery, useUpdateSubAdminMutation } from 'api/subAdminApis'
import SubAdminDialog from './sub-admin-components/SubAdminDialog'
import { get, isObject } from 'lodash'
import { useDispatch } from 'react-redux'
import { openConfirmDialog } from 'store/confirmationDialogSlice'
import { DataTable } from 'modules/common'
import { Helmet } from 'react-helmet'
import { $APP_NAME } from 'constants'
import { ADMIN_TYPES } from 'constants'

const $COLUMN_WIDTH = 200
export default function Countries() {
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()

    //==============SubAdmin data depend on coutries-list packages=======
    const [selectedSubAdmin, setSelectedSubAdmin] = useState()


    //==============Countries CRUD operations=========
    const { data: subAdmins, isLoading: subAdminsLoading, isFetching: subAdminsFetching } = useGetSubAdminsQuery({ limit: 100 })

    //======= Add =======
    const [addSubAdmin, {
        isLoading: addSubAdminLoading,
        isSuccess: addSubAdminIsSuccess,
        error: addSubAdminError,
        reset: addSubAdminReset
    }] = useCreateSubAdminMutation()

    const addSubAdminHandler = async (payload) => {
        try {
            await addSubAdmin(payload).unwrap()
            setTimeout(() => {
                closeDialogHandler()
            }, 2000);
        } catch (error) { }
    }

    //======= Update =======
    const [updateSubAdmin, {
        isLoading: updateSubAdminLoading,
        isSuccess: updateSubAdminIsSuccess,
        error: updateSubAdminError,
        reset: updateSubAdminReset
    }] = useUpdateSubAdminMutation()

    const updateSubAdminHandler = async (payload) => {
        try {
            await updateSubAdmin(payload).unwrap()
            setTimeout(() => {
                closeDialogHandler()
            }, 2000);
        } catch (error) { }
    }

    //======= Delete =======
    const [deleteSubAdmin, {
        isLoading: deleteSubAdminLoading,
        isSuccess: deleteSubAdminIsSuccess,
        error: deleteSubAdminError,
    }] = useDeleteSubAdminMutation()

    const deleteSubAdminHandler = async (id) => {
        try {
            await deleteSubAdmin(id).unwrap()
            setTimeout(() => {
                closeDialogHandler()
            }, 500);
        } catch (error) { }
    }

    const confirmDeleteSubAdmin = (id) => {
        dispatch(openConfirmDialog({
            open: true,
            onConfirm: () => deleteSubAdminHandler(id)
        }))
    }

    //=============SubAdmin Dialog============
    const [isEditing, setIsEdition] = useState(false)
    const [openSubAdminDialog, setOpenSubAdminDialog] = useState(false)

    const openSubAdminDialogHandler = (subAdmin) => {
        addSubAdminReset()
        updateSubAdminReset()
        if (!!subAdmin) {
            setIsEdition(true)
            setSelectedSubAdmin(subAdmin)
        }
        setOpenSubAdminDialog(true)
    }

    const closeDialogHandler = () => {
        setOpenSubAdminDialog(false)
        setSelectedSubAdmin(undefined)
        setIsEdition(false)
    }

    //=============Countries Table Columns============
    const [page, setPage] = useState(1)

    const pageChangeHandler = ({ page }) => {
        setPage(page)
    }

    const columns = [
        { field: "userName", headerName: t("Name"), width: $COLUMN_WIDTH * 1.2 },
        { field: "email", headerName: t("Email"), width: $COLUMN_WIDTH * 1.2 },
        { field: "type", headerName: t("Role"), valueGetter: ({ value }) => t(`adminType.${value}`), width: $COLUMN_WIDTH * 1.2 },
        {
            field: "country",
            headerName: t("Country"),
            valueGetter: ({ value, row }) => row?.type === ADMIN_TYPES.admin ? t("All") : isObject(value) ? get(value, `info.${theme.direction === "rtl" ? "arabic" : "english"}.name`) : value ?? '',
            width: $COLUMN_WIDTH * 1.2
        },
    ];

    return (
        <>
            <Helmet>
                <title>{t("Ezpay Admins")} | {$APP_NAME}</title>
            </Helmet>
            <Box mr={5}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={15}>
                    <Typography variant='h6'>{t("Admins")}</Typography>
                    <Button
                        startIcon={<Add />}
                        sx={{ textTransform: "none" }}
                        variant="contained"
                        onClick={() => openSubAdminDialogHandler()}
                    >
                        {t("Add")}
                    </Button>
                    <SubAdminDialog
                        open={openSubAdminDialog}
                        subAdminData={selectedSubAdmin}
                        onClose={closeDialogHandler}
                        isEditing={isEditing}
                        onCreate={addSubAdminHandler}
                        onUpdate={updateSubAdminHandler}
                        onDelete={confirmDeleteSubAdmin}
                        loading={addSubAdminLoading || updateSubAdminLoading || deleteSubAdminLoading}
                        error={addSubAdminError || updateSubAdminError || deleteSubAdminError}
                        isSuccess={addSubAdminIsSuccess || updateSubAdminIsSuccess || deleteSubAdminIsSuccess}
                    />
                </Box>
                <Paper sx={{ height: "80vh" }}>
                    <DataTable
                        rows={subAdmins?.docs ?? []}
                        columns={columns}
                        rowCount={subAdmins?.totalDocs ?? 0}
                        loading={subAdminsLoading || subAdminsFetching}
                        onRowClick={({ row }) => openSubAdminDialogHandler(row)}
                        onPaginationModelChange={pageChangeHandler}
                        paginationModel={{
                            page: page - 1,
                            pageSize: 100
                        }}
                    />
                </Paper>
            </Box>
        </>
    )
}
