import * as Joi from "joi";
import i18n from "i18n";

export const AdminBlogPostSchema = Joi.object({
    title_ar: Joi.string()
        .required()
        .empty('')
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` })
        .label(i18n.t("The Arabic title")),
    description_ar: Joi.string()
        .required()
        .empty('')
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` })
        .label(i18n.t("The Arabic Description")),
    title_en: Joi.string()
        .required()
        .empty('')
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` })
        .label(i18n.t("The English title")),
    description_en: Joi.string()
        .required()
        .empty('')
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` })
        .label(i18n.t("The English Description")),
    slug: Joi.string()
        .trim()
        .replace(/\s+/g, '-')
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` })
        .label(i18n.t("URL name")),
    meta: Joi.string()
        .required()
        .empty('')
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` })
        .label(i18n.t("Metadata")),
    image: Joi.string()
        .base64()
        .empty('')
        .label(i18n.t("Choose Image"))
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` })
});