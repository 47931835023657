import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ClientInfo({ transaction, ...props }) {
    const { t } = useTranslation()
    return (
        <Grid container {...props}>
            {!!transaction?.name &&
                <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                    <Box display="flex">
                        <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Name")}</Typography>
                        <Typography>{transaction?.name}</Typography>
                    </Box>
                </Grid>
            }
            {
                !!transaction?.phone &&
                <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                    <Box display="flex">
                        <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Phone")}</Typography>
                        <bdi>
                            <Typography>{transaction?.phone}</Typography>
                        </bdi>
                    </Box>
                </Grid>
            }
            {
                !!transaction?.serviceDetails &&
                <Grid item xs={12} md={12}>
                    <Box display="flex">
                        <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Description")}</Typography>
                        <Typography>{transaction?.serviceDetails}</Typography>
                    </Box>
                </Grid>
            }
        </Grid>
    )
}