import { Box, Collapse, Container, Divider, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import { useCreateTransactionMutation, useEditTransactionMutation, useLazyGetTransactionByIdQuery } from 'api/transactionApi'
import { PhoneInput, TextFieldNumber } from 'modules/common'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab'
import { cloneDeep, omitBy, pick } from 'lodash'
import { runJoiValidate } from 'utils'
import { TRANSACTION_SCHEMA } from './transactions-validation-schemas'
import { Helmet } from 'react-helmet'
import { $APP_NAME } from 'constants'
import { SOURCE_TYPE } from 'constants'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TransactionManagement() {
  const { t } = useTranslation()
  const { transactionId } = useParams()
  const navigate = useNavigate()

  const [transaction, setTransaction] = useState({
    amount: '',
    name: '',
    serviceDetails: '',
    phone: ''
  })

  const handleChange = ({ target: { name, value } }) => {
    setTransaction(prevState => ({ ...prevState, [name]: value }))
  }

  const [getTransaction, { data: transactionData }] = useLazyGetTransactionByIdQuery()

  useEffect(() => {
    if (!!transactionId) {
      getTransaction(transactionId)
    }
  }, [transactionId])

  useEffect(() => {
    if (!!transactionData) {
      setTransaction(transactionData)
    }
  }, [transactionData])

  const [validationErrors, setValidationErrors] = useState(null)

  //======= Handle Collaps =======
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  //======= Handle Submit =======
  const [createTransaction,
    { isLoading: createTransactionLoading, isSuccess: createTransactionIsSuccess, error: createTransactionError }
  ] = useCreateTransactionMutation()

  const [editTransaction,
    { isLoading: editTransactionLoading, isSuccess: editTransactionIsSuccess, error: editTransactionError }
  ] = useEditTransactionMutation()

  const onSubmitHandler = async () => {
    try {
      setValidationErrors(null)
      const payload = omitBy(pick(cloneDeep(transaction), ['amount', 'name', 'serviceDetails', 'phone']), v => !Boolean(v))
      payload.amount = Number(payload.amount)

      const errors = runJoiValidate(TRANSACTION_SCHEMA, payload)
      if (!!errors) {
        setValidationErrors(errors)
        return;
      }

      if (!!transactionId) {
        // Edit Transaction
        await editTransaction({ id: transactionId, payload }).unwrap()
        setTimeout(() => {
          navigate(`/merchant/transactions/details/${transactionId}`)
        }, 3000);
      } else {
        // Create Transaction
        let response = await createTransaction({...payload, createdFrom: SOURCE_TYPE.web}).unwrap()
        setTimeout(() => {
          navigate(`/merchant/transactions/details/${response?.id}`)
        }, 3000);
      }
    } catch (error) {

    }
  }

  const error = useMemo(() => {
    return createTransactionError ?? editTransactionError ?? null
  }, [createTransactionError, editTransactionError])

  return (
    <>
      <Helmet>
        <title>Create Transaction | {$APP_NAME}</title>
      </Helmet>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Paper sx={{ mt: 70 }}>
              <Box p={30}>
                <Typography color="primary" variant="h5" fontWeight="bold" sx={{ textAlign: "center" }}>{!!transactionId ? t("Edit Invoice") : t("Create Invoice")}</Typography>
                <TextFieldNumber
                  required
                  name="amount"
                  value={transaction?.amount ?? ''}
                  onChange={handleChange}
                  decimal={true}
                  label={t("Amount")}
                  size="small"
                  fullWidth
                  margin="normal"
                  error={!!validationErrors?.amount}
                  helperText={validationErrors?.amount ?? ''}
                />
                <Box display="flex" justifyContent="end" alignItems="center" mt={2}>
                  <Box display="flex" alignItems="center">
                    <Typography color="secondary">{t("Client Details")}</Typography>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Box>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <TextField
                    value={transaction?.name}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    id="name"
                    label={t("Client Name")}
                    name="name"
                    size="small"
                    error={!!validationErrors?.name}
                    helperText={validationErrors?.name}
                  />
                  <PhoneInput
                    label={t("Phone")}
                    name="phone"
                    value={transaction?.phone}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={validationErrors?.phone}
                  />
                  <TextField
                    multiline
                    rows={3}
                    value={transaction?.serviceDetails}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    id="serviceDetails"
                    label={t("Description")}
                    name="serviceDetails"
                    size="small"
                    error={!!validationErrors?.serviceDetails}
                    helperText={validationErrors?.serviceDetails}
                  />
                </Collapse>
              </Box>
              <Divider />
              <Grid container justifyContent="center">
                <Grid item xs={12} md={6} lg={4} my={20}>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    sx={{ textTransform: "none" }}
                    onClick={onSubmitHandler}
                    fullWidth
                    disabled={!transaction?.amount}
                    loading={createTransactionLoading || editTransactionLoading}
                  >
                    {!!transactionId ? t("Edit Invoice") : t("Create Invoice")}
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} md={12} lg={12} my={12}>
                  <Box textAlign="center">
                    {(createTransactionIsSuccess || editTransactionIsSuccess) && <Typography color="success.main">{t("Done successfully")}</Typography>}
                    {!!error && <Typography color="error">{t(error)}</Typography>}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
