import { Box, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useGetAllUsersInvoicesQuery } from "api/invoiceApi";
import { DataTable, MerchantName } from "modules/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InvoicesFilter from "./InvoicesFilter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";

const $COLUMN_WIDTH = 200
export default function AdminInvoices() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const [searchParams] = useSearchParams();
    const depositType = searchParams.get('depositType')
    const [params, setParams] = useState({
        page: 1,
        limit: 100,
        type: "CURRENT"
    })
    const { data: invoices, isLoading: invoicesLoaing, isFetching } = useGetAllUsersInvoicesQuery(params)

    const columns = () => {
        let columns = [
            { field: "user", sortable: false, renderCell: ({ value }) => <MerchantName creator={value} />, headerName: t("User name"), width: $COLUMN_WIDTH * 1.1 },
            { field: "business", sortable: false, valueGetter: ({ row }) => row?.user?.business?.businessName, headerName: t("Business Name"), width: $COLUMN_WIDTH * 1.1 },
            { field: "netAmount", sortable: false, headerName: t("Net amount"), width: $COLUMN_WIDTH * 1, },
            { field: "totalAmount", sortable: false, headerName: t("Total amount"), width: $COLUMN_WIDTH * 1, },
        ];
        if (params?.type === "CURRENT") {
            columns.push({ field: "since", valueGetter: ({ row: { createdAt } }) => moment(createdAt).fromNow(), sortable: false, headerName: t("Since"), width: $COLUMN_WIDTH * 0.8 })
        } else {
            columns.push({ field: "bankAccount", valueGetter: ({ value }) => value?.iban || t("N/A"), sortable: false, headerName: t("Bank account number"), width: $COLUMN_WIDTH * 1.4, })
        }
        if (params?.type === "SETTLED") {
            columns.push({ field: "settleBalanceDate", valueGetter: ({ value }) => value ? moment(value).format("DD/MM/YY - h:mm A") : '', sortable: false, headerName: t("Settle balance date"), width: $COLUMN_WIDTH * 0.8 })
        }
        return columns
    }

    const pageChangeHandler = ({ page }) => {
        if (!isFetching) {
            setPage(page)
            setParams((prevParams) => ({ ...prevParams, page }))
        }
    }

    const handleChange = ({ target: { name, value } }) => {
        setParams((prevParams) => ({ ...prevParams, [name]: value }))
    }

    const paramChangeHandler = (data) => {
        setParams(data)
    }

    useEffect(() => {
        setParams((prevParams) => ({ ...prevParams, depositType, type: "CURRENT" }))
    }, [depositType])

    return (
        <>
            <Helmet>
                <title>{t("Invoices List")} | {$APP_NAME}</title>
            </Helmet>
            <Box mr={5}>
                <Box display="flex" alignItems="center" my={20}>
                    <Box display="flex" alignItems="center" mb={2}>
                        <Typography variant="h6" sx={{ mr: 20 }}>{t("Invoices")}</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            value={params?.type}
                            onChange={(_, value) => handleChange({ target: { name: "type", value: value ?? "CURRENT" } })}
                            aria-label="Type"
                            size="small"
                            sx={{ textTransform: 'none' }}
                        >
                            <ToggleButton sx={{ textTransform: 'none', fontWeight: "bold" }} value="CURRENT">{t("Current")}</ToggleButton>
                            <ToggleButton sx={{ textTransform: 'none', fontWeight: "bold" }} value="SETTLED">{t("Settled")}</ToggleButton>
                            {depositType === "MANUAL" && <ToggleButton sx={{ textTransform: 'none', fontWeight: "bold" }} value="PENDING">{t("Pending")}</ToggleButton>}
                        </ToggleButtonGroup>
                        {
                            params?.type === "PENDING" &&
                            <Typography variant="body2" color="primary" sx={{ ml: 20 }}>
                                {t("Invoices created for previous transactions before activating automatic transfer.")}
                            </Typography>
                        }
                    </Box>
                </Box>
                <InvoicesFilter type={params?.type} params={params} onParamsChange={paramChangeHandler} />
                <Paper sx={{ height: "70vh", }}>
                    <DataTable
                        rows={invoices?.docs ?? []}
                        columns={columns()}
                        onPaginationModelChange={pageChangeHandler}
                        paginationModel={{ page: page - 1, pageSize: 100 }}
                        rowCount={invoices?.totalDocs ?? 0}
                        loading={invoicesLoaing || isFetching}
                        onRowClick={row => navigate(`/admin/invoice/${row?.id}`)}
                    />
                </Paper>
            </Box>
        </>
    )
}