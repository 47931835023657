import { createSlice } from "@reduxjs/toolkit"

const countrySlice = createSlice({
    name: 'country',
    initialState: { country: null },
    reducers: {
        setCurrentCountry: (state, { payload }) => {
            state.country = payload
        }
    },
})

export const { setCurrentCountry } = countrySlice.actions

export default countrySlice.reducer
