import { LoadingButton } from "@mui/lab";
import { Box, Container, CssBaseline, Link, TextField, Typography, Grid, Checkbox, Button, useTheme } from "@mui/material";
import { useMerchantRegisterMutation } from "api/authApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import runJoiValidate from "utils/runJoiValidate";
import { USER_REGISTER_SCHEMA } from "./user-auth-validation-schemas";
import { AvatarUploader, PhoneInput, ServerAutoCompleteSearch } from "modules/common";
import { cloneDeep, omit, omitBy } from "lodash";
import { useGetPublicCountriesQuery } from "api/countriesApis";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { Footer, QuickLinks } from "modules/landing/screens/common";
import { SOURCE_TYPE } from "constants";

export default function Register() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams();
  const theme = useTheme()
  const navigate = useNavigate()

  const [regiser, { isLoading, error }] = useMerchantRegisterMutation()

  const [user, setUser] = useState({
    businessName: '',
    userName: '',
    email: '',
    phone: '',
    password: '',
    password_repeat: '',
    logo: '',
    country: '',
  })

  const [validationErrors, setValidationErrors] = useState(null)

  const handleChange = ({ target: { name, value } }) => {
    if (validationErrors && validationErrors.hasOwnProperty(name)) {
      setValidationErrors(prevErrors => ({
        ...omit(prevErrors, name)
      }))
    }
    setUser(prevUser => ({
      ...prevUser,
      [name]: value
    }))
  }

  const email = searchParams.get('email')
  useEffect(() => {
    if (!!email) {
      setUser(prevUser => ({
        ...prevUser,
        email
      }))
    }
  }, [email])

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setValidationErrors(null)
      let payload = cloneDeep(user)
      const errors = runJoiValidate(USER_REGISTER_SCHEMA, payload)
      if (!!errors) {
        setValidationErrors(errors)
        return
      }
      payload = omit(payload, "password_repeat")
      let response = await regiser({...omitBy(payload, v => !v), createdFrom: SOURCE_TYPE.web}).unwrap()
      const data = response?.body?.user
      navigate(`/auth/phone-verify?id=${data?.id}&phone=${data?.phone}&email=${data?.email}`)
    } catch (error) {
      console.log(error)
    }
  }

  const [checked, setChecked] = useState(false)

  //=======Countries=======
  const [countrySearchKey, setCountrySearchKey] = useState('')
  const { data: countries, isLoading: countriesLoading } = useGetPublicCountriesQuery(omitBy({ searchKey: countrySearchKey, active: true }, v => !Boolean(v)))

  return (
    <>
      <Helmet>
        <title>{t("Register")} | {$APP_NAME}</title>
      </Helmet>
      <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
        <Container sx={{ padding: 16 }}>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
              <AvatarUploader name="logo" image={user?.logo} onChange={handleChange} error={validationErrors?.logo} />
            </Box>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={8} md={6} lg={6} xl={4.5}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                    name="businessName"
                    value={user?.businessName}
                    onChange={handleChange}
                    margin="dense"
                    label={t("Business Name")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    error={!!validationErrors?.businessName}
                    helperText={validationErrors?.businessName}
                  />
                  <TextField
                    inputProps={{ autoComplete: 'none' }}
                    name="userName"
                    value={user?.userName ?? ''}
                    onChange={handleChange}
                    margin="dense"
                    label={t("Account Manager Name")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    autocomplete="off"
                    error={!!validationErrors?.userName}
                    helperText={validationErrors?.userName}
                  />
                  <TextField
                    name="email"
                    label={t("Email")}
                    value={user?.email ?? ''}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    required
                    margin="dense"
                    error={!!validationErrors?.email}
                    helperText={validationErrors?.email}
                  />
                  <PhoneInput
                    style={{ marginTop: 4 }}
                    label={t("Phone")}
                    name="phone"
                    required
                    value={user?.phone}
                    onChange={handleChange}
                    margin="dense"
                    fullWidth
                    error={validationErrors?.phone}
                  />
                  <TextField
                    inputProps={{ autoComplete: 'new-password' }}
                    value={user?.password}
                    onChange={handleChange}
                    size="small"
                    margin="dense"
                    required
                    fullWidth
                    name="password"
                    label={t("Password")}
                    type="password"
                    id="password"
                    error={!!validationErrors?.password}
                    helperText={validationErrors?.password}
                  />
                  <TextField
                    name="password_repeat"
                    type="password"
                    label={t("Confirm Password")}
                    value={user?.password_repeat}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    required
                    error={!!validationErrors?.password_repeat}
                    helperText={validationErrors?.password_repeat}
                  />
                  <ServerAutoCompleteSearch
                    label={t("Country")}
                    value={countries?.find((country) => country?.id === user?.country) ?? null}
                    isOptionEqualToValue={(option, value) => option?.id === value}
                    loading={countriesLoading}
                    getOptionLabel={(option) => !!option?.info ? option?.info[`${theme.direction === "rtl" ? "arabic" : "english"}`]?.name : ''}
                    options={countries?.filter((country) => ["EG", "SA", "AE"].includes(country?.isoCode)) ?? []}
                    onChange={(value) => handleChange({ target: { name: "country", value: value?.id } })}
                    onInputChange={(text) => setCountrySearchKey(text)}
                    error={validationErrors?.country}
                  />
                  <Box display="flex" alignItems="flex-start" flex={1} sx={{ my: 2 }}>
                    <Checkbox
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      style={{ paddingBlock: 0 }}
                    />
                    <Typography sx={{ display: "inline-block", alignItems: "center", flex: 1 }}>
                      {`${t("I agree to the")}`}
                      <Link sx={{ px: 4, display: '' }} href="/terms-and-conditions" color="secondary">
                        {t("Terms And Conditions")}
                      </Link>
                      {` ${t("and")} `}
                      <Link sx={{ px: 4 }} href="/privacy-policy" color="secondary">
                        {t("Privacy Policy")}
                      </Link>
                    </Typography>
                  </Box>
                  <LoadingButton
                    type="submit"
                    loading={isLoading}
                    disabled={!checked}
                    fullWidth
                    variant="contained"
                    sx={{ my: 15, textTransform: "none" }}
                  >
                    {t("Register")}
                  </LoadingButton>
                  {!!error &&
                    <Box textAlign="center">
                      <Typography color="error">{t(error)}</Typography>
                    </Box>
                  }
                  <Grid container>
                    <Grid item>
                      <Button sx={{ textTransform: "none" }} onClick={() => navigate("/auth/login")}>
                        {t("I have an account? Sign In")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 18, mb: 4 }}>
            {'Copyright © '}
            <Link color="inherit" href="">
              EzPay
            </Link>{' '}
            {new Date().getFullYear()}
          </Typography>
        </Container>
        <QuickLinks allowSocial={true} />
        <Footer />
      </Box>
    </>
  )
}