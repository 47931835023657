import { useCallback, useEffect, useMemo } from "react";

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { SendInvoiceByEmail } from "./components";
import { useGetPaymentStatusMutation } from "api/myFatoorahApi";
import { useLazyGetTransactionPublicDetailsByIdQuery } from "api/transaction";

import Lottie from "lottie-react";
import { EzPayLoadingIndicator, Success, Error } from "_assets";
import { TRANSACTION_STATUSES } from "constants";
import { isEmpty } from "lodash";
import { LoadingButton } from "@mui/lab";

const InvoicePaymentResult = () => {

    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const transactionId = useMemo(_ => searchParams.get('transactionId'), [searchParams]);
    const paymentId = useMemo(_ => searchParams.get('paymentId'), [searchParams]);

    //"Pending","PAID", "Canceled"
    const [getPaymentStatus, { data: paymentStatusData, isLoading: paymentStatusLoading, error: paymentStatusError }] = useGetPaymentStatusMutation()

    useEffect(_ => {
        getPaymentStatus({ paymentId, transactionId })
    }, [paymentId, transactionId]);

    const [getTransactionById, {
        isLoading: isTransactionLoading,
        error: isTransactionError
    }] = useLazyGetTransactionPublicDetailsByIdQuery()

    const handleRetry = useCallback(async _ => {
        if (!isEmpty(transactionId)) {
            try {
                const response = await getTransactionById(transactionId);
                const transaction = response?.data;
                const country = process.env.REACT_APP_ENV === "production" ? transaction?.user?.country?.isoCode3 : "KWT"
                window.location = `${window.location.origin}/payment?id=${transactionId}&country=${country}`
            } catch (e) {
                console.log(e)
            }
        } else {
            console.log("Error")
        }
    }, [transactionId])

    useEffect(() => {
        if (paymentStatusData?.createdFrom === "API" && !!paymentStatusData?.redirectUrl) {
            setTimeout(() => {
                window.location.href = `${paymentStatusData?.redirectUrl}?transactionNumber=${paymentStatusData?.transactionNumber}&status=${paymentStatusData?.currentStatus}`
            }, 2000);
        }
    }, [paymentStatusData])

    if (paymentStatusLoading) {
        return (
            <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: "center", justifyContent: "center" }}>
                <Lottie animationData={EzPayLoadingIndicator} loop={true} style={{ width: 240, height: 240 }} />
            </Box>
        )
    }

    return (
        <Box sx={{ paddingTop: '15vh', paddingBottom: '15vh', marginInline: 'auto', display: "flex", flexDirection: "column", alignItems: "center" }} maxWidth="400px">
            <Typography sx={{ fontWeight: "bold", fontSize: "30px" }} >
                {paymentStatusData?.currentStatus === "PAID" ? t("Payment Successful") : null}
                {paymentStatusData?.currentStatus === "FAILED" ? t("Payment Failed") : null}
                {paymentStatusError ? paymentStatusError : null}
            </Typography>
            <Lottie
                animationData={paymentStatusData?.currentStatus === TRANSACTION_STATUSES.PAID ? Success : Error}
                loop={false}
                style={{ width: 240, height: 240, marginTop: "5%" }}
            />
            {
                paymentStatusData?.currentStatus === "FAILED" || !!paymentStatusError
                    ?
                    <LoadingButton
                        loading={isTransactionLoading}
                        variant="outlined"
                        onClick={handleRetry}
                        size="small"
                        sx={{ mt: 16, fontSize: 16, textTransform: 'none' }}
                    >
                        {t("Try Again")}
                    </LoadingButton>
                    : null
            }
            {!!isTransactionError ? <Typography color="error">{isTransactionError}</Typography> : null}
            {
                paymentStatusData?.currentStatus === "PAID"
                    ? <SendInvoiceByEmail invoiceId={transactionId} />
                    : null
            }
        </Box>
    )
}

export default InvoicePaymentResult;