import { apiSlice } from "./apiSlice";

export const blogApiSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['BLOG'] })
    .injectEndpoints({
        endpoints: builder => ({
            getBlogPosts: builder.query({
                query: () => ({
                    url: '/blog',
                    method: 'GET',
                }),
                providesTags: (result, _, __) =>
                    result
                        ? [
                            ...result?.docs.map(({ id }) => ({ type: "BLOG", id })),
                            { type: "BLOG", id: "BLOG-PARTIAL-LIST" },
                        ]
                        : [{ type: "BLOG", id: "BLOG-PARTIAL-LIST" }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getBlogPostsWeb: builder.query({
                query: () => ({
                    url: '/blog/web',
                    method: 'GET',
                }),
                providesTags: (result, _, __) =>
                    result
                        ? [
                            ...result?.docs.map(({ id }) => ({ type: "BLOG", id })),
                            { type: "BLOG", id: "BLOG-PARTIAL-LIST" },
                        ]
                        : [{ type: "BLOG", id: "BLOG-PARTIAL-LIST" }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getBlogPost: builder.query({
                query: (id) => ({
                    url: `/blog/${id}`,
                    method: 'GET',
                }),
                providesTags: (_, __, id) => [{ type: 'BLOG', id }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getBlogPostBySlug: builder.query({
                query: (slug) => ({
                    url: `/blog/${slug}/details`,
                    method: 'GET',
                }),
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getBlogPostWeb: builder.query({
                query: (id) => ({
                    url: `/blog/${id}/web`,
                    method: 'GET',
                }),
                providesTags: (_, __, id) => [{ type: 'BLOG', id }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            addBlogPost: builder.mutation({
                query: (payload) => ({
                    url: `/blog`,
                    method: 'POST',
                    body: { ...payload }
                }),
                invalidatesTags: [{ type: 'BLOG', id: 'BLOG-PARTIAL-LIST' }],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateBlogPost: builder.mutation({
                query: ({ id, payload }) => ({
                    url: `/blog/${id}`,
                    method: 'PATCH',
                    body: { ...payload }
                }),
                invalidatesTags: (_, __, { id }) => [
                    { type: 'BLOG', id },
                    { type: 'BLOG', id: 'BLOG-PARTIAL-LIST' },
                ],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            deleteBlogPost: builder.mutation({
                query: (id) => ({
                    url: `/blog/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_, __, { id }) => [
                    { type: 'BLOG', id },
                    { type: 'BLOG', id: 'BLOG-PARTIAL-LIST' },
                ],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
        })
    })

export const {
    useGetBlogPostsQuery,
    useGetBlogPostQuery,
    useUpdateBlogPostMutation,
    useAddBlogPostMutation,
    useDeleteBlogPostMutation,
    useGetBlogPostsWebQuery,
    useGetBlogPostWebQuery,
    useGetBlogPostBySlugQuery
} = blogApiSlice