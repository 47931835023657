import * as Joi from "joi";
import i18n from "i18n";
import { joiString, passwordValidator, leadingWhitespaceValidator } from "utils/joi";
import { parsePhoneNumber } from "react-phone-number-input";

export const USER_LOGIN_SCHEMA = Joi.object({
    phone: joiString.string().trim().required()
        .label(i18n.t("Phone"))
        .custom((value, helper) => {
            const parsedPhone = parsePhoneNumber(value, { extract: true });
            if (!parsedPhone || !parsedPhone.isValid())
                return helper.message(i18n.t("Invalid phone number", { ns: "landing" }));
            return true
        }),
    password: joiString.string()
        .label(i18n.t("Password"))
        .required()
        .empty('')
        .max(255)
        .min(6)
});

export const USER_REGISTER_SCHEMA = Joi.object({
    logo: joiString.string()
        .min(3)
        .empty('')
        .label(i18n.t("Logo")),
    businessName: joiString.string()
        .min(3)
        .empty('')
        .required()
        .custom(leadingWhitespaceValidator(i18n.t("Business Name")))
        .label(i18n.t("Business Name")),
    userName: joiString.string()
        .min(3)
        .empty('')
        .required()
        .custom(leadingWhitespaceValidator(i18n.t("Name")))
        .label(i18n.t("Name")),
    country: joiString.string()
        .min(3)
        .empty('')
        .required()
        .label(i18n.t("Country")),
    email: joiString.string()
        .empty('')
        .email({ tlds: { allow: false } })
        .required()
        .custom(leadingWhitespaceValidator(i18n.t("Email")))
        .label(i18n.t("Email")),
    phone: joiString.string()
        .empty('')
        .required()
        .label(i18n.t("Phone"))
        .custom((value, helper) => {
            const parsedPhone = parsePhoneNumber(value, { extract: true });
            if (!parsedPhone || !parsedPhone.isValid())
                return helper.message(i18n.t("validationErrors.isValidPhone"));
            return true
        }),
    password: joiString.string()
        .empty('')
        .required()
        .custom(passwordValidator)
        .label(i18n.t("Password")),
    password_repeat: joiString.string()
        .empty('')
        .required()
        .valid(Joi.ref('password'))
        .label(i18n.t("Confirm Password")),
});

export const PHONE_SCHEMA = Joi.object({
    phone: joiString.string()
        .empty('')
        .required()
        .label(i18n.t("Phone"))
        .custom((value, helper) => {
            const parsedPhone = parsePhoneNumber(value, { extract: true });
            if (!parsedPhone || !parsedPhone.isValid())
                return helper.message(i18n.t("validationErrors.isValidPhone"));
            return true
        })
})

export const RESET_PASSWORD_SCHEMA = Joi.object({
    password: joiString.string()
        .empty('')
        .required()
        .min(6)
        .label(i18n.t("Password")),
    password_repeat: joiString.string()
        .empty('')
        .required()
        .valid(Joi.ref('password'))
        .label(i18n.t("Confirm Password")),
    resetPasswordCode: joiString.string()
        .empty('')
        .required()
        .label(i18n.t("Code"))
});