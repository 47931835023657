import * as Joi from "joi";
import i18n from "i18n";
import { joiString } from "utils/joi";

export const ADD_USER_SCHEMA = Joi.object({
    userName: joiString.string()
        .label(i18n.t("Name"))
        .required()
        .empty(''),
    phone: joiString.string()
        .label(i18n.t("Phone"))
        .required()
        .empty('')
    ,
    password: joiString.string()
        .label(i18n.t("Password"))
        .min(5)
        .empty('')
        .required(),
})