import { Box, Button, Paper, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { $APP_NAME } from "constants";
import { useNavigate } from "react-router-dom";
import { useGetBlogPostsQuery } from "api/blogApi";
import { DataTable } from "modules/common";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ADMIN_TYPES } from "constants";

const $COLUMN_WIDTH = 200;

export default function AdminBlogPost() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { profile } = useSelector(state => state?.auth);

    const [page, setPage] = useState(1)

    const { data: posts, isLoading, isFetching } = useGetBlogPostsQuery({ page: 1, limit: 100 })

    const pageChangeHandler = ({ page }) => {
        if (!isFetching) {
            setPage(page)
        }
    }

    const columns = [
        { field: "title_ar", headerName: t("The Arabic title"), width: $COLUMN_WIDTH * 1.2 },
        { field: "title_en", headerName: t("The English title"), width: $COLUMN_WIDTH * 1.2 },
        { field: "meta", headerName: t("Metadata"), width: $COLUMN_WIDTH * 1.2 },
    ];
    return (
        <>
            <Helmet>
                <title>{t("Blog Posts")} | {$APP_NAME}</title>
            </Helmet>
            <Box m={2}>
                <Box sx={{marginTop: 20, marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t("Blog")}</Typography>
                    {
                        !!profile && profile?.type !== ADMIN_TYPES.accountManager
                            ?
                            <Button sx={{ textTransform: "none" }} onClick={() => navigate("/admin/blog/add")} variant="contained">{t("Add")}</Button>
                            : null
                    }
                </Box>
                <Paper sx={{ height: "80vh" }}>
                    <DataTable
                        rows={posts?.docs ?? []}
                        columns={columns}
                        onPaginationModelChange={pageChangeHandler}
                        paginationModel={{ page: page - 1, pageSize: 100 }}
                        rowCount={posts?.totalDocs ?? 0}
                        loading={isLoading || isFetching}
                        onRowClick={row => navigate(`/admin/blog/${row?.id}`)}
                    />
                </Paper>
            </Box>
        </>
    )
}