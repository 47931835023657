import { useEffect, useState } from "react";

import { Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { cloneDeep, last, mapValues, omit, omitBy, pick, split, startsWith } from "lodash";
import { useTranslation } from "react-i18next";

import runJoiValidate from "utils/runJoiValidate";
import { ImageUpload } from "modules/common";

import { USER_BUSINESS_INFO_SCHEMA } from "./ValidationSchemas";
import { useSelector } from "react-redux";
import { ROLES } from "constants";

export default function UserBusinessCard({ userBusiness, onUpdateBusiness, isLoading, isSuccess, error }) {
    const { t } = useTranslation()
    const { profile } = useSelector(state => state?.auth);

    const [validationErrors, setValidationErrors] = useState(null)
    const [user, setUser] = useState({
        businessName: '',
        logo: '',
    })

    const submitHandler = () => {
        try {
            setValidationErrors(null)
            let payload = pick(cloneDeep(user), ["businessName", "logo"])
            if (!!payload["logo"] && !startsWith(payload["logo"], "data")) {
                payload = omit(payload, ["logo"])
            }
            payload = omitBy(payload, v => !Boolean(v));
            const errors = runJoiValidate(USER_BUSINESS_INFO_SCHEMA, mapValues(payload, (value, key) => {
                return key === "logo" ? last(split(value, ",")) : value
            }))

            if (!!errors) {
                setValidationErrors(errors)
                return
            }
            onUpdateBusiness(payload)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = ({ target: { name, value, files } }, filePrepared = false) => { // "ownerId", "commercialRegister", "taxLetter", "bankLetter"
        if (["logo",].includes(name) && !filePrepared) {
            const reader = new FileReader();
            const file = files[0];
            reader.addEventListener("load", function () {
                const base64String = reader.result
                handleChange({
                    target: {
                        name,
                        value: base64String
                    }
                }, true)
            }, false);
            return reader.readAsDataURL(file);
        }
        setUser((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    useEffect(() => {
        if (!!userBusiness) {
            let data = pick(userBusiness, ["businessName", "logo", "myFatoorahVendor"]) //"ownerId", "commercialRegister", "taxLetter", "bankLetter"
            data = { ...data, confirm_password: '' }
            setUser(data)
        }
    }, [userBusiness])

    return (
        <Card>
            <CardHeader title={t("Business info")} />
            <Divider />
            <CardContent>
                <Grid container spacing={10} alignItems="flex-end">
                    <Grid item xs={4}>
                        <ImageUpload
                            variant={"rounded"}
                            name="logo"
                            image={user?.logo ?? ''}
                            title="Logo"
                            handleChange={handleChange}
                            error={validationErrors?.logo}
                            isImageClickable={true}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            name="businessName"
                            label={t("Business name")}
                            value={user?.businessName ?? ''}
                            onChange={handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                            error={!!validationErrors?.businessName}
                            helperText={validationErrors?.businessName}
                        // disabled={!allowUpdate}
                        />
                    </Grid>
                    {
                        (!!user?.myFatoorahVendor?.supplierId && profile?.role === ROLES.admin)
                        &&
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ display: 'inline-block' }} >
                                *{t("MyFatoorah Supplier ID")}:
                            </Typography>
                            <Typography sx={{ fontWeight: "bold", display: 'inline-block', mx: 5 }}>
                                {user?.myFatoorahVendor?.supplierId}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </CardContent>
            <Divider />
            {/* {
                allowUpdate
                    ? */}
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                <LoadingButton loading={isLoading} variant="contained" onClick={submitHandler} sx={{ textTransform: "none" }}>
                    {t("Submit")}
                </LoadingButton>
                {!!error && <Typography color="error">{t(error)}</Typography>}
                {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
            </CardActions>
            {/* : null
            } */}
        </Card>
    )
}