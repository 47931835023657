import { Box, Typography } from '@mui/material'
import APIUrlMethod from './APIUrlMethod'
import APIFieldsTable from './APIFieldsTable'
import SampleCodes from './SampleCodes'

export default function APISection({ api }) {
    return (
        <Box mt={40} whiteSpace="pre-wrap">
            <Typography variant="h4" color="primary" sx={{ mb: 10}}>
                {api?.title}
            </Typography>
            <Box mt={3}>
                <Typography variant="h5" fontWeight="bold" sx={{ my: 15 }}>Description</Typography>
                <Typography variant='body2'>
                    {api?.description}
                </Typography>
            </Box>
            <APIUrlMethod method={api?.method} url={api?.url} />
            {
                !!api?.requiredFields &&
                <Box mt={20}>
                    <Typography sx={{ my: 20 }} variant="h5" fontWeight="bold">Required fields</Typography>
                    <APIFieldsTable rows={api?.requiredFields?.rows} columns={api?.requiredFields?.columns} />
                </Box>
            }
            {
                !!api?.optionalFields &&
                <Box mt={20}>
                    <Typography sx={{ my: 20 }} variant="h5" fontWeight="bold">Optional fields</Typography>
                    <APIFieldsTable rows={api?.optionalFields?.rows} columns={api?.optionalFields?.columns} />
                </Box>
            }
            {/* {
                api?.models?.map(model => (
                    <Box key={model} my={4}>
                        <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>{model}</Typography>
                        <DocumentationTable rows={models?.find(el => el.name === model)?.fields} />
                    </Box>
                ))
            } */}
            {
                !!api?.sampleCodes &&
                <Box my={20}>
                    <SampleCodes data={api?.sampleCodes} />
                </Box>
            }
        </Box>
    )
}
