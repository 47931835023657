import * as Joi from "joi";
import i18n from "i18n";
import { joiString } from "utils/joi";
import { parsePhoneNumber } from "react-phone-number-input";

export const SUB_ADMIN_SCHEMA = Joi.object({
    userName: joiString.string()
        .empty('')
        .min(3)
        .max(255)
        .required()
        .label(i18n.t("Name"))
    ,
    email: joiString.string()
        .empty('')
        .email({ tlds: { allow: false } })
        .required()
        .label(i18n.t("Email"))
    ,
    phone: joiString.string()
        .empty('')
        //.required()
        .label(i18n.t("Phone"))
        .custom((value, helper) => {
            const parsedPhone = parsePhoneNumber(value, { extract: true });
            if (!parsedPhone || !parsedPhone.isValid())
                return helper.message(i18n.t("validationErrors.isValidPhone"));
            return true
        }),
    type: joiString.string()
        .valid('ADMIN', 'SUB_ADMIN', 'ACCOUNT_MANAGER')
        .required()
        .label(i18n.t("Role"))
    ,
    country: Joi.when('type', {
        is: Joi.valid('SUB_ADMIN', 'ACCOUNT_MANAGER'),
        then: joiString.string()
            .empty('')
            .min(3)
            .max(255)
            .required()
            .label(i18n.t("Country")),
        otherwise: joiString.string()
            .label(i18n.t("Country"))
            .allow(null)
            .optional()
    }),
    password: joiString.string()
        .label(i18n.t("Password"))
        .empty('')
        .max(255)
        .min(6)
})
