import { LoadingButton } from "@mui/lab";
import { Box, Container, CssBaseline, Link, Typography, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import runJoiValidate from "utils/runJoiValidate";
import { Logo } from "_assets";
import { PHONE_SCHEMA } from "./user-auth-validation-schemas";
import { PhoneInput } from "modules/common";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";

export default function ChangePhone() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    const email = searchParams.get('email')

    const [phone, setPhone] = useState('')

    const [validationErrors, setValidationErrors] = useState(null)

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setValidationErrors(null);
            const errors = runJoiValidate(PHONE_SCHEMA, { phone });
            if (!!errors) {
                setValidationErrors(errors);
                return
            }
            let params = {
                email: email,
                phone: phone
            }
            navigate(`/auth/phone-verify?phone=${params?.phone}&email=${email}&id=${id}`, { replace: true })

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>{t("Change Phone")} | {$APP_NAME}</title>
            </Helmet>
            <Container sx={{ padding: 16 }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 50,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="a" href="/" sx={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }} >
                        <img
                            alt='white-logo'
                            height="auto"
                            src={Logo}
                            style={{ cursor: "pointer", width: 100 }}
                        />
                    </Box>
                    <Grid container justifyContent="center" alignItems="center" sx={{ textAlign: "center", my: 10 }}>
                        <Grid item xs={12} sm={8} md={5} lg={4} xl={4}>
                            <Typography sx={{ fontWeight: "bold" }} color="secondary">
                                {t("Change Phone Number")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={8} md={5} lg={4} xl={4}>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <PhoneInput
                                    label={t("Phone")}
                                    name="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e?.target?.value)}
                                    fullWidth
                                    error={validationErrors?.phone}
                                />
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ my: 15, textTransform: "none" }}
                                >
                                    {t("Confirm")}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 18, mb: 4 }}>
                    {'Copyright © '}
                    <Link color="inherit" href="/">
                        EzPay
                    </Link>{' '}
                    {new Date().getFullYear()}
                </Typography>
            </Container>
        </>
    )
}