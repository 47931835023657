import { LoadingButton } from "@mui/lab";
import { Box, Container, CssBaseline, Link, TextField, Typography, Grid } from "@mui/material";
import { useLoginMutation } from "api/authApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsAuth, setProfile, setToken } from "store/authSlice";
import runJoiValidate from "utils/runJoiValidate";
import { Logo } from "_assets";
import { ADMIN_LOGIN_SCHEMA } from "./ValidationSchemas";
import { startSocket } from "socket"
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";

export default function Login() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [login, { isLoading, error }] = useLoginMutation()
    const [user, setUser] = useState({
        email: '',
        password: ''
    })
    const [validationErrors, setValidationErrors] = useState(null)

    const handleChange = ({ target: { name, value } }) => {
        setUser(prevUser => ({
            ...prevUser,
            [name]: value
        }))
    }
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setValidationErrors(null)
            const errors = runJoiValidate(ADMIN_LOGIN_SCHEMA, user)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }
            let response = await login(user).unwrap()

            dispatch(setProfile(response?.body))
            dispatch(setToken(response?.body?.token))
            dispatch(setIsAuth(true))

            startSocket({ userId: response?.body?.id, jwt: response?.body?.token })
            navigate("/admin")
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <Helmet>
                <title>{t("Login")} | {$APP_NAME}</title>
            </Helmet>
            <Container sx={{ padding: 16 }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 50,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="a" href="/" sx={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }} >
                        <img
                            alt='white-logo'
                            height="auto"
                            src={Logo}
                            style={{ cursor: "pointer", width: 100 }}
                        />
                    </Box>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={8} md={5} lg={4} xl={4}>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    value={user?.email}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t("Email")}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    error={!!validationErrors?.email}
                                    helperText={validationErrors?.email}
                                    size="small"
                                />
                                <TextField
                                    value={user?.password}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t("Password")}
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={!!validationErrors?.password}
                                    helperText={validationErrors?.password}
                                    size="small"
                                />
                                <LoadingButton
                                    type="submit"
                                    loading={isLoading}
                                    fullWidth
                                    variant="contained"
                                    sx={{ my: 15 }}
                                >
                                    {t("Log In")}
                                </LoadingButton>
                                {!!error &&
                                    <Box textAlign="center">
                                        <Typography color="error">{t(error)}</Typography>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 18, mb: 4 }}>
                    {'Copyright © '}
                    <Link color="inherit" href="/">
                        EzPay
                    </Link>{' '}
                    {new Date().getFullYear()}
                </Typography>
            </Container>
        </>
    )
}