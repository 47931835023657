import { Clear } from "@mui/icons-material";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { cloneDeep, isEmpty, omitBy } from "lodash";
import { useRef, useState } from "react";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import { TRANSACTION_STATUS } from "constants";
import { useGetUsersQuery } from "api/userApi";
import { LoadingButton } from "@mui/lab";
import { TextFieldNumber } from "modules/common";
import { useSelector } from "react-redux";
import { SOURCE_TYPE } from "constants";

export default function TransactionsFilter({
    open,
    onClose,
    onConfirm
}) {
    const { t } = useTranslation()
    const { profile } = useSelector(state => state?.auth);
    const [transactionsParams, setTransactionsParams] = useState({})

    const handleChange = ({ target: { name, value } }) => {
        setTransactionsParams(prevPeriod => {
            return { ...prevPeriod, [name]: value }
        })
    }

    const [validationErrors, setValidationErrors] = useState(null)

    const onConfirmHandlre = () => {
        try {
            setValidationErrors(null)
            let payload = omitBy(cloneDeep(transactionsParams), v => !Boolean(v))
            let offset = Number(new Date().getTimezoneOffset() * -1)

            for (let key in payload) {
                if (key === 'fromDate') {
                    payload[key] = payload[key].utcOffset(offset).startOf("day").toISOString()
                } else if (key === 'toDate') {
                    payload[key] = payload[key].utcOffset(offset).endOf("day").toISOString()
                } else if (["fromAmount", "toAmount"].includes(key)) {
                    payload[key] = Number(payload[key])
                }
            }

            if (!!payload?.fromAmount && !!payload?.toAmount) {
                if (payload?.fromAmount > payload?.toAmount) {
                    setValidationErrors({ fromAmount: t("Must not be bigger than other value") })
                    return
                }
            }

            if (payload?.currentStatus === "all") {
                payload.currentStatus = null
            }
            if (payload?.createdFrom === "all") {
                payload.createdFrom = null
            }

            onConfirm(payload)
            onCloseHandler()
        } catch (error) {

        }
    }

    //================Get users===========================
    const [userSearchKey, setUserSearchKey] = useState('')

    const { data: users, isLoading: usersLoading } = useGetUsersQuery(omitBy({ searchKey: userSearchKey, limit: 100 }, v => !Boolean(v)))

    const userTimerRef = useRef(null)
    const onUserSearchKeyChange = (newValue) => {
        clearTimeout(userTimerRef.current)
        userTimerRef.current = setTimeout(() => {
            setUserSearchKey(newValue)
        }, 1000);
    }

    const onResetParams = () => {
        try {
            setTransactionsParams({
                fromDate: null,
                toDate: null,
            })
            onConfirm({})
            onCloseHandler()
        } catch (error) {
            console.log("error")
            console.log(error)
        }
    }

    const onCloseHandler = () => {
        onClose()
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={(e) => e.preventDefault()}
        >
            <DialogTitle>{t("Advanced Filter")}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container justifyContent="space-between" alignItems="space-around" spacing={10} sx={{ mb: 20 }}>
                    <Grid item md={12} xs={12}>
                        <Typography fontWeight="bold">{t("Date")}</Typography>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                error={true}
                                maxDate={transactionsParams?.toDate ?? moment()}
                                label={t("From")}
                                value={transactionsParams?.fromDate ?? null}
                                onChange={(newValue) => {
                                    handleChange({ target: { name: "fromDate", value: newValue } })
                                }}
                                slotProps={{
                                    textField: (params) => {
                                        return {
                                            fullWidth: true,
                                            size: "small",
                                            InputProps: {
                                                ...params?.InputProps,
                                                startAdornment: !!transactionsParams?.fromDate ?
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={(event) => {
                                                            event.stopPropagation()
                                                            handleChange({ target: { name: "fromDate", value: null } })
                                                        }} size='small' color='primary'>
                                                            <Clear sx={{ fontSize: "inherit" }} />
                                                        </IconButton>
                                                    </InputAdornment> : ''
                                            },

                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                maxDate={moment()}
                                minDate={transactionsParams?.fromDate ?? null}
                                label={t("To")}
                                value={transactionsParams?.toDate}
                                onChange={(newValue) => {
                                    handleChange({ target: { name: "toDate", value: newValue ?? null } })
                                }}
                                slotProps={{
                                    textField: (params) => {
                                        return {
                                            fullWidth: true,
                                            size: "small",
                                            InputProps: {
                                                ...params?.InputProps,
                                                startAdornment: !!transactionsParams?.toDate ?
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={(event) => {
                                                            event.stopPropagation()
                                                            handleChange({ target: { name: "toDate", value: null } })
                                                        }} size='small' color='primary'>
                                                            <Clear sx={{ fontSize: "inherit" }} />
                                                        </IconButton>
                                                    </InputAdornment> : ''
                                            },
                                        }
                                    }
                                }}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item md={12} xs={12}>
                        <Typography fontWeight="bold"> {t("Amount")}</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextFieldNumber
                            name="fromAmount"
                            value={transactionsParams?.fromAmount ?? ''}
                            onChange={handleChange}
                            decimal={true}
                            label={t("From")}
                            size="small"
                            fullWidth
                            error={!!validationErrors?.fromAmount}
                            helperText={validationErrors?.fromAmount ?? ''}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextFieldNumber
                            name="toAmount"
                            value={transactionsParams?.toAmount ?? ''}
                            onChange={handleChange}
                            decimal={true}
                            label={t("To")}
                            size="small"
                            fullWidth
                            error={!!validationErrors?.toAmount}
                            helperText={validationErrors?.toAmount ?? ''}
                        />
                    </Grid>
                    {
                        !profile?.parent &&
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Autocomplete
                                value={users?.docs?.find(user => user?.id === transactionsParams?.user) ?? ''}
                                onChange={(_, value) => handleChange({ target: { name: "user", value: value?.id } })}
                                options={users?.docs ?? []}
                                onInputChange={(_, newInputValue) => {
                                    onUserSearchKeyChange(newInputValue)
                                }}
                                getOptionLabel={(option) => (option && option.userName) ? option.userName : ''}
                                disablePortal
                                fullWidth
                                size="small"
                                loading={usersLoading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("Select merchant")}
                                    />
                                )}
                            />
                        </Grid>
                    }
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormControl
                            size="small"
                            fullWidth
                        >
                            <InputLabel id="status-label">{t("Status")}</InputLabel>
                            <Select
                                name="currentStatus"
                                labelId="status-label"
                                id="status"
                                value={transactionsParams?.currentStatus ?? "all"}
                                label={t("Status")}
                                onChange={handleChange}
                            >
                                <MenuItem value={"all"}>{t("All")}</MenuItem>
                                {TRANSACTION_STATUS.map((status) => (
                                    <MenuItem value={status} key={status}>{t(status)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="createdFrom-label">{t("Transaction Source")}</InputLabel>
                            <Select
                                labelId="createdFrom-label"
                                id="createdFrom"
                                name="createdFrom"
                                value={transactionsParams?.createdFrom ?? "all"}
                                label={t("Transaction Source")}
                                onChange={handleChange}
                            >
                                <MenuItem value={"all"}>{t("All")}</MenuItem>
                                {[SOURCE_TYPE.web, SOURCE_TYPE.mobile, SOURCE_TYPE.api].map((source) => (
                                    <MenuItem value={source} key={source}>{t(source)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={onCloseHandler}
                    sx={{ textTransform: "none" }}>
                    {t("Cancel")}
                </Button>
                <Box mr="auto" />
                <Button
                    variant="contained"
                    onClick={onResetParams}
                    disabled={isEmpty(transactionsParams)}
                    sx={{ textTransform: "none" }}
                    color="error"
                >
                    {t("Reset")}
                </Button>
                <LoadingButton
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    onClick={onConfirmHandlre}
                >
                    {t("Submit")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}