import { ROLES } from "constants";
import { apiSlice } from "./apiSlice";
import { store } from "store";

const getURL = () => store.getState().auth.profile?.role === ROLES.admin ? "/payment-method" : "/payment-method/user"

export const paymentApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPaymentMethods: builder.query({
            query: (params) => ({
                url: getURL(),
                method: 'GET',
                params
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useGetPaymentMethodsQuery,
} = paymentApi