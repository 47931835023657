import { LoadingButton } from "@mui/lab";
import { Card, CardActions, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldNumber } from "modules/common";

export default function PreferencesCard({
    profileExpiryAt,
    onUpdateBusiness,
    isLoading,
    error,
    isSuccess,
}) {
    const { t } = useTranslation()

    const [expiryAt, setExpiryAt] = useState('')

    const handleChange = ({ target: { value } }) => {
        setExpiryAt(value)
    }

    useEffect(() => {
        if(!!profileExpiryAt){
            setExpiryAt(profileExpiryAt)
        }
    },[profileExpiryAt])

    const submitHandler = () => {
        try {
            onUpdateBusiness({ expiryAt: Number(expiryAt) })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Card>
            <CardHeader title={t("Payment Link")} />
            <Divider />
            <CardContent>
                <TextFieldNumber
                    name={"expiryAt"}
                    value={expiryAt}
                    onChange={handleChange}
                    decimal={false}
                    label={t("Payment Links Validity (hours)")}
                    size="small"
                    fullWidth
                />
            </CardContent>
            <Divider />
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    onClick={submitHandler}
                    sx={{ textTransform: "none" }}
                    disabled={!expiryAt}
                >
                    {t("Submit")}
                </LoadingButton>
                {!!error && <Typography color="error">{t(error)}</Typography>}
                {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
            </CardActions>
        </Card>
    )
}