import { Paper, Typography } from '@mui/material'

export default function APIUrlMethod(api) {
    return (
        <Paper sx={{ px: 20, py: 10, my: 10, backgroundColor: "rgb(230 230 230)" }}>
            <Typography fontWeight="bold" variant="h6" color="secondary" sx={{wordBreak: 'break-word'}}>
                {api?.method}
            </Typography>
            <Typography fontWeight="bold" sx={{wordBreak: 'break-word'}}>
                {api?.url}
            </Typography>
        </Paper>
    )
}
