import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ActiveMultiVendorCard({ openConfirmDialog }) {

    const { t } = useTranslation()

    return (
        <Card>
            <CardHeader title={t("Activate Automatic Transfer")} />
            <Divider />
            <CardContent>
                {t("You must first confirm some data before activating the automatic transfer")}
            </CardContent>
            <Divider />
            <CardActions>
                <Box mr="auto" />
                <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    color="primary"
                    onClick={openConfirmDialog}
                >
                    {t("Confirm Data")}
                </Button>
            </CardActions>
        </Card>
    )
}
