import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "api/apiSlice"
import authReducer from './authSlice'
import languageReducer from './languageSlice'
import countryReducer from './countrySlice'
import confirmationDialogReducer from './confirmationDialogSlice'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        lang: languageReducer,
        country: countryReducer,
        confirmDialog: confirmationDialogReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
           // ignoredActions: ['actionTypeToIgnore'], // Replace with the action type that modifies the non-serializable path
            ignoredPaths: ['confirmDialog.onConfirm'], // Replace with the path to the non-serializable value
        }
    }).concat(apiSlice.middleware)
})