import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropTypes } from "prop-types";
import { get } from 'lodash';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

const SocialShare = ({
    link,
    ...rest
}) => {

    const { t } = useTranslation();

    const gridItemStyle = {
        transition: `all .3s ease 0s`,
        '&:hover': {
            transform: `scale(1.075) translateY(-4px)`,
            // boxShadow: `0 40px 40px 0 rgb(14 17 51 / 10%)`,
            cursor: "pointer"
        },
    }

    return (
        <Box
            {...rest}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "transparent",
                paddingInlineStart: "8px",
                ...get(rest, 'sx'),
            }}
        >
            <Typography variant="body2" my={1}>{t("Share The Article")}</Typography>
            <Grid container maxWidth={200} >
                <Grid item xs={3} zIndex={7} sx={gridItemStyle}>
                    <LinkedinShareButton url={window.location.href}>
                        <LinkedinIcon size={45} round />
                    </LinkedinShareButton>
                </Grid>
                <Grid item xs={3} zIndex={8} sx={gridItemStyle}>
                    <FacebookShareButton url={window.location.href}>
                        <FacebookIcon size={45} round />
                    </FacebookShareButton>
                </Grid>
                <Grid item xs={3} zIndex={9} sx={gridItemStyle}>
                    <TwitterShareButton url={window.location.href}>
                        <TwitterIcon size={45} round />
                    </TwitterShareButton>
                </Grid>
                <Grid item xs={3} zIndex={10} sx={gridItemStyle}>
                    <WhatsappShareButton url={window.location.href}>
                        <WhatsappIcon size={45} round />
                    </WhatsappShareButton>
                </Grid>
            </Grid>
        </Box>
    );
}

SocialShare.propTypes = {
    link: PropTypes.string.isRequired,
};

export default SocialShare;