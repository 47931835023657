import { Button, Card, CardActions, CardContent, CardHeader, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "store/languageSlice";

export default function LanguagesCard() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { language } = useSelector(state => state?.lang)
    const [selectedLangauge, setSelectedLanguage] = useState(language)
    const languageChangeSubmit = () => {
        dispatch(setLanguage({ language: selectedLangauge }))
    }
    return (
        <Card>
            <CardHeader title={t("Languages")} />
            <Divider />
            <CardContent>
                <FormControl size="small" fullWidth>
                    <InputLabel id="language-label">{t("Languages")}</InputLabel>
                    <Select
                        labelId="language-label"
                        id="language"
                        value={selectedLangauge}
                        label={t("Languages")}
                        onChange={({ target: { value } }) => setSelectedLanguage(value)}
                    >
                        <MenuItem value={"ar-SA"}>العربية</MenuItem>
                        <MenuItem value={"en-US"}>English</MenuItem>
                    </Select>
                </FormControl>
            </CardContent>
            <Divider />
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                <Button
                    variant="contained"
                    onClick={languageChangeSubmit}
                    sx={{ textTransform: "none" }}
                >
                    {t("Submit")}
                </Button>
            </CardActions>
        </Card>
    )
}