import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { One, Two, Three } from "_assets";
import { WhyEzPayBox } from "./components";
import { useMemo } from "react";

export default function WhyEzPay() {

    const { t } = useTranslation()

    const LIST = useMemo(() => {
        return [
            {
                icon: One,
                title: "landing.howItWorks.oneTitle",
                description: "landing.howItWorks.oneDescription"
            },
            {
                icon: Two,
                title: "landing.howItWorks.twoTitle",
                description: `landing.howItWorks.twoDescription${window?.location?.href?.includes("ezpayuae") ? "UAE" : "SAU"}`
            },
            {
                icon: Three,
                title: "landing.howItWorks.threeTitle",
                description: "landing.howItWorks.threeDescription"
            },
        ]
    }, [])

    return (
        <Box backgroundColor="backgroundGrey.main" sx={{ px: 20 }}>
            <Container sx={{ py: 100, textAlign: "center", px: 20 }}>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>{t("landing.howItWorks.title")}</Typography>
                <Typography variant="h5">{t("landing.howItWorks.description")}</Typography>
                <Grid container justifyContent="space-between" alignItems="stretch" sx={{ mt: 40 }}>
                    {LIST.map(item => (
                        <Grid item xl={3} md={4} sm={12} key={item?.title} sx={{width:'100%'}}>
                            <WhyEzPayBox item={item} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}