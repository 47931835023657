import { useEffect, useMemo, useState } from "react";

import { Card, Box, CardContent, CardHeader, CircularProgress, Divider, FormControlLabel, Switch, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Cancel, CheckCircle, Info } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { StatusTimeline } from "modules/common";
import { ACCOUNT_STATUS, ADMIN_TYPES, ROLES } from "constants";
import { isBoolean } from "lodash";
import { LoadingButton } from "@mui/lab";

const ICONS = {
    [ACCOUNT_STATUS.active]: <CheckCircle color="success" />,
    [ACCOUNT_STATUS.waiting]: <Info color='warning' />,
    [ACCOUNT_STATUS.blocked]: <Cancel color="error" />,
    [ACCOUNT_STATUS.blockedByParent]: <Cancel color="error" />,
    [ACCOUNT_STATUS.accountCreated]: <Info color="" sx={{ color: "grey.500" }} />,
    [ACCOUNT_STATUS.phoneVerified]: <Info sx={{ color: "grey.500" }} />,
}

export default function ActivationCard({
    id,
    status,
    timeline,
    isMyFatoorahApproved,
    onUpdateStatus,
    isLoading,
    isSuccess,
    error
}) {
    const { t } = useTranslation()
    const { profile } = useSelector(state => state?.auth);

    //======= Update User Status By Merchant =========
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        let value = status === ACCOUNT_STATUS.active ? true : false
        setChecked(value)
    }, [status, error])

    const changeStatusByMerchant = async (checked) => {
        try {
            setChecked(checked)
            let currentStatus = checked ? ACCOUNT_STATUS.active : ACCOUNT_STATUS.blocked
            let payload = { currentStatus }
            if (currentStatus === status) return
            onUpdateStatus({ id, payload })
        } catch (error) {
        }
    }

    //======= Update User Status By Admin =========
    const [currentStatus, setCurrentStatus] = useState('')

    useEffect(() => {
        if (!!status) {
            setCurrentStatus(status)
        }
    }, [status])

    const changeStatusByAdmin = ({ target: { value } }) => {
        try {
            setCurrentStatus(value)
        } catch (error) {
            console.log('error', error)
        }
    }

    const submitStatusChange = () => {
        if (!!currentStatus && currentStatus !== status) {
            onUpdateStatus({ id, payload: { currentStatus } })
        }
    }

    // const shouldAllowChangeStatus = useMemo(() => {

    //     const { role, type: adminType } = profile;
    //     if (role === ROLES.merchant || adminType === undefined) {
    //         return true;
    //     }

    //     if (adminType === ADMIN_TYPES.subAdmin && (
    //         // includes([ACCOUNT_STATUS.blocked, ACCOUNT_STATUS.active], status) && isMyFatoorahApproved
    //         status === ACCOUNT_STATUS.active ||
    //         ([ACCOUNT_STATUS.blocked, ACCOUNT_STATUS.blockedByParent].includes(status) && isMyFatoorahApproved)
    //     )) {
    //         return true
    //     }

    //     // default case, applies to account manager and sub admin when above condition doesn't apply
    //     return false
    // }, [profile, status, isMyFatoorahApproved])

    const STATUS_BY_ROLE = useMemo(() => {
        if (profile?.role === ROLES.admin) {
            if (profile?.type === ADMIN_TYPES.accountManager) {
                return ["BLOCKED"]
            }
            return ["ACCOUNT_CREATED", "WAITING", "REJECTED_BY_SERVICE_PROVIDER", "ACTIVE", "BLOCKED"]
        }
        return []
    }, [profile])

    return (
        <Card>
            <CardHeader
                title={t("Account status")}
                action={
                    profile?.role === ROLES.merchant
                        ?
                        <Box sx={{ flex: 1, minWidth: 200, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                            <FormControlLabel
                                control={<Switch checked={checked} onChange={(event) => changeStatusByMerchant(event.target.checked)} />}
                                label={
                                    isLoading ? <CircularProgress size={16} /> :
                                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                            {t(checked ? "Activated" : ["ACCOUNT_CREATED", "WAITING"].includes(status) ? `UserStatus.${status}` : "Not Activated")}
                                        </Typography>
                                }
                            />
                            {!!error && <Typography color="error" variant="caption" sx={{ marginInlineEnd: 16 }} >{t(error)}</Typography>}
                        </Box>
                        :
                        null
                }
            />
            <Divider />
            <CardContent>
                {
                    profile?.role === ROLES.admin &&
                    <>
                        <Box display="flex" alignItems="center" mb={20}>
                            <Typography variant="h6">
                                {t("Status")}
                            </Typography>
                            <span style={{ marginRight: "10px", marginLeft: "10px" }}>:</span>
                            <Typography color="secondary" variant="h6">
                                {!!status && t(`UserStatus.${status}`)}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={50}>
                            <FormControl size="small" fullWidth sx={{ mr: 50 }}>
                                <InputLabel id="account-status-label">{t("Account status")}</InputLabel>
                                <Select
                                    labelId="account-status-label"
                                    id="account-status"
                                    name="currentStatus"
                                    value={currentStatus}
                                    label={t("Account status")}
                                    onChange={changeStatusByAdmin}
                                >
                                    {
                                        STATUS_BY_ROLE.map((status) => (
                                            <MenuItem key={status} value={status}>{t(`UserStatus.${status}`)}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <LoadingButton
                                onClick={submitStatusChange}
                                loading={isLoading}
                                variant="contained"
                                color="primary"
                                sx={{ textTransform: 'none' }}
                            >
                                {t("Confirm")}
                            </LoadingButton>
                        </Box>
                    </>
                }
                <StatusTimeline timeline={timeline} icons={ICONS} localePath="UserStatus" />
            </CardContent>
        </Card>
    )
}