import { useEffect, useMemo, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, Card, CardActions, CardContent, Grid, TextField, Typography, Link } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import * as Joi from "joi";
import parsePhoneNumber from 'libphonenumber-js';
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { $APP_NAME, $BASE_URL } from "constants";
import { runJoiValidate } from "utils";
import { Footer, QuickLinks, PhoneInput } from "./common";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cloneDeep, omitBy } from "lodash";

export default function ContactUs() {

    const { t } = useTranslation()
    const navigate = useNavigate();

    const { language } = useSelector(state => state?.lang)

    const CONTACT_US_SCHEMA = useMemo(() => {
        return Joi.object({
            name: Joi.string()
                .empty('')
                .min(3)
                .label(t("Name")).messages({
                    'any.required': `{#label} ${t("validationErrors.isRequired")}`,
                    'string.empty': `{#label} ${t("validationErrors.isEmpty")}`,
                    'string.min': `{#label} ${t("validationErrors.minChar", { length: 3 })}`,
                }),
            phone: Joi.string()
                .empty('')
                .label(t("Phone"))
                .required()
                .messages({
                    'any.required': `{#label} ${t("validationErrors.isRequired")}`,
                })
                .custom((value, helper) => {
                    const parsedPhone = parsePhoneNumber(value, { extract: true });
                    if (!parsedPhone || !parsedPhone.isValid())
                        return helper.message(t("Invalid phone number"));
                    return true
                }),
            email: Joi.string()
                .empty('')
                .email({ tlds: { allow: false } })
                .label(t("Email")).messages({
                    'any.required': `{#label} ${t("validationErrors.isRequired")}`,
                    'string.empty': `{#label} ${t("validationErrors.isEmpty")}`,
                    'string.min': `{#label} ${t("validationErrors.minChar", { length: 3 })}`,
                }),
            subject: Joi.string()
                .empty('')
                .min(3)
                .empty('')
                .label(t("contactUs.subject")).messages({
                    'any.required': `{#label} ${t("validationErrors.isRequired")}`,
                    'string.empty': `{#label} ${t("validationErrors.isEmpty")}`,
                    'string.min': `{#label} ${t("validationErrors.minChar", { length: 3 })}`,
                }),
            message: Joi.string()
                .empty('')
                .min(3)
                .label(t("contactUs.body")).messages({
                    'any.required': `{#label} ${t("validationErrors.isRequired")}`,
                    'string.empty': `{#label} ${t("validationErrors.isEmpty")}`,
                    'string.min': `{#label} ${t("validationErrors.minChar", { length: 3 })}`,
                }),
        })
    }, [language])

    useEffect(() => {
        setValidationErrors();
    }, [language])

    const [contactInfo, setContactInfo] = useState({
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: ""
    })

    const [validationErrors, setValidationErrors] = useState(null)
    const handleChange = ({ target: { name, value } }) => {
        setContactInfo(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const [loading, setLoading] = useState(false)

    const submitHandler = async () => {
        try {
            setLoading(true)
            setValidationErrors(null)
            let payload = cloneDeep(contactInfo)
            payload = omitBy(payload, v => !Boolean(v));
            let errors = runJoiValidate(CONTACT_US_SCHEMA, payload)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }
            await axios.post(`${$BASE_URL}/api/v1/contact`, payload)
           
            setContactInfo({
                name: "",
                phone: "",
                email: "",
                subject: "",
                message: ""
            })

            navigate("/contact-us/message-successfully-sent", { replace: true })

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    //============Email=============
    const [searchParams] = useSearchParams()
    const emailQueryString = searchParams.get("email")

    useEffect(() => {
        if (!!emailQueryString) {
            setContactInfo(prevState => ({
                ...prevState,
                email: emailQueryString
            }))
        }
    }, [emailQueryString])

    const supportContacts = useMemo(() => {
        // TODO: to be replaced with UAE frontend env variable
        if (window?.location?.href?.includes("ezpayuae")) {
            return { phone: "+971509443956", email: "pm@ezpayuae.com" }
        } else {
            return { phone: "+966581849999", email: "info@ezpay.sa" }
        }
    })

    return (
        <>
            <Helmet>
                <title>{t("Contact Us")} | {$APP_NAME}</title>
            </Helmet>
            <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
                <Box display="flex" flex={1} backgroundColor="white" >
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                            <Card sx={{ m: 20, mt: 60, p: 20 }} elevation={0}>
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
                                    <Typography variant="h5" sx={{ fontWeight: "bold" }} color="primary">
                                        {t("contactUs.title")}
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: "15px", fontWeight: "300", lineHeight: 1.5, color: "rgb(107 119 140)" }}>
                                        {t("contactUs.subtitleOne")}
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: "15px", fontWeight: "300", lineHeight: 1.5, color: "rgb(107 119 140)" }}>
                                        {t("contactUs.subtitleTwo")}
                                        <Link style={{ marginInlineStart: 8 }} color="inherit" href={`mailto:${supportContacts?.email}`}>{supportContacts?.email}</Link>
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: "15px", fontWeight: "300", lineHeight: 1.5, color: "rgb(107 119 140)" }}>
                                        {t("contactUs.subtitleThree")}
                                        <Link style={{ marginInlineStart: 8 }} color="inherit" href={`tel:${supportContacts?.email}`}><bdi>{supportContacts?.phone}</bdi></Link>
                                    </Typography>
                                </Box>
                                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                    <TextField
                                        name="name"
                                        value={contactInfo?.name ?? ''}
                                        onChange={handleChange}
                                        sx={{ my: 15 }}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t("Name")}
                                        error={!!validationErrors?.name}
                                        helperText={t(validationErrors?.name ?? '')}
                                    />
                                    <PhoneInput
                                        label={t("Phone")}
                                        handleChange={handleChange}
                                        name="phone"
                                        value={contactInfo?.phone}
                                        error={validationErrors?.phone}
                                    />
                                    <TextField
                                        name="email"
                                        value={contactInfo?.email ?? ''}
                                        onChange={handleChange}
                                        sx={{ my: 15 }}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t("Email")}
                                        error={!!validationErrors?.email}
                                        helperText={t(validationErrors?.email ?? '')}
                                    />
                                    <TextField
                                        name="subject"
                                        value={contactInfo?.subject ?? ''}
                                        onChange={handleChange}
                                        sx={{ my: 15 }}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t("contactUs.subject")}
                                        error={!!validationErrors?.subject}
                                        helperText={t(validationErrors?.subject ?? '')}
                                    />
                                    <TextField
                                        name="message"
                                        value={contactInfo?.message ?? ''}
                                        onChange={handleChange}
                                        sx={{ my: 15 }}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t("contactUs.body")}
                                        multiline rows={4}
                                        error={!!validationErrors?.message}
                                        helperText={t(validationErrors?.message ?? '')}
                                    />
                                </CardContent>
                                <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box sx={{ mr: "auto" }} />
                                    <LoadingButton
                                        onClick={submitHandler}
                                        loading={loading}
                                        sx={{ textTransform: "none" }}
                                        variant="contained">
                                        {t("Send")}
                                    </LoadingButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <QuickLinks allowSocial={true} />
                <Footer />
            </Box>
        </>
    )
}