import React, { useState, useEffect, useMemo } from 'react';
import { Container, Paper, Typography, Box, Button, Avatar, Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Edit, WhatsApp } from '@mui/icons-material';
import { includes, isEmpty, omit } from 'lodash';
import { useCancelTransactionMutation, useGetTransactionByIdQuery, useRefundTransactionMutation } from 'api/transactionApi';
import { TRANSACTION_STATUSES } from 'constants';
import ConfirmDialog from 'modules/common/ConfirmationDialog';
import { generateImageLink } from 'utils';
import { useSelector } from 'react-redux';
import { Business } from '_assets';
import InvoiceTagStatus from './components/InvoiceTagStatus';
import QRCode from "react-qr-code";
import { Helmet } from 'react-helmet';
import { $APP_NAME } from 'constants';

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const { profile } = useSelector(state => state?.auth);
  const theme = useTheme()

  const { t } = useTranslation();
  const { transactionId } = useParams()
  const { data: invoice, refetch } = useGetTransactionByIdQuery(transactionId)

  const shareMessage = useMemo(() => {
    if (!invoice) return;
    const { name, amount, serviceDetails, id, user } = invoice;
    const country = process.env.REACT_APP_ENV === "production" ? user?.country?.isoCode3 : "KWT";
    const url = `${window.origin}/payment?id=${id}&country=${country}`;

    let payload = {
      name,
      amount,
      businessName: invoice?.business?.businessName,
      serviceDetails,
      url,
      currency: user?.country?.info[theme?.direction === "rtl" ? "arabic" : "english"]?.currency
    };

    if (!payload.serviceDetails) {
      return t('invoiceDetails.shareMessageWithoutServiceDetails', omit(payload, ['serviceDetails']));
    } else {
      return t('invoiceDetails.shareMessage', payload);
    }
  }, [invoice, profile]);


  const onSendViaWhatsapp = async () => {
    try {
      let phoneNumber = invoice?.phone.replace(/\D/g, ''); // remove all non-digit characters
      let url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(shareMessage)}`;
      window.open(url);
    } catch (error) {

    }
  };

  const editable = useMemo(() => {
    if (includes([TRANSACTION_STATUSES.INITIATED, TRANSACTION_STATUSES.IN_PROGRESS], invoice?.currentStatus)) {
      return true;
    }
    return false;
  }, [invoice]);

  const [cancelTransaction, { isLoading: cancelLoading, isSuccess: cancelIsSuccess, error: cancelError, reset: cancelReset }] = useCancelTransactionMutation()

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    if (cancelIsSuccess) {
      refetch()
      setTimeout(() => {
        setOpenConfirmDialog(false);
        cancelReset();
      }, 2500);
    }
  }, [cancelIsSuccess])

  //======= Refund =======
  const [openRefundConfirmDialog, setOpenRefundConfirmDialog] = useState(false)

  const [
    refundTransaction,
    { isLoading: refundLoading,
      isSuccess: refundIsSuccess,
      error: refundError,
      reset: refundReset
    }
  ] = useRefundTransactionMutation()

  useEffect(() => {
    if (refundIsSuccess) {
      refetch()
      setTimeout(() => {
        setOpenRefundConfirmDialog(false);
        refundReset();
      }, 2500);
    }
  }, [refundIsSuccess])

  return (
    <>
      <Helmet>
        <title>{t("Transactions Details")} | {$APP_NAME}</title>
      </Helmet>
      <Container>
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          title={t("Cancel Invoice")}
          content={t("Are you sure you want to cancel this invoice?")}
          confirmFn={() => cancelTransaction(transactionId)}
          isSuccess={cancelIsSuccess}
          error={cancelError}
          isLoading={cancelLoading}
        />
        <ConfirmDialog
          open={openRefundConfirmDialog}
          onClose={() => setOpenRefundConfirmDialog(false)}
          title={t("Refund Invoice")}
          content={t("Are you sure you want to refund this invoice?")}
          confirmFn={() => refundTransaction(transactionId)}
          isSuccess={refundIsSuccess}
          error={refundError}
          isLoading={refundLoading}
        />
        <Box display="flex" justifyContent="center" my={15}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Paper sx={{ p: 15 }}>
                <Typography variant="h5" color="primary" sx={{ fontWeight: "bold", textAlign: "center", mb: 10 }}>
                  {invoice?.transactionNumber}
                </Typography>
                <Box display="flex" justifyContent="center">
                  <InvoiceTagStatus status={invoice?.currentStatus} />
                </Box>
                <Box mt={4} direction="column" align="center">
                  <Avatar
                    mb={2}
                    sx={{
                      width: 120,
                      height: 120
                    }}
                    src={
                      !isEmpty(invoice?.business?.logo)
                        ? generateImageLink(invoice?.business?.logo, "small")
                        : Business
                    }
                  />
                  <Box display="flex" flex={1} alignItems="center" justifyContent="center" mb={5}>
                    <Typography fontWeight="bold" color="primary" sx={{ mr: 10 }}>{`${t("Amount")}: `}</Typography>
                    <Typography fontWeight="bold" color="primary">
                      {t("SAR", { price: invoice?.amount })}
                    </Typography>
                  </Box>
                  <Box display="flex" flex={1} alignItems="center" justifyContent="center" mb={5}>
                    <Typography fontWeight="bold" color="primary" sx={{ mr: 10 }}>{`${t("Date")}: `}</Typography>
                    <Typography fontWeight="bold" color="primary">
                      {moment(invoice?.createdAt).format("DD MMM YYYY - hh:mm A")}
                    </Typography>
                  </Box>
                  {!!invoice?.name &&
                    <Box display="flex" flex={1} alignItems="center" justifyContent="center" mb={5}>
                      <Typography fontWeight="bold" color="primary" sx={{ mr: 10 }}>{`${t("Client")}: `}</Typography>
                      <Typography fontWeight="bold" color="primary">
                        {invoice?.name}
                      </Typography>
                    </Box>
                  }
                  {!!invoice?.serviceDetails &&
                    <Box display="flex" flex={1} alignItems="center" justifyContent="center" mb={5}>
                      <Typography fontWeight="bold" color="primary" sx={{ mr: 10 }}>{`${t("Description")}: `}</Typography>
                      <Typography fontWeight="bold" color="primary">
                        {invoice?.serviceDetails}
                      </Typography>
                    </Box>
                  }
                  {
                    !!invoice?.createdFrom &&
                    <Box display="flex" flex={1} alignItems="center" justifyContent="center" mb={5}>
                      <Typography fontWeight="bold" color="primary" sx={{ mr: 10 }}>{`${t("Transaction Source")}: `}</Typography>
                      <Typography fontWeight="bold" color="primary">
                        {t(invoice.createdFrom)}
                      </Typography>
                    </Box>
                  }
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  my={10}
                  borderWidth={1}
                  alignSelf='center'
                  borderRadius={16}
                >
                  <QRCode
                    size={200}
                    style={{ borderColor: "#EFEFEF", borderWidth: 2, borderStyle: "solid", borderRadius: 5, padding: 10, }}
                    value={`${window.origin}/payment?id=${invoice?.id}&country=${process.env.REACT_APP_ENV === "production" ? invoice?.user?.country?.isoCode3 : "KWT"}`}
                  />
                </Box>
                <Box justifyContent="center" align="center">
                  <Box justifyContent="center" align="center" width={264}>
                    {
                      invoice?.phone
                        ?
                        <Button
                          onClick={onSendViaWhatsapp}
                          sx={{ color: "primary", textTransform: "none" }}
                          variant='text'
                          startIcon={<WhatsApp color="secondary" />}
                        >
                          {t("Send via Whatsapp")}
                        </Button>
                        : null
                    }
                  </Box>
                </Box>
                {(invoice?.currentStatus === TRANSACTION_STATUSES.PAID && isEmpty(profile?.parent)) &&
                  <Box textAlign="end" mt={10}>
                    <Button
                      onClick={() => setOpenRefundConfirmDialog(true)}
                      disabled={refundLoading}
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      color="error"
                    >
                      {t("Refund Invoice")}
                    </Button>
                  </Box>
                }
                {[TRANSACTION_STATUSES.INITIATED, TRANSACTION_STATUSES.IN_PROGRESS].includes(invoice?.currentStatus) &&
                  <Box textAlign="end" mt={10}>
                    <Button
                      onClick={() => setOpenConfirmDialog(true)}
                      disabled={cancelLoading}
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      color="error"
                    >
                      {t("Cancel Invoice")}
                    </Button>
                    {
                      editable
                        ?
                        <Button
                          onClick={() => navigate(`/merchant/transactions/${transactionId}`)}
                          color="success"
                          variant="contained"
                          startIcon={<Edit />}
                          sx={{ fontTransform: "none", ml: 10 }}
                        >
                          {t("Edit")}
                        </Button>
                        : null
                    }
                  </Box>
                }
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default InvoiceDetails;
