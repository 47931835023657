import { useState, useEffect, useRef } from "react"

import { Helmet } from "react-helmet";
import { Box, Paper, Typography, Grid, InputAdornment, TextField, useTheme } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";

import { $APP_NAME } from "constants";
import { DataTable } from "modules/common";
import { useGetBanksQuery, useImportBanksMutation, useLazyGetBankDetailsQuery } from "api/banksAPI";

import BankDetails from "./BankDetails";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { ADMIN_TYPES } from "constants";
import { Search } from "@mui/icons-material";
import { omitBy } from "lodash";

const $COLUMN_WIDTH = 200;

export default function Banks() {
    const { t } = useTranslation();
    const theme = useTheme();

    const [page, setPage] = useState(1)
    const [selectedBank, setSelectedBank] = useState(null)
    const { profile } = useSelector(state => state?.auth);

    const [searchText, setSearchText] = useState(null)
    const [searchKey, setSearchKey] = useState(null)
    const timerRef = useRef(null)

    const onSearchText = ({ target: { value } }) => {
        setSearchText(value)
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
            setSearchKey(value)
        }, 1000);
    }

    const { data: banks, isLoading, isFetching, refetch } = useGetBanksQuery(omitBy({ searchKey }, v => !Boolean(v)));

    const [importBanks, {
        isLoading: importLoading,
        error: importError,
        isSuccess: importSuccess,
    }] = useImportBanksMutation();

    const handleImportClick = async () => {
        try {
            await importBanks();
            refetch()
        } catch (e) {

        }
    };

    const pageChangeHandler = ({ page }) => {
        if (!isFetching || !isLoading) {
            setPage(page)
        }
    }

    const [getBankDetails] = useLazyGetBankDetailsQuery()
    const [dialogOpen, setDialogOpen] = useState(false)

    const handleRowClick = async (row) => {
        setSelectedBank(row)
        setDialogOpen(true)
        await getBankDetails(row.id)
        refetch()
    }
    const handleDialogClose = (shouldRefresh) => {
        setDialogOpen(false);
        setSelectedBank(null);
        if (shouldRefresh) { refetch(); }
    }

    const columns = [
        { field: "myFatoorahId", headerName: t("MyFatoorah ID"), width: $COLUMN_WIDTH, },
        { field: "nameAr", headerName: t("Arabic Name"), width: $COLUMN_WIDTH, },
        { field: "nameEn", headerName: t("English Name"), width: $COLUMN_WIDTH, },
    ];

    const [searchParams, setSearchParams] = useSearchParams()

    const bankId = searchParams.get("bankId")

    const presentBankHandler = async () => {
        let response = await getBankDetails(bankId)
        if (response?.isSuccess) {
            setSelectedBank(response?.data)
            setDialogOpen(true)
            refetch();
        }
    }

    useEffect(() => {
        if (!!bankId) {
            searchParams.delete("bankId");
            setSearchParams(searchParams);
            presentBankHandler()
        }
    }, [bankId])

    return (
        <>
            <Helmet>
                <title>{t("Banks")} | {$APP_NAME}</title>
            </Helmet>
            <Box mr={5}>
                <BankDetails open={dialogOpen} onClose={handleDialogClose} bank={selectedBank} />
                <Box display="flex" justifyContent="space-between" alignItems="center" my={15}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t('Banks')}</Typography>
                    {profile?.type === ADMIN_TYPES.subAdmin &&
                        <Box display="flex" alignItems="center">
                            {importSuccess && <Typography variant="caption" color="success.main">{t("Done successfully")}</Typography>}
                            {!!importError && <Typography variant="caption" color="error">{t(importError)}</Typography>}
                            <LoadingButton
                                loading={importLoading}
                                variant="contained"
                                onClick={handleImportClick}
                                sx={{ textTransform: "none" }}
                            >
                                {t("Import")}
                            </LoadingButton>
                        </Box>
                    }
                </Box>
                <Grid container spacing={2} sx={{ mb: 15 }}>
                    <Grid item xl={4} lg={4} md={6} sm={10} xs={10}>
                        <TextField
                            fullWidth
                            label={t("Search")}
                            size="small"
                            value={searchText ?? ''}
                            onChange={onSearchText}
                            InputProps={
                                theme.direction === "rtl" ?
                                    {
                                        endAdornment: <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>,
                                    }
                                    :
                                    {
                                        startAdornment: <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>,
                                    }
                            }
                        />
                    </Grid>
                </Grid>
                <Paper sx={{ height: "75vh" }}>
                    <DataTable
                        rows={banks ?? []}
                        columns={columns}
                        onPaginationModelChange={pageChangeHandler}
                        paginationModel={{ page: 1, pageSize: 100 }}
                        rowCount={banks?.length ?? 0}
                        loading={isLoading || isFetching}
                        onRowClick={({ row }) => handleRowClick(row)}
                    />
                </Paper>
            </Box>
        </>
    );
};