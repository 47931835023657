import HTTPService from "./HttpService";
import { $BASE_URL } from "constants";

const apiUrl = $BASE_URL + "/api/v1";

async function getInvoiceDetails(id) {
    return HTTPService.get(`${apiUrl}/transaction/public/${id}`)
}

const PaymentService = {
    getInvoiceDetails
};

export default PaymentService;