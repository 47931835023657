import * as Joi from "joi";
import i18n from "i18n";
import { joiNumber, joiString } from "utils/joi";
import { parsePhoneNumber } from "react-phone-number-input";

export const TRANSACTION_SCHEMA = Joi.object({
    amount: joiNumber.number()
        .label(i18n.t("Amount"))
        .required()
    ,
    serviceDetails: joiString.string()
        .label(i18n.t("Description"))
        .empty(''),
    phone: joiString.string().trim()
        .label(i18n.t("Phone"))
        .empty('')
        .allow(null)
        .custom((value, helper) => {
            const parsedPhone = parsePhoneNumber(value, { extract: true });
            if (!parsedPhone || !parsedPhone.isValid())
                return helper.message(i18n.t("Invalid phone number", { ns: "landing" }));
            return true
        })
    ,
    name: joiString.string()
        .label(i18n.t("Client Name"))
        .empty(''),

});
