import { LoadingButton } from "@mui/lab";
import { Box, Card, CardActions, CardContent, CardHeader, Divider, TextField, Typography } from "@mui/material";
import { cloneDeep, pick } from "lodash";
import { PhoneInput } from "modules/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import runJoiValidate from "utils/runJoiValidate";
import { USER_INFO_SCHEMA } from "../profile-validation-schemas";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { useUpdateProfileMutation } from "api/authApi";

export default function UserInfoCard({ profile }) {
  const { t } = useTranslation()

  const [updateProfile, { isLoading, error, isSuccess, reset }] = useUpdateProfileMutation()
  const [validationErrors, setValidationErrors] = useState(null)

  const [user, setUser] = useState({
    userName: '',
    email: '',
    phone: '',
  })

  const handleChange = ({ target: { name, value } }) => {
    setUser((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  useEffect(() => {
    if (!!profile) {
      let data = pick(profile, ["userName", "email", "phone"])
      setUser(data)
    }
  }, [profile])

  const submitHandler = async () => {
    try {
      setValidationErrors(null)
      reset()

      let payload = cloneDeep(user)

      const errors = runJoiValidate(USER_INFO_SCHEMA, payload)
      if (!!errors) {
        setValidationErrors(errors)
        return
      }

      await updateProfile(payload)

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Card>
      <CardHeader title={t("Account basic info")} />
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" my={7}>
          <Typography sx={{ fontWeight: "bold" }}>{t("Email verified")}</Typography>
          {profile?.emailVerified ? <CheckCircle color="success" /> : <Cancel color="error" />}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" my={7}>
          <Typography sx={{ fontWeight: "bold" }}>{t("Phone verified")}</Typography>
          {profile?.phoneVerified ? <CheckCircle color="success" /> : <Cancel color="error" />}
        </Box>
        <Divider sx={{ my: 15 }} />
        <TextField
          name="userName"
          label={t("Name")}
          value={user?.userName}
          onChange={handleChange}
          size="small"
          variant="outlined"
          fullWidth
          sx={{ my: 7 }}
          error={!!validationErrors?.userName}
          helperText={validationErrors?.userName}
        />
        <TextField
          name="email"
          label={t("Email")}
          value={user?.email ?? ''}
          onChange={handleChange}
          size="small"
          variant="outlined"
          fullWidth
          sx={{ my: 7 }}
          error={!!validationErrors?.email}
          helperText={validationErrors?.email}
        />
        <PhoneInput
          label={t("Phone")}
          name="phone"
          value={user?.phone}
          onChange={handleChange}
          fullWidth
          sx={{ my: 7 }}
          error={validationErrors?.phone}
        />
      </CardContent>
      <Divider />
      <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
        <LoadingButton loading={isLoading} variant="contained" onClick={submitHandler} sx={{ textTransform: "none" }}>
          {t("Submit")}
        </LoadingButton>
        {!!error && <Typography color="error">{t(error)}</Typography>}
        {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
      </CardActions>
    </Card>
  )
}