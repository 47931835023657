import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { NewIntro as IntroIllustration } from "_assets"
import { QuickLinks } from "modules/landing/screens/common"
import { useState } from "react"
import { VideoDialog } from "modules/common"

export default function Intro() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const downLg = useMediaQuery(theme => theme.breakpoints.down('md'));
    const downMd = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [openVideo, setOpenVideo] = useState(false);

    return (
        <Box backgroundColor="white" sx={{ px: 20 }}>
            <Container sx={{ py: 50, px: 20, textAlign: downLg && "center" }}>
                <Grid container justifyContent="center" alignItems="center" id="home">
                    <Grid item xl={8} lg={8} md={6} sm={12} xs={12} alignSelf="center">
                        <Typography color="primary" variant="h5" sx={{ fontWeight: "bold" }}>
                            {t("landing.intro.subtitle")}
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                            {t("landing.intro.title")}
                        </Typography>
                        <Typography variant="h6" sx={{ my: 20, fontWeight: "bold" }}>
                            {t("landing.intro.description")}
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => navigate("/contact-us")}
                            size="small"
                            sx={{
                                color: "secondary.main",
                                width: 200,
                                fontWeight: "bold",
                                fontSize: 20,
                                my: downLg && 20, borderRadius: 40,
                                mr: 5
                            }}
                        >
                            {t("landing.contactUs")}
                        </Button>
                        <VideoDialog open={openVideo} onClose={() => setOpenVideo(false)} />
                        <Button
                            variant="outlined"
                            onClick={() => setOpenVideo(true)}
                            size="small"
                            sx={{
                                color: "secondary.main",
                                width: 200,
                                fontWeight: "bold",
                                fontSize: 20,
                                my: downLg && 20, borderRadius: 40
                            }}
                        >
                            {t("landing.watchVideo")}
                        </Button>
                        <QuickLinks sx={{marginInlineStart: downMd ? 0 : -5}}/>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={9} >
                        <img width="100%" height="100%" style={{ objectFit: 'cover', marginBottom: -6 }} src={IntroIllustration} alt="softpos, pos, mpos, payment" />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}