import { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { cloneDeep, filter, map, set } from 'lodash';

import { DataTable } from 'modules/common';
import { useAddAppVersionMutation, useGetAppVersionsQuery, useUpdateAppVersionMutation, useDeleteAppVersionMutation } from 'api/appVersionsApi';

import AppVersionDialog from './AppVersionDialog';
import MinimumSupportedVersion from './MinimumSupportedVersion';
import { Helmet } from 'react-helmet';
import { $APP_NAME } from 'constants';

const $COLUMN_WIDTH = 200;

export default function AppVersions() {
  const { t } = useTranslation();

  //============AppVersion payload==============
  const [appVersion, setAppVersion] = useState({
    version: undefined,
    platform: "ANDROID",
    changeLog: ''
  });

  const handleChange = ({ target: { name, value } }) => {
    setAppVersion((prev) => {
      let clone = !!prev ? cloneDeep(prev) : {};
      set(clone, name, value);
      return clone;
    });
  };

  //==============AppVersions CRUD operations=========
  const [page, setPage] = useState(1);

  const { data: appVersions, isLoading: appVersionsLoading, isFetching: appVersionsFetching } = useGetAppVersionsQuery({ page, limit: 100 });

  const pageChangeHandler = ({ page }) => {
    if (!appVersionsLoading && !appVersionsFetching) {
      setPage(page);
    }
  };

  //-----Add-----
  const [addAppVersion, { isLoading: addAppVersionLoading, isSuccess: addAppVersionIsSuccess, error: addAppVersionError, reset: addAppVersionReset }] = useAddAppVersionMutation();

  //-----Update-----
  const [updateAppVersion, { isLoading: updateAppVersionLoading, isSuccess: updateAppVersionIsSuccess, error: updateAppVersionError, reset: updateAppVersionReset }] = useUpdateAppVersionMutation();

  //-----Delete-----
  const [deleteAppVersion, { isLoading: deleteAppVersionLoading, isSuccess: deleteAppVersionIsSuccess, error: deleteAppVersionError, reset: deleteAppVersionReset }] = useDeleteAppVersionMutation();

  //=============AppVersion Dialog============
  const [isEditing, setIsEdition] = useState(false);
  const [openAppVersionDialog, setOpenAppVersionDialog] = useState(false);

  const openAppVersionDialogHandler = (appVersion) => {
    if (!!appVersion) {
      setIsEdition(true);
      setAppVersion(appVersion);
    }
    setOpenAppVersionDialog(true);
  };

  const closeDialogHandler = () => {
    setOpenAppVersionDialog(false);
    setAppVersion({
      version: undefined,
      platform: "ANDROID",
      changeLog: ''
    });
    setIsEdition(false);
    addAppVersionReset();
    updateAppVersionReset();
    deleteAppVersionReset();
  };

  //=============AppVersions Table Columns============
  const columns = [
    { field: "platform", headerName: t("Platform"), width: $COLUMN_WIDTH * 1.2 },
    { field: "version", headerName: t("Version"), width: $COLUMN_WIDTH * 1.2 },
    { field: "changeLog", headerName: t("Change Log"), width: $COLUMN_WIDTH * 1.2 },
  ];

  return (
    <>
      <Helmet>
        <title>{t("App Versions")} | {$APP_NAME}</title>
      </Helmet>
      <Box mr={5}>
        <Box display="flex" justifyContent="space-between" alignItems="center" my={20}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t("App Versions")}</Typography>
          <Button
            startIcon={<Add />}
            sx={{ textTransform: "none" }}
            variant="contained"
            onClick={() => openAppVersionDialogHandler()}
          >
            {t("Add")}
          </Button>
        </Box>
        <MinimumSupportedVersion
          ios={
            map(
              filter(appVersions?.docs || [], version => version.platform === "IOS"),
              ({ version }) => version
            )
          }
          android={
            map(
              filter(appVersions?.docs || [], version => version.platform === "ANDROID"),
              ({ version }) => version
            )
          }
        />
        <AppVersionDialog
          openAppVersionDialog={openAppVersionDialog}
          appVersion={appVersion}
          handleChange={handleChange}
          onCloseDialog={closeDialogHandler}
          isEditing={isEditing}
          onAddAppVersion={addAppVersion}
          onUpdateAppVersion={updateAppVersion}
          onDeleteAppVersion={deleteAppVersion}
          loading={addAppVersionLoading || updateAppVersionLoading || deleteAppVersionLoading}
          error={addAppVersionError || updateAppVersionError || deleteAppVersionError}
          isSuccess={addAppVersionIsSuccess || updateAppVersionIsSuccess || deleteAppVersionIsSuccess}
        />
        <Paper sx={{ height: "65vh" }}>
          <DataTable
            rows={appVersions?.docs ?? []}
            columns={columns}
            rowCount={appVersions?.totalDocs ?? 0}
            loading={appVersionsLoading || appVersionsFetching}
            onRowClick={({ row }) => openAppVersionDialogHandler(row)}
            onPaginationModelChange={pageChangeHandler}
            paginationModel={{
              page: page - 1,
              pageSize: 100
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
