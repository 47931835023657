import { Container, Grid } from "@mui/material";
import { useGetBusinessProfileQuery, useCompleteBussinesProfleMutation, useUpdateBusinessProfileMutation } from "api/businessApi";
// import { useGetSettingsQuery } from "api/settingsApi";
import AutomaticTransferCard from "modules/admin/AdminUserDetails/AutomaticTransferCard";
import UserBusinessCard from "modules/admin/AdminUserDetails/UserBusinessCard";
import PreferencesCard from "./components/PreferencesCard";
import { useState } from "react";
import { get, pick } from "lodash";
import UserDocuments from "modules/admin/AdminUserDetails/UserDocuments";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { useTranslation } from "react-i18next";
import { useDeleteDocumentMutation } from "api/adminApi";
import { DOCUMNETS } from "constants";

const CARDS = {
    PREFERENCES: "PREFERENCES",
    BUSINESSINFO: "BUSINESSINFO",
    DOCUMENTS: "DOCUMENTS",
    BANK: "BANK",
}

export default function BusinessProfile() {
    const { t } = useTranslation()
    const { data: businessProfile } = useGetBusinessProfileQuery();
    // const { data: settings } = useGetSettingsQuery({});

    const [currentCard, setCurrentCard] = useState(null)
    //======= Update Business =========
    const [updateBusiness,
        {
            isLoading: updateBusinessLoading,
            error: updateBusinessError,
            isSuccess: updateBusinessIsSuccess,
            reset: updateBusinessReset
        }] = useUpdateBusinessProfileMutation()

    const onUpdateBusinessHandler = async (payload) => {
        try {
            updateBusinessReset()
            await updateBusiness(payload).unwrap()
        } catch (error) { }
    }

    //======= Update Bank Account =========
    const [completeBusinessProfile,
        {
            isLoading: completeBusinessProfileLoading,
            error: completeBusinessProfileError,
            isSuccess: completeBusinessProfileIsSuccess,
            reset: completeBusinessProfileReset
        }] = useCompleteBussinesProfleMutation()

    const onCompleteBusinessProfileHandler = async (payload) => {
        try {
            completeBusinessProfileReset()
            await completeBusinessProfile(payload).unwrap()
        } catch (error) { }
    }

    //======= Delete Documents By Admin =========
    const [deleteDocument,
        {
            isLoading: deleteDocumentLoading,
            error: deleteDocumentError,
            isSuccess: deleteDocumentIsSuccess
        }] = useDeleteDocumentMutation()

    const deleteDocumentHandler = async (key) => {
        try {
            await deleteDocument({ payload: { key } }).unwrap
        } catch (error) { }
    }

    return (
        <>
            <Helmet>
                <title>{t("Merchant Business profile")} | {$APP_NAME}</title>
            </Helmet>
            <Container sx={{ my: 20 }}>
                <Grid container spacing={20} alignItems="flex-start">
                    <Grid item container xs={12} md={6} spacing={20} >
                        <Grid item xs={12}>
                            <UserBusinessCard
                                userBusiness={businessProfile}
                                onUpdateBusiness={(payload) => {
                                    setCurrentCard(CARDS.BUSINESSINFO)
                                    onUpdateBusinessHandler(payload)
                                }}
                                isLoading={currentCard === CARDS.BUSINESSINFO && updateBusinessLoading}
                                isSuccess={currentCard === CARDS.BUSINESSINFO && updateBusinessIsSuccess}
                                error={currentCard === CARDS.BUSINESSINFO && updateBusinessError}
                                allowUpdate={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <UserDocuments
                                userStatus={get(businessProfile, 'user.currentStatus')}
                                userDocuments={pick(businessProfile, DOCUMNETS.map(doc => doc.name))}
                                onUpdate={(payload) => {
                                    setCurrentCard(CARDS.DOCUMENTS)
                                    onCompleteBusinessProfileHandler(payload)
                                }}
                                onDelete={deleteDocumentHandler}
                                isLoading={currentCard === CARDS.DOCUMENTS && (completeBusinessProfileLoading || deleteDocumentLoading)}
                                isSuccess={currentCard === CARDS.DOCUMENTS && (completeBusinessProfileIsSuccess || deleteDocumentIsSuccess)}
                                error={currentCard === CARDS.DOCUMENTS && (completeBusinessProfileError || deleteDocumentError)}
                            // supportContacts={pick(settings, ['supportEmail', 'supportPhone'])}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6} spacing={20} >
                        <Grid item xs={12}>
                            <AutomaticTransferCard
                                userBusiness={businessProfile}
                                onUpdateBankAccount={(payload) => {
                                    setCurrentCard(CARDS.BANK)
                                    onCompleteBusinessProfileHandler(payload)
                                }}
                                isLoading={currentCard === CARDS.BANK && completeBusinessProfileLoading}
                                isSuccess={currentCard === CARDS.BANK && completeBusinessProfileIsSuccess}
                                error={currentCard === CARDS.BANK && completeBusinessProfileError}
                            // supportContacts={pick(settings, ['supportEmail', 'supportPhone'])}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PreferencesCard
                                profileExpiryAt={businessProfile?.expiryAt}
                                onUpdateBusiness={(payload) => {
                                    setCurrentCard(CARDS.PREFERENCES)
                                    onUpdateBusinessHandler(payload)
                                }}
                                isLoading={currentCard === CARDS.PREFERENCES && updateBusinessLoading}
                                isSuccess={currentCard === CARDS.PREFERENCES && updateBusinessIsSuccess}
                                error={currentCard === CARDS.PREFERENCES && updateBusinessError}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
