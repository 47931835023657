import { ROLES } from "constants";
import { MerchantLayout } from "layouts";
import { AdminInvoiceDetails } from "modules/admin";
import { TransactionManagement, TransactionDetails, TransactionsList, UsersList, Profile, UserDetails, InvoicesList, Statistics } from "modules/merchant";
import BusinessProfile from "modules/merchant/BusinessProfile";
import { Navigate } from "react-router-dom";

const CheckBeforeEnter = (role, currentStatus) => {
    if (currentStatus === "BLOCKED") {
        return <Navigate to="/block" replace />
    } else {
        return role === ROLES.merchant ? <MerchantLayout /> : <Navigate to="/auth/login" replace />
    }
}

export const MerchantRoutes = ({ role, currentStatus }) => [
    {
        path: "merchant",
        element: CheckBeforeEnter(role, currentStatus),
        children: [
            { path: "", element: <Statistics /> },
            { path: "create-transaction", element: <TransactionManagement /> },
            { path: "users", element: <UsersList /> },
            { path: "users/:id", element: <UserDetails /> },
            {
                path: "transactions",
                children: [
                    { path: "", element: <TransactionsList /> },
                    { path: ":transactionId", element: <TransactionManagement /> },
                    { path: "details/:transactionId", element: <TransactionDetails /> },
                ]
            },
            { path: "profile", element: <Profile /> },
            {
                path: "invoices",
                children: [
                    { path: "", element: <InvoicesList /> },
                    { path: ":invoiceId", element: <AdminInvoiceDetails role={ROLES.merchant} /> },
                ]
            },
            { path: "business-profile", element: <BusinessProfile /> },
            // { path: "transaction/:transactionId", element: <MerchantTransactionDetails /> },
            // { path: "invoices", element: <MerchantInvoices /> },
            // { path: "invoice/:invoiceId", element: <MerchantInvoiceDetails /> },
            // { path: "settings", element: <MerchantSettings /> },
            // { path: "user/:id", element: <MerchantUserDetails /> },
        ]
    },
]