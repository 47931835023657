import { Box, Chip, Container, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { DataTable, MerchantName } from "modules/common";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserDialog from "./UserDialog";
import { Add, Search } from "@mui/icons-material";
import { useAddUserMutation, useGetUsersQuery } from "api/userApi";
import { omit } from "lodash";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { SOURCE_TYPE } from "constants";

const $COLUMN_WIDTH = 200
export default function UsersList() {
  const status_colors = { WAITING: "warning", ACCOUNT_CREATED: "primary", BLOCKED: "error", BLOCKED_BY_PARENT: "error", ACTIVE: "success" }
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()
  const { profile } = useSelector(state => state?.auth);
  const [page, setPage] = useState(1)
  const [params, setParams] = useState({
    page: 1,
    limit: 100,
  })
  const { data: users, isLoading: usersLoading, isFetching } = useGetUsersQuery(params)

  const UserStatus = ({ value }) => {
    return (
      <Chip
        label={t(`UserStatus.${value}`)}
        sx={{ color: "white", flex: 1, fontWeight: 500 }}
        color={status_colors[value]}
      />
    )
  }


  let columns = [
    { field: "userName", renderCell: ({ value }) => <MerchantName creator={{ userName: value, parent: profile?.id }} />, sortable: false, headerName: t("Name"), width: $COLUMN_WIDTH * 1.1 },
    { field: "phone", sortable: false, headerName: t("Phone"), width: $COLUMN_WIDTH * 1 },
    { field: "currentStatus", renderCell: UserStatus, sortable: false, headerName: t("Status"), width: $COLUMN_WIDTH * 0.9 },
    { field: "createdFrom", valueGetter: ({ value }) => t(value ?? ''), sortable: false, headerName: t("Account Source"), width: $COLUMN_WIDTH * 0.8 },
    { field: "createdAt", valueGetter: ({ value }) => moment(value).format("DD/MM/YY - h:mm A"), sortable: false, headerName: t("Created At"), width: $COLUMN_WIDTH * 0.8 },
  ];

  const pageChangeHandler = ({ page }) => {
    if (!isFetching) {
      setPage(page)
      setParams((prevParams) => ({ ...prevParams, page }))
    }
  }


  const [searchKey, setSearchKey] = useState('');
  const timerRef = useRef(null)

  const onSearchKey = ({ target: { value } }) => {
    setSearchKey(value)
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      if (!!value) {
        setParams((prevParams) => ({ ...prevParams, searchKey: value }))
      } else {
        setParams(omit(params, ['searchKey']))
      }
    }, 1500);
  }

  //======================Create user handlers==================
  const [addUser, { isLoading: createUserLoading, isSuccess: createUserIsSuccess, error: createUserError, reset }] = useAddUserMutation()
  const [userDialogOpen, setUserDialogOpen] = useState(false)

  const closeUserDialogHandler = () => {
    setUserDialogOpen(false)
    reset()
  }

  const userDialogOpenHandler = () => {
    reset()
    setUserDialogOpen(true)
  }

  const addUserHandler = async (payload) => {
    try {
      await addUser({...payload, createdFrom: SOURCE_TYPE.web}).unwrap()
      setTimeout(() => {
        closeUserDialogHandler()
      }, 2500)
    } catch (error) { }
  }


  return (
    <>
      <Helmet>
        <title>{t("Users List")} | {$APP_NAME}</title>
      </Helmet>
      <Container>
        <Box display="flex" alignItems="center" my={10}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t("Users")}</Typography>
          <Tooltip title={t("Add new user")}>
            <IconButton onClick={userDialogOpenHandler} color="primary" size="small" sx={{ ml: 20, borderWidth: "1px", borderColor: "primary", borderStyle: "solid" }}>
              <Add />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container alignItems="center" sx={{ my: 10 }}>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextField
              fullWidth
              label={t("Search")}
              size="small"
              value={searchKey}
              onChange={onSearchKey}
              InputProps={
                theme.direction === "rtl" ?
                  {
                    endAdornment: <InputAdornment position="end">
                      <Search />
                    </InputAdornment>,
                  }
                  :
                  {
                    startAdornment: <InputAdornment position="start">
                      <Search />
                    </InputAdornment>,
                  }
              }
            />
          </Grid>
        </Grid>
        <UserDialog
          open={userDialogOpen}
          onClose={closeUserDialogHandler}
          loading={createUserLoading}
          isSuccess={createUserIsSuccess}
          error={createUserError}
          onCreate={addUserHandler}
        />
        <Paper sx={{ height: "76vh" }}>
          <DataTable
            rows={users?.docs ?? []}
            columns={columns}
            onPaginationModelChange={pageChangeHandler}
            paginationModel={{ page: page - 1, pageSize: 100 }}
            rowCount={users?.totalDocs ?? 0}
            loading={usersLoading || isFetching}
            onRowClick={row => navigate(`/merchant/users/${row?.id}`)}
          />
        </Paper>
      </Container>
    </>
  )
}