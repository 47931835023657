
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";


export default function ContactUs() {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const status = searchParams.get('status')

    const initTimer = () => {
        setTimeout(() => {
            navigate("/", { replace: true })
        }, 4000);
    }

    useEffect(() => {
        initTimer()
    }, [])

    return (
        <>
            <Helmet>
                <title>{t("Request to delete account")} | {$APP_NAME}</title>
            </Helmet>
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    {status === "SUCCESS" &&
                        <Box display="flex" justifyContent="center" alignItems="center" p={10}>
                            <CheckCircle color="success" sx={{ mr: 10, fontSize: 50  }} />
                            <Typography variant="h4" color="success.main" >{"We have received your request and we will contact you as soon as possible"}</Typography>
                        </Box>
                    }
                    {status === "FAILED" &&
                        <Box display="flex" justifyContent="center" alignItems="center" p={10}>
                            <Cancel color="error" sx={{ mr: 10, fontSize: 50 }} />
                            <Typography variant="h4" color="error.main" >{"Something went wrong please try again"}</Typography>
                        </Box>
                    }
                </Box>
            </Container>
        </>
    )
}