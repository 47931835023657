import { apiSlice } from "./apiSlice";

export const contactusApi = apiSlice.injectEndpoints({
    keepUnusedDataFor: 1,
    endpoints: builder => ({
        getContacts: builder.query({
            query: (params) => ({
                url: `/contact`,
                method: 'GET',
                params
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getContactById: builder.query({
            query: (id) => ({
                url: `/contact/${id}`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getDeleteRequests: builder.query({
            query: (params) => ({
                url: `/delete-message`,
                method: 'GET',
                params
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getDeleteRequestById: builder.query({
            query: (id) => ({
                url: `/delete-message/${id}`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        addContactMessage: builder.mutation({
            query: (id) => ({
                url: `/contact`,
                method: 'POST',
            }),
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useGetContactsQuery,
    useLazyGetContactsQuery,
    useLazyGetContactByIdQuery,
    useAddContactMutation,
    useGetDeleteRequestsQuery,
    useGetDeleteRequestByIdQuery,
    useLazyGetDeleteRequestByIdQuery
} = contactusApi