import { apiSlice } from "./apiSlice";

export const userApi = apiSlice
    .enhanceEndpoints({ addTagTypes: ["USER", "USER-BUSINESS-INFO"] })
    .injectEndpoints({
        keepUnusedDataFor: 1,
        endpoints: builder => ({
            getUsers: builder.query({
                query: (params) => ({
                    url: `/user`,
                    method: 'GET',
                    params
                }),
                providesTags: (result, _, __) =>
                    result
                        ? [
                            ...result?.docs.map(({ id }) => ({ type: "USER", id })),
                            { type: "USER", id: "USER-PARTIAL-LIST" },
                        ]
                        : [{ type: "USER", id: "USER-PARTIAL-LIST" }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            addUser: builder.mutation({
                query: (payload) => ({
                    url: `/user`,
                    method: 'POST',
                    body: { ...payload }
                }),
                invalidatesTags: [{ type: "USER", id: "USER-PARTIAL-LIST" }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateUser: builder.mutation({
                query: ({ id, payload }) => ({
                    url: `/user/${id}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: (_, __, { id }) => [
                    { type: "USER", id },
                    { type: "USER", id: "USER-PARTIAL-LIST" },
                ],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateUserStatus: builder.mutation({
                query: ({ id, payload }) => ({
                    url: `/user/update-status/${id}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: (_, __, { id }) => [
                    { type: "USER", id },
                    { type: "USER", id: "USER-PARTIAL-LIST" },
                ],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            deleteUser: builder.mutation({
                query: (id) => ({
                    url: `/user/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: [{ type: "USER", id: "USER-PARTIAL-LIST" }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getUserById: builder.query({
                query: (id) => ({
                    url: `/user/${id}`,
                    method: 'GET',
                }),
                providesTags: (_, __, id) => [{ type: "USER", id }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getWaitingUsersCount: builder.query({
                query: () => ({
                    url: `/user/waiting-count`,
                    method: 'GET',
                }),
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getUserBusinessById: builder.query({
                query: (id) => ({
                    url: `/business/${id}`,
                    method: 'GET',
                }),
                providesTags: (_, __, id) => [
                    "USER-BUSINESS-INFO",
                    { type: "USER-BUSINESS-INFO", id },
                ],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getCounters: builder.query({
                query: () => ({
                    url: `/setting/counters`,
                    method: 'GET',
                }),
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
        })
    })

export const {
    useGetUsersQuery,
    useGetUserByIdQuery,
    useGetUserBusinessByIdQuery,
    useLazyGetUserBusinessByIdQuery,
    useGetWaitingUsersCountQuery,
    useAddUserMutation,
    useUpdateUserMutation,
    useUpdateUserStatusMutation,
    useGetCountersQuery,
} = userApi