import socketIO from 'socket.io-client';
import { $BASE_URL } from "constants";

export var socket = null
export const startSocket = ({ userId, jwt }) => {
    if (!!userId && !!jwt && !socket) {
        socket = socketIO(
            $BASE_URL,
            {
                transports: ['websocket'],
                jsonp: false,
                auth: {
                    token: `Bearer ${jwt}`
                }
            }
        );
        socket.on('connect', () => {
            socket.emit('register', { userId })
        });

        socket.on('disconnect', (reason) => {
            if (reason !== "io client disconnect") {
                socket.connect();
            }
        });

        socket.on("connect_error", (error) => {
            console.log(error)
        });
    }
}

export const disconnectSocket = () => {
    socket.disconnect()
    socket = null
}
