import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, useTheme, Box, FormControl, InputLabel, Select, MenuItem, Typography, FormHelperText } from '@mui/material'
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { cloneDeep, isObject, omitBy, pick } from 'lodash';
import { runJoiValidate } from 'utils';
import { SUB_ADMIN_SCHEMA } from '../sub-admins-validation-schemas';
import { ADMIN_TYPES } from 'constants';
import { PasswordTextField, PhoneInput, ServerAutoCompleteSearch } from 'modules/common';
import { useGetCountriesQuery } from 'api/countriesApis';
import { useSelector } from 'react-redux';

const SubAdminDialog = ({
    open,
    isEditing,
    subAdminData,
    onCreate,
    onUpdate,
    onDelete,
    onClose,
    loading,
    isSuccess,
    error
}) => {

    const { t } = useTranslation()
    const theme = useTheme();
    const { profile } = useSelector(state => state?.auth);

    //========Get countries=========
    const [countrySearchKey, setCountrySearchKey] = useState('')
    const { data: countries, isLoading: countriesLoading } = useGetCountriesQuery(omitBy({ limit: 100, searchKey: countrySearchKey, active: true }, v => !Boolean(v)))

    const [subAdmin, setSubAdmin] = useState({
        userName: '',
        email: '',
        country: '',
        password: '',
        type: 'SUB_ADMIN'
    })

    useEffect(() => {
        if (isEditing && !!subAdminData) {
            console.log("subAdminData", subAdminData)
            setSubAdmin(pick(subAdminData, ["userName", "email", "phone", "country", "password", "type"]))
        }
    }, [subAdminData, isEditing, profile])

    const handleChange = ({ target: { name, value } }) => {
        setSubAdmin(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const [validationErrors, setValidationErrors] = useState(null)

    const onConfirmHandler = () => {
        try {
            let payload = cloneDeep(pick(subAdmin, ["userName", "email", "phone", "country", "password", "type"]))

            if (payload?.type === ADMIN_TYPES.admin) {
                delete payload?.country
            } else if (profile?.type === ADMIN_TYPES.subAdmin) {
                payload.country = profile?.country?.id
                payload.type = ADMIN_TYPES.accountManager
            }

            const SCHEMA = isEditing ? SUB_ADMIN_SCHEMA : SUB_ADMIN_SCHEMA.fork(
                ['password'],
                (x) => x.required()
            );

            if (!!payload?.country && isObject(payload?.country)) {
                payload.country = payload?.country?.id
            }

            const errors = runJoiValidate(SCHEMA, payload)
            console.log("errors")
            console.log(payload)
            console.log(errors)
            if (errors) {
                setValidationErrors(errors)
                return
            }

            payload = omitBy(payload, v => !v)

            if (isEditing) {
                onUpdate({ id: subAdminData?.id ?? '', payload })
            } else {
                onCreate(payload)
            }
            
        } catch (error) {
            console.log("error")
            console.log(error)
        }
    }

    useEffect(() => {
        if (!open) {
            setSubAdmin({
                name: '',
                email: '',
                phone: '',
                country: '',
                password: '',
                type: 'SUB_ADMIN'
            })
            setValidationErrors(null)
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={(e) => e.preventDefault()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle>{isEditing ? t("Edit Admin") : t("Add Admin")}</DialogTitle>
            <Divider />
            <DialogContent>
                <TextField
                    name="userName"
                    label={t("Name")}
                    fullWidth
                    margin="dense"
                    size="small"
                    value={subAdmin?.userName}
                    onChange={handleChange}
                    error={!!validationErrors?.userName}
                    helperText={validationErrors?.userName ?? ''}
                />
                <TextField
                    name="email"
                    label={t("Email")}
                    fullWidth
                    margin="dense"
                    size="small"
                    value={subAdmin?.email}
                    onChange={handleChange}
                    error={!!validationErrors?.email}
                    helperText={validationErrors?.email ?? ''}
                />
                <PhoneInput
                    fullWidth
                    label={t("Phone")}
                    name="phone"
                    margin="dense"
                    value={subAdmin?.phone}
                    onChange={handleChange}
                    error={validationErrors?.phone}
                />
                {
                    profile?.type !== ADMIN_TYPES.subAdmin &&
                    <>
                        <FormControl margin="dense" fullWidth size="small">
                            <InputLabel id="type-label">{t("Role")}</InputLabel>
                            <Select
                                labelId="type-label"
                                id="type"
                                name="type"
                                value={subAdmin?.type}
                                label={t("Role")}
                                onChange={handleChange}
                                error={!!validationErrors?.type}
                            >
                                <MenuItem value={ADMIN_TYPES.admin}>{t("Admin")}</MenuItem>
                                <MenuItem value={ADMIN_TYPES.subAdmin}>{t("Sub Admin")}</MenuItem>
                                <MenuItem value={ADMIN_TYPES.accountManager}>{t("Account Manager")}</MenuItem>
                            </Select>
                            <FormHelperText error={!!validationErrors?.type}>{validationErrors?.type}</FormHelperText>
                        </FormControl>
                        {
                            subAdmin?.type !== ADMIN_TYPES.admin &&
                            <ServerAutoCompleteSearch
                                label={t("Countries")}
                                value={countries?.docs?.find(country => country?.id === subAdmin?.country?.id || country?.id === subAdmin?.country) ?? null}
                                isOptionEqualToValue={(option, value) => option?.id === value}
                                loading={countriesLoading}
                                getOptionLabel={(option) => !!option?.info ? option?.info[`${theme.direction === "rtl" ? "arabic" : "english"}`]?.name : ''}
                                options={countries?.docs?.filter((country) => ["EG", "SA", "AE"].includes(country?.isoCode)) ?? []}
                                onChange={(value) => handleChange({ target: { name: "country", value: value?.id } })}
                                onInputChange={(text) => setCountrySearchKey(text)}
                                error={validationErrors?.country}
                                textFiledProps={{ margin: "dense" }}
                            />
                        }
                    </>
                }
                <PasswordTextField
                    name="password"
                    label={t("Password")}
                    fullWidth
                    margin="dense"
                    inputProps={{ autoComplete: 'new-password' }}
                    value={subAdmin?.password}
                    onChange={handleChange}
                    error={!!validationErrors?.password}
                    helperText={validationErrors?.password ?? ''}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={onClose} variant="contained" sx={{ textTransform: "none" }}>
                    {t("Close")}
                </Button>
                <Box mr="auto" />
                {isSuccess && <Typography color="success.main">{t("Done successfully")}</Typography>}
                {!!error && <Typography color="error">{t(error)}</Typography>}
                <Box mr="auto" />
                {
                    isEditing &&
                    <LoadingButton
                        loading={loading}
                        onClick={() => onDelete(subAdminData?.id ?? '')}
                        variant="contained"
                        color="error"
                        sx={{ textTransform: "none" }}
                    >
                        {t("Delete")}
                    </LoadingButton>
                }
                <LoadingButton
                    loading={loading}
                    onClick={onConfirmHandler}
                    variant="contained"
                    sx={{ textTransform: "none" }}
                >
                    {isEditing ? t("Edit") : t("Add")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default SubAdminDialog;
