import { useEffect } from "react";

import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import { setLanguage } from "store/languageSlice";
import { $APP_NAME } from "constants";

import { About, AdDialog, EmailAddress, Highlights, Intro, WhyEzPay } from "./components";
import { QuickLinks, Footer, PaymentLogos } from '../common';
import { DOMAINS } from "constants";

const Landing = () => {

    const dispatch = useDispatch();

    useEffect(_ => {
        // TODO: to be replaced with SA frontend env variable
        if (window?.location?.href?.includes("ezpay.sa")) {
            dispatch(setLanguage({ language: "ar-SA" }))
        }
    }, [window])

    return (
        <>
            <Helmet>
                <title>{$APP_NAME}</title>
            </Helmet>
            <Intro />
            <Highlights />
            <About />
            <WhyEzPay />
            <EmailAddress />
            <PaymentLogos />
            <QuickLinks allowSocial={true} />
            {!window?.location?.href?.includes(DOMAINS.uae) ? <AdDialog /> : null}
            <Footer />
        </>
    )
}

export default Landing;