import { useEffect, useMemo, useState } from 'react';

import { Divider, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid, Box, IconButton, TextField, useTheme } from '@mui/material';
import { Clear } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import { cloneDeep, omitBy, pick } from 'lodash';

import { useCreateBankMutation, useUpdateBankDetailsMutation } from 'api/banksAPI';
import { BANK_SCHEMA } from './validationSchema';
import { runJoiValidate } from 'utils';
import { ServerAutoCompleteSearch } from 'modules/common';
import { useGetCountriesQuery } from 'api/countriesApis';
import { useSelector } from 'react-redux';
import { ADMIN_TYPES } from 'constants';

export default function BankDetails({ open, bank, onClose }) {

    const { t } = useTranslation();
    const theme = useTheme();
    const { profile } = useSelector(state => state?.auth);

    const [state, setState] = useState({});
    const [validationErrors, setValidationErrors] = useState();

    const [updateBankDetails, { isLoading, error, isSuccess, reset }] = useUpdateBankDetailsMutation();
    const [createBank, { isLoading: createLoading, isSuccess: createBankIsSuccess, error: createError, reset: createReset }] = useCreateBankMutation();

    const isEditing = useMemo(() => !!bank, [bank])

    useEffect(() => {
        if (bank) {
            setState({ ...pick(bank, ['nameAr', 'nameEn', 'country', 'myFatoorahId']) })
        }
    }, [bank])

    const handleChange = ({ target: { name, value } }) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const submitHandler = async () => {
        try {
            setValidationErrors(null)
            let payload = cloneDeep(state)
            payload = omitBy(payload, v => !Boolean(v));
            const errors = runJoiValidate(BANK_SCHEMA, payload)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }
            if (isEditing) {
                await updateBankDetails({ id: bank?.id, payload })
            } else {
                await createBank(payload)
            }

            setTimeout(() => {
                handleClose(true)
            }, 2000);

        } catch (error) {
            console.log(error)
        }
    }

    const handleClose = (shouldRefresh = false) => {
        setState();
        onClose(shouldRefresh);
    }

    useEffect(() => {
        if (!open) {
            reset()
            createReset()
        }
    }, [open])

    //=======Countries=======
    const [countrySearchKey, setCountrySearchKey] = useState('')
    const { data: countries, isLoading: countriesLoading } = useGetCountriesQuery(omitBy({ searchKey: countrySearchKey, active: true }, v => !Boolean(v)))


    const allowUpdate = useMemo(() => {
        if (!!profile && profile?.type !== ADMIN_TYPES.accountManager) {
            return true
        }
        return false
    }, [profile])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: 24 }}>
                {t("Bank Details")}
                <IconButton onClick={handleClose} color="primary">
                    <Clear />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container alignItems="flex-start" justifyContent="space-between" spacing={10}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            name="nameAr"
                            value={state?.nameAr}
                            onChange={handleChange}
                            label={t("Arabic Name")}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={!!validationErrors?.nameAr}
                            helperText={validationErrors?.nameAr ?? ''}
                            disabled={!allowUpdate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            name="nameEn"
                            value={state?.nameEn}
                            onChange={handleChange}
                            label={t("English Name")}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={!!validationErrors?.nameEn}
                            helperText={validationErrors?.nameEn ?? ''}
                            disabled={!allowUpdate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ServerAutoCompleteSearch
                            label={t("Country")}
                            value={countries?.docs?.find((country) => country?.id === state?.country) ?? null}
                            isOptionEqualToValue={(option, value) => option?.id === value}
                            loading={countriesLoading}
                            getOptionLabel={(option) => !!option?.info ? option?.info[`${theme.direction === "rtl" ? "arabic" : "english"}`]?.name : ''}
                            options={countries?.docs?.filter((country) => ["EG", "SA", "AE"].includes(country?.isoCode)) ?? []}
                            onChange={(value) => handleChange({ target: { name: "country", value: value?.id } })}
                            onInputChange={(text) => setCountrySearchKey(text)}
                            error={validationErrors?.country}
                            disabled={!allowUpdate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            name="myFatoorahId"
                            value={state?.myFatoorahId}
                            onChange={handleChange}
                            label={t("MyFatoorah ID")}
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ mt: 7 }}
                            error={!!validationErrors?.myFatoorahId}
                            helperText={validationErrors?.myFatoorahId ?? ''}
                            disabled={!allowUpdate}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            {
                allowUpdate
                    ?
                    <>
                        <Divider />
                        <DialogActions>
                            <Box mr="auto" />
                            {(!!error || !!createError) && <Typography color="error">{t(error ?? createError)}</Typography>}
                            {(isSuccess || createBankIsSuccess) && <Typography color="success.main">{t("Done successfully")}</Typography>}
                            <Box mr="auto" />
                            <LoadingButton
                                loading={isLoading || createLoading}
                                variant="contained"
                                onClick={submitHandler}
                                sx={{ textTransform: "none" }}
                            >
                                {t(isEditing ? "Update" : "Add")}
                            </LoadingButton>
                        </DialogActions>
                    </>
                    : null
            }
        </Dialog>
    )
}
