import { Box, Card, CardActions, CardContent, CardHeader, Divider, Grid, IconButton, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { includes } from "lodash";
import { UploadDocument } from "modules/common";
import { useSelector } from "react-redux";
import { ACCOUNT_STATUS } from "constants";
import { ROLES } from "constants";
import { DOCUMNETS } from "constants";


export default function UserDocuments({
    userStatus,
    userDocuments,
    onUpdate,
    onDelete,
    isLoading,
    error,
    isSuccess,
}) {
    const { t } = useTranslation();
    const { profile } = useSelector(state => state.auth);

    const [documents, setDocuments] = useState({
        civilId: '',
        civilIdBack: '',
        commercialRegister: '',
    })

    useEffect(() => {
        if (!!userDocuments) {
            setDocuments(userDocuments)
        }
    }, [userDocuments])

    const [currentFile, setCurrentFile] = useState(null);

    const uploadDocument = ({ target: { name, value } }) => {
        if (!!name && !!value) {
            setCurrentFile(name)
            onUpdate({ [name]: value })
        }
    }

    const deleteDocument = (name) => {
        if (!!name) {
            setCurrentFile(name)
            onDelete(name)
        }
    }

    const canUpdate = useMemo(_ => {
        if (profile?.role === ROLES.admin) return true
        const allowedStatuses = [ACCOUNT_STATUS.accountCreated, ACCOUNT_STATUS.waiting];
        return includes(allowedStatuses, userStatus);
    }, [userStatus, profile]);

    const supportContacts = useMemo(() => {
        // TODO: to be replaced with UAE frontend env variable
        if (window?.location?.href?.includes("ezpayuae")) {
            return { phone: "+971509443956", email: "pm@ezpayuae.com" }
        } else {
            return { phone: "+966581849999", email: "info@ezpay.sa" }
        }
    })

    return (
        <Card>
            <CardHeader title={t("Documents")} />
            <Divider />
            <CardContent>
                <Grid container spacing={10}>
                    {
                        DOCUMNETS.map(doc => (
                            <Grid item xs={4}>
                                <UploadDocument
                                    name={doc.name}
                                    image={(!!documents && !!documents[doc.name]) ? documents[doc.name] : ''}
                                    title={doc?.label}
                                    onChange={uploadDocument}
                                    onDelete={deleteDocument}
                                    isImageClickable={true}
                                    disabled={!canUpdate}
                                    isLoading={currentFile === doc.name && isLoading}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </CardContent>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                    {!!error && <Typography color="error">{t(error)}</Typography>}
                    {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
                </Box>
                {
                    !canUpdate
                    &&
                    <Box sx={{ display: "flex", flexDirection: "row", }}>
                        <Box sx={{ display: "flex" }}>
                            <IconButton href={`https://wa.me/${supportContacts?.phone}`} color="primary">
                                <WhatsAppIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                            <InfoIcon style={{ fontSize: 16, marginInlineEnd: 4, marginTop: 2 }} />
                            {t("To update your documents, kindly contact support")}
                        </Typography>
                    </Box>
                }
            </CardActions>

        </Card>
    )
}