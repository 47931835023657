import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { generateImageLink } from "utils";
import BusinessIcon from '@mui/icons-material/Business';
import { isValidBase64ImageString } from "utils/base64Utils";

const UploadImage = ({ image, handleChange, error, variant, imgProps, disabled, ...props }) => {

  const { t } = useTranslation();

  const [source, setSource] = useState();

  useEffect(() => {
    if (!image) return
    if (isValidBase64ImageString(image))
      setSource(image)
    else
      setSource(
        generateImageLink(image, "small")
      )
  }, [image])

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onloadend = () => {
        }
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
}

const fileReadHandler = async (e) => {
    try {
        let base64 = await readFileAsync(e.target.files[0])

        handleChange({ target: { name: "image", value: base64 } })
    } catch (error) {
        console.log(error)
    } finally {  }
}

  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            src={source}
            sx={{
              height: 125,
              width: 125,
              // mb: 1,
              justifyContent: ''
            }}
            variant={variant}
            imgProps={imgProps}
          >
            <BusinessIcon sx={{ fontSize: 100 }} />
          </Avatar>
          {
            error &&
            <Typography color='text.danger' mx={1} variant="subtitle2">
              {error}
            </Typography>
          }
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button sx={{ fontWeight: 'bold' }} fullWidth component="label" disabled={disabled}>
          {t("Choose Image")}
          <input
            hidden
            name="file"
            type="file"
            accept="image/*"
            onChange={fileReadHandler}
          />
        </Button>
      </CardActions>
    </Card >
  );
};

export default UploadImage;
