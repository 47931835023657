import axios from 'axios';
import { useEffect, useState } from 'react'

export default function useGetCurrentCountry() {
    const [currentCountry, setCurrentCountry] = useState(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get('https://ipapi.co/json/')
            .then(response => {
                const data = response.data;
                setCurrentCountry(data);
                setLoading(false)
            })
            .catch(error => {
                console.log('Error:', error);
                setLoading(false)
            })
    }, []);

    return {
        currentCountry,
        loading
    }
}
