import { Box } from '@mui/material'
import { useEffect, useState } from 'react';
import { JsonView, collapseAllNested, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

// const data = [
//     {
//         label: "Request",
//         value:"JSON OBJECT"
//     }
// ]

export default function SampleCodes({ data }) {

    const [currentTab, setCurrentTab] = useState();

    useEffect(() => {
        if (!!data?.length) {
            setCurrentTab(data[0]);
        }
    }, [data])

    return (
        <Box>
            <Box display="flex" alignItems="center" flex={1} bgcolor="#d6d6d6">
                {
                    data?.map(el => (
                        <Box p={7} onClick={() => setCurrentTab(el)} bgcolor={currentTab?.label === el?.label ? "#F8F8F8" : "#d6d6d6"} sx={{ cursor: "pointer" }}>
                            {el?.label}
                        </Box>
                    ))
                }
            </Box>
            {
                !!currentTab?.value &&
                <JsonView data={currentTab?.value} shouldExpandNode={collapseAllNested} style={defaultStyles} />
            }
        </Box>
    )
}
