// import { setCredentials, logOut } from '../../features/auth/profileSlice'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { $BASE_URL } from 'constants'

const baseQuery = fetchBaseQuery({
    baseUrl: `${$BASE_URL}/api/v1`,
    //  credentials: 'include',
    prepareHeaders: (headers) => { //(headers, { getState })
        const token = localStorage.getItem("token") || null
        if (token) {
            headers.set("Authorization", `Bearer ${token}`)
        }
        const lang = localStorage.getItem("i18nextLng") || "ar-SA"
        headers.set("Accept-Language", lang)
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    return result

    /**************** That when token expire and we want to refresh it ***************/

    // if (result?.error?.originalStatus === 403) {
    //     console.log('sending refresh token')
    //     // send refresh token to get new access token 
    //     const refreshResult = await baseQuery('/refresh', api, extraOptions)
    //     console.log(refreshResult)
    //     if (refreshResult?.data) {
    //         const user = api.getState().auth.user
    //         // store the new token 
    //         api.dispatch(setCredentials({ ...refreshResult.data, user }))
    //         // retry the original query with new access token 
    //         result = await baseQuery(args, api, extraOptions)
    //     } else {
    //         api.dispatch(logOut())
    //     }
    // }

    // return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})