import { Box, Chip, Container, FormControl, InputAdornment, OutlinedInput, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { $BASE_URL } from "constants";

const CustomTextField = styled(OutlinedInput)(({ theme }) => ({
    '& input': {
        border: "none",
        backgroundColor: "white",
        borderRadius: 27.5,
        height: 27.5,
        "&:-webkit-autofill": {
            borderRadius: 27.5,
            WebkitBoxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1)"
        },
        font: `normal 16px/23px ${theme.typography.fontFamily}`,
        paddingInline: 30,
    },
    '& fieldset': {
        borderRadius: '27.5px',
    },
}));

export default function EmailAddress() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [email, setEmail] = useState('')

    useEffect(() => {
        return () => {
            setEmail('')
        }
    }, [])

    return (
        <Box backgroundColor="primary.main">
            <Container sx={{ py: 30, px: 20 }}>
                <FormControl fullWidth sx={{ color: "white", backgroundColor: "white", borderRadius: 20 }}>
                    <CustomTextField
                        onChange={(event) => setEmail(event?.target?.value)}
                        id="outlined-adornment-password"
                        endAdornment={
                            <InputAdornment position="end">
                                <Chip
                                    onClick={() => {
                                        navigate(`/auth/register?email=${email}`)
                                    }}
                                    //label={t("landing.startNow")}
                                    label={t("Register now")}
                                    component="a"
                                    color="primary"
                                    variant="contained"
                                    clickable
                                    disabled={!Boolean(email)}
                                    sx={{ px: 25 }}
                                />
                            </InputAdornment>
                        }
                        placeholder={t("landing.email")}
                    />
                </FormControl>
            </Container>
        </Box >
    )
}