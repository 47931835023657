import { Container, Grid } from "@mui/material";
import { useGetProfileQuery } from "api/authApi";
import ChangePasswordCard from "./components/ChangePasswordCard";
import InfoCard from "./components/InfoCard";
import AccountStatus from "./components/AccountStatus";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { useTranslation } from "react-i18next";
import { CopyContentCard } from "modules/common";
import WebhookCard from "./components/WebhookCard";

export default function Profile() {
    const { t } = useTranslation()
    const { data: profile } = useGetProfileQuery()

    return (
        <>
            <Helmet>
                <title>{t("Merchant Profile")} | {$APP_NAME}</title>
            </Helmet>
            <Container>
                <Grid container spacing={10} sx={{ mt: 10 }}>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={12}>
                            <AccountStatus status={profile?.body?.currentStatus} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={10} xs={12}>
                        <Grid item xs={12} md={6}>
                            <InfoCard profile={profile?.body} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ChangePasswordCard />
                        </Grid>
                    </Grid>
                    {
                        !!profile?.body?.apiKey &&
                        <Grid item xs={12} md={6}>
                            <CopyContentCard
                                title={t("API Key")}
                                value={profile?.body?.apiKey}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <WebhookCard allowUpdate={true}/>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
