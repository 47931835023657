import { Avatar, Box, Grid, Typography } from "@mui/material";
import { ROLES } from "constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generateImageLink } from "utils";

export default function MerchantInfo({ invoice }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { profile } = useSelector(state => state.auth); 

    return (
        <Grid container spacing={20} sx={{ my: 10 }}>
            <Grid item xs={12} display="flex" justifyContent="center">
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Avatar onClick={() => profile?.role === ROLES.admin && navigate(`/admin/user/${invoice?.user?.id}`)} sx={{ width: 50, height: 50, marginInlineEnd: 6, cursor: "pointer" }} src={generateImageLink(invoice?.business?.logo, "small")} alt="business logo" />
                    <Typography sx={{ cursor: "pointer" }} onClick={() => profile?.role === ROLES.admin && navigate(`/admin/user/${invoice?.user?.id}`)} variant="h6">{invoice?.business?.businessName}</Typography>
                </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Name")}</Typography>
                    <Typography>{invoice?.user?.userName}</Typography>
                </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Phone")}</Typography>
                    <bdi>
                        <Typography>{invoice?.user?.phone}</Typography>
                    </bdi>
                </Box>
            </Grid>
            {
                !!invoice?.user?.business?.bankAccount?.accountNumber
                    ?
                    <Grid item xs={12}>
                        <Box display="flex">
                            <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Bank account number")}</Typography>
                            <Typography>{invoice?.user?.business?.bankAccount?.accountNumber}</Typography>
                        </Box>
                    </Grid>
                    : null
            }
        </Grid>
    )
}