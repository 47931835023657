import { LoadingButton } from "@mui/lab";
import { Card, CardActions, CardContent, CardHeader, Divider, TextField, Typography } from "@mui/material";
import { pick } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import runJoiValidate from "utils/runJoiValidate";
import { USER_CHANGE_PASSWORD_SCHEMA } from "../profile-validation-schemas";
import { useUpdateProfilePasswordMutation } from "api/authApi";

export default function ChangePasswordCard() {
    const { t } = useTranslation()

    const [updateProfilePassword, { isLoading, error, isSuccess, reset }] = useUpdateProfilePasswordMutation()
    const [validationErrors, setValidationErrors] = useState(null)

    const [passwords, setPaswords] = useState({
        currentPassword: '',
        password: '',
        confirmPassword: '',
    })

    const handleChange = ({ target: { name, value } }) => {
        setPaswords((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const submitHandler = async () => {
        try {
            setValidationErrors(null)
            reset()

            const errors = runJoiValidate(USER_CHANGE_PASSWORD_SCHEMA, passwords)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }

            await updateProfilePassword(pick(passwords, ["password", "currentPassword"]))

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Card>
            <CardHeader title={t("Change Password")} />
            <Divider />
            <CardContent>
                <TextField
                    name="currentPassword"
                    type="password"
                    label={t("Current Password")}
                    value={passwords?.currentPassword}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 7 }}
                    inputProps={{
                        autoComplete: "off"
                    }}
                    error={!!validationErrors?.currentPassword}
                    helperText={validationErrors?.currentPassword}
                />
                <TextField
                    name="password"
                    type="password"
                    label={t("New Password")}
                    value={passwords?.password}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 7 }}
                    inputProps={{
                        autoComplete: "off"
                    }}
                    error={!!validationErrors?.password}
                    helperText={validationErrors?.password}
                />
                <TextField
                    name="confirmPassword"
                    type="password"
                    label={t("Confirm Password")}
                    value={passwords?.confirmPassword}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 7 }}
                    error={!!validationErrors?.confirmPassword}
                    helperText={validationErrors?.confirmPassword}
                />
            </CardContent>
            <Divider />
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                <LoadingButton loading={isLoading} variant="contained" onClick={submitHandler} sx={{ textTransform: "none" }}>
                    {t("Submit")}
                </LoadingButton>
                {!!error && <Typography color="error">{t(error)}</Typography>}
                {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
            </CardActions>
        </Card>
    )
}