import * as Joi from "joi";
import i18n from "i18n";
import { joiString } from "utils/joi";

export const COUNTRY_EDIT_SCHEMA = Joi.object({
    info: Joi.object({
        arabic: Joi.object({
            name: joiString.string()
                .label(i18n.t("Country name in English"))
                .empty('')
                .required()
            ,
            currency: joiString.string()
                .label(i18n.t("Currency in English"))
                .empty('')
                .required()
            ,
        }).required(),
        english: Joi.object({
            name: joiString.string()
                .label(i18n.t("Country name in English"))
                .empty('')
                .required()
            ,
            currency: joiString.string()
                .label(i18n.t("Currency in English"))
                .empty('')
                .required()
        }).required()
    }),
    isoCode: joiString.string()
        .label(i18n.t("Country ISO Code"))
        .empty(''),
    isoCode3: joiString.string()
        .required()
        .label(i18n.t("Country ISO Code 3"))
        .empty(''),
    timezone: joiString.string()
        .label(i18n.t("Timezone"))
        .empty(''),
    phoneCode: joiString.string()
        .label(i18n.t("Phone Code"))
        .empty(''),
    active: joiString.boolean()
        .allow(null)
        .label(i18n.t("Active"))
})

export const COUNTRY_ADD_SCHEMA = COUNTRY_EDIT_SCHEMA.fork(
    ['info', 'isoCode', 'timezone', 'phoneCode'],
    (x) => x.required()
);