import { Container, Typography, Alert, AlertTitle, Box, Link } from '@mui/material';
import { Helmet } from 'react-helmet';
import { $APP_NAME } from 'constants';
import APISection from './components/APISection';
import { CANCEL_TRANSACTION_API, CREATE_TRANSACTION_API, CREATE_USER, DELETE_USER, GET_TRANSACTION, GET_USER, UPDATE_TRANSACTION_API, UPDATE_USER, WEBHOOK_SAMPLE_CODE } from './resources';
import SampleCodes from './components/SampleCodes';

export default function IntegrationApisDocumentation() {
    return (
        <Box height="100%" backgroundColor="white">
            <Helmet>
                <title>Documentation | {$APP_NAME}</title>
            </Helmet>
            <Container maxWidth="md" sx={{ my: 20 }}>
                <Box dir="ltr" pb={5}>
                    <Typography my={25} variant="h3">
                        {"API Documentation"}
                    </Typography>
                    <Typography>
                        {`Welcome to Ezpay documentation. In order to use our service you need to have a Ezpay account. You can sign up from `}
                        <Link
                            target="_blank"
                            href="https://www.ezpay.sa/auth/register"
                            color="inherit"
                            underline="hover">
                            here
                        </Link>
                    </Typography>
                    <Alert severity="info" sx={{ my: 10 }}>
                        <AlertTitle>Request Header</AlertTitle>
                        {`Add "Authorization": "{Token}" to request header.`}
                        {` You can get your authorization token from your dashboard profile (Profile -> API Key)`}
                    </Alert>

                    {/* <Typography>
                        {`Welcome to Ezpay documentation. In order to use our service you need to have a Ezpay account. You can sign up from `}
                        <Link
                            target="_blank"
                            href="https://www.ezpay.sa/auth/register"
                            color="inherit"
                            underline="hover">
                            here
                        </Link>
                    </Typography> */}
                    <Typography mt={30} variant="h3">
                        {"User APIs"}
                    </Typography>
                    <Alert severity="info" sx={{ my: 10 }}>
                        <AlertTitle>{"User (agent)"}</AlertTitle>
                        {"The user here in the documentation represents the agent."}
                    </Alert>
                    <APISection api={CREATE_USER} />
                    <Alert severity="info" sx={{ my: 10 }}>
                        <AlertTitle>Note</AlertTitle>
                        {"When creating a user, the user will receive an email with a password and the user can update the password later for the Application."}
                    </Alert>
                    <APISection api={GET_USER} />
                    <APISection api={UPDATE_USER} />
                    <APISection api={DELETE_USER} />
                    <Typography mt={50} variant="h3">
                        {"Transaction APIs"}
                    </Typography>
                    <APISection api={CREATE_TRANSACTION_API} />
                    <APISection api={GET_TRANSACTION} />
                    <Alert severity="info" sx={{ my: 10 }}>
                        <AlertTitle>Accept Language Header</AlertTitle>
                        {"In order to send messages in Arabic to the user or customer you have to add at header accept-langauge: 'ar-SA',\n the default value is 'en-US' if you do not add it."}
                    </Alert>
                    <APISection api={UPDATE_TRANSACTION_API} />
                    <Alert severity="info" sx={{ my: 10 }}>
                        <AlertTitle>Change referenceID</AlertTitle>
                        {'When the referenceID in the invoice is assigned to another user, an alert will be applied to the new user, and deleted from the previous user.'}
                    </Alert>
                    <APISection api={CANCEL_TRANSACTION_API} />
                    <Box mt={50}>
                        <Box mt={3}>
                            <Typography variant="h4" color="primary" sx={{ my: 20 }}>
                                {"Webhook URL"}
                            </Typography>
                            <Alert severity="info" sx={{ my: 10 }}>
                                <AlertTitle>Description</AlertTitle>
                                {'You can track the status of your transactions by adding your webhook link to your profile page at your Ezpay Dashboard (Dashboard -> Profile -> Web Hook Card), and through this Webhook you can track all updates regarding the status of transactions.'}
                            </Alert>
                            <Box my={20}>
                                <SampleCodes data={WEBHOOK_SAMPLE_CODE} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
