export default function FileInput({
    name,
    onChange,
    onLoading,
    // onError,
    onUploaded,
    ...props
}) {

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            // reader.onloadend = () => {
            //     setIsSuccess(true)
            // }
            reader.onerror = reject;
            reader.readAsDataURL(file);
        })
    }

    const fileReadHandler = async (e) => {
        try {
            // onUploadStart()
            !!onLoading && onLoading(true)
            let base64 = await readFileAsync(e.target.files[0])
            onChange({ target: { name: e.target.name, value: base64 } })
            !!onUploaded && onUploaded()
        } catch (error) {
            console.log(error)
        } finally {
            // onUploadEnded()
            !!onLoading && onLoading(false)
        }
    }

    return (
        <input hidden name={name} accept="image/*" type="file" onChange={fileReadHandler} {...props} />
    )
}