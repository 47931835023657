import { useTranslation } from "react-i18next";
import {
    Box,
    Typography,
    Container,
    Paper,
    Button,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { $APP_NAME } from 'constants';
import Lottie from "lottie-react";
import { Success } from "_assets";

export default function ContactUsMessageSuccessfullySent() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (

        <>
            <Helmet>
                <title>{t("Message Has Sent")} | {$APP_NAME}</title>
            </Helmet>
            <Container sx={{ py: 3, display: "flex", alignItems: "center", justifyContent: "center", height: "85vh" }}>
                <Paper sx={{ pb: 100, width: { xs: "92vw", md: "50vw", lg: "45vw" }, boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.25)", position: "relative" }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Lottie
                            animationData={Success}
                            loop={false}
                            style={{ width: 240, height: 240 }}
                        />
                        <Typography color='success.main' variant={isXsScreen ? "h4" : "h3"} textAlign="center">
                            {t("Success")}!
                        </Typography>
                        <Typography color='grey' variant={isXsScreen ? "h6" : "h4"} textAlign="center" mt={25}>
                            {t("ContactYouSoon")}
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={_ => navigate('/')}
                        size="large"
                        sx={{
                            bgcolor: "success.main",
                            position: "absolute",
                            py: 15,
                            bottom: "-4%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: { xs: "70%", md: "60%", lg: "40%" }
                        }}
                    >
                        {t("Back to Homepage")}
                    </Button>
                </Paper>
            </Container>
        </>
    )
}