import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, MenuItem, Select, Typography, Box, Divider } from '@mui/material';
import { TRANSACTION_STATUS } from 'constants';
import { ConfirmationDialog } from 'modules/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function UpdateTransactionDialog({ open, onClose, transaction, updateTransaction, loading, isSuccess, error, refetchTransactions }) {
    const { t } = useTranslation()
    const [status, setStatus] = useState('')

    useEffect(() => {
        if (!!transaction) {
            setStatus(transaction?.currentStatus)
        }
    }, [transaction])

    const onCloseHandler = () => {
        setStatus('')
        onClose()
    }

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const onUpdateTransaction = async () => {
        setOpenConfirmDialog(false)
        await updateTransaction({ transactionId: transaction?.id, payload: { currentStatus: status } })
        refetchTransactions()
        setTimeout(() => {
            onCloseHandler()
        }, 2500);
    }
    return (
        <>
            <ConfirmationDialog
                title="Warning"
                open={openConfirmDialog}
                confirmFn={onUpdateTransaction}
                onClose={() => setOpenConfirmDialog(false)}
                content={"Are you sure you want to update the transaction?"}
            />
            <Dialog
                open={open}
                onClose={(e) => e.preventDefault()}
                aria-labelledby="update-transaction-dialog-title"
                aria-describedby="update-transaction-dialog-description"
                fullWidth
            >
                <DialogTitle id="update-transaction-dialog-title">
                    {t("Update transaction status")}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl size="small" fullWidth>
                        <InputLabel id="status-label">{t("Status")}</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            value={status}
                            label={t("Status")}
                            onChange={({ target: { value } }) => setStatus(value)}
                        >
                            {TRANSACTION_STATUS.filter(status => status !== "PAID").map((status) => (
                                <MenuItem value={status} key={status}>{t(status)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                    <Box display="flex" justifyContent="center">
                        <Button variant="contained" onClick={onCloseHandler} sx={{ mr: 5 }}>{t("Cancel")}</Button>
                        <LoadingButton
                            variant="contained"
                            loading={loading}
                            onClick={() => setOpenConfirmDialog(true)}
                            disabled={!status || status === transaction?.currentStatus}
                        >
                            {t("Update")}
                        </LoadingButton>
                    </Box>
                    {!!error && <Typography color="error">{t(error)}</Typography>}
                    {!!isSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
                </DialogActions>
            </Dialog>
        </>
    );
}
