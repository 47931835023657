import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    Button,
    Box,
} from "@mui/material";
import { Check, FileCopyOutlined } from "@mui/icons-material";
import copy from 'copy-to-clipboard';
import { useTranslation } from "react-i18next";
import { useState } from "react";

export default function CopyContentCard({
    title,
    value,
}) {
    const { t } = useTranslation()
    const [isCopied, setIsCopied] = useState(false)
    const handleClick = () => {
        copy(value)
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 3000);
    }

    return (
        <Card>
            <CardHeader title={title} action={
                <Button
                    m={1}
                    variant="contained"
                    startIcon={isCopied ? <Check color="success" /> : <FileCopyOutlined />}
                    size="small"
                    onClick={handleClick}
                    sx={{ textTransform: "none" }}
                >
                    {t("Copy")}
                </Button>
            } />
            <Divider />
            <CardContent>
                <bdi>
                    <Box backgroundColor="#F4F6F8" p={1} borderRadius={2} textAlign="center">
                        <Typography variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
                            {value ?? '..........................'}
                        </Typography>
                    </Box>
                </bdi>
            </CardContent>
            <Divider />
        </Card>
    );
};
