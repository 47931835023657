import { Alert, Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PostDetails from "./components/PostDetails";
import Editor from "./components/Editor";
import { AdminBlogPostSchema } from "./ValidationSchema";
import { runJoiValidate } from "utils";
import { last, mapValues, pick, split } from "lodash";
import { useTranslation } from "react-i18next";
import { startsWith, omit } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useAddBlogPostMutation, useDeleteBlogPostMutation, useGetBlogPostQuery, useUpdateBlogPostMutation } from "api/blogApi";
import { openConfirmDialog } from "store/confirmationDialogSlice";
import { ADMIN_TYPES } from "constants";

export default function AdminBlogPosts() {
    const { id } = useParams();
    const { profile } = useSelector(state => state?.auth);
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [validationErrors, setValidationErrors] = useState(null)

    //======= Blog Post Handler =======
    const [post, setPost] = useState({
        title_ar: "",
        description_ar: "",
        title_en: "",
        description_en: "",
        slug: "",
        meta: "",
        image: ""
    })

    const handleChange = (event) => {
        console.log(event.target.value);
        setPost((prevPost) => ({
            ...prevPost,
            [event.target.name]: event.target.value,
        }));
    };

    //================ Blog post CRUD =========================

    /*******Get blog post********/
    const { data: postData, /*isLoading: postLoading, isSuccess: postSuccess */ } = useGetBlogPostQuery(id)

    useEffect(() => {
        if (!!postData) {
            setPost(postData)
        }
    }, [postData])

    /*******create blog post********/
    const [addBlogPost, {
        isLoading: addBlogPostLoading,
        isSuccess: addBlogPostIsSuccess,
        error: addBlogPostError
    }] = useAddBlogPostMutation()

    const createBlogPostHandler = async () => {
        try {
            setValidationErrors(null)
            let payload = { ...post }
            const errors = runJoiValidate(AdminBlogPostSchema, payload);
            if (errors) {
                setValidationErrors(errors)
                return
            }
            if (!startsWith(payload.image, "data")) {
                payload = omit(payload, ["image"])
            }
            await addBlogPost(payload).unwrap()
            navigate("/admin/blog")
        } catch (error) {
            console.log(error)
        }
    }

    /*******update blog post********/
    const [updateBlogPost, {
        isLoading: updateBlogPostLoading,
        isSuccess: updateBlogPostIsSuccess,
        error: updateBlogPostError
    }] = useUpdateBlogPostMutation()

    const updateBlogPostHandler = async () => {
        try {
            setValidationErrors(null)
            let payload = pick(post, ["title_ar", "description_ar", "title_en", "description_en", "meta", "image", "PostDetails", "slug"])
            if (payload["image"] && !startsWith(payload["image"], "data")) {
                payload = omit(payload, ["image"])
            }
            const errors = runJoiValidate(AdminBlogPostSchema, mapValues(payload, (value, key) => {
                return key === "image" ? last(split(value, ",")) : value
            }));
            if (errors) {
                setValidationErrors(errors)
                return
            }
            await updateBlogPost({ id, payload }).unwrap()
        } catch (error) {
            console.log(error)
        }
    }

    /*******Delete blog post********/
    const [deleteBlogPost, {
        isLoading: deleteBlogPostLoading,
        isSuccess: deleteBlogPostIsSuccess,
        error: deleteBlogPostError
    }] = useDeleteBlogPostMutation()

    const deleteBlogPostHandler = async () => {
        try {
            await deleteBlogPost(id).unwrap()
            navigate("/admin/blog")
        } catch (error) {
            console.log(error)
        }
    }

    const confirmDeleteBlogPost = (id) => {
        dispatch(openConfirmDialog({
            open: true,
            title: t("Confirm Deletion"),
            content: t("Are you sure you want to delete it?"),
            onConfirm: () => deleteBlogPostHandler(id)
        }))
    }
    //=====================================
    const actionLoading = addBlogPostLoading || updateBlogPostLoading || deleteBlogPostLoading
    const errorMessage = addBlogPostError || updateBlogPostError || deleteBlogPostError
    const isSuccess = addBlogPostIsSuccess || updateBlogPostIsSuccess || deleteBlogPostIsSuccess

    const allowUpdate = useMemo(() => {
        if (!!profile && profile?.type !== ADMIN_TYPES.accountManager) {
            return true
        }
        return false
    }, [profile])

    return (
        <>
            <Box pb={6} m={2}>
                {
                    !!isSuccess &&
                    <Alert sx={{ my: 2 }} severity="success">{t("Updated successfully")}</Alert>
                }
                {
                    !!errorMessage &&
                    <Alert sx={{ my: 2 }} severity="error">{t(errorMessage)}</Alert>
                }
                <PostDetails
                    sx={{ mb: 20 }}
                    post={post}
                    handleChange={handleChange}
                    validationErrors={validationErrors}
                    allowUpdate={allowUpdate}
                />
                {
                    !!validationErrors?.description_ar &&
                    <Alert sx={{ my: 2 }} severity="error">{t("Please, description is required")}</Alert>
                }
                <Typography variant="h5" sx={{ my: 20 }}>{t("The Arabic Description")}</Typography>
                <Editor
                    name="description_ar"
                    value={post?.description_ar}
                    handleChange={handleChange}
                    allowUpdate={allowUpdate}
                />
                {!!validationErrors?.description_ar && <Typography variant="caption" color="error">{validationErrors?.description_ar}</Typography>}
                <Typography variant="h5" sx={{ my: 20 }}>{t("The English Description")}</Typography>
                <Editor
                    name="description_en"
                    value={post?.description_en}
                    handleChange={handleChange}
                    allowUpdate={allowUpdate}
                />
                {!!validationErrors?.description_en && <Typography variant="caption" color="error">{validationErrors?.description_en}</Typography>}
                {
                    allowUpdate
                        ?
                        <Box display="flex" justifyContent={id ? "space-between" : "right"} my={4}>
                            {!!id &&
                                <LoadingButton variant="contained" x={{ textTransform: "none" }} onClick={confirmDeleteBlogPost} size="medium" color="error" aria-label="collection" sx={{ mx: 2 }}>
                                    {t("Delete")}
                                </LoadingButton>
                            }
                            <Box>
                                {!!id ?
                                    <LoadingButton
                                        variant="contained"
                                        sx={{ mx: 2, textTransform: "none" }}
                                        loading={actionLoading}
                                        onClick={updateBlogPostHandler}
                                        size="medium" color="primary" aria-label="edit">
                                        {t("Edit")}
                                    </LoadingButton>
                                    :
                                    <LoadingButton
                                        variant="contained"
                                        sx={{ mx: 2, textTransform: "none" }}
                                        loading={actionLoading}
                                        onClick={createBlogPostHandler}
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                    >
                                        {t("Save")}
                                    </LoadingButton>}
                            </Box>
                        </Box>
                        : null
                }
            </Box>
        </>
    )
}