import { createSlice } from "@reduxjs/toolkit"
import { changeLanguage } from "i18next"

const languageSlice = createSlice({
    name: 'language',
    initialState: { language: localStorage.getItem("i18nextLng") },
    reducers: {
        setLanguage: (state, { payload: { language } }) => {
            changeLanguage(language)
            state.language = language
        },
    },
})

export const { setLanguage } = languageSlice.actions

export default languageSlice.reducer
