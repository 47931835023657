import {IntegrationApisDocumentation, MapitApistDocumentation} from "modules/documentation";
import InvoicePayment, { InvoicePaymentResult } from "modules/invoice-payment";
import { LandingLayout, Landing, ContactUs, PrivacyPolicy, TermsAndConditions, DeleteRequest, BlockPage, Pricing, ContactUsMessageSuccessfullySent } from 'modules/landing'
import { BlogPost, BlogGallery } from "modules/landing/screens/landing/blog";

export const PublicRoutes = [
    {
        path: "",
        element: <LandingLayout />,
        children: [
            { path: "", element: <Landing /> },
            { path: "blog", element: <BlogGallery /> },
            { path: "blog/:slug", element: <BlogPost /> },
        ]
    },
    {
        path: "contact-us",
        element: <LandingLayout />,
        children: [
            { path: "", element: <ContactUs /> },
            { path: "message-successfully-sent", element: <ContactUsMessageSuccessfullySent /> },
        ]
    },
    {
        path: "pricing",
        element: <LandingLayout />,
        children: [
            { path: "", element: <Pricing /> },
        ]
    },
    {
        path: "delete-request-status",
        element: <LandingLayout />,
        children: [
            { path: "", element: <DeleteRequest /> },
        ]
    },
    {
        path: "privacy-policy",
        element: <LandingLayout />,
        children: [
            { path: "", element: <PrivacyPolicy /> },
        ]
    },
    {
        path: "terms-and-conditions",
        element: <LandingLayout />,
        children: [
            { path: "", element: <TermsAndConditions /> },
        ]
    },
    {
        path: "block",
        children: [
            { path: "", element: <BlockPage /> },
        ]
    },
    {
        path: "webview",
        children: [
            { path: "privacy-policy", element: <PrivacyPolicy /> },
            { path: "terms-and-conditions", element: <TermsAndConditions /> },
        ]
    },
    {
        path: "payment",
        children: [
            { path: "", element: <InvoicePayment /> },
            { path: "thanks", element: <InvoicePaymentResult /> },
        ],
    },
    {
        path: "documentation",
        element: <LandingLayout />,
        children: [
            { path: "", element: <MapitApistDocumentation /> },
            { path: "api", element: <IntegrationApisDocumentation /> },
        ]
    },
]