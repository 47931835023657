import axios from "axios";
import { $__DEV__ } from "constants";

axios.interceptors.request.use((request) => {
  $__DEV__ && console.log("Starting Request", JSON.stringify(request, null, 2));
  return request;
});

axios.interceptors.response.use(
  response => {
    $__DEV__ && console.log("Response:", JSON.stringify(response, null, 2));
    return response;
  },
  error => {
    $__DEV__ && console.log("Error:", JSON.stringify(error, null, 2));
    return Promise.reject(error);
  }
);

function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  setHttpMessagesLanguage(localStorage.getItem("i18nextLng") || "ar-SA")
}

function setHttpMessagesLanguage(lang) {
  axios.defaults.headers.common['Accept-Language'] = lang
}

const HttpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  request: axios.request,
  setJwt,
  setHttpMessagesLanguage
};

export default HttpService;