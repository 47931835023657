import { LoadingButton } from '@mui/lab';
import { Divider, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ConfirmDialog({
    open,
    onClose,
    title,
    content,
    confirmFn,
    isSuccess,
    error,
    isLoading
}) {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={(e) => e.preventDefault()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            {!!title
                &&
                <>
                    <DialogTitle id="alert-dialog-title">
                        {t(title)}
                    </DialogTitle>
                    <Divider />
                </>
            }
            <DialogContent>
                {t(content)}
            </DialogContent>
            {(isSuccess || !!error) &&
                <DialogContent>
                    {isSuccess && <Typography color="success.main">{t("Done successfully")}</Typography>}
                    {!!error && <Typography color="error">{t(error)}</Typography>}
                </DialogContent>
            }
            <Divider />
            <DialogActions>
                <LoadingButton
                    sx={{ textTransform: "none", }}
                    variant='contained'
                    onClick={onClose}
                    loading={isLoading}
                >
                    {t("Close")}
                </LoadingButton>
                <LoadingButton
                    sx={{ textTransform: "none", mx: '4px' }}
                    variant='contained'
                    onClick={confirmFn}
                    autoFocus
                    loading={isLoading}
                >
                    {t("Confirm")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
