import { Clear, Search } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import { cloneDeep, omit } from "lodash";
import { useRef, useState } from "react";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider, } from '@mui/x-date-pickers';

export default function InvoicesFilter({ params, onParamsChange, type }) {
    const { t } = useTranslation()
    const theme = useTheme()
    const [searchKey, setSearchKey] = useState(null)
    const timerRef = useRef(null)

    const onSearchKey = ({ target: { value } }) => {
        setSearchKey(value)
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
            if (!!value) {
                onParamsChange({ ...cloneDeep(params), searchKey: value })
            } else {
                onParamsChange(omit(params, ['searchKey']))
            }
        }, 1000);
    }

    const [period, setPeriod] = useState({ fromDate: null, toDate: null })
    const handleChangePeriod = ({ target: { name, value } }) => {
        setPeriod(prevPeriod => {
            let offset = Number(new Date().getTimezoneOffset() * -1)
            let payload = { ...prevPeriod, [name]: value }
            if (name === "fromDate") {
                if (!!value) {
                    let fromDate = value.utcOffset(offset).startOf("day").toISOString()
                    onParamsChange({ ...cloneDeep(params), fromDate })
                } else {
                    onParamsChange(omit(cloneDeep(params), ["fromDate"]))
                }
            }
            if (name === "toDate") {
                if (!!value) {
                    let toDate = value.utcOffset(offset).endOf("day").toISOString()
                    onParamsChange({ ...cloneDeep(params), toDate })
                } else {
                    onParamsChange(omit(cloneDeep(params), ["toDate"]))
                }
            }
            return payload
        })
    }
    return (
        <Grid container justifyContent="space-between" alignItems="space-around" spacing={10} sx={{ mb: 20 }}>
            <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
                <TextField
                    fullWidth
                    label={t("Search")}
                    size="small"
                    value={searchKey ?? ''}
                    onChange={onSearchKey}
                    InputProps={
                        theme.direction === "rtl" ?
                            {
                                endAdornment: <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>,
                            }
                            :
                            {
                                startAdornment: <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>,
                            }
                    }
                />
            </Grid>
            {type === "SETTLED" &&
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                        <DatePicker
                            format="DD/MM/YYYY"
                            error={true}
                            maxDate={period?.toDate ?? moment()}
                            label={t("From")}
                            value={period?.fromDate}
                            onChange={(newValue) => {
                                handleChangePeriod({ target: { name: "fromDate", value: newValue } })
                            }}
                            slotProps={{
                                textField: (params) => {
                                    return {
                                        fullWidth: true,
                                        size: "small",
                                        InputProps: {
                                            ...params?.InputProps,
                                            startAdornment: !!period?.fromDate ?
                                                <InputAdornment position="start">
                                                    <IconButton onClick={(event) => {
                                                        event.stopPropagation()
                                                        handleChangePeriod({ target: { name: "fromDate", value: null } })
                                                    }} size='small' color='primary'>
                                                        <Clear sx={{ fontSize: "inherit" }} />
                                                    </IconButton>
                                                </InputAdornment> : ''
                                        },

                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                        <DatePicker
                            format="DD/MM/YYYY"
                            minDate={period?.fromDate ?? moment()}
                            label={t("To")}
                            value={period?.toDate}
                            onChange={(newValue) => {
                                handleChangePeriod({ target: { name: "toDate", value: newValue } })
                            }}
                            slotProps={{
                                textField: (params) => {
                                    return {
                                        fullWidth: true,
                                        size: "small",
                                        InputProps: {
                                            ...params?.InputProps,
                                            startAdornment: !!period?.toDate ?
                                                <InputAdornment position="start">
                                                    <IconButton onClick={(event) => {
                                                        event.stopPropagation()
                                                        handleChangePeriod({ target: { name: "toDate", value: null } })
                                                    }} size='small' color='primary'>
                                                        <Clear sx={{ fontSize: "inherit" }} />
                                                    </IconButton>
                                                </InputAdornment> : ''
                                        },
                                    }
                                }
                            }}
                        />
                    </Grid>
                </LocalizationProvider>
            }
        </Grid>
    )
}