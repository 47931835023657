import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { useGetUserInvoicesQuery } from "api/invoiceApi";
import { DataTable, MerchantName } from "modules/common";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const $COLUMN_WIDTH = 200
export default function InvoicesHistoryTable({ id }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [page, setPage] = useState(1)
    const { data: invoices, isLoading: invoicesLoaing, isFetching } = useGetUserInvoicesQuery({ id, params: { page, limit: 100, type: "SETTLED" } })

    let columns = [
        { field: "user", renderCell: ({ value }) => <MerchantName creator={value} />, sortable: false, headerName: t("User name"), width: $COLUMN_WIDTH * 1.1 },
        { field: "netAmount", sortable: false, headerName: t("Net amount"), width: $COLUMN_WIDTH * 1, },
        { field: "totalAmount", sortable: false, headerName: t("Total amount"), width: $COLUMN_WIDTH * 1, },
        { field: "bankAccountNumber", sortable: false, headerName: t("Bank account number"), width: $COLUMN_WIDTH * 1.2, },
        { field: "settleBalanceDate", valueGetter: ({ value }) => value ? moment(value).format("DD/MM/YY - h:mm A") : '', sortable: false, headerName: t("Settle balance date"), width: $COLUMN_WIDTH * 0.8 },
    ];

    const pageChangeHandler = () => {
        if (!isFetching) {
            setPage(page)
        }
    }

    return (
        <Card>
            <CardHeader title={t("Invoices History")} />
            <Divider />
            <CardContent sx={{ height: "70vh", p: 7 }}>
                <DataTable
                    rows={invoices?.docs ?? []}
                    columns={columns}
                    onPaginationModelChange={pageChangeHandler}
                    paginationModel={{ page: page - 1, pageSize: 100 }}
                    rowCount={invoices?.totalDocs ?? 0}
                    loading={invoicesLoaing}
                    onRowClick={row => navigate(`/admin/invoice/${row?.id}`)}
                />
            </CardContent>
        </Card>
    )
}