import { Box, Card, Typography } from "@mui/material";
import { Cancel, CheckCircle, Info } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { ACCOUNT_STATUS } from "constants";
import { get } from "lodash";

const ICONS = {
    [ACCOUNT_STATUS.active]: <CheckCircle color="success" />,
    [ACCOUNT_STATUS.waiting]: <Info color='warning' />,
    [ACCOUNT_STATUS.blocked]: <Cancel color="error" />,
    [ACCOUNT_STATUS.blockedByParent]: <Cancel color="error" />,
    [ACCOUNT_STATUS.accountCreated]: <Info color="" sx={{ color: "grey.500" }} />,
    [ACCOUNT_STATUS.phoneVerified]: <Info sx={{ color: "grey.500" }} />,
}

export default function AccountStatus({ status }) {
    const { t } = useTranslation();

    return (
        <Card>
            <Box sx={{ padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h5">
                    {t("Account status")}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <Typography sx={{ marginInlineEnd: 5 }}>
                        {t(`UserStatus.${status}`)}
                    </Typography>
                    {get(ICONS, status)}
                </Box>
            </Box>
        </Card>
    )
}
