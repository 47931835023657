import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Router from 'router';
import { useLazyGetProfileQuery } from 'api/authApi';
import { setIsAuth, setProfile } from 'store/authSlice';
import { useDispatch } from 'react-redux';
import { startSocket } from "socket"
import ReactGA from "react-ga4";
import { GlobalConfirmDialog } from 'modules/common';

const trackers = [
  {
    trackingId: "G-P4NE8M6E3J",
    //gaOptions: { name: "tracker1" },
  },
];

function App() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [getProfile] = useLazyGetProfileQuery()

  const initiateApp = async () => {
    try {
      let response = await getProfile()
      if (localStorage.getItem("token") && response?.data?.body?.id) {
        dispatch(setProfile(response?.data?.body))
        dispatch(setIsAuth(true))
        startSocket({ userId: response?.data?.body?.id, jwt: localStorage.getItem("token") })
      }
      // dispatch(setSocket(socketServer))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    initiateApp()
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.initialize(trackers);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }
  }, [])

  //======= Ad Dialog =======
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const isAdAppear = localStorage.getItem("isAdAppear");
      if (isAdAppear === "OPEN") {
        localStorage.setItem("isAdAppear", 'CLOSE');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  //======= Hotjar =======
  useEffect(() => {
    // Define the Hotjar script as a function
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 5044288, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);

  if (loading) {
    return (
      <Box display="flex" flex={1} height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <GlobalConfirmDialog />
      <Router />
    </>
  );
}

export default App;