import * as Joi from "joi";
import i18n from "i18n";
import { joiArray, joiNumber, joiString } from "utils/joi";
import parsePhoneNumber from 'libphonenumber-js';

export const DEFAULT_PRICING_SCHEMA = Joi.array()
    .items(
        Joi.object({
            paymentMethod: Joi.string()
                .valid("mada", "master", "visa", "stcpay")
                .required(),
            fixedFee: Joi.number()
                .min(0)
                .required()
                .messages({
                    'any.required': `${i18n.t("validationErrors.isRequired")}`,
                    'number.empty': `${i18n.t("validationErrors.isEmpty")}`,
                    'number.base': `${i18n.t("validationErrors.isNumber")}`,
                    // 'number.min': `${i18n.t("validationErrors.minNumber")}`,
                    // 'number.max': `${i18n.t("validationErrors.maxNumber")}`,
                }),
            percentage: Joi.number()
                .min(0)
                .max(100)
                .required()
                .messages({
                    'any.required': `${i18n.t("validationErrors.isRequired")}`,
                    'number.empty': `${i18n.t("validationErrors.isEmpty")}`,
                    'number.base': `${i18n.t("validationErrors.isNumber")}`,
                    'number.min': `${i18n.t("validationErrors.minNumber")} {#limit}`,
                    'number.max': `${i18n.t("validationErrors.maxNumber")} {#limit}`,
                }),
        }).required()
    )
    .min(4)
    .max(4)
    .required()


export const RESTAURNAT_PAYMENT_METHODS_EDIT_SCHEMA = joiArray.array()
    .items(
        joiString.object({
            // paymentMethod: joiString.string()
            //     .valid("VISA/MASTER", "MADA", "STC PAY", "APPLE PAY", "AMEX", "OTHER")
            //     .required(),
            payment: joiString.string()
                .label(i18n.t("Payment"))
                .required(),
            fixedFee: joiNumber.number()
                .label(i18n.t("Fixed fee"))
                .min(0)
                .max(999999)
                .required(),
            percentage: joiNumber.number()
                .label(i18n.t("Percentage"))
                .min(0)
                .max(100)
                .required(),
        }).required()
    )
    .required()

export const SUPPORT_CONTACTS_SCHEMA = Joi.object({
    supportEmail: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .label(i18n.t("Email")).messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
            'email.invalid': `{#label} ${i18n.t("validationErrors.isEmail")}`,
        }),
    supportPhone: Joi.string()
        .required()
        .label(i18n.t("Phone"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
        })
        .custom((value, helper) => {
            const parsedPhone = parsePhoneNumber(value, { extract: true });
            if (!parsedPhone || !parsedPhone.isValid())
                return helper.message(i18n.t("validationErrors.isValidPhone"));
            return true
        }),
});