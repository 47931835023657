import "./index.css"

//Examples of usage:
// columns = ["Input Parameter", "Type", "Description"]
// rows = [ { inputParameter: "name", type: "string", description: "Name of the user" } ]

export default function APIFieldsTable({ columns, rows }) {

  console.log("APIFieldsTable -> columns", columns)
  const renderHeader = () => {
    return (
      <tr>
        {columns.map((column) => (
          <th key={column?.label}>{column?.label}</th>
        ))}
      </tr>
    );
  };

  const renderRows = () => {
    return rows.map((row) => (
      <tr key={row[columns[0]?.value]}>
        {Object.keys(row).map((key, index) => (
          <td
            key={`td-${index}`}
            style={{ fontWeight: index === 0 ? "bold" : "" }}
            dangerouslySetInnerHTML={{ __html: row[key] }}
          ></td>
        ))}
      </tr>
    ));
  };

  return (
    <table>
      <thead>{renderHeader()}</thead>
      <tbody>{renderRows()}</tbody>
    </table>
  )

}
