import * as Joi from "joi";
import i18n from "i18n";
import parsePhoneNumber from 'libphonenumber-js';
import { joiNumber, joiObject, joiString } from "utils/joi";

export const USER_INFO_SCHEMA = Joi.object({
    userName: Joi.string()
        .required()
        .empty('')
        .label(i18n.t("Name"))
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` }),
    country: Joi.string()
        .empty('')
        .label(i18n.t("Country"))
        .optional()
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        // .required()
        .label(i18n.t("Email")).messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
            'email.invalid': `{#label} ${i18n.t("validationErrors.isEmail")}`,
        }),
    phone: Joi.string()
        .required("required")
        .label(i18n.t("Phone"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
        })
        .custom((value, helper) => {
            const parsedPhone = parsePhoneNumber(value, { extract: true });
            if (!parsedPhone || !parsedPhone.isValid())
                return helper.message(i18n.t("validationErrors.isValidPhone"));
            return true
        }),
    password: Joi.string()
        .min(6)
        .label(i18n.t("Password")).messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
            'string.min': `{#label} ${i18n.t("validationErrors.minChar", { length: 6 })}`,
        }),
    // confirm_password: Joi.string()
    //     .min(6)
    //     .valid(Joi.ref('password'))
    //     .label(i18n.t("Confirm password"))
    //     .messages({
    //         'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
    //         'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
    //         'any.only': `{#label} ${i18n.t("validationErrors.isPasswordMatch")}`
    //     }),
    confirm_password: Joi.when('password', {
        is: Joi.exist(),
        then: Joi.string()
            .min(6)
            .valid(Joi.ref('password'))
            .label(i18n.t("Confirm password"))
            .required()
            .messages({
                'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
                'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
                'any.only': `{#label} ${i18n.t("validationErrors.isPasswordMatch")}`
            }),
        otherwise: Joi.forbidden(),
    }),
});

export const USER_BUSINESS_INFO_SCHEMA = Joi.object({
    businessName: Joi.string()
        .label(i18n.t("Business name"))
        .required()
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    commercialRegister: Joi.string().base64()
        .label(i18n.t("Commercial Register"))
    ,
    ownerId: Joi.string().base64()
        .label(i18n.t("Owner ID"))
    ,
    logo: Joi.string().base64().label(i18n.t("Logo")),
    bankLetter: Joi.string().base64().label(i18n.t("Bank Letter")),
    taxLetter: Joi.string().base64().label(i18n.t("Tax Letter"))
});

export const USER_BANK_ACCOUNT_SCHEMA = Joi.object({
    bank: Joi.string().empty('').label(i18n.t("bankAccountDetails.bank")),
    accountNumber: Joi.string().empty('').label(i18n.t("bankAccountDetails.accountNumber")),
    accountName: Joi.string().empty('').label(i18n.t("bankAccountDetails.accountName")),
    iban: Joi.string().empty('').label(i18n.t("bankAccountDetails.iban"))
})

export const DEPOSITE_SCHEMA = joiObject.object({
    terms: joiString.string()
        .empty('')
        .valid('Daily', 'Weekly', 'Monthly')
        .required()
        .label(i18n.t("Terms")),
    days: joiString.string()
        .optional()
        .when('terms', {
            is: Joi.string().valid('Daily'),
            then: Joi.optional(),
            otherwise: Joi.required()
        })
        .label(i18n.t("Days"))
})
    .label(i18n.t("Payment Period"))
    .required()

export const USER_BUSINESS_SCHEMA = Joi.object({
    logo: joiObject.alternatives().try(
        joiString.string().required().label(i18n.t("Logo")),
        joiObject.object({
            sizes: joiString.array().items(joiString.string().valid('small', 'medium', 'large')).empty(''),
            baseURL: joiString.string().required(),
            provider: joiString.string().empty(''),
            name: joiString.string().required()
        }).required().label(i18n.t("Logo (Object)"))
    ).required().label(i18n.t("Logo")),
    businessName: joiString.string()
        .empty('')
        .label(i18n.t("Business name"))
        .required()
    ,
    expiryAt: joiNumber.number()
        .optional()
        .label(i18n.t("Payment Links Validity (hours)")),
    // civilId: joiString.string()
    //     .empty('')
    //     //.required()
    //     .label(i18n.t("Civil ID")),
    // civilIdBack: joiString.string()
    //     .empty('')
    //     .label(i18n.t("Civil ID Back")),
    // otherDocument: joiString.string()
    //     .empty('')
    //     .label(i18n.t("Other")),
    // commercialRegister: joiString.string()
    //     .empty('')
    //     //.required()
    //     .label(i18n.t("Commercial Register")),
    // bankAccountLetter: joiString.string()
    //     .empty('')
    //     //.required()
    //     .label(i18n.t("Bank Account Letter")),
    // articlesOfAssociation: joiString.string()
    //     .empty('')
    //     //.required()
    //     .label(i18n.t("Articles Of Association")),
    // signatureAuthorization: joiString.string()
    //     .empty('')
    //     //.required()
    //     .label(i18n.t("Signature Authorization")),
    // commercialLicense: joiString.string()
    //     .empty('')
    //     //.required()
    //     .label(i18n.t("Commercial License")),
    bankAccount: joiObject.object({
        bank: joiString.string()
            .empty('')
            .required()
            .label(i18n.t("bankAccountDetails.bank")),
        accountNumber: joiString.string()
            .empty('')
            .required()
            .label(i18n.t("bankAccountDetails.accountNumber")),
        accountName: joiString.string()
            .empty('')
            .required()
            .label(i18n.t("bankAccountDetails.accountName")),
        iban: joiString.string()
            .empty('')
            .required()
            .label(i18n.t("bankAccountDetails.iban"))
    }).label(i18n.t("Bank Account Info")).required(),
    deposit: DEPOSITE_SCHEMA
})