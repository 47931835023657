import { apiSlice } from "./apiSlice";

export const transactionApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTransactionById: builder.query({
            query: (transactionId) => ({
                url: `/transaction/${transactionId}`,
                method: 'GET'
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getTransactionPublicDetailsById: builder.query({
            query: (transactionId) => ({
                url: `/transaction/public/${transactionId}`,
                method: 'GET'
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useGetTransactionByIdQuery,
    useLazyGetTransactionByIdQuery,
    useLazyGetTransactionPublicDetailsByIdQuery
} = transactionApi