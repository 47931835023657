import {
    AdminInvoices,
    AdminTransactions,
    AdminUsers,
    AdminSettings,
    AdminUserDetails,
    AdminTransactionDetails,
    AdminInvoiceDetails,
    AdminEditor,
    AdminContactUsMessagesList,
    Banks,
    AdminDeleteRequestsList,
    SubAdmins,
    Countries,
    AdminStatistics,
    AdminBlogPosts,
    AdminBlogPost
} from "modules/admin";
import { AdminLayout } from "layouts";
import { AppVersions } from "modules/admin/MobileAppVersions";
import { Navigate } from "react-router-dom";
import { ROLES } from "constants";

export const AdminRoutes = ({ role }) => [
    {
        path: "admin",
        element: ROLES.admin === role ? <AdminLayout /> : <Navigate to="/auth/admin/login" replace />,
        children: [
            { path: "", element: <AdminStatistics /> },
            { path: "users", element: <AdminUsers /> },
            { path: "transactions", element: <AdminTransactions /> },
            { path: "contact-us-messages", element: <AdminContactUsMessagesList /> },
            { path: "delete-requests-list", element: <AdminDeleteRequestsList /> },
            { path: "transaction/:transactionId", element: <AdminTransactionDetails /> },
            { path: "invoices", element: <AdminInvoices /> },
            { path: "invoice/:invoiceId", element: <AdminInvoiceDetails role={ROLES.admin} /> },
            { path: "settings", element: <AdminSettings /> },
            { path: "user/:id", element: <AdminUserDetails /> },
            { path: "privacy_policy", element: <AdminEditor type="privacy" /> },
            { path: "terms_and_conditions", element: <AdminEditor type="terms_conditions" /> },
            { path: "banks", element: <Banks /> },
            { path: "app-versions", element: <AppVersions /> },
            { path: "admins", element: <SubAdmins /> },
            { path: "countries", element: <Countries /> },
            {
                path: "blog",
                children: [
                    { path: "", element: <AdminBlogPosts /> },
                    { path: "add", element: <AdminBlogPost /> },
                    { path: ":id", element: <AdminBlogPost /> }
                ]
            },
        ]
    },
]