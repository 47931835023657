import { useEffect, useState } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import CountryDialog from './countries-components/CountryDialog'
import { getCountry } from "countries-and-timezones"
import { cloneDeep, find, set } from 'lodash'
import { useAddCountryMutation, useGetCountriesQuery, useUpdateCountryMutation } from 'api/countriesApis'
import { ActivationStatus, DataTable } from 'modules/common'
import { Helmet } from 'react-helmet'
import { $APP_NAME } from 'constants'

const $COLUMN_WIDTH = 200;
export default function Countries() {
  const { t } = useTranslation()

  //============County payload==============
  const [country, setCountry] = useState(null)
  const handleChange = ({ target: { name, value } }) => {
    if (!!country) {
      let clone = cloneDeep(country)
      set(clone, name, value)
      setCountry(clone)
    }
  }

  //========= Country data depend on coutries-list packages =======
  const [selectedCountry, setSelectedCountry] = useState(null)
  const handleCountryChange = (countryData) => {
    setSelectedCountry(countryData)

    let formatedCountry = null
    if (!!countryData) {
      const data = getCountry(countryData.iso2)
      const timezone = data.timezones[0]

      formatedCountry = {
        info: {
          arabic: {
            name: countryData.native,
            currency: countryData.currency[0],
          },
          english: {
            name: countryData.name,
            currency: countryData.currency[0],
          },
        },
        isoCode: countryData.iso2,
        isoCode3: countryData.iso3,
        phoneCode: `+${countryData.phone[0]}`,
        timezone
      }
    }

    setCountry(formatedCountry)
  }


  //==============Countries CRUD operations=========
  const { data: countries, isLoading: countriesLoading, isFetching: countriesFetching } = useGetCountriesQuery({ limit: 250 })

  //-----Add-----
  const [addCountry, { isLoading: addCountryLoading, isSuccess: addCountryIsSuccess, error: addCountryError, reset: addCountryReset }] = useAddCountryMutation()

  //-----Update-----
  const [updateCountry, { isLoading: updateCountryLoading, isSuccess: updateCountryIsSuccess, error: updateCountryError, reset: updateCountryReset }] = useUpdateCountryMutation()

  //=============Country Dialog============
  const [isEditing, setIsEdition] = useState(false)
  const [openCountryDialog, setOpenCountryDialog] = useState(false)

  const openCountryDialogHandler = (country) => {
    if (!!country) {
      setIsEdition(true)
      setCountry(country)
    }
    setOpenCountryDialog(true)
  }

  useEffect(() => {
    if (!!countries && isEditing) {
      const data = find(countries?.docs, (el) => el.id === country.id)
      if (!!data) {
        setCountry(data)
      }
    }
  }, [isEditing, countries])

  const closeDialogHandler = () => {
    setOpenCountryDialog(false)
    setSelectedCountry(null)
    setCountry(null)
    setIsEdition(false)
    addCountryReset()
    updateCountryReset()
  }

  //=============Countries Table Columns============
  const [page, setPage] = useState(1)

  const pageChangeHandler = ({ page }) => {
    setPage(page)
  }

  const columns = [
    { field: "name_en", headerName: t("Country name in English"), valueGetter: ({ row }) => row.info.english.name, width: $COLUMN_WIDTH * 1.2 },
    { field: "name_ar", headerName: t("Country name in Arabic"), valueGetter: ({ row }) => row.info.arabic.name, width: $COLUMN_WIDTH * 1.2 },
    { field: "currency_en", headerName: t("Currency in English"), valueGetter: ({ row }) => row.info.english.currency, width: $COLUMN_WIDTH * 1 },
    { field: "currency_ar", headerName: t("Currency in Arabic"), valueGetter: ({ row }) => row.info.arabic.currency, width: $COLUMN_WIDTH * 1 },
    { field: "isoCode", headerName: t("Country ISO Code"), width: $COLUMN_WIDTH * 0.7 },
    { field: "isoCode3", headerName: t("Country ISO Code 3"), width: $COLUMN_WIDTH * 0.7 },
    { field: "phoneCode", headerName: t("Phone Code"), width: $COLUMN_WIDTH * 0.7 },
    { field: "timezone", headerName: t("Timezone"), width: $COLUMN_WIDTH * 0.7 },
    {
      field: "active",
      renderCell: ({ value }) => <ActivationStatus color={value ? "success" : "error"} text={t(value ? "Activated" : "Deactivated")} />,
      headerName: t("Active"),
      width: $COLUMN_WIDTH * 0.7
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Countries")} | {$APP_NAME}</title>
      </Helmet>
      <Box mr={5}>
        <Box display="flex" justifyContent="space-between" alignItems="center" my={15}>
          <Typography variant='h6'>{t("Countries")}</Typography>
          <Button
            startIcon={<Add />}
            sx={{ textTransform: "none" }}
            variant="contained"
            onClick={() => openCountryDialogHandler()}
          >
            {t("Add")}
          </Button>
          <CountryDialog
            openCountryDialog={openCountryDialog}
            country={country}
            handleChange={handleChange}
            selectedCountry={selectedCountry}
            handleCountryChange={handleCountryChange}
            onCloseDialog={closeDialogHandler}
            isEditing={isEditing}
            onAddCountry={addCountry}
            onUpdateCountry={updateCountry}
            loading={addCountryLoading || updateCountryLoading}
            error={addCountryError || updateCountryError}
            isSuccess={addCountryIsSuccess || updateCountryIsSuccess}
            countries={countries?.docs ?? []}
          />
        </Box>
        <Paper sx={{ height: "80vh" }}>
          <DataTable
            rows={countries?.docs ?? []}
            columns={columns}
            rowCount={countries?.totalDocs ?? 0}
            loading={countriesLoading || countriesFetching}
            onRowClick={({ row }) => openCountryDialogHandler(row)}
            onPaginationModelChange={pageChangeHandler}
            paginationModel={{
              page: page - 1,
              pageSize: 100
            }}
          />
        </Paper>
      </Box>
    </>
  )
}
