import * as Joi from "joi";
import i18n from "i18n";
import { join, set } from "lodash";

const extractJoiErrorDetails = (error) => {
  const { details } = error;
  const errorDetails = {};
  details.forEach((detail) => {
    const nestedPath = join(detail.path, ".");
    set(errorDetails, nestedPath, detail.message.replace(/"/g, ""));
  });
  return errorDetails;
};

export const runJoiValidate = (schema, values) => {
  const { error } = schema.validate(values, { abortEarly: false });
  if (!error) return;
  if (typeof values === 'object') return extractJoiErrorDetails(error)
  if (typeof values === 'string') return error.details[0].message
};

export const joiObject = Joi.extend((joi) => {
  return {
    type: 'object',
    base: joi.object(),
    messages: {
      'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
      'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
    }
  }
});

export const joiString = Joi.extend((joi) => {
  return {
    type: 'string',
    base: joi.string(),
    messages: {
      'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
      'string.uri': `{#label} ${i18n.t("validationErrors.isURI")}`,
      'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
      'string.min': `{#label} ${i18n.t("validationErrors.minChar")} {#limit}`,
      'string.max': `{#label} ${i18n.t("validationErrors.maxChar")} {#limit}`,
      'string.email': `{#label} ${i18n.t("validationErrors.isEmail")}`,
      'any.only': `{#label} ${i18n.t("validationErrors.isPasswordMatch")}`,
      'string.base': `{#label} ${i18n.t("validationErrors.isString")}`,
    }
  }
});

export const joiNumber = Joi.extend((joi) => {
  return {
    type: 'number',
    base: joi.number(),
    messages: {
      'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
      'number.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
      'number.min': `{#label} ${i18n.t("validationErrors.minNumber")} {#limit}`,
      'number.max': `{#label} ${i18n.t("validationErrors.maxNumber")} {#limit}`,
      'number.base': `{#label} ${i18n.t("validationErrors.isNumber")}`,
      'number.positive': `{#label} ${i18n.t("validationErrors.isMoreThanZero")}`,
    }
  }
});

export const joiArray = Joi.extend((joi) => {
  return {
    type: 'array',
    base: joi.array(),
    messages: {
      'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
      'array.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
      'array.min': `{#label} ${i18n.t("validationErrors.minArray")} {#limit}`,
      'array.max': `{#label} ${i18n.t("validationErrors.maxArray")} {#limit}`,
      'array.base': `{#label} ${i18n.t("validationErrors.isArray")}`,
    }
  }
});

export const passwordValidator = (value, helper) => {
 // const regexPassword = /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[\W_\x7B-\xFF]).{6,15}$/i;
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{1,}$/;

  if (!passwordRegex.test(value)) {
    return helper.message(i18n.t("validationErrors.passwordRegex"));
  }
  return value;
}

export const leadingWhitespaceValidator = (label) => (value, helper) => {
  const regexLeadingWhitespace = /^[\s]+/;
  if (regexLeadingWhitespace.test(value)) {
    return helper.message(i18n.t("validationErrors.leadingWhitespaces", { label }));
  }
  return true;
}

export const leadingAndTrailingWhitespaceValidator = (label) => (value, helper) => {
  const regexLeadingAndTrailingWhitespace = /^[\s]+|[\s]+$/;
  if (regexLeadingAndTrailingWhitespace.test(value)) {
    return helper.message(i18n.t("validationErrors.leadingAndTrailingWhitespace", { label }));
  }
  return true;
}
