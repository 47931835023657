import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Divider,
  Typography,
} from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import { generateImageLink } from "utils";
import { isValidBase64ImageString } from "utils/base64Utils";

export default function ImageUpload({
  title = "Choose image",
  image,
  handleChange,
  error,
  variant,
  imgProps,
  name,
  isImageClickable,
  disabled,
  ...props
}) {
  const { t } = useTranslation();

  const [source, setSource] = useState();

  useEffect(() => {
    if (!image) return
    if (isValidBase64ImageString(image)) {
      setSource(image)
    } else {
      setSource(
        generateImageLink(image, "small")
      )
    }
  }, [image])

  const [openImage, setOpenImage] = useState(false)

  return (
    <>
      <Dialog onClose={() => setOpenImage(false)} open={openImage}>
        <img src={source} style={{ objectFit: 'contain' }} alt={`upload ${name}`} />
      </Dialog>
      <Card {...props}>
        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Avatar
              src={source}
              sx={{
                height: 100,
                width: 100,
                cursor: isImageClickable ? 'pointer' : ''
              }}
              variant={variant}
              imgProps={imgProps}
              onClick={() => {
                if (isImageClickable) {
                  setOpenImage(true)
                }
              }}
            >
              <BusinessIcon sx={{ fontSize: 100 }} />
            </Avatar>
            {
              !!error &&
              <Typography color='error' mx={1} variant="caption" sx={{ textAlign: "center" }}>
                {error}
              </Typography>
            }
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button sx={{ fontWeight: 'bold', textAlign: "center", textTransform: "none" }} fullWidth component="label" disabled={disabled}>
            {t(title)}
            <input
              hidden
              name={name}
              type="file"
              accept="image/*"
              onChange={handleChange}
            />
          </Button>
        </CardActions>
      </Card>
    </>
  );
};