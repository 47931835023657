import React, { useEffect, useRef } from "react";
// import { generateDataGridLocaleText } from "src/_utils";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme, styled } from "@mui/material";

const CustomStyleDataGrid = styled(DataGrid)(({ theme }) => ({
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-row': {
        cursor: "pointer"
    },
    // '&.MuiDataGrid-root .MuiTablePagination-toolbar': {
    //     direction: theme.direction === 'rtl' ? 'ltr' : ''
    // },
    // '&.MuiDataGrid-root  .MuiTablePagination-actions': {
    //     direction: theme.direction === 'rtl' ? 'rtl' : ''
    // }
}));

export default function DataTable({ onPaginationModelChange, ...props }) {

    const theme = useTheme()
    const virtualScrollerElement = useRef(null);
    const headerElement = useRef(null);

    //Hande datagrid header scroll when it's RTL
    useEffect(() => {
        if (theme.direction === "rtl") {
            let doc = document.getElementsByClassName("MuiDataGrid-virtualScroller")
            if (!virtualScrollerElement.current && doc && doc.length) {
                virtualScrollerElement.current = doc[0]
                virtualScrollerElement.current.addEventListener("scroll", function (event) {
                    if (!headerElement.current) {
                        let header = document.getElementsByClassName("MuiDataGrid-columnHeadersInner")[0]
                        headerElement.current = header
                    }
                    headerElement.current.style.transform = `translate3d(${Math.ceil(doc[0].scrollLeft * -1)}px, 0px, 0px)`
                })
            }
        }
    })

    return (
        <CustomStyleDataGrid
            paginationMode="server"
            disableVirtualization
            disableRowSelectionOnClick
            pageSizeOptions={[100]}
            // localeText={generateDataGridLocaleText(t)}
            onPaginationModelChange={({ page, pageSize }) => {
                onPaginationModelChange({ page: page + 1, pageSize })
            }}
            {...props}
        />
    );
}