import { Helmet } from "react-helmet";
import { useState, useEffect } from "react"
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import AdminContactUsMessageDialog from "./AdminContactUsMessageDialog";
import { useSearchParams } from "react-router-dom";
import { $APP_NAME } from "constants";
import { useGetContactsQuery, useLazyGetContactByIdQuery } from "api/contactusApi";
import { DataTable } from "modules/common";
import 'modules/common/TableRowStyle.css';
import { useGetCountersQuery } from "api/userApi";

const $COLUMN_WIDTH = 200;
export default function AdminContactUsMessagesList() {
    const { t } = useTranslation();

    const [page, setPage] = useState(1)
    const [selectContactusMessage, setSelectContactusMessage] = useState(null)
    const { refetch: refetchCounters } = useGetCountersQuery()

    const { data: messages, isLoading: messagesLoading, isFetching: messagesFetching, refetch: messageRefetch } = useGetContactsQuery({ page })
    // const [deleteContactMesssage, { isLoading: deleteContactMesssageLoading, isSuccess: deleteContactMesssageIsSuccess, error: deleteContactMesssageError }] = useDeleteContactUsMessageMutation()

    //==========Get and navigate through orders==========
    const pageChangeHandler = ({ page }) => {
        if (!messagesFetching || !messagesLoading) {
            setPage(page)
        }
    }

    //=========== Constact us dialog ==================
    const [getContactusMessageById] = useLazyGetContactByIdQuery()
    const [openContactUsMessageDialog, setOpenContactUsMessageDialog] = useState(false)

    const openContactUsMessageDialogHandler = async (row) => {
        setSelectContactusMessage(row)
        setOpenContactUsMessageDialog(true)
        await getContactusMessageById(row.id)
        messageRefetch()
        refetchCounters()
    }
    const closeContactUsMessageDialogHandler = () => {
        setOpenContactUsMessageDialog(false)
        setSelectContactusMessage(null)
    }

    const getRowClassName = ({ row }) => row?.seen || 'unread'

    const columns = [
        { field: "name", headerName: t("Name"), width: $COLUMN_WIDTH, },
        { field: "email", headerName: t("Email"), width: $COLUMN_WIDTH * 1.5, },
        { field: "phone", renderCell: ({ value }) => <bdi>{value}</bdi>, headerName: t("Phone"), width: $COLUMN_WIDTH, },
        { field: "subject", headerName: t("Subject"), width: $COLUMN_WIDTH, },
        { field: "message", headerName: t("Message"), width: $COLUMN_WIDTH * 2, },
        { field: "createdAt", headerName: t("Created At"), width: $COLUMN_WIDTH, valueGetter: ({ value }) => value ? moment(value).format("DD/MM/YYYY - hh:mm A") : '-' },
    ];

    //=========When open contact us message from notifcations list===========
    const [searchParams, setSearchParams] = useSearchParams()
    const messageId = searchParams.get("messageId")

    const presentMessageHandler = async () => {
        let response = await getContactusMessageById(messageId)
        if (response?.isSuccess) {
            setSelectContactusMessage(response?.data)
            setOpenContactUsMessageDialog(true)
            messageRefetch()
            refetchCounters()
        }
    }

    useEffect(() => {
        if (!!messageId) {
            searchParams.delete("messageId");
            setSearchParams(searchParams);
            presentMessageHandler()
        }
    }, [messageId])

    return (
        <>
            <Helmet>
                <title>{t("Contact Messages")} | {$APP_NAME}</title>
            </Helmet>
            <Box mr={5}>
                <AdminContactUsMessageDialog
                    open={openContactUsMessageDialog}
                    contactusMessage={selectContactusMessage}
                    onClose={closeContactUsMessageDialogHandler}
                // onDelete={deleteMessageHandler}
                // loading={deleteContactMesssageLoading}
                // isSuccess={deleteContactMesssageIsSuccess}
                // error={deleteContactMesssageError ?? ''}
                />
                <Box display="flex" justifyContent="space-between" alignItems="center" my={20}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t('Messages')}</Typography>
                </Box>
                <Paper sx={{ height: "80vh" }}>
                    <DataTable
                        rows={messages?.docs ?? []}
                        columns={columns}
                        onPaginationModelChange={pageChangeHandler}
                        paginationModel={{ page: page - 1, pageSize: 20 }}
                        rowCount={messages?.totalDocs ?? 0}
                        loading={messagesLoading || messagesFetching}
                        onRowClick={({ row }) => openContactUsMessageDialogHandler(row)}
                        getRowClassName={getRowClassName}
                    />
                </Paper>
            </Box>
        </>
    );
};