import { Avatar, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { Cancel, CheckCircle, Info } from '@mui/icons-material';
import { useGetTransactionByIdQuery } from "api/transaction";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { generateImageLink } from "utils";
import ClientInfo from "./ClientInfo";
import PaymentInfo from "./PaymentInfo";
import { TRANSACTION_STATUSES } from "constants";
import { StatusTimeline } from "modules/common";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";

const ICONS = {
    [TRANSACTION_STATUSES.INITIATED]: <Info color="" sx={{ color: "grey.500" }} />,
    [TRANSACTION_STATUSES.IN_PROGRESS]: <Info color='warning' />,
    [TRANSACTION_STATUSES.PAID]: <CheckCircle color="success" />,
    [TRANSACTION_STATUSES.FAILED]: <Cancel color="error" />,
    [TRANSACTION_STATUSES.CANCELED]: <Info color="error" />,
    [TRANSACTION_STATUSES.CANCELED_BY_BLOCK]: <Info color="error" />,
    [TRANSACTION_STATUSES.CANCELED_BY_PARENT_BLOCK]: <Info color="error" />,
    [TRANSACTION_STATUSES.EXPIRED]: <Info color="error" />,
}

export default function AdminTransactionDetails() {
    const { t } = useTranslation()
    const { transactionId } = useParams()
    const { data: transaction } = useGetTransactionByIdQuery(transactionId)
    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>{t("Transactions List")} | {$APP_NAME}</title>
            </Helmet>
            <Box sx={{ my: 30, mr: 5 }}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xl={9} lg={9} md={10} sm={12} xs={12}>
                        <Typography variant="h5" sx={{ mb: 20, textAlign: "center" }}>
                            {t("The Transaction")}
                        </Typography>
                        <Paper>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", p: 10, textAlign: "center" }}>
                                {transaction?.transactionNumber ?? '-------'}
                            </Typography>
                            <Box p={20}>
                                {/********************Merchant Info**********************/}
                                <Divider>
                                    <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Merchant Info")}</Typography>
                                </Divider>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <Avatar onClick={() => navigate(`/admin/user/${transaction?.user?.id}`)} sx={{ cursor: "pointer", width: 60, height: 60, mr: 6 }} src={generateImageLink(transaction?.business?.logo, "small")} alt="business logo" />
                                    <Typography onClick={() => navigate(`/admin/user/${transaction?.user?.id}`)} sx={{ cursor: "pointer", fontWeight: "bold" }}>{transaction?.business?.businessName}</Typography>
                                </Box>
                                {/********************Transaction created by**********************/}
                                <Divider>
                                    <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Transaction created by")}</Typography>
                                </Divider>
                                <Box display="flex" my={10}>
                                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Name")}</Typography>
                                    <Typography>{transaction?.user?.userName}</Typography>
                                </Box>
                                <Box display="flex" my={10}>
                                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Transaction Source")}</Typography>
                                    <Typography>{t(transaction?.createdFrom)}</Typography>
                                </Box>
                                {/********************Client Info**********************/}
                                <Divider>
                                    <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Client Info")}</Typography>
                                </Divider>
                                <ClientInfo transaction={transaction} spacing={20} sx={{ my: 10 }} />
                                {/********************Payment Info**********************/}
                                <Divider>
                                    <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Payment Info")}</Typography>
                                </Divider>
                                <PaymentInfo transaction={transaction} />
                                {/********************Transaction Status track**********************/}
                                <Divider>
                                    <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Transaction Status track")}</Typography>
                                </Divider>
                                <StatusTimeline timeline={transaction?.statusTrack} icons={ICONS} sx={{ marginTop: 10 }} />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box >
        </>
    )
}