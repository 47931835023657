import { Avatar, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Logo } from "_assets";
import { useNavigate } from "react-router-dom";

export default function PoweredByEzPay({ ...props }) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" {...props} >
            <Typography variant="body1" sx={{ fontWeight: "bold", color: "#000" }}>{t("Powered by")}</Typography>
            <Avatar
                onClick={() => navigate("/")}
                variant='square'
                src={Logo}
                sx={{ height: 30, width: 42, cursor: "pointer" }}
                style={{ objectFit: "contain", paddingInline: 6}}
            />
        </Box>
    )
}
