import { Helmet } from "react-helmet";
import { useState, useEffect } from "react"
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import DeleteRequestDialog from "./DeleteRequestDialog";
import { useSearchParams } from "react-router-dom";
import { $APP_NAME } from "constants";
import { useGetDeleteRequestsQuery, useLazyGetDeleteRequestByIdQuery } from "api/contactusApi";
import { DataTable } from "modules/common";
import 'modules/common/TableRowStyle.css';
import { useGetCountersQuery } from "api/userApi";

const $COLUMN_WIDTH = 200;
export default function AdminDeleteRequestsList() {
    const { t } = useTranslation();

    const [page, setPage] = useState(1)
    const [selectDeleteRequest, setSelectDeleteRequest] = useState(null)

    const { data: messages, isLoading: messagesLoading, isFetching: messagesFetching, refetch: messageRefetch } = useGetDeleteRequestsQuery({ page })
    // const [deleteDeleteRequestMesssage, { isLoading: deleteDeleteRequestMesssageLoading, isSuccess: deleteDeleteRequestMesssageIsSuccess, error: deleteDeleteRequestMesssageError }] = useDeleteDeleteRequesteMutation()

    const { refetch: refetchCounters } = useGetCountersQuery()

    //==========Get and navigate through orders==========
    const pageChangeHandler = ({ page }) => {
        if (!messagesFetching || !messagesLoading) {
            setPage(page)
        }
    }

    //=========== Constact us dialog ==================
    const [getDeleteRequestById] = useLazyGetDeleteRequestByIdQuery()
    const [openDeleteRequesteDialog, setOpenDeleteRequesteDialog] = useState(false)

    const openDeleteRequesteDialogHandler = async (row) => {
        setSelectDeleteRequest(row)
        setOpenDeleteRequesteDialog(true)
        await getDeleteRequestById(row.id)
        messageRefetch()
        refetchCounters()
    }
    const closeDeleteRequesteDialogHandler = () => {
        setOpenDeleteRequesteDialog(false)
        setSelectDeleteRequest(null)
    }

    const getRowClassName = ({ row }) => row?.seen || 'unread'

    const columns = [
        { field: "name", headerName: t("Name"), width: $COLUMN_WIDTH, },
        { field: "phone", renderCell: ({ value }) => <bdi>{value}</bdi>, headerName: t("Phone"), width: $COLUMN_WIDTH, },
        { field: "email", headerName: t("Email"), width: $COLUMN_WIDTH * 1.5, },
        { field: "message", headerName: t("Message"), width: $COLUMN_WIDTH * 2, },
        { field: "createdAt", headerName: t("Created At"), width: $COLUMN_WIDTH, valueGetter: ({ value }) => value ? moment(value).format("DD/MM/YYYY - hh:mm A") : '-' },
    ];

    //=========When open contact us message from notifcations list===========
    const [searchParams, setSearchParams] = useSearchParams()
    const messageId = searchParams.get("messageId")

    const presentMessageHandler = async () => {
        let response = await getDeleteRequestById(messageId)
        if (response?.isSuccess) {
            setSelectDeleteRequest(response?.data)
            setOpenDeleteRequesteDialog(true)
            messageRefetch()
        }
    }

    useEffect(() => {
        if (!!messageId) {
            searchParams.delete("messageId");
            setSearchParams(searchParams);
            presentMessageHandler()
        }
    }, [messageId])

    return (
        <>
            <Helmet>
                <title>{t("Delete requests")} | {$APP_NAME}</title>
            </Helmet>
            <Box mr={5}>
                <DeleteRequestDialog
                    open={openDeleteRequesteDialog}
                    deleteRequest={selectDeleteRequest}
                    onClose={closeDeleteRequesteDialogHandler}
                />
                <Box display="flex" justifyContent="space-between" alignItems="center" my={20}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t('Messages')}</Typography>
                </Box>
                <Paper sx={{ height: "80vh" }}>
                    <DataTable
                        rows={messages?.docs ?? []}
                        columns={columns}
                        onPaginationModelChange={pageChangeHandler}
                        paginationModel={{ page: page - 1, pageSize: 20 }}
                        rowCount={messages?.totalDocs ?? 0}
                        loading={messagesLoading || messagesFetching}
                        onRowClick={({ row }) => openDeleteRequesteDialogHandler(row)}
                        getRowClassName={getRowClassName}
                    />
                </Paper>
            </Box>
        </>
    );
};