import { isIOS, isMacOs } from 'react-device-detect';
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { STCPay } from "_assets";
import { useExecutePaymentMutation, useInitiateSessionMutation } from "api/myFatoorahApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";

const CardViewStyle = {
    hideCardIcons: false,
    direction: "ltr",
    cardHeight: 170,
    tokenHeight: 170,
    input: {
        color: "black",
        fontSize: "13px",
        fontFamily: "sans-serif",
        inputHeight: "37px",
        inputMargin: "7px",
        borderColor: "c7c7c7",
        borderWidth: "1px",
        borderRadius: "8px",
        boxShadow: "",
        placeHolder: {
            holderName: "Name On Card",
            cardNumber: "Number",
            expiryDate: "MM / YY",
            securityCode: "CVV",
        }
    },
    text: {
        saveCard: "Save card info for future payment.",
        addCard: "Use another Card!",
        deleteAlert: {
            title: "Delete",
            message: "Test",
            confirm: "yes",
            cancel: "no"
        }
    },
    label: {
        display: false,
        color: "black",
        fontSize: "13px",
        fontWeight: "normal",
        fontFamily: "sans-serif",
        text: {
            holderName: "Card Holder Name",
            cardNumber: "Card Number",
            expiryDate: "Expiry Date",
            securityCode: "Security Code",
        },
    },
    error: {
        borderColor: "red",
        borderRadius: "8px",
        boxShadow: "0px",
    },
}

export default function MyFatoorahPayment({ invoiceId, requiredToPay, userCountry, userCurrency = "SAR" }) {

    const { t } = useTranslation()
    const navigation = useNavigate()

    const [searchParams] = useSearchParams()

    const country = searchParams.get("country")


    useEffect(() => {
        // console.log("country", country)
        // console.log("userCountry", userCountry)
        console.log("Env", process.env.REACT_APP_ENV)
        if (!country) {
            return navigation('/', { replace: true })
        }
        if (!!country && !!userCountry) {
            if (country !== userCountry) {
                if (process.env.REACT_APP_ENV !== "production" && country === "KWT") {
                    return
                }
               return navigation('/', { replace: true })
            }
        }
    }, [country, userCountry])

    //==========Initiate session=========
    const [initiateSessionData, setInitiateSessionData] = useState()
    const [initiateSession, { isLoading: initiateSessionLoading }] = useInitiateSessionMutation()

    const initiateSessionHandler = async () => {
        try {
            let data = await initiateSession({ transactionId: invoiceId }).unwrap()
            setInitiateSessionData(data)
        } catch (error) {

        }
    }
    useEffect(() => {
        initiateSessionHandler()
    }, [])

    //============Execute payment===============
    const [payType, setPayType] = useState('')
    const [executePayment, { isLoading: paymentLoading, error: paymentError }] = useExecutePaymentMutation()

    async function executePaymentHandler({ payload, payTypeValue }) {
        try {
            setPayType(payTypeValue)
            let response = await executePayment({ ...payload, displayCurrencyIso: userCurrency }).unwrap()
            window.open(response?.PaymentURL, "_self")
        } catch (error) {
            console.log(error)
        }
    }

    //===========Card view payment============
    function cardViewSubmit() {
        window.myFatoorah.submit()
            // On success
            .then(function (response) {
                // Here you need to pass session id to you backend here
                var sessionId = response.sessionId;
                // var cardBrand = response.cardBrand;

                executePaymentHandler({ payload: { sessionId, transactionId: invoiceId }, payTypeValue: "CARD" })
            })
            // In case of errors
            .catch(function (error) {
                // console.log(error);
            });
    }

    useEffect(() => {
        if (!!initiateSessionData) {
            window?.myFatoorah?.init({
                countryCode: country, // Here, add your Country Code.
                sessionId: initiateSessionData.SessionId, // Here you add the "SessionId" you receive from InitiateSession Endpoint.
                cardViewId: "card-element",
                //onCardBinChanged: handleBinChanges,
                // The following style is optional.
                style: CardViewStyle,
            });
        }
    }, [initiateSessionData])

    //==========Apple pay==============
    function payment(response) {
        // Here you need to pass session id to you backend here 
        var sessionId = response.sessionId;
        // var cardBrand = response.cardBrand;

        executePaymentHandler({ payload: { sessionId, transactionId: invoiceId }, payTypeValue: "APPLE" })
    };

    /**
     *   Update Display Amount (Optional)
     *   You can update the amount to be displayed in the payment sheet 
     *   after initializing the button by calling the following function:
     **/
    // function updateAmount(amount) {
    //     window.myFatoorahAP.updateAmount(amount);
    // }

    useEffect(() => {
        if (!!initiateSessionData && !!userCurrency && ((isIOS || isMacOs))) {
            window?.myFatoorahAP?.init({
                sessionId: initiateSessionData.SessionId, // Here you add the "SessionId" you receive from InitiateSession Endpoint.
                countryCode: country, // Here, add your Country Code.
                currencyCode: userCurrency, // Here, add your Currency Code.
                amount: requiredToPay, // Add the invoice amount.
                cardViewId: "apple-button-element",
                callback: payment,
                style: {
                    frameHeight: 51,
                    button: {
                        height: "35px",
                        text: "Pay with", // Accepted texts ["", "Buy with", "Pay with", "Check Out with", "Continue with", "Book with", "Donate with", "Subscribe with", "Reload with", "Add Money with", "Top Up with", "Order with", "Rent with", "Support with", "Contribute with", "Tip with", "Set Up"]
                        borderRadius: "8px"
                    }
                }
            });
        }
    }, [initiateSessionData, requiredToPay, userCurrency, isIOS, isMacOs])


    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingInline: "5%"
            }}
        >
            {initiateSessionLoading ?
                <CircularProgress />
                :
                <>
                    {
                        (isIOS || isMacOs) ?
                            <div id="apple-button-element" style={{ width: "340px" }}></div>
                            : null
                    }
                    {/* {
                        !window?.location?.href?.includes("ezpayuae") &&
                        <Box onClick={() => executePaymentHandler({ payload: { transactionId: invoiceId }, payTypeValue: "STC" })} display="flex" alignItems="center" justifyContent="center" width={340} py={4} mb={20} borderRadius={2} flex={1} backgroundColor="#5F1896" sx={{ cursor: "pointer" }}>
                            {payType === "STC" && paymentLoading ?
                                <CircularProgress sx={{ color: "white" }} size={30} />
                                :
                                <img style={{ objectFit: "cover", height: "30px" }} src={STCPay} alt="stc-button-pay" />
                            }
                        </Box>
                    } */}
                    {
                        (isIOS || isMacOs) &&
                        <Divider sx={{ display: "flex", flex: 1, width: "340px", mb: 20, fontWeight: "bold" }}>{t("Or pay with card")}</Divider>
                    }
                    <div id="card-element" style={{ width: "340px" }}></div>
                    <LoadingButton fullWidth loading={payType === "CARD" && paymentLoading} variant="contained" sx={{ background: "#183F9C", width: "340px", textTransform: "none" }} onClick={cardViewSubmit}>
                        {`${t("Pay")} ${requiredToPay} ${t(`Currency.${userCurrency}`)}`}
                    </LoadingButton>
                    {!!paymentError && <Typography sx={{ mt: 20 }} color="error">{paymentError}</Typography>}
                </>
            }
        </Box >
    )
}
