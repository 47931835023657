export const $APP_NAME = "EzPay";
export const $__DEV__ = process.env.REACT_APP_ENV === "development";
export const $BASE_URL = `${process.env.REACT_APP_SERVER_URL}`;

export const ROLES = {
    admin: "ADMIN",
    merchant: "MERCHANT"
}

export const I18N_LANGUAGES = {
    ar: "ar-SA",
    en: "en-US"
}

export const TRANSACTION_STATUS = [
    "IN_PROGRESS",
    "PAID",
    "FAILED",
    "EXPIRED",
    "CANCELED",
    "REFUNDED",
    "CANCELED_BY_BLOCK",
    "CANCELED_BY_PARENT_BLOCK"
]

export const TRANSACTION_STATUSES = {
    "INITIATED": 'INITIATED',
    "IN_PROGRESS": 'IN_PROGRESS',
    "PAID": 'PAID',
    "FAILED": 'FAILED',
    "CANCELED": 'CANCELED',
    "EXPIRED": 'EXPIRED',
    "REFUNDED": 'REFUNDED',
    "CANCELED_BY_BLOCK": 'CANCELED_BY_BLOCK',
    "CANCELED_BY_PARENT_BLOCK": 'CANCELED_BY_PARENT_BLOCK',
}

export const AUTO_TRANSFER_STATUS = {
    active: 'Active',
    pending: 'Pending',
    rejected: 'Rejected',
    closed: 'Closed',
    dormant: 'Dormant',
}

export const ADMIN_TYPES = {
    admin: "ADMIN",
    subAdmin: "SUB_ADMIN",
    accountManager: "ACCOUNT_MANAGER"
}

export const MYTFATOORAH_CARDS_TEST = "https://demo.myfatoorah.com/cardview/v2/session.js"
export const MYTFATOORAH_CARDS_PRODUCTION = "https://portal.myfatoorah.com/cardview/v2/session.js"
export const MYTFATOORAH_CARDS_PRODUCTION_SA = "https://sa.myfatoorah.com/cardview/v2/session.js"

export const MYTFATOORAH_APPLE_PAY_TEST = "https://demo.myfatoorah.com/applepay/v3/applepay.js"
export const MYTFATOORAH_APPLE_PAY_PRODUCTION = "https://portal.myfatoorah.com/applepay/v3/applepay.js"
export const MYTFATOORAH_APPLE_PAY_PRODUCTION_SA = "https://sa.myfatoorah.com/applepay/v3/applepay.js"

export const PaymentMethods = {
    'VISA/MASTER': 'https://demo.myfatoorah.com/imgs/payment-methods/vm.png',
    'MADA': 'https://demo.myfatoorah.com/imgs/payment-methods/md.png',
    'STC PAY': 'https://demo.myfatoorah.com/imgs/payment-methods/stc.png',
    'APPLE PAY': 'https://demo.myfatoorah.com/imgs/payment-methods/ap.png',
    'AMEX': 'https://demo.myfatoorah.com/imgs/payment-methods/ae.png',
    'OTHER': '',
    'CASH': 'https://cdn-icons-png.flaticon.com/512/1052/1052866.png',
    //'POS': 'https://cdn-icons-png.flaticon.com/512/4696/4696793.png',
}

export const DEFAULT_PRICING = [
    { paymentMethod: "VISA/MASTER", fixedFee: '', percentage: '' },
    { paymentMethod: "MADA", fixedFee: '', percentage: '', },
    { paymentMethod: "STC PAY", fixedFee: '', percentage: '', },
    { paymentMethod: "APPLE PAY", fixedFee: '', percentage: '', },
    { paymentMethod: "AMEX", fixedFee: '', percentage: '', },
    { paymentMethod: "OTHER", fixedFee: '', percentage: '', },
]

export const DAYS = [
    { id: 1, label: 'Sunday' },
    { id: 2, label: 'Monday' },
    { id: 3, label: 'Tuesday' },
    { id: 4, label: 'Wednesday' },
    { id: 5, label: 'Thursday' },
]

export const ACCOUNT_STATUS = {
    active: 'ACTIVE',
    waiting: 'WAITING',
    blocked: 'BLOCKED',
    blockedByParent: 'BLOCKED_BY_PARENT',
    accountCreated: 'ACCOUNT_CREATED',
    phoneVerified: 'PHONE_VERIFIED',
    rejectedByServiceProvider: 'REJECTED_BY_SERVICE_PROVIDER',
}

export const ACCOUNT_STATUS_COLORS = {
    [ACCOUNT_STATUS.accountCreated]: "info",
    [ACCOUNT_STATUS.phoneVerified]: "info",
    [ACCOUNT_STATUS.waiting]: "warning",
    [ACCOUNT_STATUS.active]: "success",
    [ACCOUNT_STATUS.blocked]: "error",
    [ACCOUNT_STATUS.blockedByParent]: "error"
}

export const BLOG_POST_CHARACTER_LIMIT = 90

export const SOURCE_TYPE = {
    admin: "ADMIN",
    web: "WEB",
    mobile: "MOBILE",
    api: "API"
}

export const DOMAINS = {
    uae: "ezpayuae.com",
    sau: "ezpay.sa"
}

export const DOCUMNETS = [
    { name: "civilId", label: "Civil ID" },
    { name: "civilIdBack", label: "Civil ID Back" },
    { name: "commercialRegister", label: "Commercial Register" },
    { name: "commercialLicense", label: "Commercial License" },
    { name: "articlesOfAssociation", label: "Articles of Association" },
    { name: "signatureAuthorization", label: "Signature Authorization" },
    { name: "bankAccountLetter", label: "Bank Account Letter" },
    { name: "website", label: "Website" },
    { name: "otherDocument", label: "Other" },
]

export const status_colors = {
    CANCELED: "info",
    CANCELED_BY_BLOCK: "info",
    CANCELED_BY_PARENT_BLOCK: "info",
    EXPIRED: "default",
    FAILED: "error",
    IN_PROGRESS: "warning",
    PAID: "success"
}

