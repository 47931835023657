import { TextField } from "@mui/material"

export default function TextFieldNumber({ value, onChange, decimal = true, error, max, ...props }) {
    const changeHandler = ({ target: { name, value } }) => {
        let regex = /^[0-9]+\.?[0-9]*$/
        if (decimal === false) {
            regex = /^[1-9][0-9]*$/
        }
        if (value === '' || (value.match(regex))) {
            if(Number(value) > max) return
            onChange({ target: { name: name, value: value } })
        }
    }
    return (
        <TextField
            inputProps={{ inputMode: 'numeric', maxLength: 999999 }}
            value={value}
            onChange={changeHandler}
            error={!!error}
            helperText={error ?? ''}
            InputLabelProps={{
                sx: {
                    color: "text.placeholder",
                },
            }}
            {...props}
        />
    )
}