import { Clear, Search } from "@mui/icons-material";
import { Autocomplete, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { cloneDeep, omitBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import { TRANSACTION_STATUS, SOURCE_TYPE } from "constants";
import { useGetBusinessesQuery } from "api/businessApi";

export default function TransactionsFilter({
    onParamsChange,
}) {
    const { t } = useTranslation()
    const theme = useTheme()
    const [searchKey, setSearchKey] = useState(null)
    const timerRef = useRef(null)

    const [filterPayload, setFilterPayload] = useState({
        business: null,
        searchKey: null,
        fromDate: null,
        toDate: null,
        createdFrom: 'all',
        currentStatus: 'all'
    })

    const setFilterPayloadHandler = ({ target: { name, value } }) => {
        setFilterPayload((prev) => {
            let data = {
                ...prev,
                [name]: value
            }
            return data
        })
    }

    useEffect(() => {
        let params = cloneDeep(filterPayload)
        if (params?.currentStatus === "all") {
            params.currentStatus = null
        }
        if (params?.createdFrom === "all") {
            params.createdFrom = null
        }
        if (!!params?.fromDate) {
            let offset = Number(new Date().getTimezoneOffset() * -1)
            params.fromDate = params.fromDate.utcOffset(offset).startOf("day").toISOString()
        } else if (!!params?.toDate) {
            let offset = Number(new Date().getTimezoneOffset() * -1)
            params.toDate = params.toDate.utcOffset(offset).endOf("day").toISOString()
        }
        onParamsChange(omitBy(params, v => !Boolean(v)))
    }, [filterPayload])


    const onSearchKey = ({ target: { name, value } }) => {
        setSearchKey(value)
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
            setFilterPayloadHandler({ target: { name, value } })
        }, 1000);
    }


    //================Get business in ezpay===========================
    const [businessSearchKey, setBusinessSearchKey] = useState('')

    const {
        data: businesses,
        isLoading: businessesLoading,
    } = useGetBusinessesQuery(omitBy({ searchKey: businessSearchKey, limit: 100 }, v => !Boolean(v)))

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" spacing={10} sx={{ mb: 10 }}>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <TextField
                        fullWidth
                        name="searchKey"
                        label={t("Search")}
                        size="small"
                        value={searchKey ?? ''}
                        onChange={onSearchKey}
                        InputProps={
                            theme.direction === "rtl" ?
                                {
                                    endAdornment: <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>,
                                }
                                :
                                {
                                    startAdornment: <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>,
                                }
                        }
                    />
                </Grid>
            </Grid>
            <Grid container alignItems="space-around" spacing={10} sx={{ mb: 10 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                        <DatePicker
                            format="DD/MM/YYYY"
                            error={true}
                            maxDate={filterPayload?.toDate ?? moment()}
                            label={t("From")}
                            value={filterPayload?.fromDate}
                            onChange={(newValue) => {
                                setFilterPayloadHandler({ target: { name: "fromDate", value: newValue } })
                            }}
                            slotProps={{
                                textField: (params) => {
                                    return {
                                        fullWidth: true,
                                        size: "small",
                                        InputProps: {
                                            ...params?.InputProps,
                                            startAdornment: !!filterPayload?.fromDate ?
                                                <InputAdornment position="start">
                                                    <IconButton onClick={(event) => {
                                                        event.stopPropagation()
                                                        setFilterPayloadHandler({ target: { name: "fromDate", value: null } })
                                                    }} size='small' color='primary'>
                                                        <Clear sx={{ fontSize: "inherit" }} />
                                                    </IconButton>
                                                </InputAdornment> : ''
                                        },

                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                        <DatePicker
                            format="DD/MM/YYYY"
                            maxDate={moment()}
                            minDate={filterPayload?.fromDate ?? moment()}
                            label={t("To")}
                            value={filterPayload?.toDate}
                            onChange={(newValue) => {
                                setFilterPayloadHandler({ target: { name: "toDate", value: newValue } })
                            }}
                            slotProps={{
                                textField: (params) => {
                                    return {
                                        fullWidth: true,
                                        size: "small",
                                        InputProps: {
                                            ...params?.InputProps,
                                            startAdornment: !!filterPayload?.toDate ?
                                                <InputAdornment position="start">
                                                    <IconButton onClick={(event) => {
                                                        event.stopPropagation()
                                                        setFilterPayloadHandler({ target: { name: "toDate", value: null } })
                                                    }} size='small' color='primary'>
                                                        <Clear sx={{ fontSize: "inherit" }} />
                                                    </IconButton>
                                                </InputAdornment> : ''
                                        },
                                    }
                                }
                            }}
                        />
                    </Grid>
                </LocalizationProvider>
                <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Autocomplete
                        value={businesses?.docs?.find(business => business.id === filterPayload?.business) ?? null}
                        onChange={(_, value) => setFilterPayloadHandler({ target: { name: "business", value: value?.id } })}
                        options={businesses?.docs ?? []}
                        onInputChange={(event, newInputValue) => {
                            setBusinessSearchKey(newInputValue)
                        }}
                        getOptionLabel={(option) => (option && option.businessName)}
                        disablePortal
                        fullWidth
                        size="small"
                        loading={businessesLoading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("Select Business")}
                            />
                        )}
                    />
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                    <FormControl size="small" fullWidth>
                        <InputLabel id="status-label">{t("Status")}</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            name="currentStatus"
                            value={filterPayload?.currentStatus ?? "all"}
                            label={t("Status")}
                            onChange={setFilterPayloadHandler}
                        >
                            <MenuItem value={"all"}>{t("All")}</MenuItem>
                            {TRANSACTION_STATUS.map((status) => (
                                <MenuItem value={status} key={status}>{t(status)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                    <FormControl size="small" fullWidth>
                        <InputLabel id="createdFrom-label">{t("Transaction Source")}</InputLabel>
                        <Select
                            labelId="createdFrom-label"
                            id="createdFrom"
                            name="createdFrom"
                            value={filterPayload?.createdFrom ?? "all"}
                            label={t("Transaction Source")}
                            onChange={setFilterPayloadHandler}
                        >
                            <MenuItem value={"all"}>{t("All")}</MenuItem>
                            {[SOURCE_TYPE.web, SOURCE_TYPE.mobile, SOURCE_TYPE.api].map((source) => (
                                <MenuItem value={source} key={source}>{t(source)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}