import { Box, Container, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Footer() {

    const navigate = useNavigate();
    const { t } = useTranslation();

    // TODO: fix header not showing on mobile

    const [location, setLocation] = useState(null);

    useEffect(() => {
        axios.get('https://ipapi.co/json/')
            .then(response => {
                const data = response.data;
                setLocation(data.country_name);
            })
            .catch(error => {
                console.log('Error:', error);
            });
    }, []);

    return (
        <Box backgroundColor="backgroundGrey.main" p={10}>
            <Container>
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                        <Typography onClick={() => navigate("/terms-and-conditions")} sx={{ mx: 5, cursor: "pointer" }} variant="caption">
                            {t("Terms And Conditions")}
                        </Typography>
                        <Typography onClick={() => navigate("/privacy-policy")} sx={{ mx: 5, cursor: "pointer" }} variant="caption">
                            {t("Privacy Policy")}
                        </Typography>
                    </Box>
                    {
                        location === 'Egypt' ?
                            <Typography variant="caption">
                                {t("This service is provided by")}
                                <span style={{ fontWeight: "bold", marginLeft: 3, marginRight: 3, paddingTop: 2 }}>Blue Points Creative Solutions</span>
                            </Typography>
                            :
                            <Typography variant="caption">{t("footer.allRightsReserved")}</Typography>
                    }
                </Box>
            </Container>
        </Box>
    )
}