import { Box, Container, Grid, InputAdornment, Paper, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { DataTable } from "modules/common";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { omit, omitBy } from "lodash";
import { Search } from "@mui/icons-material";
import { useGetMyInvoicesQuery } from "api/invoiceApi";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";

const $COLUMN_WIDTH = 200
export default function InvoicesList() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const theme = useTheme()

    const [page, setPage] = useState(1)
    const [params, setParams] = useState({
        page: 1,
        limit: 100,
        type: "CURRENT"
    })

    const {
        data: invoices, isLoading: invoicesLoaing, isFetching
    } = useGetMyInvoicesQuery(omitBy(params, v => !Boolean(v)))

    const pageChangeHandler = ({ page }) => {
        if (!isFetching) {
            setPage(page)
            setParams((prevParams) => ({ ...prevParams, page }))
        }
    }

    const [searchKey, setSearchKey] = useState(null)
    const timerRef = useRef(null)

    const onSearchKey = ({ target: { value } }) => {
        setSearchKey(value)
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
            if (!!value) {
                setParams(prev => ({ ...prev, searchKey: value }))
            } else {
                setParams(omit(params, ['searchKey']))
            }
        }, 1000);
    }


    const columns = () => {
        let columns = [
            { field: "invoiceNumber", sortable: false, headerName: t("Invoice Number"), width: $COLUMN_WIDTH * 1.1 },
            { field: "netAmount", sortable: false, headerName: t("Net amount"), width: $COLUMN_WIDTH * 1, },
            { field: "totalAmount", sortable: false, headerName: t("Total amount"), width: $COLUMN_WIDTH * 1, },
            { field: "bankAccountNumber", sortable: false, headerName: t("Bank account number"), width: $COLUMN_WIDTH * 1.2, },
        ];
        if (params?.type === "CURRENT") {
            columns.push({ field: "since", valueGetter: ({ row: { createdAt } }) => moment(createdAt).fromNow(), sortable: false, headerName: t("Since"), width: $COLUMN_WIDTH * 0.8 },)
        } else if (params?.type === "SETTLED") {
            columns.push({ field: "settleBalanceDate", valueGetter: ({ value }) => value ? moment(value).format("DD/MM/YY - h:mm A") : '', sortable: false, headerName: t("Settle balance date"), width: $COLUMN_WIDTH * 0.8 })
        }
        return columns
    }


    return (
        <>
            <Helmet>
                <title>{t("Invoices List")} | {$APP_NAME}</title>
            </Helmet>
            <Container>
                <Box display="flex" alignItems="center" my={10}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t("Invoices")}</Typography>
                </Box>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ mb: 10 }}>
                    <Grid item xl={4} lg={4} md={6} sm={10} xs={10}>
                        <TextField
                            fullWidth
                            label={t("Search")}
                            size="small"
                            value={searchKey ?? ''}
                            onChange={onSearchKey}
                            InputProps={
                                theme.direction === "rtl" ?
                                    {
                                        endAdornment: <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>,
                                    }
                                    :
                                    {
                                        startAdornment: <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>,
                                    }
                            }
                        />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={10} xs={10} sx={{ textAlign: "end" }}>
                        <ToggleButtonGroup
                            exclusive
                            color="secondary"
                            size="small"
                            value={params?.type}
                            onChange={(_, value) => {
                                console.log("value")
                                console.log(value)
                                setParams(prev => ({ ...prev, type: value ?? "CURRENT" }))
                            }}
                        >
                            <ToggleButton sx={{ textTransform: "none" }} value="CURRENT">
                                {t("Current Invoices")}
                            </ToggleButton>
                            <ToggleButton sx={{ textTransform: "none" }} value="SETTLED">
                                {t("Invoices History")}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>

                <Paper sx={{ height: "75vh" }}>
                    <DataTable
                        rows={invoices?.docs ?? []}
                        columns={columns()}
                        rowCount={invoices?.totalDocs ?? 0}
                        onPaginationModelChange={pageChangeHandler}
                        paginationModel={{ page: page - 1, pageSize: 100 }}
                        loading={invoicesLoaing || isFetching}
                        onRowClick={row => navigate(`/merchant/invoices/${row?.id}`)}
                    />
                </Paper>
            </Container>
        </>
    )
}