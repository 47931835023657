import { apiSlice } from "./apiSlice";

export const transactionApi = apiSlice
    .enhanceEndpoints({ addTagTypes: ['TRANSACTION'] })
    .injectEndpoints({
        keepUnusedDataFor: 1,
        endpoints: builder => ({
            getTransactions: builder.query({
                query: (params) => ({
                    url: `/transaction`,
                    method: 'GET',
                    params
                }),
                providesTags: (result, _, __) =>
                    result
                        ? [
                            ...result?.docs.map(({ id }) => ({ type: "TRANSACTION", id })),
                            { type: "TRANSACTION", id: "TRANSACTION-PARTIAL-LIST" },
                        ]
                        : [{ type: "TRANSACTION", id: "TRANSACTION-PARTIAL-LIST" }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getTransactionExcel: builder.query({
                query: (params) => ({
                    url: `/transaction/xls`,
                    method: 'GET',
                    params
                }),
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getTransactionById: builder.query({
                query: (id) => ({
                    url: `/transaction/${id}`,
                    method: 'GET',
                }),
                providesTags: (_, __, id) => [{ type: "TRANSACTION", id }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            createTransaction: builder.mutation({
                query: (payload) => ({
                    url: `/transaction`,
                    method: 'POST',
                    body: { ...payload }
                }),
                invalidatesTags: [{ type: "TRANSACTION", id: "TRANSACTION-PARTIAL-LIST" }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            editTransaction: builder.mutation({
                query: ({ id, payload }) => ({
                    url: `/transaction/${id}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: (_, __, { id }) => [
                    { type: "TRANSACTION", id },
                    { type: "TRANSACTION", id: "TRANSACTION-PARTIAL-LIST" },
                ],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            cancelTransaction: builder.mutation({
                query: (id) => ({
                    url: `/transaction/cancel/${id}`,
                    method: 'PUT',
                }),
                invalidatesTags: (_, __, id) => [
                    { type: "TRANSACTION", id },
                    { type: "TRANSACTION", id: "TRANSACTION-PARTIAL-LIST" },
                ],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getTransactionsForAdmin: builder.query({
                query: (params) => ({
                    url: '/transaction/admin',
                    method: 'GET',
                    params
                }),
                providesTags: (result, _, __) =>
                    result
                        ? [
                            ...result?.docs.map(({ id }) => ({ type: "TRANSACTION", id })),
                            { type: "TRANSACTION", id: "TRANSACTION-PARTIAL-LIST" },
                        ]
                        : [{ type: "TRANSACTION", id: "TRANSACTION-PARTIAL-LIST" }],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateTransactionStatusByAdmin: builder.mutation({
                query: ({ transactionId, payload }) => ({
                    url: `/transaction/admin/${transactionId}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: (_, __, { transactionId }) => [
                    { type: "TRANSACTION", id: transactionId },
                    { type: "TRANSACTION", id: "TRANSACTION-PARTIAL-LIST" },
                ],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            requestTransactionsXls: builder.query({
                query: (params) => ({
                    url: `/transaction/admin/xls`,
                    method: 'GET',
                    params
                }),
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            refundTransaction: builder.mutation({
                query: (id) => ({
                    url: `/transaction/${id}/refund`,
                    method: 'PATCH',
                }),
                invalidatesTags: (_, __, id) => [
                    { type: "TRANSACTION", id },
                    { type: "TRANSACTION", id: "TRANSACTION-PARTIAL-LIST" },
                ],
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
        })
    })

export const {
    useGetTransactionsQuery,
    useGetTransactionExcelQuery,
    useLazyGetTransactionExcelQuery,
    useGetTransactionByIdQuery,
    useLazyGetTransactionByIdQuery,
    useCreateTransactionMutation,
    useEditTransactionMutation,
    useCancelTransactionMutation,
    useGetTransactionsForAdminQuery,
    useUpdateTransactionStatusByAdminMutation,
    useLazyRequestTransactionsXlsQuery,
    useRefundTransactionMutation,
} = transactionApi