import { LoadingButton } from "@mui/lab";
import { Box, Container, CssBaseline, Link, TextField, Typography, Grid, Button } from "@mui/material";
import { useMerchantLoginMutation } from "api/authApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsAuth, setProfile, setToken } from "store/authSlice";
import runJoiValidate from "utils/runJoiValidate";
import { USER_LOGIN_SCHEMA } from "./user-auth-validation-schemas";
import { startSocket } from "socket"
import { PhoneInput } from "modules/common";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { Footer, QuickLinks } from "modules/landing/screens/common";

export default function MerchantLogin() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [login, { isLoading, error }] = useMerchantLoginMutation()
    const [user, setUser] = useState({
        phone: '',
        password: ''
    })
    const [validationErrors, setValidationErrors] = useState(null)

    const handleChange = ({ target: { name, value } }) => {
        setUser(prevUser => ({
            ...prevUser,
            [name]: value
        }))
    }
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setValidationErrors(null)
            const errors = runJoiValidate(USER_LOGIN_SCHEMA, user)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }
            let response = await login(user).unwrap()
            const userData = response?.body?.user
            if (!userData?.phoneVerified) {
                navigate(`/auth/phone-verify?id=${userData?.id}&phone=${userData?.phone}&email=${userData?.email}`)
                return
            }

            dispatch(setProfile(response?.body?.user))
            dispatch(setToken(response?.body?.token))
            dispatch(setIsAuth(true))

            startSocket({ userId: userData?.id, jwt: response?.body?.token })
            navigate("/merchant")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>{t("Login")} | {$APP_NAME}</title>
            </Helmet>
            <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
                <Container sx={{ padding: 16 }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 50,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h3" color="grey" sx={{ mb: 50 }}>
                            {t("Log in")}
                        </Typography>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={8} md={5} lg={4} xl={4}>
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                    <PhoneInput
                                        label={t("Phone")}
                                        name="phone"
                                        value={user?.phone}
                                        onChange={handleChange}
                                        fullWidth
                                        error={validationErrors?.phone}
                                    />
                                    <TextField
                                        value={user?.password}
                                        onChange={handleChange}
                                        size="small"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={t("Password")}
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        error={!!validationErrors?.password}
                                        helperText={validationErrors?.password}
                                    />
                                    <LoadingButton
                                        type="submit"
                                        loading={isLoading}
                                        fullWidth
                                        variant="contained"
                                        sx={{ my: 15 }}
                                    >
                                        {t("Log In")}
                                    </LoadingButton>
                                    {!!error &&
                                        <Box textAlign="center">
                                            <Typography color="error">{t(error)}</Typography>
                                        </Box>
                                    }
                                    <Grid container>
                                        <Grid item xs>
                                            <Button sx={{ textTransform: "none" }} onClick={() => navigate("/auth/forgot-password")}>
                                                {t("Forgot password?")}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button sx={{ textTransform: "none" }} onClick={() => navigate("/auth/register")}>
                                                {t("Don't have an account? Sign Up")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 18, mb: 4 }}>
                        {'Copyright © '}
                        <Link color="inherit" href="/">
                            EzPay
                        </Link>{' '}
                        {new Date().getFullYear()}
                    </Typography>
                </Container>
                <Box>
                    <QuickLinks allowSocial={true} />
                    <Footer />
                </Box>
            </Box>
        </>
    )
}