import { LoadingButton } from '@mui/lab';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Box, Typography } from '@mui/material';
import { PhoneInput } from 'modules/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import runJoiValidate from 'utils/runJoiValidate';
import { cloneDeep, omitBy, pick} from 'lodash';
import { ADD_USER_SCHEMA } from './user-validation-schemas';

export default function UserDialog({ open, onClose, loading, isSuccess, error, onCreate }) {
    const { t } = useTranslation()

    const [validationErrors, setValidationErrors] = useState(null)
    const [user, setUser] = useState({
        userName: '',
        phone: '',
        password: '',
    })

    const handleChange = ({ target: { name, value } }) => {
        setUser((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const onCloseHandler = () => {
        onClose();
    };

    const createUserHandler = () => {
        try {
            const SubAccount = [ "userName", "phone", "password"]
            setValidationErrors(null)

            let payload = cloneDeep(pick(user, SubAccount))
            payload = omitBy(payload, v => !Boolean(v));

            const errors = runJoiValidate(ADD_USER_SCHEMA, payload)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }
            onCreate(payload)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (open === false) {
            setUser({
                userName: '',
                phone: '',
                password: '',
            })
        }
        setValidationErrors(null)
    }, [open])

    return (
        <Dialog fullWidth open={open} onClose={(e) => e.preventDefault()}>
            <DialogTitle>{t("Add new user")}</DialogTitle>
            <Divider />
            <DialogContent>
                <TextField
                    name="userName"
                    value={user?.userName}
                    onChange={handleChange}
                    margin="normal"
                    label={t("Name")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!validationErrors?.userName}
                    helperText={validationErrors?.userName}
                />
                <PhoneInput
                    label={t("Phone")}
                    name="phone"
                    value={user?.phone}
                    onChange={handleChange}
                    fullWidth
                    error={validationErrors?.phone}
                />
                <TextField
                    name="password"
                    type="password"
                    label={t("Password")}
                    value={user?.password}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 7 }}
                    inputProps={{
                        autoComplete: "off"
                    }}
                    error={!!validationErrors?.password}
                    helperText={validationErrors?.password}
                />
            </DialogContent>
            <Divider />
            <DialogActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" onClick={onCloseHandler} sx={{ mr: 5, textTransform: "none" }}>{t("Cancel")}</Button>
                    <LoadingButton sx={{ textTransform: "none" }} variant="contained" loading={loading} onClick={createUserHandler}>
                        {t("Add")}
                    </LoadingButton>
                </Box>
                {!!error && <Typography color="error">{t(error)}</Typography>}
                {!!isSuccess && <Typography color="success.main">{t("Added successfully")}</Typography>}
            </DialogActions>
        </Dialog>
    );
}