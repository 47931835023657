import { Helmet } from 'react-helmet';
import { $APP_NAME } from "constants";
import { Box, Container, useTheme } from '@mui/material';
import { useParams } from "react-router-dom";
import { get } from 'lodash';
import { PostHeaderView } from './components';
import './BlogPost.css';
import { replace } from 'lodash';
import SocialShareHorizontal from './components/SocialShareHorizontal';
import { useGetBlogPostBySlugQuery } from 'api/blogApi';

const BlogPost = () => {

    const theme = useTheme();
    const { slug } = useParams();
    const { data: post, isLoading: loading } = useGetBlogPostBySlugQuery(slug)

    return (
        <>
            <Helmet>
                <title>
                    {get(post, theme?.direction === "rtl" ? 'title_ar' : 'title_en') || `${$APP_NAME} Blog`}
                </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "white",
                    flexGrow: 1
                }}
            >
                <Container
                    maxWidth="md"
                    sx={{
                        px: { xs: 4 },
                        mt: 8
                    }}
                >
                    {
                        loading
                            ?
                            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", py: 10 }}>
                            </Box>
                            :
                            <>
                                <PostHeaderView
                                    id={get(post, 'id')}
                                    image={get(post, 'image')}
                                    title={get(post, 'title_ar')}
                                    createdAt={get(post, 'createdAt')}
                                    sx={{ mb: 4 }}
                                />
                                <div dangerouslySetInnerHTML={{ __html: replace(get(post, 'description_ar'), '<img ', `<img class="blogImage"`) }} />
                                <SocialShareHorizontal link={`${window.location.origin}/blog/${post?.id}`} sx={{ zIndex: 5, marginTop: 3, marginBottom: 8 }} />
                            </>
                    }
                </Container>
            </Box>
        </>
    );
}

export default BlogPost;