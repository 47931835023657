import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { get, map } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * timeline is an array of steps, each step will have the following structure:
 *  {
 *      "dateTime": "2024-04-15T09:14:05.797Z",
 *      "_id": "661cefde20b0c4151bbb14f1",
 *      "status": "ACCOUNT_CREATED"
 *  }
 */

/**
 * colors is an object of color mappings for step statuses, for example:
 *  {
 *      CANCELED: "info", 
 *      EXPIRED: "default", 
 *      FAILED: "error", 
 *      IN_PROGRESS: "warning", 
 *      PAID: "success"
 *  }
 */

export default function StatusTimeline({ timeline, icons, localePath, sx, ...rest }) {

    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', ...sx }} {...rest}>
            <Stepper activeStep={(timeline?.length ?? 0) - 1} orientation="vertical" >
                {
                    map(
                        timeline, (step) => (
                            <Step key={step?._id}>
                                <StepLabel
                                    sx={{ paddingBlock: 0 }}
                                    StepIconComponent={_ => get(icons, step.status)}
                                    optional={
                                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                            {t(localePath ? `${localePath}.${step?.status}` : step?.status)}
                                            <Typography display="inline-block" variant="caption" sx={{ marginInlineStart: 4 }}>
                                                {`- ${moment(step.dateTime).format("D MMM YYYY - hh:mm A")}`}
                                            </Typography>
                                        </Typography>}
                                />
                            </Step>
                        )
                    )
                }
            </Stepper>
        </Box>
    );
}