import { Info } from "@mui/icons-material";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { ROLES } from "constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function InvoicePaymentInfo({ invoice }) {
    const { t } = useTranslation()
    const { profile } = useSelector(state => state?.auth)

    return (
        <Grid container spacing={20} sx={{ my: 10 }}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Total invoice")}</Typography>
                    <Typography>{invoice?.totalAmount?.toFixed(2)}</Typography>
                </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box display="flex">
                    <Tooltip
                        title={
                            profile?.role === ROLES.admin ?
                                `${t("Total invoice")} - ${t("Payment gateway fees includes tax")} - ${t("Ezpay fees includes tax")} = ${t("Net Profit")}`
                                :
                                `${t("Total invoice")} - ${t("Ezpay fees includes tax")} = ${t("Net Profit")}`
                        }
                        placement="top"
                        color="primary"
                        sx={{ mr: 5 }}
                    >
                        <Info />
                    </Tooltip>
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Net Profit")}</Typography>
                    <Typography>{invoice?.netAmount?.toFixed(2)}</Typography>
                </Box>
            </Grid>
            {
                profile?.role === ROLES.admin ?
                    <>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box display="flex">
                                <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Ezpay fees includes tax")}</Typography>
                                <Typography>{invoice?.ezpayFees?.toFixed(2)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box display="flex">
                                <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Payment gateway fees includes tax")}</Typography>
                                <Typography>{invoice?.paymentGatewayFees?.toFixed(2)}</Typography>
                            </Box>
                        </Grid>
                    </>
                    :
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box display="flex">
                            <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Ezpay fees includes tax")}</Typography>
                            <Typography>{(Number(invoice?.ezpayFees) + Number(invoice?.paymentGatewayFees))?.toFixed(2)}</Typography>
                        </Box>
                    </Grid>
            }
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box display="flex">
                    <Typography sx={{ fontWeight: "bold", mr: 20 }}>{t("Settle balance date")}</Typography>
                    <Typography>{!!invoice?.settleBalanceDate ? moment(invoice?.settleBalanceDate).format("DD/MM/YY - h:mm A") : "----"}</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}