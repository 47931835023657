import * as Joi from "joi";
import i18n from "i18n";

export const ADMIN_LOGIN_SCHEMA = Joi.object({
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required("required")
        .label(i18n.t("Email")).messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
            'string.email': `${i18n.t("validationErrors.isEmail")}`,
        }),
    password: Joi.string()
        .required()
        .min(6)
        .label(i18n.t("Password")).messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
            'string.min': `{#label} ${i18n.t("validationErrors.minChar", { length: 6 })}`,
        }),
});
