import { LoadingButton } from "@mui/lab";
import { Box, Container, CssBaseline, Link, TextField, Typography, Grid } from "@mui/material";
import { useResetForgottenPasswordMutation } from "api/authApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import runJoiValidate from "utils/runJoiValidate";
import { Logo } from "_assets";
import { RESET_PASSWORD_SCHEMA } from "./user-auth-validation-schemas";
import { omit } from "lodash";
import { $APP_NAME } from "constants";
import { Helmet } from "react-helmet";

export default function MerchantLogin() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams();
  const phone = searchParams.get('phone')

  const [resetForgottenPassword, { isLoading, error }] = useResetForgottenPasswordMutation()

  const [userPassword, setUserPassword] = useState({
    password: '',
    password_repeat: '',
    resetPasswordCode: ''
  })

  const [validationErrors, setValidationErrors] = useState(null)

  const handleChange = ({ target: { name, value } }) => {
    setUserPassword(prevUser => ({
      ...prevUser,
      [name]: value
    }))
  }

  const handleSubmit = async (event) => {
    try {

      event.preventDefault();
      setValidationErrors(null)

      const errors = runJoiValidate(RESET_PASSWORD_SCHEMA, userPassword)

      if (!!errors) {
        setValidationErrors(errors)
        return
      }

      await resetForgottenPassword({
        ...omit(userPassword, "password_repeat"),
        phone: `+${phone}`.replace(/\s/g, "")
      }).unwrap()

      setTimeout(() => navigate("/auth/login"), 2000);
    } catch (error) {
      console.log(error)
    }

  }

  return (
    <>
      <Helmet>
        <title>{t("Reset Password")} | {$APP_NAME}</title>
      </Helmet>
      <Container sx={{ padding: 16 }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="a" href="/" sx={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }} >
            <img
              alt='white-logo'
              height="auto"
              src={Logo}
              style={{ cursor: "pointer", width: 100 }}
            />
          </Box>
          <Grid container justifyContent="center" alignItems="center" sx={{ textAlign: "center", my: 10 }}>
            <Grid item xs={12} sm={8} md={5} lg={4} xl={4}>
              <Typography sx={{ fontWeight: "bold" }} color="secondary">
                {t("Reset Your Password")}
              </Typography>
              <Typography>
                {t("Enter your phone number and you will receive a code to verify your password reset.")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={8} md={5} lg={4} xl={4}>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  name="resetPasswordCode"
                  value={userPassword?.resetPasswordCode}
                  onChange={handleChange}
                  margin="normal"
                  label={t("Verification Code")}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  error={!!validationErrors?.resetPasswordCode}
                  helperText={validationErrors?.resetPasswordCode}
                />
                <TextField
                  value={userPassword?.password}
                  onChange={handleChange}
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={!!validationErrors?.password}
                  helperText={validationErrors?.password}
                />
                <TextField
                  name="password_repeat"
                  type="password"
                  label={t("Confirm Password")}
                  value={userPassword?.password_repeat}
                  onChange={handleChange}
                  size="small"
                  variant="outlined"
                  fullWidth
                  sx={{ my: 7 }}
                  error={!!validationErrors?.password_repeat}
                  helperText={validationErrors?.password_repeat}
                />
                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  fullWidth
                  variant="contained"
                  sx={{ my: 15, textTransform: "none" }}
                >
                  {t("Confirm")}
                </LoadingButton>
                {!!error &&
                  <Box textAlign="center">
                    <Typography color="error">{t(error)}</Typography>
                  </Box>
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 18, mb: 4 }}>
          {'Copyright © '}
          <Link color="inherit" href="">
            EzPay
          </Link>{' '}
          {new Date().getFullYear()}
        </Typography>
      </Container>
    </>
  )
}