import React, { useEffect, useState } from "react";
import { TextField, Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography, useMediaQuery, useTheme, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { cloneDeep, pick } from "lodash";
import { APP_VERSION_SCHEMA } from "./app-versions-validation-schemas";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "modules/common";
import { runJoiValidate } from "utils";
import { LoadingButton } from "@mui/lab";

export default function AppVersionDialog({
    openAppVersionDialog,
    loading,
    appVersion,
    handleChange,
    onAddAppVersion,
    onUpdateAppVersion,
    onDeleteAppVersion,
    onCloseDialog,
    error,
    isSuccess,
    isEditing
}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

    const [validationErrors, setValidationErrors] = useState(null)

    const handleSubmit = () => {
        setValidationErrors(null)
        let payload = pick(cloneDeep(appVersion), ["platform", "version", "changeLog"])
        const errors = runJoiValidate(APP_VERSION_SCHEMA, payload)
        if (!!errors) {
            setValidationErrors(errors)
            return
        }

        if (isEditing) {
            onUpdateAppVersion({ id: appVersion?.id, payload })
        } else {
            onAddAppVersion(payload)
        }
    }

    const onCloseDialogHandler = () => {
        onCloseDialog()
        setValidationErrors(null)
    }

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                onCloseDialogHandler()
            }, 2000);
        }
    }, [isSuccess])

    return (
        <Dialog
            maxWidth="md"
            dir={theme.direction}
            fullWidth
            fullScreen={fullScreen}
            open={openAppVersionDialog}
            aria-labelledby="appVersion-dialog-title"
        >
            <ConfirmationDialog
                title="Warning"
                open={openConfirmDialog}
                confirmFn={() => onDeleteAppVersion(appVersion?.id)}
                onClose={() => setOpenConfirmDialog(false)}
                content={"Are you sure you want to delete this App Version?"}
            />
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {t(isEditing ? "Edit App Version" : "Add App Version")}
                <IconButton onClick={onCloseDialogHandler} color="primary">
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>

                <FormControl margin="dense" size="small" fullWidth>
                    <InputLabel id="paltform-label">{t("Platform")}</InputLabel>
                    <Select
                        labelId="paltform-label"
                        id="paltform"
                        name="platform"
                        value={appVersion?.platform}
                        label="Platform"
                        onChange={handleChange}
                    >
                        <MenuItem value="ANDROID">{t("Android")}</MenuItem>
                        <MenuItem value="IOS">{t("IOS")}</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    name="version"
                    value={appVersion?.version ?? ''}
                    onChange={handleChange}
                    fullWidth
                    error={!!validationErrors?.version}
                    helperText={validationErrors?.version ?? ''}
                    label={t("Version")}
                    size="small"
                    variant="outlined"
                />
                <TextField
                    margin="dense"
                    name="changeLog"
                    value={appVersion?.changeLog ?? ''}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label={t("Change Log")}
                    inputProps={{ maxLength: 255 }}
                    error={!!validationErrors?.changeLog}
                    helperText={validationErrors?.changeLog ?? ''}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Box mr="auto" />
                {isSuccess && <Typography color="success.main">{t("Done successfully")}</Typography>}
                {!!error && <Typography color="error">{error}</Typography>}
                <Box mr="auto" />
                {
                    isEditing &&
                    <LoadingButton
                        disabled={isSuccess}
                        variant="contained"
                        disableElevation
                        onClick={() => setOpenConfirmDialog(true)}
                        loading={loading}
                        sx={{ textTransform: "none" }}
                        color="error"
                    >
                        {t("Delete")}
                    </LoadingButton>
                }
                <LoadingButton
                    disabled={isSuccess}
                    variant="contained"
                    disableElevation
                    onClick={handleSubmit}
                    loading={loading}
                    sx={{ textTransform: "none", ml: 1 }}
                >
                    {t(isEditing ? "Edit" : "Add")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}