import { Stack, IconButton } from "@mui/material";
import { Tiktok, Twitter, Instagram, Location } from "_assets";

import Icon from "./Icon";

export default function Social({ links, ...props }) {
    if (!links)
        return null

    const { tiktok, twitter, instagram, location } = links;

    return (
        <Stack {...props}>
            <Stack direction={"row"} sx={{ justifyContent: 'center' }}>
                {
                    tiktok &&
                    <IconButton size="small" onClick={_ => window.open(tiktok, "_blank")} >
                        <Icon source={Tiktok} />
                    </IconButton>
                }
                {
                    twitter &&
                    <IconButton size="small" onClick={_ => window.open(twitter, '_blank')}>
                        <Icon source={Twitter} />
                    </IconButton>
                }
                {
                    instagram &&
                    <IconButton size="small" onClick={_ => window.open(instagram, '_blank')}>
                        <Icon source={Instagram} />
                    </IconButton>
                }
                {
                    location &&
                    <IconButton size="small" onClick={_ => window.open(location, '_blank')}>
                        <Icon source={Location} />
                    </IconButton>
                }
            </Stack>
            {/* <Button component={Link} onClick={() => navigate("/")}></Button> */}
            {/* <Link href="/" sx={{mt: 2, textAlign: "center"}}>
                <Typography variant="button" sx={{fontWeight: "bold"}}>{t("Show menu")}</Typography>
            </Link> */}
        </Stack>
    )
};