import * as Joi from "joi";
import i18n from "i18n";
import { joiString } from "utils/joi";

export const CREATE_USER_SCHEMA = Joi.object({
    isParent: Joi.boolean(),
    parent: joiString.string().allow(null).empty(''),
    userName: joiString.string().label(i18n.t("Name")).required().empty(''),
    phone: joiString.string().label(i18n.t("Phone")).required().empty(''),
    password: joiString.string().label(i18n.t("Password")).min(5).required(),
    businessName: Joi.when('isParent', {
        is: false,
        then: Joi.string(),
        otherwise: joiString.string()
            .required()
            .label(i18n.t("Business name"))
            .empty(''),
    }),
    country: Joi.when('isParent', {
        is: false,
        then: Joi.string(),
        otherwise: joiString.string()
            .required()
            .label(i18n.t("Country"))
            .empty(''),
    }),
    email: Joi.when('isParent', {
        is: false,
        then: joiString.string().email({ tlds: { allow: false } }).trim(),
        otherwise: joiString.string()
            .email({ tlds: { allow: false } })
            .trim()
            .required()
            .label(i18n.t("Email"))
            .empty('')
        ,
    }),
    logo: joiString.string()
        .min(3)
        .empty('')
        .label(i18n.t("Logo")),
    civilId: joiString.string()
        .empty('')
        .label(i18n.t("Civil ID")),
    civilIdBack: joiString.string()
        .empty('')
        .label(i18n.t("Civil ID Back")),
    otherDocument: joiString.string()
        .empty('')
        .label(i18n.t("Other")),
    commercialRegister: joiString.string()
        .empty('')
        .label(i18n.t("Commercial Register")),
    bankAccountLetter: joiString.string()
        .empty('')
        .label(i18n.t("Bank Account Letter")),
    articlesOfAssociation: joiString.string()
        .empty('')
        .label(i18n.t("Articles Of Association")),
    signatureAuthorization: joiString.string()
        .empty('')
        .label(i18n.t("Signature Authorization")),
    commercialLicense: joiString.string()
        .empty('')
        .label(i18n.t("Commercial License")),
    website: joiString.string()
        .empty('')
        .label(i18n.t("Website")),
})