import { apiSlice } from "./apiSlice";

export const settingsApi = apiSlice.injectEndpoints({
    keepUnusedDataFor: 1,
    endpoints: builder => ({
        getSettings: builder.query({
            query: () => ({
                url: `/setting`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        updateDefaultPricing: builder.mutation({
            query: (payload) => ({
                url: `/setting/default-pricing`,
                method: 'PUT',
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        updateSettings: builder.mutation({
            query: (payload) => ({
                url: `/setting`,
                method: 'PUT',
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        updateExpiryAt: builder.mutation({
            query: (payload) => ({
                url: `/setting/expiry-at`,
                method: 'PUT',
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useGetSettingsQuery,
    useUpdateSettingsMutation,
    useUpdateDefaultPricingMutation,
    useUpdateExpiryAtMutation,
} = settingsApi