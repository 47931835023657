import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { AppStoreBadgeArabic, AppStoreBadgeEnglish, PlayStoreBadgeArabic, PlayStoreBadgeEnglish } from "_assets";
import { useSelector } from "react-redux";

import { Social } from "./components";
import { useGetSettingsQuery } from "api/settingsApi";
import { useMemo } from "react";
import { pick } from "lodash";
import { useTranslation } from "react-i18next";
import { LocationOn } from "@mui/icons-material";

export default function QuickLinks({ allowSocial, ...props }) {

    const { t } = useTranslation();
    const downLg = useMediaQuery(theme => theme.breakpoints.down('md'));

    const { language } = useSelector(state => state?.lang)

    const { data: settings } = useGetSettingsQuery()

    const socialLinks = useMemo(() => {
        if (!!settings) {
            return pick(settings, "twitter", "snapchat", "instagram", "youtube", "linkedin")
        }
        return {}
    }, [settings])

    return (
        <Container {...props}>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBlock: 24 }}>
                {
                    allowSocial ?
                        <Grid item md={4} xs={12}>
                            <Box display="flex" flexDirection='column' alignItems={downLg ? "center" : "flex-start"} pb={downLg ? 20 : 0}>
                                <Box display="flex" flexDirection='row'>
                                    <LocationOn sx={{ fontSize: 18, marginInlineEnd: 4, marginBlockStart: 4, display: 'block' }} />
                                    <Typography variant="subtitle" sx={{ whiteSpace: "pre-wrap", }}>
                                        {t("landing.quickLinks.address")}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        : null
                }
                <Grid item md={4} xs={12}>
                    <Box display="flex" justifyContent={!allowSocial && !downLg ? "flex-start" : "center"} alignItems="center" pb={downLg ? 20 : 0}>
                        <img
                            onClick={() => window.open("https://play.google.com/store/apps/details?id=com.ezpay.ezpay")}
                            src={language === "ar-SA" ? PlayStoreBadgeArabic : PlayStoreBadgeEnglish}
                            alt="Ezpay Play Store"
                            height={44}
                            width={135}
                            style={{ objectFit: "contain", borderRadius: "5px", cursor: "pointer" }}
                        />
                        <Box sx={{ marginInline: 5 }} />
                        <img
                            onClick={() => window.open("https://apps.apple.com/eg/app/ezpay-%D8%A5%D9%8A%D8%B2%D9%8A-%D8%A8%D8%A7%D9%8A/id6476637112")}
                            src={language === "ar-SA" ? AppStoreBadgeArabic : AppStoreBadgeEnglish}
                            alt="Ezpay App Store"
                            height={44}
                            width={135}
                            style={{ objectFit: "contain", borderRadius: "5px", cursor: "pointer" }}
                        />
                    </Box>
                </Grid>
                {
                    !!allowSocial
                        ?
                        <Grid item md={4} xs={12}>
                            <Social
                                direction={"row"}
                                sx={{ justifyContent: downLg ? 'center' : 'end' }}
                                links={{ ...socialLinks }}
                            />
                        </Grid>
                        : null
                }
            </Grid>
        </Container >
    )
}