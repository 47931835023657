import { apiSlice } from "./apiSlice";

export const email = apiSlice.injectEndpoints({
    endpoints: builder => ({
        sendInvoiceByEmail: builder.mutation({
            query: body => ({
                url: `/transaction/send-email`,
                method: 'POST',
                body
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useSendInvoiceByEmailMutation
} = email