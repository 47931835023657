import { Divider, Drawer, List, ListItem, ListItemButton, ListItemText, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const DrawerListItemButton = styled(ListItemButton)(({ theme }) => ({
    textTransform: "none",
    fontWeight: 500,
    fontSize: "1rem",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    '&:hover': {
        color: theme.palette.secondary.main,
    },
}));


export default function LandingDrawer({ open, toggleDrawer, blogPosts }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const goTo = ({ id, top, route }) => {
        toggleDrawer()
        if (!!route && route !== location?.pathname) {
            navigate(route)
            if (!!id) {
                setTimeout(() => {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: top ? 0 : document.getElementById(id).getBoundingClientRect().top + window.scrollY - 50
                    })
                }, 1000);
            }
        } else if (!!id) {
            window.scrollTo({
                behavior: 'smooth',
                top: top ? 0 : document.getElementById(id).getBoundingClientRect().top + window.scrollY - 50
            })
        }
    }

    return (
        <Drawer
            variant="temporary"
            open={open}
            onClose={toggleDrawer}
            sx={{
                width: 260,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: 260, boxSizing: 'border-box' },
            }}
        >
            <Divider />
            <List>
                <ListItem disablePadding>
                    <DrawerListItemButton onClick={() => goTo({ id: 'home', top: true, route: "/" })}>
                        <ListItemText primary={t("landing.home")} />
                    </DrawerListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <DrawerListItemButton onClick={() => goTo({ id: "about-us", route: "/" })}>
                        <ListItemText primary={t("landing.aboutUs")} />
                    </DrawerListItemButton>
                </ListItem>
                {
                    !window?.location?.href?.includes("ezpayuae")
                        ?
                        <ListItem disablePadding>
                            <DrawerListItemButton onClick={() => { toggleDrawer(); navigate("/pricing") }}>
                                <ListItemText primary={t("landing.pricing.title")} />
                            </DrawerListItemButton>
                        </ListItem>
                        : null
                }
                <ListItem disablePadding>
                    <DrawerListItemButton onClick={() => { toggleDrawer(); navigate("/contact-us") }}>
                        <ListItemText primary={t("landing.contactUs")} />
                    </DrawerListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <DrawerListItemButton onClick={() => { toggleDrawer(); navigate("/documentation") }}>
                        <ListItemText primary={t("Documentation")} />
                    </DrawerListItemButton>
                </ListItem>
                {
                    !!blogPosts?.docs?.length &&
                    <ListItem disablePadding>
                        <DrawerListItemButton onClick={() => { toggleDrawer(); navigate("/blog") }}>
                            <ListItemText primary={t("Blog")} />
                        </DrawerListItemButton>
                    </ListItem>
                }
                <ListItem disablePadding onClick={() => { toggleDrawer(); navigate("/auth/login") }}>
                    <DrawerListItemButton>
                        <ListItemText primary={t("landing.login")} />
                    </DrawerListItemButton>
                </ListItem>
            </List>
        </Drawer>
    )
}