import { apiSlice } from "./apiSlice";

export const authApiSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['PROFILE'] })
    .injectEndpoints({
        endpoints: builder => ({
            login: builder.mutation({
                query: credentials => ({
                    url: '/user/admin/login',
                    method: 'POST',
                    body: { ...credentials }
                }),
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            merchantLogin: builder.mutation({
                query: credentials => ({
                    url: '/user/login',
                    method: 'POST',
                    body: { ...credentials }
                }),
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            merchantRegister: builder.mutation({
                query: (payload) => ({
                    url: '/user/register',
                    method: 'POST',
                    body: { ...payload }
                }),
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            veifyEmail: builder.mutation({
                query: (payload) => ({
                    url: '/user/verify-mail',
                    method: 'POST',
                    body: { ...payload }
                }),
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            veifyPhone: builder.mutation({
                query: (payload) => ({
                    url: '/user/verify-phone',
                    method: 'POST',
                    body: { ...payload }
                }),
                transformResponse: (response, meta, arg) => response?.body,
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            forgetPassword: builder.mutation({
                query: (payload) => ({
                    url: '/user/forget-password',
                    method: 'POST',
                    body: { ...payload }
                }),
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            resetForgottenPassword: builder.mutation({
                query: (payload) => ({
                    url: '/user/reset-forgotten-password',
                    method: 'PUT',
                    body: { ...payload }
                }),
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updatePhoneAndSendVerifyCode: builder.mutation({
                query: (payload) => ({
                    url: '/user/update-phone',
                    method: 'PUT',
                    body: { ...payload }
                }),
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            getProfile: builder.query({
                query: () => ({
                    url: '/user/profile',
                    method: 'GET',
                }),
                providesTags: ['PROFILE'],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateProfile: builder.mutation({
                query: (payload) => ({
                    url: '/user/profile',
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ['PROFILE'],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
            updateProfilePassword: builder.mutation({
                query: (payload) => ({
                    url: '/user/update-password',
                    method: 'PUT',
                    body: { ...payload }
                }),
                invalidatesTags: ['PROFILE'],
                transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
            }),
        })
    })

export const {
    useLoginMutation,
    useLazyGetProfileQuery,
    useGetProfileQuery,
    useMerchantLoginMutation,
    useUpdateProfileMutation,
    useUpdateProfilePasswordMutation,
    useMerchantRegisterMutation,
    useForgetPasswordMutation,
    useResetForgottenPasswordMutation,
    useUpdatePhoneAndSendVerifyCodeMutation,
    useVeifyEmailMutation,
    useVeifyPhoneMutation
} = authApiSlice