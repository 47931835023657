import { LoadingButton } from '@mui/lab'
import { Box, Card, CardActions, CardContent, CardHeader, Divider, TextField, Typography } from '@mui/material'
import { useLazyGetThirdPartyByAdminQuery, useLazyGetThirdPartyQuery, useUpdateThirdPartyByAdminMutation, useUpdateThirdPartyMutation } from 'api/thirdPartyApi'
import { ROLES } from 'constants';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { THIRD_PARTY_SCHEMA } from '../profile-validation-schemas';
import { runJoiValidate } from 'utils';
import { pick } from 'lodash';

export default function WebhookCard({ id, allowUpdate }) {
    const { t } = useTranslation()
    const { profile } = useSelector(state => state?.auth);

    const [getThirdPartyByAdmin] = useLazyGetThirdPartyByAdminQuery()
    const [getThirdParty] = useLazyGetThirdPartyQuery()

    const [updateThirdParty, { isSuccess: updateThirdPartyIsSuccess, updateThirdPartyLoading, error: updateThirdPartyError }] = useUpdateThirdPartyMutation()
    const [updateThirdPartyByAdmin, { isSuccess: updateThirdPartyAdminIsSuccess, updateThirdPartyAdminLoading, error: updateThirdPartyAdminError }] = useUpdateThirdPartyByAdminMutation()

    const [data, setData] = useState({
        name: '',
        webhookUrl: '',
        redirectUrl: '',
    })

    const handleChange = ({ target: { name, value } }) => {
        setData(prev => ({ ...prev, [name]: value }))
    }

    const [validationErrors, setValidationErrors] = useState(null)
    const updateThirdPartyHandler = async () => {
        try {
            setValidationErrors(null)
            const errors = runJoiValidate(THIRD_PARTY_SCHEMA, data)
            console.log('data', data)
            console.log('errors', errors)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }

            if (profile?.role === ROLES.admin) {
                await updateThirdPartyByAdmin({ userId: id, payload: data }).unwrap()
                return
            }
            await updateThirdParty(data).unwrap()

        } catch (error) {
            console.log('error', error)
        }
    }

    const getThirdPartyHandler = async () => {
        try {
            let result = {
                name: '',
                webhookUrl: '',
                redirectUrl: '',
            }

            if (profile?.role === ROLES.admin) {
                if (!!id) {
                    result = await getThirdPartyByAdmin(id).unwrap()
                }
            } else {
                result = await getThirdParty().unwrap()
            }

            if (!!result?.length) {
                setData(pick(result[0], ['name', 'webhookUrl', 'redirectUrl']))
            }

        } catch (error) { }
    }

    useEffect(() => {
        if (!!profile) {
            getThirdPartyHandler()
        }
    }, [profile, id])

    return (
        <Card>
            <CardHeader title={t("Third Party Urls")} />
            <Divider />
            <CardContent>
                <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    size="small"
                    label={t("Name")}
                    name="name"
                    value={data?.name ?? ''}
                    inputProps={{
                        maxlength: 90
                    }}
                    onChange={handleChange}
                    required={true}
                    error={!!validationErrors?.name}
                    helperText={validationErrors?.name ?? ''}
                    disabled={!allowUpdate}
                />
                <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    size="small"
                    label={t("Webhook Url")}
                    name="webhookUrl"
                    value={data?.webhookUrl ?? ''}
                    inputProps={{
                        maxlength: 255
                    }}
                    onChange={handleChange}
                    required={true}
                    error={!!validationErrors?.webhookUrl}
                    helperText={validationErrors?.webhookUrl ?? ''}
                    disabled={!allowUpdate}
                />
                <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    size="small"
                    label={t("Redirect Url")}
                    name="redirectUrl"
                    value={data?.redirectUrl ?? ''}
                    inputProps={{
                        maxlength: 255
                    }}
                    onChange={handleChange}
                    required={true}
                    error={!!validationErrors?.redirectUrl}
                    helperText={validationErrors?.redirectUrl ?? ''}
                    disabled={!allowUpdate}
                />
            </CardContent>
            {
                allowUpdate
                    ?
                    <>
                        <Divider />
                        <CardActions>
                            {(updateThirdPartyIsSuccess || updateThirdPartyAdminIsSuccess) && <Typography color="success.main">{t("Done successfully")}</Typography>}
                            {(!!updateThirdPartyError || !!updateThirdPartyAdminError) && <Typography color="error">{updateThirdPartyError || updateThirdPartyAdminError}</Typography>}
                            <Box mr="auto" />
                            <LoadingButton
                                onClick={updateThirdPartyHandler}
                                loading={updateThirdPartyLoading || updateThirdPartyAdminLoading}
                                variant="contained"
                                sx={{ textTransform: "none" }}
                            >
                                {t("Submit")}
                            </LoadingButton>
                        </CardActions>
                    </>
                    : null
            }
        </Card>
    )
}
