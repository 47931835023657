import { CheckCircle, UploadFile } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

export default function InputExcelFile({ handleChange, title, name, ...props }) { //onUploadStart, onUploadEnded,
    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onloadend = () => {
                setIsSuccess(true)
            }
            reader.onerror = reject;
            reader.readAsDataURL(file);
        })
    }

    const fileReadHandler = async (e) => {
        try {
            // onUploadStart()
            setLoading(true)
            let base64 = await readFileAsync(e.target.files[0])
            handleChange({ target: { name: e.target.name, value: base64 } })
        } catch (error) {
            console.log(error)
        } finally {
            // onUploadEnded()
            setLoading(false)
        }
    }

    return (
        <LoadingButton
            loading={loading}
            startIcon={<UploadFile />}
            endIcon={isSuccess && <CheckCircle color="success" />}
            variant="outlined"
            component="label"
            {...props}
        >
            {title}
            <input hidden name={name} accept="image/*" multiple type="file" onChange={fileReadHandler} />
        </LoadingButton>
    )
}