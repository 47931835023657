import { Paper, Typography } from "@mui/material";
import { useGetPaymentMethodsQuery } from "api/paymentApi";
import { ROLES } from "constants";
import { DataTable, PaymentMethodIcon, MerchantName } from "modules/common";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { generateImageLink } from "utils";

const $COLUMN_WIDTH = 200
export default function CompletedTransactionsTable({ transactions, loading, page, pageChangeHandler, role }) {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const IDCell = ({ value }) => (
        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
            {value ?? '-'}
        </Typography>
    )

    const { data: paymentMethods } = useGetPaymentMethodsQuery({})

    const PaymentMethodImage = ({ value }) => {
        if (!value?.paymentMethod) return <></>
        const method = paymentMethods?.find(method => method?.info?.english?.name?.toLowerCase() === value?.paymentMethod?.toLowerCase())
        if (!method) return <></>
        return (
            <img style={{ objectFit: "contain", maxWidth: 50, height: "30px" }} src={generateImageLink(method?.image, "small")} alt={method} />
        )
    }

    let columns = [
        { field: "transactionNumber", renderCell: IDCell, sortable: false, headerName: t("Transaction number"), width: $COLUMN_WIDTH * 1.3 },
        { field: "name", valueGetter: ({ value }) => value ?? t("N/A"), sortable: false, headerName: t("Client"), width: $COLUMN_WIDTH * 1.1 },
        { field: "user", renderCell: ({ value }) => <MerchantName creator={value} />, sortable: false, headerName: t("Merchant"), width: $COLUMN_WIDTH * 1.1 },
        { field: "amount", sortable: false, headerName: t("Amount"), width: $COLUMN_WIDTH * 0.5, },
        {
            field: "source",
            renderCell: PaymentMethodImage,
            headerName: t("Payment Method"),
            width: $COLUMN_WIDTH * 0.7
        },
        { field: "createdAt", valueGetter: ({ value }) => moment(value).format("DD/MM/YY - h:mm A"), sortable: false, headerName: t("Created At"), width: $COLUMN_WIDTH * 0.8 },
    ];

    return (
        <Paper sx={{ height: "50vh" }}>
            <DataTable
                //Check again
                rows={
                    (!!transactions && !!transactions?.length)
                        ?
                        transactions
                        :
                        []
                }
                columns={columns}
                loading={loading}
                onRowClick={row => {
                    if (role === ROLES.admin) {
                        navigate(`/admin/transaction/${row?.id}`)
                    } else {
                        navigate(`/merchant/transactions/details/${row?.id}`)
                    }
                }
                }
                rowCount={transactions?.length ?? 0}
                paginationModel={{ page: page - 1, pageSize: 100 }}
                onPaginationModelChange={pageChangeHandler}
            />
        </Paper>
    )
}