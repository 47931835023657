import React from 'react';
import { Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { closeConfirmDialog } from 'store/confirmationDialogSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function ConfirmDialog() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { open, title, content, onConfirm, onClose } = useSelector((state) => state.confirmDialog);

    return (
        <Dialog
            open={open}
            onClose={(e) => e.preventDefault()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            {!!title
                &&
                <>
                    <DialogTitle id="alert-dialog-title">
                        {t(title)}
                    </DialogTitle>
                    <Divider />
                </>
            }
            <DialogContent>
                {t(content)}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    sx={{ textTransform: "none", color: "white", fontWeight: 'bold' }}
                    variant='contained'
                    onClick={() => {
                        if (typeof onClose === 'function') onClose();
                        dispatch(closeConfirmDialog());
                    }}
                >
                    {t("Cancel")}
                </Button>
                {
                    !!onConfirm &&
                    <Button
                        sx={{ textTransform: "none", color: "white", mx: '4px', fontWeight: 'bold' }}
                        variant='contained'
                        onClick={() => {
                            onConfirm();
                            dispatch(closeConfirmDialog());
                        }}
                        autoFocus
                    >
                        {t("Confirm")}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}
