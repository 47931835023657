import { apiSlice } from "api/apiSlice";
import { get, has } from "lodash";

const transformErrorResponse = (response) => has(response?.data, 'message') ? get(response?.data, 'message') : "Something went wrong please try again";

export const countriesSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ['Country'] })
    .injectEndpoints({
        endpoints: builder => ({
            getCountries: builder.query({
                query: (params) => ({
                    url: '/country',
                    method: 'GET',
                    params
                }),
                transformResponse: (response) => response?.body,
                transformErrorResponse,
                providesTags: ['Country'],
            }),
            getPublicCountries: builder.query({
                query: (params) => ({
                    url: '/country/public',
                    method: 'GET',
                    params
                }),
                transformResponse: (response) => response?.body,
                transformErrorResponse,
                providesTags: ['Country'],
            }),
            getCountryById: builder.query({
                query: (id) => ({
                    url: `/country/${id}`,
                    method: 'GET',
                }),
                transformResponse: (response) => response?.body,
                transformErrorResponse,
            }),
            addCountry: builder.mutation({
                query: (payload) => ({
                    url: '/country',
                    method: 'POST',
                    body: { ...payload }
                }),
                transformErrorResponse,
                invalidatesTags: ['Country'],
            }),
            updateCountry: builder.mutation({
                query: ({ id, payload }) => ({
                    url: `/country/${id}`,
                    method: 'PUT',
                    body: { ...payload }
                }),
                transformErrorResponse,
                invalidatesTags: ['Country'],
            }),
            countryActivationToggle: builder.mutation({
                query: (id) => ({
                    url: `/country/activate-deactivate/${id}`,
                    method: 'PUT',
                }),
                transformErrorResponse,
                invalidatesTags: ['Country'],
            }),

        })
    });

export const {
    useGetCountriesQuery,
    useGetPublicCountriesQuery,
    useGetCountryByIdQuery,
    useAddCountryMutation,
    useUpdateCountryMutation,
    useCountryActivationToggleMutation
} = countriesSlice;
