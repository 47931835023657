import { Container, Typography, Link, Alert, AlertTitle, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { $APP_NAME } from 'constants';
import APISection from './components/APISection';
import { CANCEL_TRANSACTION, CREATE_TRANSACTION, GET_TRANSACTION_BY_ID, LIST_TRANSACTIONS, REFUND_TRANSACTION, WEBHOOK_SAMPLE_CODE } from './resources';
import SampleCodes from './components/SampleCodes';

export default function Documentation() {
    return (
        <Box height="100%" backgroundColor="white">
            <Helmet>
                <title>Documentation | {$APP_NAME}</title>
            </Helmet>
            <Container maxWidth="md" sx={{ my: 20 }}>
                <Box dir="ltr" pb={5}>
                    <Typography my={25} variant="h3">
                        {"API Documentation"}
                    </Typography>
                    <Typography>
                        {`Welcome to Ezpay documentation. In order to use our service you need to have a Ezpay account. You can sign up from `}
                        <Link
                            target="_blank"
                            href="https://www.ezpay.sa/auth/register"
                            color="inherit"
                            underline="hover">
                            here
                        </Link>
                    </Typography>
                    <Alert severity="info" sx={{ my: 10 }}>
                        <AlertTitle>Request Header</AlertTitle>
                        {`Add "Authorization": "{Token}" to request header.`}
                        {` You can get your authorization token from your dashboard profile (Profile -> API Key)`}
                    </Alert>
                    <APISection api={CREATE_TRANSACTION} />
                    <APISection api={LIST_TRANSACTIONS} />
                    <APISection api={GET_TRANSACTION_BY_ID} />
                    <APISection api={CANCEL_TRANSACTION} />
                    <APISection api={REFUND_TRANSACTION} />
                    <Box mb={50}>
                        {/* <Typography variant="h4" color="primary" sx={{ my: 20 }}>
                            Traking transactions status (WEBHOOK)
                        </Typography> */}
                        <Box mt={3}>
                            <Typography variant="h4" color="primary" sx={{ my: 20 }}>
                                {"Webhook URL"}
                            </Typography>
                            <Alert severity="info" sx={{ my: 10 }}>
                                <AlertTitle>Description</AlertTitle>
                                {'You can track the status of your transactions by adding your webhook link to your profile page at your Ezpay Dashboard (Dashboard -> Profile -> Web Hook Card), and through this Webhook you can track all updates regarding the status of transactions.'}
                            </Alert>
                            <Box my={20}>
                                <SampleCodes data={WEBHOOK_SAMPLE_CODE} />
                            </Box>
                        </Box>
                        <Box mt={3}>
                            <Typography variant="h4" color="primary" sx={{ my: 20 }}>
                                {"Redirect URL"}
                            </Typography>
                            <Alert severity="info" sx={{ my: 10 }}>
                                <AlertTitle>Description</AlertTitle>
                                {'Through adding the redirect URL to your profile page at your Ezpay Dashboard (Dashboard -> Profile -> Web Hook Card), you can specify the link you want to go to directly after the payment process, after payment we use you entered redirect url "www.example.com/path" and add to it the transactionNumber and transaction status (PAID - FAILED) to be "ww.examole.come/path?transactionNumber=1234&status=PAID" .'}
                            </Alert>
                        </Box>
                    </Box>
                    {/* {
                        apis?.map(api => <APISection key={api?.title} api={api} models={models} />)
                    } */}
                    {/* <Box mt={3}>
                        <Typography variant="h3" color="primary" sx={{ mt: 5, mb: 2 }}>
                            Webhook API
                        </Typography>
                        <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>Description</Typography>
                        <Typography>
                            We use webhooks to notify your application when an event happens in your account. you will receive an event called <span style={{ color: "#FC9E16", backgroundColor: "#F8F8F8", padding: "2px 5px", borderRadius: 5 }}>order_status_changed</span> when the order currentStatus field is changed to the following statuses:
                        </Typography>
                        <Box mt={3} display="flex" flexDirection="column">
                            <StatusTable />
                        </Box>
                        <Typography variant="h5" fontWeight="bold" sx={{ my: 3 }}>Example for web hook response</Typography>
                        <SampleCodes sample={{ ...HookResponse }} />
                    </Box> */}
                </Box>
            </Container>
        </Box>
    )
}
