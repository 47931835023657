import { LoadingButton } from '@mui/lab';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Box, Typography, FormControlLabel, Checkbox, Collapse, Autocomplete, useTheme } from '@mui/material';
import { useGetUsersByAdminQuery } from 'api/adminApi';
import { PhoneInput, ServerAutoCompleteSearch, UploadImageButton } from 'modules/common';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import runJoiValidate from 'utils/runJoiValidate';
import { CREATE_USER_SCHEMA } from './ValidationSchemas';
import { cloneDeep, omit, omitBy, pick } from 'lodash';
import { useGetCountriesQuery } from 'api/countriesApis';
import { DOCUMNETS } from 'constants';

const USER_FIELDS = {
    userName: '',
    phone: '',
    email: '',
    password: '',
    businessName: '',
    country: '',
    logo: '',
    commercialRegister: '',
    articlesOfAssociation: '',
    bankAccountLetter: '',
    signatureAuthorization: '',
    civilId: '',
    civilIdBack: '',
    otherDocument: '',
    commercialLicense: ''
}

export default function UserDialog({ open, onClose, loading, isSuccess, error, onCreate }) {
    const { t } = useTranslation()
    const theme = useTheme()
    const [searchKey, setSearchKey] = useState('')
    const { data: mainAccounts, isLoading: mainAccountsLoading, isFetching: mainAccountsFetching } = useGetUsersByAdminQuery(omitBy({
        parent: true,
        searchKey,
        limit: 1000
    }, v => !v))
    const [isParent, setIsParent] = useState(false)
    const [validationErrors, setValidationErrors] = useState(null)
    const [user, setUser] = useState({ ...USER_FIELDS })

    const handleChange = ({ target: { name, value } }) => {
        setUser((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const onCloseHandler = () => {
        onClose();
    };

    const createUserHandler = () => {
        try {
            const MainAccount = [...Object.keys(USER_FIELDS)]
            const SubAccount = ["parent", "userName", "phone", "password", "email"]

            setValidationErrors(null)
            let payload = cloneDeep(pick(user, isParent ? MainAccount : SubAccount))
            payload = omitBy(payload, v => !Boolean(v));
            if (!isParent && !!payload?.parent) {
                payload.parent = payload?.parent?.id
            } else if (isParent) {
                payload = omit(payload, ["parent"])
            }
            const errors = runJoiValidate(CREATE_USER_SCHEMA, { ...payload, isParent })
            console.log(errors)
            if (!!errors) {
                setValidationErrors(errors)
                return
            }
            onCreate(payload)
        } catch (error) {
            console.log(error)
        }
    }

    const timerRef = useRef()
    const getMainAccountHandler = (newInputValue) => {
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
            setSearchKey(newInputValue)
        }, 1000);
    }

    useEffect(() => {
        if (open === false) {
            setUser({ ...USER_FIELDS })
        }
        setValidationErrors(null)
    }, [open])

    //=======Countries=======
    const [countrySearchKey, setCountrySearchKey] = useState('')
    const { data: countries, isLoading: countriesLoading } = useGetCountriesQuery(omitBy({ searchKey: countrySearchKey, active: true }, v => !Boolean(v)))

    return (
        <Dialog fullWidth open={open} onClose={(e) => e.preventDefault()}>
            <DialogTitle>{t("Add new user")}</DialogTitle>
            <Divider />
            <DialogContent>
                <FormControlLabel control={<Checkbox checked={isParent} onChange={(event) => setIsParent(event.target.checked)} />} label={t("Main account")} />
                {
                    !isParent &&
                    <Autocomplete
                        value={user?.parent}
                        onChange={(event, newValue) => {
                            handleChange({ target: { name: "parent", value: newValue } })
                        }}
                        options={mainAccounts?.docs ?? []}
                        onInputChange={(event, newInputValue) => {
                            getMainAccountHandler(newInputValue)
                        }}
                        getOptionLabel={(option) => (option && option?.business?.businessName) ? option?.business?.businessName : option.userName ?? ''}
                        fullWidth
                        size="small"
                        loading={mainAccountsLoading || mainAccountsFetching}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("Select the main account")}
                                error={!!validationErrors?.parent}
                                helperText={validationErrors?.parent}
                            />
                        )}
                    />
                }
                <TextField
                    name="userName"
                    value={user?.userName}
                    onChange={handleChange}
                    margin="normal"
                    label={t("Name")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!validationErrors?.userName}
                    helperText={validationErrors?.userName}
                />
                <PhoneInput
                    label={t("Phone")}
                    name="phone"
                    value={user?.phone}
                    onChange={handleChange}
                    fullWidth
                    error={validationErrors?.phone}
                />
                <TextField
                    name="password"
                    type="password"
                    label={t("Password")}
                    value={user?.password}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 7 }}
                    inputProps={{
                        autoComplete: "off"
                    }}
                    error={!!validationErrors?.password}
                    helperText={validationErrors?.password}
                />
                <TextField
                    name="email"
                    label={t("Email")}
                    value={user?.email}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 7 }}
                    error={!!validationErrors?.email}
                    helperText={validationErrors?.email}
                />
                <Collapse in={isParent}>
                    <TextField
                        name="businessName"
                        label={t("Business name")}
                        value={user?.businessName ?? ''}
                        onChange={handleChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                        error={!!validationErrors?.businessName}
                        helperText={validationErrors?.businessName}
                    />
                    <ServerAutoCompleteSearch
                        label={t("Country")}
                        value={countries?.docs?.find((country) => country?.id === user?.country) ?? null}
                        isOptionEqualToValue={(option, value) => option?.id === value}
                        loading={countriesLoading}
                        getOptionLabel={(option) => !!option?.info ? option?.info[`${theme.direction === "rtl" ? "arabic" : "english"}`]?.name : ''}
                        options={countries?.docs?.filter((country) => ["EG", "SA", "AE"].includes(country?.isoCode)) ?? []}
                        onChange={(value) => handleChange({ target: { name: "country", value: value?.id } })}
                        onInputChange={(text) => setCountrySearchKey(text)}
                        error={validationErrors?.country}
                    />
                    <UploadImageButton sx={{ mt: 15, mr: 10, textTransform: "none" }} title={t("Logo")} name="logo" handleChange={handleChange} />
                    {
                        DOCUMNETS.map(doc => (
                            <UploadImageButton sx={{ mt: 15, mr: 10, textTransform: "none" }} title={t(doc?.label)} name={doc?.name} handleChange={handleChange} />
                        ))
                    }
                </Collapse>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" onClick={onCloseHandler} sx={{ mr: 5, textTransform: "none" }}>{t("Cancel")}</Button>
                    <LoadingButton sx={{ textTransform: "none" }} variant="contained" loading={loading} onClick={createUserHandler}>
                        {t("Add")}
                    </LoadingButton>
                </Box>
                {!!error && <Typography color="error">{t(error)}</Typography>}
                {!!isSuccess && <Typography color="success.main">{t("Added successfully")}</Typography>}
            </DialogActions>
        </Dialog>
    );
}