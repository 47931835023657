import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { ApplePay, STC, Master, Mada, Visa } from "_assets";
import { useMemo } from 'react';

export default function PaymentLogos() {
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const imageHeight = useMemo(() => {
        if (isXsScreen) return 17;
        return 25;
    }, [isXsScreen]);

    // const pciHeight = useMemo(() => {
    //     if (isXsScreen) return 25;
    //     return 35;
    // }, [isXsScreen]);

    return (
        <Box sx={{ backgroundColor: "#f0f0f0" }}>
            <Container sx={{ py: 30, px: 20 }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img style={{ objectFit: "contain", marginRight: 10, marginLeft: 10, height: `${imageHeight}px` }} src={ApplePay} alt="group" />
                    <img style={{ objectFit: "contain", marginRight: 10, marginLeft: 10, height: `${imageHeight}px` }} src={Master} alt="group" />
                    <img style={{ objectFit: "contain", marginRight: 10, marginLeft: 10, height: `${imageHeight}px` }} src={Visa} alt="group" />
                    <img style={{ objectFit: "contain", marginRight: 10, marginLeft: 10, height: `${imageHeight}px` }} src={Mada} alt="group" />
                    <img style={{ objectFit: "contain", marginRight: 10, marginLeft: 10, height: `${imageHeight}px` }} src={STC} alt="STC" />
                    {/* <img height={pciHeight} style={{ objectFit: "contain" }} src={PCI} alt="PCI" /> */}
                </Box>
            </Container>
        </Box>
    )
}
