import { useEffect, useMemo, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'constants';
import { useSelector } from 'react-redux';
import { useUpdateUserStatusByAdminMutation } from 'api/adminApi';
import { ADMIN_TYPES } from 'constants';

export default function UserStatusDialog({ user, open, onClose }) {
    const { t } = useTranslation()
    const { profile } = useSelector(state => state?.auth)

    const STATUS_BY_ROLE = useMemo(() => {
        if (profile?.role === ROLES.admin) {
            if (profile?.type === ADMIN_TYPES.accountManager) {
                return ["BLOCKED"]
            }
            return ["ACCOUNT_CREATED", "WAITING", "ACTIVE", "BLOCKED", "REJECTED_BY_SERVICE_PROVIDER"]
        }
        return []
    }, [profile])

    const [currentStatus, setCurrentStatus] = useState('')

    useEffect(() => {
        if (!!user) {
            setCurrentStatus(user?.currentStatus)
        }
    }, [user])

    const [updateUserStatus, {
        isLoading,
        isSuccess,
        error,
        reset
    }] = useUpdateUserStatusByAdminMutation()

    const onUpdateUserStatusHandler = () => {
        if (!!currentStatus && currentStatus !== user?.currentStatus) {
            updateUserStatus({ id: user?.id, payload: { currentStatus } })
        }
    }

    useEffect(() => {
        if (!open) {
            reset()
        }
    }, [open])

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                onClose()
            }, 2000)
        }
    }, [isSuccess])

    return (
        <Dialog fullWidth open={open} onClose={(e) => e.preventDefault()}>
            <DialogTitle>{t("User Status")}</DialogTitle>
            <Divider />
            <DialogContent>
                <FormControl size="small" fullWidth>
                    <InputLabel id="account-status-label">{t("Account status")}</InputLabel>
                    <Select
                        labelId="account-status-label"
                        id="account-status"
                        name="currentStatus"
                        value={currentStatus}
                        label={t("Account status")}
                        onChange={({ target: { value } }) => setCurrentStatus(value)}
                    >
                        {
                            STATUS_BY_ROLE.map((status) => (
                                <MenuItem key={status} value={status}>{t(`UserStatus.${status}`)}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" onClick={onClose} sx={{ textTransform: 'none' }}>
                    {t("Close")}
                </Button>
                <Box mr="auto" />
                {isSuccess && <Typography color="success.main">{t("Done successfully")}</Typography>}
                {!!error && <Typography color="error">{t(error)}</Typography>}
                <Box mr="auto" />
                <LoadingButton
                    onClick={onUpdateUserStatusHandler}
                    loading={isLoading}
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: 'none' }}
                >
                    {t("Confirm")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
