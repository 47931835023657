import { Box, Grid } from "@mui/material";

import {
    useUpdateBankInfoByAdminMutation,
    useUpdateBusinessByAdminMutation,
    useUpdateBusinessPricingByAdminMutation,
    useUpdateDocumentsByAdminMutation,
    useUpdateUserByAdminMutation,
    useUpdateUserStatusByAdminMutation,
    useDeleteDocumentMutation
} from "api/adminApi";

import { useLazyGetUserBusinessByIdQuery, useGetUserByIdQuery } from "api/userApi";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivationCard from "./ActivationCard";
import CurrentInvoiceCard from "./CurrentInvoiceCard";
import InvoicesHistoryTable from "./InvoicesHistoryTable";
import UserBusinessCard from "./UserBusinessCard";
import UserInfoCard from "./UserInfoCard";
import AutomaticTransferCard from "./AutomaticTransferCard";
import { get, omit, pick } from "lodash";
import PaymentMethodsCard from "../AdminSettings/PaymentMethodsCard";
import ActiveMultiVendorCard from "./ActiveMultiVendorCard";
import ConfirmBusinessInfoDialog from "./ConfirmBusinessInfoDialog";
import { useTranslation } from "react-i18next";
import UserDocuments from "./UserDocuments";
import AutoTransferStatusCard from "./AutoTransferStatusCard";
import MyFatoorahTransferPeriodCard from "./MyFatoorahTransferPeriodCard";
import { CopyContentCard } from "modules/common";
import { Helmet } from "react-helmet";
import { $APP_NAME, ADMIN_TYPES } from "constants";
import WebhookCard from "modules/merchant/Profile/components/WebhookCard";
import { useSelector } from "react-redux";
import { DOCUMNETS } from "constants";

export default function AdminUserDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { profile } = useSelector(state => state?.auth);

    // const { data: settings } = useGetSettingsQuery({});
    const { data: userData, isError } = useGetUserByIdQuery(id)

    const isChildUser = useMemo(() => {
        if (!!userData && !userData?.parent) return false
        return true
    }, [userData])

    const [getUserBusinessById, { data: userBusiness, refech: refetchUserBusiness }] = useLazyGetUserBusinessByIdQuery()

    useEffect(() => {
        if (!!userData?.business?.id) {
            getUserBusinessById(userData?.business?.id)
        }
    }, [userData])

    useEffect(() => {
        if (isError) {
            navigate(-1, { replace: true })
        }
    }, [isError])

    //======= Update Business By Admin =========
    const [updateBusinessByAdmin,
        {
            isLoading: updateBusinessLoading,
            error: updateBusinessError,
            isSuccess: updateBusinessIsSuccess
        }] = useUpdateBusinessByAdminMutation()

    const onUpdateBusinessHandler = async (payload) => {
        try {
            await updateBusinessByAdmin({ businessId: userBusiness?.id, payload }).unwrap()
            // refetchUserInfo()
        } catch (error) { }
    }

    //======= Update Bank Account By Admin =========
    const [updateBankInfoByAdmin,
        {
            isLoading: updateBankInfoByAdminLoading,
            error: updateBankInfoByAdminError,
            isSuccess: updateBankInfoByAdminIsSuccess
        }] = useUpdateBankInfoByAdminMutation()

    const onUpdateBankAccountHandler = async (payload) => {
        try {
            await updateBankInfoByAdmin({ businessId: userBusiness?.id, payload }).unwrap
            // refetchUserInfo()
        } catch (error) { }
    }

    //======= Update Documents By Admin =========
    const [updateDocumentsByAdmin,
        {
            isLoading: updateDocumentsByAdminLoading,
            error: updateDocumentsByAdminError,
            isSuccess: updateDocumentsByAdminIsSuccess
        }] = useUpdateDocumentsByAdminMutation()

    const onUpdateDocumentsByAdminHandler = async (payload) => {
        try {
            await updateDocumentsByAdmin({ businessId: userBusiness?.id, payload }).unwrap
            // refetchUserInfo()
        } catch (error) { }
    }
    //======= Delete Documents By Admin =========
    const [deleteDocument,
        {
            isLoading: deleteDocumentLoading,
            error: deleteDocumentError,
            isSuccess: deleteDocumentIsSuccess
        }] = useDeleteDocumentMutation()

    const deleteDocumentHandler = async (key) => {
        try {
            await deleteDocument({ businessId: userBusiness?.id, payload: { key } }).unwrap()
        } catch (error) { }
    }

    //======= Update User Info By Admin =========
    const [updateUserByAdmin, { isLoading: updateUserLoading, error: updateUserError, isSuccess: updateUserIsSuccess }] = useUpdateUserByAdminMutation()

    const onUpdateUserHandler = (payload) => {
        updateUserByAdmin({ id, payload: omit(payload, ["email"]) })
    }

    //======= Update User Status By Admin =========
    const [updateUserStatus, {
        isLoading: updateUserStatusLoading,
        isSuccess: updateUserStatusIsSuccess,
        error: updateUserStatusError
    }] = useUpdateUserStatusByAdminMutation()

    const onUpdateUserStatusHandler = (payload) => {
        updateUserStatus(payload)
    }

    //======= Update Business Pricing By Admin =========
    const [updateBusinessPricing,
        {
            isLoading: updatePricingLoading,
            error: updatePricingError,
            isSuccess: updatePricingIsSuccess
        }
    ] = useUpdateBusinessPricingByAdminMutation()

    const updateBusinessPricingHandler = (payload) => {
        updateBusinessPricing({ payload: { pricing: payload }, businessId: userBusiness?.id })
    }


    //======= Auto Transfer Data Confirmation Dialog =========
    const [open, setOpen] = useState(false);

    const allowUpdatePricing = useMemo(() => {
        if (profile?.type !== ADMIN_TYPES.accountManager) {
            return !userBusiness?.myFatoorahVendor?.supplierId || userBusiness?.myFatoorahVendor?.isApproved
        }
        return false
    }, [profile, userBusiness])

    return (
        <>
            <Helmet>
                <title>{t("User Details")} | {$APP_NAME}</title>
            </Helmet>
            <Box sx={{ my: 20, mr: 5 }}>
                <Grid container spacing={20} alignItems="flex-start">
                    <Grid item container xs={12} md={isChildUser ? 12 : 6} spacing={20} >
                        <Grid item xs={12} md={isChildUser ? 6 : 12}>
                            <UserInfoCard
                                id={id}
                                userData={userData}
                                onUpdate={onUpdateUserHandler}
                                isLoading={updateUserLoading}
                                isSuccess={updateUserIsSuccess}
                                error={updateUserError}
                            />
                        </Grid>
                        {(!!userData && userData?.parent === null)
                            &&
                            <Grid item xs={12}>
                                <PaymentMethodsCard
                                    title={t("Pricing")}
                                    onConfirm={updateBusinessPricingHandler}
                                    isSuccess={updatePricingIsSuccess}
                                    error={updatePricingError}
                                    loading={updatePricingLoading}
                                    pricingData={userBusiness?.pricing}
                                    allowUpdate={allowUpdatePricing}
                                />
                            </Grid>
                        }
                        {(!!userData && userData?.parent === null && !!userData?.apiKey)
                            &&
                            <Grid item xs={12}>
                                <CopyContentCard
                                    title={t("API Key")}
                                    value={userData?.apiKey}
                                />
                            </Grid>
                        }
                        {(!!userData && userData?.parent === null)
                            &&
                            <Grid item xs={12}>
                                <CurrentInvoiceCard id={id} allowSettleBalance={!!profile && profile?.type !== ADMIN_TYPES.accountManager} />
                            </Grid>
                        }
                        <Grid item xs={12} md={isChildUser ? 6 : 12}>
                            <ActivationCard
                                id={id}
                                status={userData?.currentStatus}
                                timeline={userData?.statusTrack}
                                isMyFatoorahApproved={userData?.business?.myFatoorahVendor?.isApproved}
                                onUpdateStatus={onUpdateUserStatusHandler}
                                isLoading={updateUserStatusLoading}
                                isSuccess={updateUserStatusIsSuccess}
                                error={updateUserStatusError}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6} spacing={20} >
                        {(!!userData && userData?.parent === null)
                            &&
                            <Grid item xs={12}>
                                <UserBusinessCard
                                    userBusiness={userBusiness}
                                    onUpdateBusiness={onUpdateBusinessHandler}
                                    isLoading={updateBusinessLoading}
                                    isSuccess={updateBusinessIsSuccess}
                                    error={updateBusinessError}
                                // allowUpdate={!!profile && profile?.type !== ADMIN_TYPES.accountManager}
                                />
                            </Grid>
                        }
                        {(!!userData && userData?.parent === null)
                            &&
                            <Grid item xs={12}>
                                <AutomaticTransferCard
                                    userBusiness={{ ...userBusiness, user: { country: { id: get(userData, 'country') } } }}
                                    onUpdateBankAccount={onUpdateBankAccountHandler}
                                    isLoading={updateBankInfoByAdminLoading}
                                    isSuccess={updateBankInfoByAdminIsSuccess}
                                    error={updateBankInfoByAdminError}
                                />
                            </Grid>
                        }
                        {(!!userData && userData?.parent === null)
                            &&
                            <Grid item xs={12}>
                                <UserDocuments
                                    userDocuments={pick(userBusiness, DOCUMNETS.map(doc => doc.name))}
                                    onUpdate={onUpdateDocumentsByAdminHandler}
                                    onDelete={deleteDocumentHandler}
                                    isLoading={updateDocumentsByAdminLoading || deleteDocumentLoading}
                                    isSuccess={updateDocumentsByAdminIsSuccess || deleteDocumentIsSuccess}
                                    error={updateDocumentsByAdminError || deleteDocumentError}
                                    iSAdmin={true}
                                />
                            </Grid>
                        }
                        {(!!userData && userData?.parent === null && !!userBusiness?.myFatoorahVendor?.supplierId)
                            ?
                            <Grid item xs={12}>
                                <MyFatoorahTransferPeriodCard businessId={userBusiness?.id} deposit={userBusiness?.deposit} allowUpdate={!!profile && profile?.type !== ADMIN_TYPES.accountManager} />
                            </Grid>
                            : null
                        }
                        {
                            !!profile && profile?.type !== ADMIN_TYPES.accountManager ?
                                !!userData && userData?.parent === null &&
                                <Grid item xs={12}>
                                    {!userBusiness?.myFatoorahVendor?.supplierId ?
                                        <>
                                            <ConfirmBusinessInfoDialog
                                                open={open}
                                                userBusiness={userBusiness}
                                                refetchUserBusiness={refetchUserBusiness}
                                                onClose={() => setOpen(false)}
                                            />
                                            <ActiveMultiVendorCard openConfirmDialog={() => setOpen(true)} />
                                        </>
                                        :
                                        <AutoTransferStatusCard
                                            businessId={userBusiness?.id}
                                            myFatoorahVendor={userBusiness?.myFatoorahVendor}
                                        />
                                    }
                                </Grid>
                                : null
                        }
                        {
                            (!!userData && userData?.parent === null) &&
                            <Grid item xs={12} md={12}>
                                <WebhookCard id={id} allowUpdate={!!profile && profile?.type !== ADMIN_TYPES.accountManager} />
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={20} sx={{ marginTop: 0 }}>
                    {(!!userData && userData?.parent === null)
                        &&
                        <Grid item md={12} sm={12} xs={12}>
                            <InvoicesHistoryTable id={id} />
                        </Grid>
                    }
                </Grid>
            </Box>
        </>
    )
}