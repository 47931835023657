import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

const NoPosts = (props) => {

    const { t } = useTranslation();

    return (
        <Box
            {...props}
            sx={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                // py: 10,
                ...get(props, 'sx')
            }}
        >
            <Typography color="text.brandOrange" fontSize="36px" lineHeight="40px" fontWeight={900} textAlign="center">
                {t("No Posts Yet")}
            </Typography>
        </Box>
    );
}

export default NoPosts;