import { Box, Grid } from '@mui/material'
import { $APP_NAME } from 'constants';
import { ADMIN_TYPES } from 'constants';
import { includes } from 'lodash';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'

export default function AdminStatistics() {
    const { t } = useTranslation()
    const { profile } = useSelector(state => state?.auth);

    const mongoDBCountryId = useMemo(() => {
        if (process.env.REACT_APP_ENV === "production") {
            if (profile?.country?.isoCode === 'SA') return '018e9ce4-9e56-44c1-83df-04c79735d9a0'
            if (profile?.country?.isoCode === 'AE') return '4fb55a31-e8cf-45b8-98c4-c562b6e275a7'
        } else {
            if (profile?.country?.isoCode === 'SA') return 'cf5c0105-3acc-46a2-b705-72a840597324'
            if (profile?.country?.isoCode === 'AE') return 'ae5c80e3-f1e1-4b95-a11c-0eb58c26aa72'
        }
        return ''
    }, [profile])

    return (
        <>
            <Helmet>
                <title>{t("Statistics")} | {$APP_NAME}</title>
            </Helmet>
            <Box m={5}>
                {
                    (profile?.type === ADMIN_TYPES.admin || (!!profile && !profile?.type)) &&
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "45vh" }}>
                            <iframe
                                style={{ background: '#FFFFFF', border: 'none' }}
                                width="100%"
                                height="100%"
                                src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '51fa4e5d-debd-4284-a292-9f1888e20d7d' : '6641dc38-c775-47b7-8fa1-af1138c29a2f'}&maxDataAge=3600&theme=light&autoRefresh=true`}></iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "45vh" }}>
                            <iframe
                                style={{ background: '#FFFFFF', border: 'none' }}
                                width="100%"
                                height="100%"
                                src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? 'e09badab-532c-4b1c-a931-712bdfed2e6f' : '66421723-33b8-4445-8f4c-940728484ac5'}&maxDataAge=3600&theme=light&autoRefresh=true`}></iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "45vh" }}>
                            <iframe
                                style={{ background: '#FFFFFF', border: 'none' }}
                                width="100%"
                                height="100%"
                                src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? 'd31917e8-d7e5-47ba-b30a-164cd3943b10' : '66438768-b1a5-48b8-8d5d-9e086043cb65'}&maxDataAge=3600&theme=light&autoRefresh=true`}></iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "45vh" }}>
                            <iframe
                                style={{ background: '#FFFFFF', border: 'none' }}
                                width="100%"
                                height="100%"
                                src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '1f7e6b7f-4ca8-4fef-bf8e-8f4823d2c59d' : '666ac209-e68b-4c62-8838-2f302efd1a09'}&maxDataAge=3600&theme=light&autoRefresh=true`}></iframe>
                        </Grid>
                    </Grid>
                }
                {
                    (!!profile && includes([ADMIN_TYPES.subAdmin, ADMIN_TYPES.accountManager], profile?.type)) &&
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <iframe
                                style={{ background: '#FFFFFF', border: 'none' }}
                                width="100%"
                                height="550"
                                src={`https://charts.mongodb.com/charts-project-0-aexyqiz/public/dashboards/${mongoDBCountryId}`}>

                            </iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "45vh" }}>
                            <iframe style={{ background: '#FFFFFF', border: 'none' }} width="100%" height="100%" src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '36966f18-aa2c-4e9c-a4b6-d82313883fef' : 'beec51c0-ad7d-48f7-9d3f-b9a68a0342ae'}&maxDataAge=3600&theme=light&autoRefresh=true&filter={'country' : ObjectId('${profile?.country?.id}')}`}></iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "45vh" }}>
                            <iframe style={{ background: '#FFFFFF', border: 'none' }} width="100%" height="100%" src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '9f8c1cbd-8695-4834-a13f-9ac9590ef310' : '664383d1-569c-4909-8ffb-29b4519c2b89'}&maxDataAge=3600&theme=light&autoRefresh=true&filter={'user_lookup_users.country_lookup_countries._id' : ObjectId('${profile?.country?.id}')}`}></iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "45vh" }}>
                            <iframe style={{ background: '#FFFFFF', border: 'none' }} width="100%" height="100%" src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '1df63a13-85b4-4c36-9e18-acadec0a2eaf' : '6666d4f8-9be0-4ef9-8bf5-75c79107a75e'}&maxDataAge=3600&theme=light&autoRefresh=true&filter={'country' : ObjectId('${profile?.country?.id}')}`}></iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "45vh" }}>
                            <iframe style={{ background: '#FFFFFF', border: 'none' }} width="100%" height="100%" src={`https://charts.mongodb.com/charts-project-0-aexyqiz/embed/charts?id=${process.env.REACT_APP_ENV === "production" ? '4feadf48-e6e7-4729-8a57-539577de85e0' : 'ab2c74cc-be04-4734-a518-5468ee752c62'}&maxDataAge=3600&theme=light&autoRefresh=true&filter={'user_lookup_users.country_lookup_countries._id' : ObjectId('${profile?.country?.id}')}`}></iframe>
                        </Grid>
                    </Grid>
                }
            </Box>
        </>
    )
}
