import { Forum, Group, Paid, Receipt, Settings, AccountBalance, Delete, Smartphone, SupervisorAccount, Public, Analytics, PostAdd } from "@mui/icons-material";
import { Avatar, Box, Chip, CircularProgress, Divider, Drawer, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Logo } from "_assets";
import { useGetCountersQuery } from "api/userApi";
import { DrawerList } from "layouts/common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";
import { ADMIN_TYPES } from "constants";
import { cloneDeep, filter, includes } from "lodash";
import { useMemo } from "react";
const drawerWidth = 260;

export default function AdminDrawer({ openDrawer, toggleDrawer }) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const lessThanSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"));
    const LIST_ITEMS = [
        {
            title: "Statistics",
            href: "/admin",
            icon: Analytics,
        },
        {
            title: "Users",
            href: "/admin/users",
            icon: Group,
        },
        {
            title: "Transactions",
            href: "/admin/transactions",
            icon: Paid,
        },
        {
            title: "Invoices",
            icon: Receipt,
            children: [
                {
                    title: "Issued By Myfatoorah",
                    href: "/admin/invoices?depositType=AUTOMATIC",
                },
                {
                    title: "Issued By Ezpay",
                    href: "/admin/invoices?depositType=MANUAL",
                },
            ]
        },
        {
            title: "Banks",
            href: "/admin/banks",
            icon: AccountBalance,
        },
        // {
        //     title: "Terms and conditions",
        //     href: "/admin/terms_and_conditions",
        //     icon: Description,
        // },
        {
            title: "Messages",
            href: "/admin/contact-us-messages",
            icon: Forum,
        },
        {
            title: "Account Delete Requests",
            href: "/admin/delete-requests-list",
            icon: Delete,
        },
        {
            title: "App Versions",
            href: "/admin/app-versions",
            icon: Smartphone,
        },
        {
            title: "Admins",
            href: "/admin/admins",
            icon: SupervisorAccount,
        },
        {
            title: "Countries",
            href: "/admin/countries",
            icon: Public,
        },
        {
            title: "Blog",
            href: "/admin/blog",
            icon: PostAdd,
        },
        {
            title: "Settings",
            href: "/admin/settings",
            icon: Settings,
        }
    ]
    const { data: count, isLoading } = useGetCountersQuery()

    const { profile } = useSelector(state => state?.auth);


    const ListItems = useMemo(() => {
        const { notSeenContactMessagesCount, notSeenDeleteRequestCount } = count || {}
        let list = cloneDeep(LIST_ITEMS)

        list = list.map(item => {
            if (item.href === "/admin/contact-us-messages" && !!notSeenContactMessagesCount) {
                item.secondaryAction = <Chip
                    size="small"
                    label={notSeenContactMessagesCount}
                    sx={{ color: "white" }}
                    color="warning"
                />
            }
            if (item.href === "/admin/delete-requests-list" && !!notSeenDeleteRequestCount) {
                item.secondaryAction = <Chip
                    size="small"
                    label={notSeenDeleteRequestCount}
                    sx={{ color: "white" }}
                    color="warning"
                />
            }
            return item
        })

        if (!!profile?.type && includes([ADMIN_TYPES.accountManager], profile?.type)) {
            return filter(list, v => !["/admin/admins", "/admin/countries", "/admin/app-versions"].includes(v.href))
        }
        if (!!profile?.type && includes([ADMIN_TYPES.subAdmin], profile?.type)) {
            return filter(list, v => !["/admin/countries", "/admin/app-versions"].includes(v.href))
        }

        return list

    }, [profile, count])

    return (
        <Drawer
            open={openDrawer}
            onClose={toggleDrawer}
            variant={lessThanSmall ? "temporary" : "permanent"}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box display="flex" justifyContent="center" alignItems="center" my={5} mb={10}>
                <Avatar onClick={() => navigate("/")} variant='square' src={Logo} sx={{ height: 75, width: 75, cursor: "pointer" }} style={{ objectFit: "cover" }} />
            </Box>
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center" mx={20} my={20}>
                <Typography variant="caption" color="primary" sx={{ fontWeight: "bold" }}>{t("Waiting for activation")}</Typography>
                {
                    isLoading ?
                        <CircularProgress color="secondary" size={20} />
                        :
                        <Chip
                            size="small"
                            label={count?.waitingCount}
                            sx={{ color: "white" }}
                            color="warning"
                            onClick={() => navigate("/admin?status=WAITING")}
                        />
                }
            </Box>
            <Divider />
            <Box mt={10} sx={{ overflow: 'auto' }}>
                <DrawerList tabs={ListItems} />
            </Box>
        </Drawer>
    )
}