import { CKEditor } from 'ckeditor4-react';
import { useEffect, useState } from 'react';

export default function Editor({ name, value, handleChange, allowUpdate }) {

    const [editorInstance, setEditorInstance] = useState(null)

    useEffect(() => {
        if (editorInstance) {
            editorInstance.setData(value)
        }
    }, [value, editorInstance])

    return (
        <CKEditor
            readOnly={!allowUpdate}
            initData={""}
            editorUrl="//cdn.ckeditor.com/4.19.1/full/ckeditor.js"
            onInstanceReady={({ editor }) => {
                setEditorInstance(editor)
            }}
            onChange={({ editor }) => {
                const data = editor.getData();
                handleChange({ target: { name, value: data } })
            }}
        />
    )
}