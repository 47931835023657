import React, { useEffect, useMemo, useState } from "react";
import { TextField, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography, useMediaQuery, useTheme, FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import ClearIcon from '@mui/icons-material/Clear';
import CountriesListAutoComplete from "./CountriesListAutoComplete";
import { pick } from "lodash";
import { COUNTRY_ADD_SCHEMA, COUNTRY_EDIT_SCHEMA } from "../countries-validation-schemas";
import { useCountryActivationToggleMutation } from "api/countriesApis";
import { runJoiValidate } from "utils";

export default function CountryDialog({
    openCountryDialog,
    loading,
    country,
    handleChange,
    handleCountryChange,
    selectedCountry,
    onAddCountry,
    onUpdateCountry,
    onCloseDialog,
    error,
    isSuccess,
    isEditing,
    countries
}) {

    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [validationErrors, setValidationErrors] = useState(null);

    const handleSubmit = () => {
        setValidationErrors(null);

        let payload = pick(country, ["info", "timezone", "isoCode", "phoneCode", "isoCode3"]);

        if (isEditing) {
            const errors = runJoiValidate(COUNTRY_EDIT_SCHEMA, payload);
            if (!!errors) {
                setValidationErrors(errors);
                return;
            }
            onUpdateCountry({ id: country?.id, payload });
        } else {
            const errors = runJoiValidate(COUNTRY_ADD_SCHEMA, payload);
            if (!!errors) {
                setValidationErrors(errors);
                return;
            }
            onAddCountry(payload);
        }
    };

    const onCloseDialogHandler = () => {
        onCloseDialog();
        setValidationErrors(null);
    };

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                onCloseDialogHandler();
            }, 2000);
        }
    }, [isSuccess]);

    const helperTextError = useMemo(() => {
        if (typeof validationErrors?.info === "string" || typeof validationErrors?.info?.arabic === "string" || typeof validationErrors?.info?.english === "string") {
            return "Please fill in the required fields!";
        }
        return null;
    }, [validationErrors]);

    //===========Edit country activation==============
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (isEditing && typeof country?.active === "boolean") {
            setIsActive(country?.active);
        }
    }, [isEditing, country]);

    const [countryActiveToggle,
        { isLoading: activeToggleIsLoading, isSuccess: activeToggleIsSuccess, error: activeToggleError, reset: activeToggleReset }
    ] = useCountryActivationToggleMutation();

    return (
        <Dialog
            maxWidth="md"
            dir={theme.direction}
            fullWidth
            fullScreen={fullScreen}
            open={openCountryDialog}
            aria-labelledby="country-dialog-title"
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {isEditing ? t("Edit country") : t("Add country")}
                <IconButton onClick={onCloseDialogHandler} color="primary">
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {
                    isEditing &&
                    <Grid container spacing={10} sx={{ mb: 3 }}>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                        {t("Country status")}
                                    </Typography>
                                    <FormControlLabel
                                        sx={{ mx: 1 }}
                                        control={
                                            <Switch
                                                checked={isActive}
                                                onChange={(event) => {
                                                    setIsActive(event?.target?.checked);
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={isActive ? t("Activated") : t("Deactivated")}
                                    />
                                </Box>
                                <LoadingButton
                                    onClick={() => {
                                        activeToggleReset();
                                        countryActiveToggle(country?.id);
                                    }}
                                    loading={activeToggleIsLoading}
                                    disabled={isActive === country?.active}
                                    sx={{ textTransform: "none" }}
                                    variant="contained"
                                >
                                    {t("Confirm")}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={10} alignItems="flex-start">
                    {
                        !isEditing &&
                        <Grid item xs={12} md={12}>
                            <CountriesListAutoComplete
                                country={selectedCountry}
                                onChange={handleCountryChange}
                                excludedCountries={countries?.map(country => country?.isoCode)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <TextField
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                            required
                            name="info.english.name"
                            value={country?.info?.english?.name ?? ""}
                            disabled
                            label={t("Country name in English")} size="small" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                            required
                            name="info.arabic.name"
                            value={country?.info?.arabic?.name ?? ""}
                            error={!!validationErrors?.info?.arabic?.name}
                            helperText={validationErrors?.info?.arabic?.name ?? ''}
                            onChange={handleChange}
                            label={t("Country name in Arabic")} size="small" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                            required
                            name="info.english.currency"
                            disabled
                            value={country?.info?.english?.currency ?? ""}
                            label={t("Currency in English")} size="small" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                            required
                            name="info.arabic.currency"
                            value={country?.info?.arabic?.currency ?? ""}
                            error={!!validationErrors?.info?.arabic?.currency}
                            helperText={validationErrors?.info?.arabic?.currency ?? ''}
                            onChange={handleChange}
                            label={t("Currency in Arabic")} size="small" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                            required
                            name="isoCode"
                            value={country?.isoCode ?? ""}
                            disabled
                            label={t("Country ISO Code")} size="small" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                            required
                            name="isoCode3"
                            value={country?.isoCode3 ?? ""}
                            disabled
                            label={t("Country ISO Code 3")} size="small" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                            required
                            name="phoneCode"
                            value={country?.phoneCode ?? ""}
                            dir="ltr"
                            disabled
                            label={t("Phone Code")} size="small" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            inputProps={{ maxLength: 255 }}
                            fullWidth
                            required
                            name="timezone"
                            value={country?.timezone ?? ""}
                            disabled
                            label={t("Timezone")} size="small" variant="outlined" />
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                    }}
                    onClick={onCloseDialogHandler}>
                    {t("Close")}
                </Button>
                <Box mr="auto" />
                <Box>
                    {(isSuccess || activeToggleIsSuccess) && <Typography color="success.main">{t("Done successfully")}</Typography>}
                    {!!error && <Typography color="error">{t(error)}</Typography>}
                    {!!activeToggleError && <Typography color="error">{t(activeToggleError)}</Typography>}
                    {!!helperTextError && <Typography color="error">{t(helperTextError)}</Typography>}
                </Box>
                <Box mr="auto" />
                <LoadingButton
                    disabled={Boolean(isSuccess)}
                    variant="contained"
                    disableElevation
                    onClick={handleSubmit}
                    loading={loading}
                    sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                    }}
                >
                    {isEditing ? t("Edit") : t("Add")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
