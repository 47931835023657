import { Box, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

export default function ActivationStatus({ color, text }) {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircleIcon color={color} sx={{ mr: 0.5, fontSize: 18 }} />
            {!!text && <Typography variant="caption">{text}</Typography>}
        </Box>
    )
}