import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function WhyEzPayBox({ item }) {

    const { t } = useTranslation()

    return (
        <Box display="flex" flexDirection="column" p={10} alignItems="center" height="100%">
            <img style={{ objectFit: 'contain', height: "90px", width: "90px" }} src={item?.icon} alt={t(item?.title)} />
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 8, mb: 5 }}>{t(item?.title)}</Typography>
            <Typography variant="h6" sx={{ textAlign: "center", lineHeight: '1.7rem' }}>{t(item?.description)}</Typography>
        </Box>
    )
}