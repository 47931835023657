
import { Navigate } from "react-router-dom";
import { Login } from "modules/auth";
import { Register, MerchantLogin, ForgotPassword, ResetPassword, PhoneVerifcationCode, ChangePhone } from "modules/merchant";
import { ROLES } from "constants";
import { LandingLayout } from "modules/landing";

const checkRole = (isAuth, role) => {
    if (isAuth) {
        if (role === ROLES.admin) {
            return <Navigate to="/admin" replace />
        } else if (role === ROLES.merchant) {
            return <Navigate to="/merchant" replace />
        }
    }
    return <LandingLayout />
}

export const AuthRoutes = ({ isAuth, role }) => [
    {
        path: "auth",
        element: checkRole(isAuth, role),
        children: [
            { path: "admin/login", element: <Login /> },
            { path: "login", element: <MerchantLogin /> },
            { path: "register", element: <Register /> },
            { path: "forgot-password", element: <ForgotPassword /> },
            { path: "reset-password", element: <ResetPassword /> },
            { path: "phone-verify", element: <PhoneVerifcationCode /> },
            { path: "change-phone", element: <ChangePhone /> },
        ]
    }
]