import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { generateImageLink } from "utils";
import { isValidBase64ImageString } from "utils/base64Utils";
import { Delete, Edit } from "@mui/icons-material";
import { Building, DownLoadPDF } from "_assets";

export default function UploadDocument({
  title = "Choose image",
  image,
  onChange,
  error,
  variant,
  imgProps,
  name,
  isImageClickable,
  disabled,
  isLoading,
  onDelete,
  hideDelete = false,
  ...props
}) {
  const { t } = useTranslation();

  const [source, setSource] = useState();


  useEffect(() => {
    if (!image) {
      setSource()
      return
    }
    if (isValidBase64ImageString(image)) {
      setSource(image)
    } else {
      let img = { ...image }
      if (!!image?.thumbnail) {
        img.name = image?.thumbnail
      }
      setSource(generateImageLink(img))
    }
  }, [image])

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onloadend = () => {
      }
      reader.onerror = reject;
      reader.readAsDataURL(file);
    })
  }

  const [fileError, setFileError] = useState(null)
  const fileReadHandler = async (e) => {
    try {
      setFileError(null);
      const file = e.target.files[0];
      if (file) {
        const fileSizeInMB = file.size / 1024 / 1024;
        if (fileSizeInMB > 5) {
          setFileError(t("File size should not exceed 5 MB"));
          return;
        }
        let base64 = await readFileAsync(file)
        onChange({ target: { name, value: base64 } })
      }
    } catch (error) {
      console.log(error)
    } finally { }
  }

  const [openImage, setOpenImage] = useState(false)

  const isPdf = !!image?.name?.includes("pdf")

  const sourceFile = useMemo(() => {
    if (image?.name) {
      return source
    }
    return Building
  }, [image, source])

  console.log("source", source)


  return (
    <>
      <Dialog onClose={() => setOpenImage(false)} open={openImage}>
        <img src={source} style={{ objectFit: 'contain' }} alt={`upload ${name}`} />
      </Dialog>
      <Card {...props}>
        {
          isLoading ?
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={100}>
              <CircularProgress size={30} />
            </Box>
            :
            <Box height="100px" position="relative" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CardMedia
                component="img"
                style={{ height: "100%" }}
                image={sourceFile}
                onError={() => setSource(Building)}
                alt={t(title)}
                sx={{ cursor: (isImageClickable && !!image?.name) ? 'pointer' : '', objectFit: 'contain', ...imgProps }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (isImageClickable && !!image?.name) {
                    if (isPdf) {
                      window.open(generateImageLink(image), "_blank");
                    } else {
                      setOpenImage(true)
                    }
                  }
                }}
              />
              {
                (!!image?.name && !disabled) &&
                <Box position="absolute" top={0} right={0}>
                  {
                    !hideDelete &&
                    <IconButton size="small" onClick={() => onDelete(name)}>
                      <Delete color="error" sx={{ fontSize: "inherit" }} />
                    </IconButton>
                  }
                  <IconButton component="label" size="small" sx={{ fontSize: "inherit" }}>
                    <Edit color="primary" />
                    <input
                      hidden
                      name={name}
                      type="file"
                      accept="image/*, .pdf"
                      onChange={fileReadHandler}
                    />
                  </IconButton>
                </Box>
              }
            </Box>
        }
        {
          (!!error || !!fileError) &&
          <CardContent>
            <Typography color='error' mx={1} variant="caption" sx={{ textAlign: "center" }}>
              {error ?? t(fileError)}
            </Typography>
          </CardContent>
        }
        <Divider />
        <CardActions>
          {
            !!image?.name ?
              <Typography variant="button" color="primary" sx={{ fontWeight: 'bold', textAlign: "center", textTransform: "none", width: "100%", p: 6 }}>
                {t(title)}
              </Typography>
              :
              <Button
                sx={{
                  fontWeight: 'bold',
                  textAlign: "center",
                  textTransform: "none"
                }}
                fullWidth
                component="label"
                disabled={disabled}
              >
                {t(title)}
                <input
                  hidden
                  name={name}
                  type="file"
                  accept="image/*, .pdf"
                  onChange={fileReadHandler}
                />
              </Button>
          }
        </CardActions>
      </Card>
    </>
  );
};