import { Box, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import { useGetInvoiceByIdQuery, useLazyGetInvoiceExcelQuery, useSettleMerchantBalanceMutation } from "api/invoiceApi";
import { useParams } from "react-router-dom";
import InvoicePaymentInfo from "./InvoicePaymentInfo";
import { useTranslation } from "react-i18next";
import MerchantInfo from "./MerchantInfo";
import { ConfirmationDialog, DownloadExcleDialog } from "modules/common";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import CompletedTransactionsTable from "./CompletedTransactionsTable";
import { ROLES } from "constants";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { useSelector } from "react-redux";
import { ADMIN_TYPES } from "constants";

export default function AdminInvoiceDetails({ role }) {
    const { t } = useTranslation();
    const { profile } = useSelector(state => state?.auth);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [openExcelDialog, setOpenExcelDialog] = useState(false)
    const { invoiceId } = useParams()
    const [page, setPage] = useState(1)
    const { data: invoice, isLoading: invoiceLoading, isFetching: invoiceFetching, refetch } = useGetInvoiceByIdQuery({ invoiceId, params: { page, limit: 100 } })
    const [settleBalance, { isLoading: settleBalanceLoading, isSucess: settleBalanceIsSuccess, error: settleBalanceError }] = useSettleMerchantBalanceMutation()
    const [getInvoiceExcel,
        { isFetching: invoiceExcelLoading, isSuccess: invoiceExcelIsSuccess, error: invoiceExcelError }
    ] = useLazyGetInvoiceExcelQuery()

    const settleBalanceHandler = async () => {
        setOpenConfirmDialog(false)
        await settleBalance(invoice?.user?.id ?? invoice?.user)//Check again
        refetch()
    }

    const invoiceExcelHandler = async (payload) => {
        if (!!payload) {
            await getInvoiceExcel({ invoiceId: invoice?.id, payload })
        } else {
            await getInvoiceExcel({ invoiceId: invoice?.id })
        }
        // refetch()
    }

    const pageChangeHandler = ({ page }) => {
        if (!invoiceLoading && !invoiceFetching) {
            setPage(page)
        }
    }

    const closeExcelDialogHanlder = () => {
        setOpenExcelDialog(false)
    }

    return (
        <>
            <Helmet>
                <title>{t("Invoice Details")} | {$APP_NAME}</title>
            </Helmet>
            <Box sx={{ my: 30, mr: 5 }}>
                <DownloadExcleDialog
                    open={openExcelDialog}
                    loading={invoiceExcelLoading}
                    isSuccess={invoiceExcelIsSuccess}
                    error={invoiceExcelError}
                    onSend={invoiceExcelHandler}
                    onClose={closeExcelDialogHanlder}
                />
                <ConfirmationDialog
                    title="Warning"
                    open={openConfirmDialog}
                    confirmFn={settleBalanceHandler}
                    onClose={() => setOpenConfirmDialog(false)}
                    content={"Are you sure you want to settle the balance?"}
                />
                <Grid container justifyContent="center" spacing={30}>
                    <Grid item xl={9} lg={9} md={10} sm={12} xs={12}>
                        <Typography variant="h5" sx={{ mb: 20, textAlign: "center" }}>
                            {t("The Invoice")}
                        </Typography>
                        <Paper>
                            <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>{invoice?.invoiceNumber}</Typography>
                            {/********************Invoice status**********************/}
                            <Divider>
                                <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Invoice status")}</Typography>
                            </Divider>
                            <Box display="flex" justifyContent="center" alignItems="center" pt={20}>
                                {!!invoice?.type &&
                                    <Chip
                                        label={t(`invocieStatus.${invoice?.type}`)}
                                        sx={{ color: "white" }}
                                        color={invoice?.type === "SETTLED" ? "success" : "warning"}
                                    />
                                }
                            </Box>
                            <Box p={20}>
                                {/********************Merchant Info**********************/}
                                <Divider>
                                    <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Merchant Info")}</Typography>
                                </Divider>
                                <MerchantInfo invoice={invoice} />
                                {/********************Payment Info**********************/}
                                <Divider>
                                    <Typography sx={{ fontWeight: "bold", color: "lightGrey.main" }}>{t("Payment Info")}</Typography>
                                </Divider>
                                <InvoicePaymentInfo invoice={invoice} />
                            </Box>
                            <Divider />
                            <Box display="flex" flexDirection="row-reverse" justifyContent="space-between" alignItems="center" p={10}>
                                <Box display="flex" alignItems="center">
                                    {
                                        (role === ROLES.admin && profile.type !== ADMIN_TYPES.accountManager && ["PENDING", "CURRENT"].includes(invoice?.type) && invoice?.depositType === "MANUAL")
                                            ?
                                            <LoadingButton
                                                disabled={!invoice?.totalAmount}
                                                sx={{ textTransform: "none", mr: 10 }}
                                                variant="contained"
                                                onClick={() => setOpenConfirmDialog(true)}
                                                loading={settleBalanceLoading || invoiceExcelLoading}
                                            >
                                                {t("Settled Balance")}
                                            </LoadingButton>
                                            : null
                                    }
                                    <LoadingButton
                                        sx={{ textTransform: "none" }}
                                        variant="contained"
                                        onClick={() => setOpenExcelDialog(true)}
                                        loading={settleBalanceLoading || invoiceExcelLoading}
                                    >
                                        {t("Download Balance Report")}
                                    </LoadingButton>
                                </Box>
                                {!!settleBalanceError && <Typography color="error">{t(settleBalanceError)}</Typography>}
                                {!!settleBalanceIsSuccess && <Typography color="success.main">{t("Updated successfully")}</Typography>}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h5" sx={{ mb: 20, textAlign: "center" }}>
                            {t("Transactions")}
                        </Typography>
                        <CompletedTransactionsTable
                            role={role}
                            transactions={invoice?.completedTransactions}
                            loading={invoiceLoading || invoiceFetching}
                            page={page}
                            pageChangeHandler={pageChangeHandler}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}