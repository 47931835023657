import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import Joi from "joi";
import { LoadingButton } from "@mui/lab";
import { isEmpty } from "lodash";

import { useSendInvoiceByEmailMutation } from "api/email";
import runJoiValidate from "utils/runJoiValidate";
import i18n from "i18n";

const EMAIL_SCHEMA = Joi.object({
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required("required")
        .label(i18n.t("Email")).messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
            'string.email': `${i18n.t("validationErrors.isEmail")}`,
        }),
});


const SendInvoiceByEmail = ({ invoiceId }) => {

    const { t } = useTranslation();
    const [sendInvoiceByEmail, { isLoading, error, isSuccess }] = useSendInvoiceByEmailMutation()

    const [email, setEmail] = useState("");
    const [validationErrors, setValidationErrors] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const errors = validate();
        if (errors?.email) return
        try {
            await sendInvoiceByEmail({ email, transactionId: invoiceId });
        } catch (error) {
            console.log(error);
        }
    }

    const validate = useCallback(_ => {
        const errors = runJoiValidate(EMAIL_SCHEMA, { email });
        setValidationErrors(errors);
        return errors
    }, [email])

    useEffect(_ => {
        if (!isEmpty(email) && !isEmpty(validationErrors)) {
            validate();
        }
    }, [email, validationErrors, validate])

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
                width: "90%",
                maxWidth: "329px",
                padding: 20,
                border: '1px solid',
                borderColor: 'secondary.light',
                borderRadius: 5
            }}>
            {
                isSuccess
                    ?
                    <Typography sx={{ fontWeight: "500", fontSize: "14px", textAlign: "center" }}>
                        {t("payment.emailSentTo", { email })}
                    </Typography>
                    :
                    <>
                        <Typography sx={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
                            {t("payment.emailMessage")}
                        </Typography>
                        <TextField
                            size="small"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("Email")}
                            name="email"
                            autoComplete="email"
                            style={{ fontWeight: "500", fontSize: "14px", marginTop: 20 }}
                            error={!!validationErrors?.email}
                            helperText={validationErrors?.email}
                        />
                        <LoadingButton
                            type="submit"
                            loading={isLoading}
                            fullWidth
                            variant="outlined"
                            loadingIndicator={<CircularProgress color="primary" size={18} />}
                            sx={{ mt: 16, fontWeight: "500", fontSize: "16px", textTransform: 'none', backgroundColor: "primary.main", color: "secondary.main", borderRadius: 2, height: 44 }}
                        >
                            {!isLoading ? t("payment.sendInvoice") : ""}
                        </LoadingButton>
                        <Typography sx={{ fontWeight: "500", fontSize: "14px", color: 'danger', textAlign: "center" }}>
                            {error}
                        </Typography>
                    </>
            }
        </Box>
    )
}

export default SendInvoiceByEmail;