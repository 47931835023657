import { apiSlice } from "./apiSlice";

export const banksAPI = apiSlice.injectEndpoints({
    keepUnusedDataFor: 1,
    endpoints: builder => ({
        getBanks: builder.query({
            query: (params) => ({
                url: `/bank`,
                method: 'GET',
                params
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        getBankDetails: builder.query({
            query: (id) => ({
                url: `/bank/${id}`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        updateBankDetails: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/bank/${id}`,
                method: 'PUT',
                body: { ...payload } //TODO: Confirm country with backend
            }),
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        createBank: builder.mutation({
            query: (payload) => ({
                url: `/bank`,
                method: 'POST',
                body: { ...payload } //TODO: Confirm country with backend
            }),
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
        importBanks: builder.mutation({
            query: () => ({
                url: `/bank/import-my-fatoorah`,
                method: 'POST',
            }),
            transformResponse: (response, meta, arg) => response?.body,
            transformErrorResponse: (response, meta, arg) => response?.data?.message ?? "Something went wrong please try again",
        }),
    })
})

export const {
    useGetBanksQuery,
    useLazyGetBanksQuery,
    useGetBankDetailsQuery,
    useLazyGetBankDetailsQuery,
    useUpdateBankDetailsMutation,
    useImportBanksMutation,
    useCreateBankMutation,
} = banksAPI