import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function HighlightBox({ item }) {

    const { t } = useTranslation()

    return (
        <Paper
            elevation={0}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: 'center',
                p: 20,
                minHeight: 200,
                height: "100%",
                borderWidth: 0,
                transition: `all .3s linear`,
                borderRadius: '8px',
                '&:hover': {
                    transform: `translate(0, -15px)`,
                    boxShadow: `0 40px 40px 0 rgb(14 17 51 / 10%)`,
                },
            }}
        >
            <img src={item?.icon} style={{ height: "100px", width: "100px", objectFit: 'contain' }} alt={t(item?.title)} />
            <Typography color="primary" variant="body1" sx={{ fontWeight: "bold", mt: 15 }}>{t(item?.title)}</Typography>
        </Paper>
    )
}