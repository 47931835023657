import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { get } from 'lodash';
import { PropTypes } from "prop-types";
import { generateImageLink } from 'utils';
import moment from "moment";

const PostHeaderView = ({
    id,
    image,
    title,
    createdAt,
    ...rest
}) => {

    return (
        <Box
            {...rest}
            sx={{
                backgroundColor: "white",
                ...get(rest, 'sx'),
            }}
        >
            <Grid container>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    lineHeight: 1.3,
                                    fontSize: { xs: "25px", md: "40px" },
                                    fontWeight: 'bold',
                                    mb: 3
                                }}
                            >
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    lineHeight: 1.5,
                                    fontSize: { xs: "16px", md: "20px" },
                                    flexGrow: 1,
                                    mb: 3
                                }}
                            >
                                {createdAt && moment(createdAt).format("DD MMMM YYYY")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <img
                                    src={`${generateImageLink(image, "large")}`}
                                    srcSet={`${generateImageLink(image)}`}
                                    loading="lazy"
                                    style={{ width: '100%', objectFit: "cover" }}
                                    alt="post header"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

PostHeaderView.propTypes = {
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
};

export default PostHeaderView;