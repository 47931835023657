import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, useMediaQuery } from '@mui/material'
import { useLazyGetUsersByAdminQuery } from 'api/adminApi';
import { pick } from 'lodash';
import { DataTable, MerchantName } from 'modules/common'
import moment from 'moment';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const $COLUMN_WIDTH = 200
export default function SubUsersDialog({ open, onClose, parentId }) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

    let columns = [
        { field: "userName", renderCell: ({ row }) => <MerchantName creator={pick(row, ['userName', 'parent', 'id'])} />, sortable: false, headerName: t("Name"), width: $COLUMN_WIDTH * 1.1 },
        { field: "email", sortable: false, headerName: t("Email"), width: $COLUMN_WIDTH * 1.2, },
        { field: "phone", sortable: false, headerName: t("Phone"), width: $COLUMN_WIDTH * 1 },
        // { field: "currentStatus", renderCell: UserStatus, sortable: false, headerName: t("Status"), width: $COLUMN_WIDTH * 0.9 },
        { field: "createdFrom", valueGetter: ({ value }) => t(value ?? ''), sortable: false, headerName: t("Account Source"), width: $COLUMN_WIDTH * 0.8 },
        { field: "createdAt", valueGetter: ({ value }) => moment(value).format("DD/MM/YY - h:mm A"), sortable: false, headerName: t("Created At"), width: $COLUMN_WIDTH * 0.8 },
    ];

    const [getUsers, { data: users, isLoading: usersLoading }] = useLazyGetUsersByAdminQuery()

    useEffect(() => {
        if (!!parentId) {
            getUsers({ limit: 100, user: parentId })
        }
    }, [parentId])


    return (
        <Dialog
            maxWidth="lg"
            fullScreen={fullScreen}
            fullWidth
            open={open}
            onClose={(e) => e.preventDefault()}
        >
            <DialogTitle>{t("Sub accounts")}</DialogTitle>
            <Divider />
            <DialogContent sx={{ height: "80vh" }}>
                <DataTable
                    rows={users?.docs ?? []}
                    columns={columns}
                    onPaginationModelChange={() => { }}
                    paginationModel={{ page: 1, pageSize: 100 }}
                    rowCount={users?.totalDocs ?? 0}
                    loading={usersLoading}
                    onRowClick={row => navigate(`/admin/user/${row?.id}`)}
                />
            </DialogContent>
            <DialogActions>
                <Box mr="auto" />
                <Button variant="contained" onClick={onClose} sx={{ mr: 5, textTransform: "none" }}>
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
